import {
  Grid,
  IconButton,
  Box,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  FormControl,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { cardContainer } from '../../../styles/globalStyles'
import ActionPage from '../../../ui-components/ActionPage'
import { green } from '@mui/material/colors'
import { AppProfileEntity, AppProfileAttribute, TableRelationShips, TableRelationInput } from '../../../businessObjects'
import { DataTableRow } from '../../../ui-components/DataTable/DataTableTypes'
import ShadowTable from '../../../ui-components/ShadowTable/ShadowTable'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../../Routes/constants'
import { useGetDataPodInfo } from '../../../hooks/datapodHooks'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { AddOutlined, CancelOutlined } from '@mui/icons-material'
import {
  useAddTableRelationship,
  useGetEntityRelationships,
  useGetTableRelationships,
  useUpdateTableRelationship,
} from '../../../hooks/applicationHooks'
import { LucidPopUp } from '../../../ui-components/LucidPopUp/LucidPopUp'
import { useAddNewDataTable } from '../../../hooks/dataTableHooks'

type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
}

export const DiscoverRelationships = () => {
  const { dataPodId } = useParams<ViewResultsParams>()
  const [searchText, setSearchText] = useState<string>()
  const [openRelationPopUp, setOpenRelationPopup] = useState<boolean>(false)
  const [editRelationId, setEditRelationId] = useState<number>()
  // missing tableColumnname, tableNames in tablerelationInput for add and update
  const [tableRelation, setTableRelation] = useState<TableRelationInput>()

  const navigate = useNavigate()
  const { dataPodInfo } = useGetDataPodInfo(dataPodId)
  const { tableRelationShips, refreshTableRelations } = useGetTableRelationships(dataPodId, searchText)
  const { addNewRelationShip } = useAddTableRelationship(dataPodId)

  const { updateTableRelationShip } = useUpdateTableRelationship(dataPodId)

  const onClickHome = () => {
    const dataProfilingRoute = generatePath(ROUTES.Overview, { dataPodId: dataPodId })
    navigate(dataProfilingRoute)
  }

  const onClickDataModel = () => {
    const enterpriseDataModels = generatePath(ROUTES.DataModelingEnterpriseModels, { dataPodId: dataPodId })
    navigate(enterpriseDataModels)
  }

  const onClickEntityRelationships = () => {
    const entityRelationships = generatePath(ROUTES.DataModelingEntityRelationships, { dataPodId: dataPodId })
    navigate(entityRelationships)
  }

  const handleRelationInp = (name: string, value: string | number): void => {
    setTableRelation(
      (prv) =>
        ({
          ...prv,
          [name]: value,
        } as TableRelationInput),
    )
  }

  const saveNewRelation = async () => {
    if (tableRelation) {
      const resp = await addNewRelationShip(tableRelation)
      if (resp?.id) {
        refreshTableRelations()
        setTableRelation(undefined)
        setOpenRelationPopup(false)
      }
    }
  }

  const closeAddPopUp = () => {
    setOpenRelationPopup(false)
    setTableRelation(undefined)
  }

  return (
    <ActionPage>
      <Grid item container spacing={2}>
        <Grid item xs={2}>
          <IconButton onClick={onClickHome} disableFocusRipple>
            <ArrowBackIcon />
            <Typography>Back</Typography>
          </IconButton>
        </Grid>
      </Grid>
      <Box style={cardContainer}>
        {/* <Grid container spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={4} alignItems={'center'}>
                  <Grid item container xs={15} spacing={1}>
                    <Grid item xs={6}>
                      <Typography component="span" variant="subtitle1" sx={{ fontSize: 14 }}>
                        Data Pod Name
                      </Typography>
                    </Grid>
                    <Grid item />

                    <Grid item xs={5}>
                      <Typography component="span" variant="subtitle1" sx={{ fontSize: 14 }}>
                        Data pod ID
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={15} />

                    <Grid item container xs={15} spacing={1}>
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          id="outlined-select-dataSource"
                          variant="outlined"
                          value={dataPodInfo?.name}
                          required
                          size="small"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={1} />
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          id="outlined-select-dataSource"
                          variant="outlined"
                          value={dataPodInfo?.id}
                          required
                          size="small"
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12} />
          </Grid>
        </Grid> */}
        <Grid item container spacing={2}>
          <Grid item container alignContent={'baseline'} alignItems={'center'} spacing={1}>
            <Grid item xs={0.5}>
              <IconButton onClick={onClickHome}>
                <HomeOutlinedIcon />
              </IconButton>
            </Grid>
            <Grid item xs={3}>
              <TextField
                placeholder="Search"
                value={searchText ?? ''}
                onChange={({ target }) => setSearchText(target.value)}
                margin="dense"
                size="small"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: !!searchText ? (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearchText(undefined)}>
                        <CancelOutlined fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <></>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={8} container alignContent={'baseline'} spacing={1}>
              <Grid item xs={4}>
                <Button variant="contained" fullWidth onClick={onClickEntityRelationships}>
                  Entity Relationships
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button variant="contained" fullWidth onClick={onClickDataModel}>
                  Show Data Model
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  fullWidth
                  startIcon={<AddOutlined />}
                  onClick={() => setOpenRelationPopup(true)}
                >
                  New Relationships
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12} />
          <LucidPopUp
            headingText={<Typography variant="h4">Add Relationship</Typography>}
            openPopUp={openRelationPopUp}
            onConfirm={saveNewRelation}
            closePopup={closeAddPopUp}
            confirmText={'Save'}
            midButtonText="Validate"
            solidMidButton
            solidCancelButton
            midButtonAction={() => {
              return
            }}
            cancelText="Cancel"
          >
            <Grid container spacing={2}>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography variant="h5">Left</Typography>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="outlined-select-dataSource"
                      variant="outlined"
                      value={tableRelation?.leftTableName ?? ''}
                      required
                      onChange={({ target }) => handleRelationInp('leftTableName', target.value)}
                      label="TableName"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="outlined-select-dataSource"
                      variant="outlined"
                      value={tableRelation?.leftColumnName ?? ''}
                      onChange={({ target }) => handleRelationInp('leftColumnName', target.value)}
                      required
                      size="small"
                      label="columnName"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography variant="h5">Right</Typography>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="outlined-select-dataSource"
                      variant="outlined"
                      value={tableRelation?.rightTableName ?? ''}
                      onChange={({ target }) => handleRelationInp('rightTableName', target.value)}
                      required
                      size="small"
                      label="TableName"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="outlined-select-dataSource"
                      variant="outlined"
                      value={tableRelation?.rightColumnName ?? ''}
                      onChange={({ target }) => handleRelationInp('rightColumnName', target.value)}
                      required
                      size="small"
                      label="columnName"
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="outlined-select-dataSource"
                      variant="outlined"
                      value={tableRelation?.cardinality ?? ''}
                      onChange={({ target }) => handleRelationInp('cardinality', target.value)}
                      required
                      size="small"
                      label="cardinality"
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Validation"
                      id="outlined-select-dataSource"
                      variant="outlined"
                      value={tableRelation?.validationResult ?? ''}
                      onChange={({ target }) => handleRelationInp('validationResult', target.value)}
                      size="small"
                      required
                      rows={3}
                      multiline
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </LucidPopUp>

          <Grid item xs={12} container>
            <DataModelingTable tableRelations={tableRelationShips} />
          </Grid>
        </Grid>
      </Box>
      <Grid item container>
        <Grid item xs={9} />
        <Grid item xs={3}></Grid>
      </Grid>
    </ActionPage>
  )
}

type DataModelingTableProps = {
  tableRelations: TableRelationShips[]
}

const DataModelingTable = ({ tableRelations }: DataModelingTableProps) => {
  const [selectedTableIDs, setSelectedTableID] = useState<number[]>([])

  //const [leftTable, setLeftTable] = useState<DataTableRow[]>([])
  const [rightTable, setRightTable] = useState<DataTableRow[]>([])

  const rightTableColumns = useMemo(() => {
    return [
      {
        label: 'Left Table',
        sortableColumn: true,
      },
      {
        label: 'Right Table',
        sortableColumn: true,
      },
      {
        label: 'Left Column',
        sortableColumn: true,
      },
      {
        label: 'Right Column',
        sortableColumn: true,
      },
      {
        label: 'Cardinality',
      },
      {
        label: 'Validation Status',
      },
      {
        label: 'User Confirmation',
      },
      {
        label: 'Edit',
      },
    ]
  }, [])
  const leftTableColumns = useMemo(() => {
    return [{ label: 'List Of Tables' }]
  }, [])

  const getTableColumns = (id: number) => {
    const tbID = Number(id)
    const tbIds = selectedTableIDs.includes(tbID)
      ? selectedTableIDs.filter((tid) => tid != tbID)
      : [...selectedTableIDs, tbID]

    setSelectedTableID(tbIds)
  }
  useEffect(() => {
    if (tableRelations.length) {
      const rtRows = tableRelations
        .sort((t1, t2) => (t1.leftTableName > t2.leftTableName ? 1 : -1))
        .map((dt) => ({
          id: `${dt.id}`,
          values: [
            dt.leftTableName,
            dt.rightTableName,
            dt.leftColumnname,
            dt.rightColumnname,
            dt.cardinality,
            'SUCCESS',
            'SUCCESS',
            'Edit',
          ],
        })) as DataTableRow[]
      setRightTable(rtRows)
    }
  }, [tableRelations])

  const updatedRightTable = useMemo(() => {
    if (!selectedTableIDs.length) {
      return rightTable
    }
    return rightTable.filter((rt) => selectedTableIDs.includes(Number(rt.id)))
  }, [rightTable, selectedTableIDs])

  return (
    <Grid item container xs={16} md={12} spacing={2}>
      <Grid item xs={2.5}>
        <Card sx={{ backgroundColor: 'white' }}>
          <CardHeader
            disableTypography
            sx={{ backgroundColor: 'grey' }}
            title={<Typography sx={{ fontSize: 15, align: 'right' }}>{'List Of Tables'}</Typography>}
          />
          <Divider />
          <List sx={{ width: '100%', maxWidth: 360, marginTop: -2 }} dense>
            {tableRelations.map((p, ix) => (
              <ListItem key={ix}>
                <ListItemButton onClick={() => getTableColumns(p.id)}>
                  <ListItemIcon>
                    <Checkbox
                      sx={{
                        color: 'green',
                        '&.Mui-checked': {
                          color: green[600],
                        },
                      }}
                      edge="start"
                      tabIndex={-1}
                      checked={selectedTableIDs.includes(Number(p.id))}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={p.leftTableName} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Card>
      </Grid>

      <Grid item container xs={9}>
        <ShadowTable rows={updatedRightTable} columns={rightTableColumns} />
      </Grid>
    </Grid>
  )
}

type EntityRelationShipsTableProps = {
  dataPodID?: string
}
const EntityRelationShipsTable = ({ dataPodID }: EntityRelationShipsTableProps) => {
  const [tableRows, setTableRows] = useState<DataTableRow[]>([])
  const { entityRelationShips } = useGetEntityRelationships(dataPodID)
  const navigate = useNavigate()
  const columnLabels = useMemo(() => {
    return [
      {
        label: 'Left Entity',
      },
      {
        label: 'Left Attribute ',
      },
      {
        label: 'Right Entity',
      },
      {
        label: 'Right Attribute',
      },
    ]
  }, [])

  useEffect(() => {
    if (entityRelationShips.length) {
      const tRows = entityRelationShips.map((er) => ({
        id: String(er.id),
        values: [er.leftEntityName, er.leftAttributeName, er.rightEntityName, er.rightAttributeName] as string[],
      })) as DataTableRow[]
      setTableRows(tRows)
    }
  }, [entityRelationShips])

  const onClick = () => {
    if (dataPodID) {
      const routePath = generatePath(ROUTES.DataModelingEntityRelationships, { dataPodId: dataPodID })
      navigate(routePath)
    }
  }

  return (
    <Grid container>
      <Grid item>
        <Button fullWidth onClick={onClick} variant="contained">
          Show Data Model
        </Button>
      </Grid>

      <Grid item container xs={9}>
        <ShadowTable rows={tableRows} columns={columnLabels} />
      </Grid>
    </Grid>
  )
}
