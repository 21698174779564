import { useEffect, useState } from 'react'
import { useLucidAuthContext } from '../LucidAuthContext/LucidAuthContext'
import { applicationProfileServices } from '../apiServices/dataprofiling'
import {
  AppProfile,
  AppProfileAttribute,
  AppProfileEntity,
  JobRequestParams,
  JobStatusResp,
  PaginationParams,
} from '../businessObjects'
import { AxiosError } from 'axios'
import { FAILED, JobName, SUCCESS, TERMINATED } from './constants'
import { appInsights } from '../utils/ApplicationInsightsService'

export type JobTriggerResp = {
  jobInpParams: JobRequestParams
  result?: string
  running?: boolean
}

export const useGetProfileInputs = (
  dataPodId?: string,
  subjectAreaID?: number,
  dataSystemID?: number,
  pageParams?: PaginationParams,
  updatePagesCount?: (pages: number) => void,
): { profileInputs: AppProfile[] | undefined; loading: boolean | undefined; error: boolean | undefined } => {
  const [profileInputs, setProfileInputs] = useState<AppProfile[]>()
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()

  const getProfile = async () => {
    setLoading(true)
    try {
      const token = await getBearerToken()
      if (dataPodId && token) {
        const { data, status, headers } = await applicationProfileServices.getProfileInp(
          token,
          dataPodId,
          subjectAreaID,
          dataSystemID,
          pageParams?.recordsPerPage,
          pageParams?.pageNumber,
        )
        if (status === 200) {
          setLoading(false)
          setProfileInputs(data)
          if (pageParams?.recordsPerPage && updatePagesCount) {
            const totalRecords = headers['x-total-count']
            const totalPages = Math.round(totalRecords / pageParams?.recordsPerPage)
            updatePagesCount(totalPages)
          }
        }
      }
    } catch (error) {
      const err = error as AxiosError
      //appInsights.trackException({ error: err })
      console.log(err.message)
      setLoading(false)
      setError(true)
    }
  }
  useEffect(() => {
    getProfile()
  }, [dataPodId, subjectAreaID, dataSystemID, pageParams?.pageNumber, pageParams?.recordsPerPage])

  return { profileInputs, loading, error }
}

export const useGetProfileEntity = (
  dataPodId?: string,
  dataSystemID?: number,
  subjectAreaID?: number,
  pageParams?: PaginationParams,
  searchText?: string,
): {
  profileEntities: AppProfileEntity[]
  refetchEntities?: () => void
  loading: boolean | undefined
  error: boolean | undefined
} => {
  const [profileEntities, setProfileEntities] = useState<AppProfileEntity[]>([])
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()

  const getProfileEntities = async () => {
    setLoading(true)
    // try {
    const token = await getBearerToken()
    if (dataPodId && token) {
      const { data, status } = await applicationProfileServices.getProfileEntityOp(
        token,
        dataPodId,
        subjectAreaID,
        dataSystemID,
        pageParams?.pageNumber,
        pageParams?.recordsPerPage,
        searchText,
      )
      if (status === 200) {
        setLoading(false)
        setProfileEntities(data)
      }
    }
    // } catch (error) {
    //   const err = error as AxiosError
    //   console.log(err.message)
    //   setLoading(false)
    //   setError(true)
    // }
  }
  useEffect(() => {
    getProfileEntities()
  }, [dataPodId, subjectAreaID, dataSystemID, pageParams?.pageNumber, pageParams?.recordsPerPage, searchText])

  return { profileEntities, refetchEntities: getProfileEntities, loading, error }
}

export const useGetProfileEntitites = (
  dataPodId?: string,
  dataSystemID?: number,
  subjectAreaID?: number,
  pageParams?: PaginationParams,
  searchText?: string,
): { profileEntities: AppProfileEntity[] | undefined; refetchEntities: () => void } => {
  const [profileEntities, setProfileEntities] = useState<AppProfileEntity[]>()

  const { getBearerToken } = useLucidAuthContext()

  const getProfileEntities = async () => {
    setProfileEntities(undefined)
    try {
      const token = await getBearerToken()
      if (dataPodId && token && dataSystemID) {
        const { data, status } = await applicationProfileServices.getProfileEntityOp(
          token,
          dataPodId,
          subjectAreaID,
          dataSystemID,
          pageParams?.recordsPerPage,
          pageParams?.pageNumber,
          searchText,
        )
        if (status === 200) {
          setProfileEntities(data)
        }
      }
    } catch (error) {
      const err = error as AxiosError
      console.log(err.message)
    }
  }
  useEffect(() => {
    if (dataPodId) {
      getProfileEntities()
    }
  }, [dataPodId, subjectAreaID, dataSystemID, pageParams?.pageNumber, pageParams?.recordsPerPage, searchText])

  return { profileEntities, refetchEntities: getProfileEntities }
}

export const useGetProfileAttributes = (
  dataPodId?: string,
  dataSystemID?: number,
  subjectAreaID?: number,

  dataTableID?: number,
  dataEntityID?: number,
  pageParams?: PaginationParams,
): {
  profileAttributes: AppProfileAttribute[] | undefined
  loading: boolean | undefined
  error: boolean | undefined
} => {
  const [profileAttributes, setProfileAttributes] = useState<AppProfileAttribute[]>()
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()

  const getProfileAttributes = async () => {
    setLoading(true)
    try {
      const token = await getBearerToken()
      if (dataPodId && token) {
        const { data, status } = await applicationProfileServices.getProfileAttributes(
          token,
          dataPodId,
          subjectAreaID,
          dataSystemID,
          dataTableID,
          dataEntityID,
          undefined,
          pageParams?.pageNumber,
          pageParams?.recordsPerPage,
        )
        if (status === 200) {
          setLoading(false)
          setProfileAttributes(data)
        }
      }
    } catch (error) {
      const err = error as AxiosError
      console.log(err.message)
      setLoading(false)
      setError(true)
    }
  }
  useEffect(() => {
    getProfileAttributes()
  }, [
    dataPodId,
    subjectAreaID,
    dataSystemID,
    pageParams?.pageNumber,
    pageParams?.recordsPerPage,
    dataEntityID,
    dataTableID,
  ])

  return { profileAttributes, loading, error }
}
export const useGetProfileAttributesByEntityId = (
  dataPodId?: string,
  dataSystemID?: number,
  dataEntityID?: number,
  searchText?: string,
  pageParams?: PaginationParams,
): { profileAttributes: AppProfileAttribute[]; refetch: () => void } => {
  const [profileAttributes, setProfileAttributes] = useState<AppProfileAttribute[]>([])

  const { getBearerToken } = useLucidAuthContext()

  const getProfileAttributes = async () => {
    const token = await getBearerToken()
    if (dataPodId && token && dataEntityID) {
      const { data, status } = await applicationProfileServices.getProfileAttributes(
        token,
        dataPodId,
        undefined,
        dataSystemID,
        undefined,
        dataEntityID,
        searchText,
        pageParams?.pageNumber,
        pageParams?.recordsPerPage,
      )
      if (status === 200) {
        setProfileAttributes(data)
      }
    }
  }
  useEffect(() => {
    getProfileAttributes()
  }, [dataPodId, dataSystemID, pageParams?.pageNumber, pageParams?.recordsPerPage, dataEntityID, searchText])

  return { profileAttributes, refetch: getProfileAttributes }
}

export const useGetProfileAttibuteOutput = (
  dataPodId?: string,
  dataSystemID?: number,
  dataEntityID?: number,
  tableId?: number,
  searchText?: string,
  pageParams?: PaginationParams,
): { profileAttributes: AppProfileAttribute[] | undefined; refetch: () => void } => {
  const [profileAttributes, setProfileAttributes] = useState<AppProfileAttribute[]>()

  const { getBearerToken } = useLucidAuthContext()

  const getProfileAttributes = async () => {
    const token = await getBearerToken()
    if (token && dataPodId && dataEntityID && dataSystemID && tableId) {
      const { data, status } = await applicationProfileServices.getProfileAttributes(
        token,
        dataPodId,
        undefined,
        dataSystemID,
        tableId,
        dataEntityID,
        searchText,
        pageParams?.recordsPerPage ?? 1000,
        pageParams?.pageNumber,
      )
      if (status === 200) {
        setProfileAttributes(data)
      }
    }
  }
  useEffect(() => {
    getProfileAttributes()
  }, [dataPodId, dataSystemID, pageParams?.pageNumber, pageParams?.recordsPerPage, dataEntityID, searchText])

  return { profileAttributes, refetch: getProfileAttributes }
}

export const useRegisterDataPod = (dataPodId?: string): string | undefined => {
  const [registeredPod, setRegisteredPod] = useState<JobStatusResp>()
  const [registering, setRegistering] = useState<boolean>(false)
  const { getBearerToken } = useLucidAuthContext()
  const registerDataPod = async () => {
    const token = await getBearerToken()
    if (dataPodId && token) {
      try {
        const { data, status } = await applicationProfileServices.runBackendJob(token, 'RegisterNewDataPod', {
          dataPodId: dataPodId,
        })
        if (status == 200) {
          setRegisteredPod(data)
          setRegistering(true)
        }
      } catch (error) {
        const err = error as AxiosError
        //appInsights.trackException({ error: err })
        return
      }
    }
  }
  // const registrationStatus = async () => {
  //   const token = await getBearerToken()
  //   if (token && dataPodId) {
  //     try {
  //       const { data, status } = await applicationProfileServices.getJobStatus(token, 'RegisterNewDataPod', {
  //         dataPodId,
  //       })
  //       if (status == 200) {
  //         setRegisteredPod(data)
  //       }
  //     } catch (error) {
  //       const err = error as AxiosError
  //       //appInsights.trackException({ error: err })
  //       return
  //     }
  //   }
  // }
  useEffect(() => {
    registerDataPod()
  }, [dataPodId])
  const jobStatus = registeredPod?.jobStatus
  // useEffect(() => {
  //   if (!(jobStatus === SUCCESS || jobStatus === TERMINATED || jobStatus === FAILED)) {
  //     let timerId = setInterval(registrationStatus, 10000)
  //     return () => clearInterval(timerId)
  //   }
  // }, [registeredPod])
  return !(jobStatus === SUCCESS || jobStatus === TERMINATED || jobStatus === FAILED) ? undefined : jobStatus
}

export const useRegisterDataSource = (dataPodId?: string, dataSystemId?: number): string | undefined => {
  const [registeredDataSource, setRegisteredDataSource] = useState<JobStatusResp>()
  const [submitted, setSubmitted] = useState<boolean>(false)
  const { getBearerToken } = useLucidAuthContext()
  const submitDataSource = async () => {
    const token = await getBearerToken()
    if (dataPodId && token && dataSystemId) {
      try {
        const { data, status } = await applicationProfileServices.runBackendJob(token, 'RegisterNewDataSource', {
          dataPodId: dataPodId,
          dataSystemId: dataSystemId,
        })
        if (status == 200) {
          setRegisteredDataSource(data)
          setSubmitted(true)
        }
      } catch (error) {
        return
      }
    }
  }
  const getStatus = async () => {
    const token = await getBearerToken()
    if (dataPodId && token && dataSystemId) {
      try {
        const { data, status } = await applicationProfileServices.getJobStatus(token, 'RegisterNewDataSource', {
          dataPodId,
          dataSystemId,
        })
        if (status == 200) {
          setRegisteredDataSource(data)
        }
      } catch (error) {
        const err = error as AxiosError
        //appInsights.trackException({ error: err })
        return
      }
    }
  }
  useEffect(() => {
    submitDataSource()
  }, [dataPodId])

  const jobStatus = registeredDataSource?.jobStatus
  useEffect(() => {
    if (!(jobStatus === SUCCESS || jobStatus === FAILED || jobStatus === TERMINATED)) {
      let timerId = setInterval(getStatus, 10000)

      return () => clearInterval(timerId)
    }
  })
  return !(jobStatus === SUCCESS || jobStatus === TERMINATED || jobStatus === FAILED) ? undefined : jobStatus
}

export const useGetJobStatus = (
  runJob?: JobName,
  dataPodID?: string,
): { getJobStatus: (params: JobRequestParams) => Promise<void>; jobStatus: JobTriggerResp } => {
  // const [triggerResp, setTriggerResp] = useState<JobStatusResp>()
  const [validResp, setValidResp] = useState<JobTriggerResp>({ jobInpParams: {} })
  const { getBearerToken } = useLucidAuthContext()
  const getStatus = async (triggerJobParams: JobRequestParams) => {
    const token = await getBearerToken()
    const { dataPodId } = triggerJobParams
    const dPodId = !dataPodId ? dataPodID : dataPodId
    if (token && runJob && dPodId) {
      try {
        const { data, status } = await applicationProfileServices.getJobStatus(token, runJob, {
          ...triggerJobParams,
          dataPodId: dPodId,
        })
        if (status == 200) {
          const { jobStatus } = data as JobStatusResp

          setValidResp({
            jobInpParams: { ...triggerJobParams, dataPodId: dPodId },
            result: jobStatus,
          })
        }
      } catch (error) {
        setValidResp({
          jobInpParams: { ...triggerJobParams, dataPodId: dPodId },
          result: 'error',
        })

        return
      }
    }
  }

  return { getJobStatus: getStatus, jobStatus: validResp }
}

export const useGetJobStatusForIds = (
  runJob?: JobName,
  dataPodId?: string,
): { getJobStatus: (params: JobRequestParams[]) => void; jobStatus: JobTriggerResp[] } => {
  const [triggerList, setTriggerList] = useState<JobRequestParams[]>([])
  const [triggerJobParams, setTriggerJobParams] = useState<JobRequestParams>()
  const [triggerResp, setTriggerResp] = useState<JobStatusResp>()
  const [validResp, setValidResp] = useState<JobTriggerResp[]>([])
  const { getBearerToken } = useLucidAuthContext()
  const getStatus = async (param: JobRequestParams) => {
    const token = await getBearerToken()
    if (token && runJob && dataPodId) {
      try {
        const { data, status } = await applicationProfileServices.getJobStatus(token, runJob, {
          ...param,
          dataPodId,
        })
        if (status == 200) {
          setTriggerResp(data)
          const { jobStatus } = data as JobStatusResp
        }
      } catch (error) {
        const { status, message, request, response, code } = error as AxiosError

        // appInsights.trackException({
        //   properties: {
        //     status,
        //     message,
        //     request,
        //     response,
        //     code,
        //   },
        // })
        return
      }
    }
  }
  const runTheTrigger = () => {
    triggerList.forEach((p) => getStatus(p))
  }

  const jobStatus = triggerResp?.jobStatus
  useEffect(() => {
    if (!(jobStatus === SUCCESS || jobStatus === FAILED || jobStatus === TERMINATED)) {
      let timerId = setInterval(runTheTrigger, 10000)

      return () => clearInterval(timerId)
    }
  })
  return { getJobStatus: setTriggerList, jobStatus: validResp }
}

export const useRunJob = (
  jobName?: JobName,
  dataPodId?: string,
): [(params: JobRequestParams) => Promise<void>, JobTriggerResp | undefined] => {
  const [postJobResp, setPostJobResp] = useState<JobTriggerResp>({ jobInpParams: {} })
  const { getBearerToken } = useLucidAuthContext()

  const triggerJob = async (params: JobRequestParams) => {
    setPostJobResp({ ...postJobResp, running: true })
    const token = await getBearerToken()
    try {
      const podId = !params.dataPodId ? dataPodId : params.dataPodId
      if (podId && jobName && token) {
        const { data, status } = await applicationProfileServices.runBackendJob(token, jobName, {
          ...params,
          dataPodId: podId,
        })
        const { jobStatus } = data as JobStatusResp
        if (status == 200) {
          setPostJobResp({
            jobInpParams: params,
            result: jobStatus,
            running: false,
          })
        }
      }
    } catch (error) {
      setPostJobResp({
        jobInpParams: params,
        result: 'error',
      })
    }
  }

  return [triggerJob, postJobResp]
}

export const useGetProfileAttributesTablesByEntityId = (
  dataPodId?: string,
  dataSystemID?: number,
  searchText?: string,
  pageParams?: PaginationParams,
): {
  getProfileAttributes: (dataEntityID?: number, tableID?: number) => Promise<AppProfileAttribute[] | undefined>
} => {
  const { getBearerToken } = useLucidAuthContext()

  const getProfileAttributes = async (dataEntityID?: number, tableID?: number) => {
    try {
      const token = await getBearerToken()
      if (dataPodId && token && (dataEntityID || tableID)) {
        const { data, status } = await applicationProfileServices.getProfileAttributes(
          token,
          dataPodId,
          undefined,
          dataSystemID,
          tableID,
          dataEntityID,
          searchText,
          pageParams?.pageNumber,
          pageParams?.recordsPerPage,
        )
        if (status == 200) {
          return data as AppProfileAttribute[]
        }
      }
    } catch (error) {
      console.log({ error })
    }
  }

  return { getProfileAttributes }
}
