import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

type Props = {
  title: string
  description?: string
  isOpen: boolean
  onClick: (id?: any) => void
}

export default function LucidConfirmDialog({ actionId, title = '', description = '', isOpen = false, onClick }: any) {
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])
  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = () => onClick(actionId)

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        {description && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose}>CANCEL</Button>
          <Button onClick={handleConfirm} autoFocus>
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
