import axios, { AxiosRequestConfig } from 'axios'
import { Industry } from '../businessObjects'
import { environment } from '../utils/environment'
import { getAxiosRequestConfig } from './apiserviceHelpers'
import { sendRequest } from '../requestManager/sendRequest'

const industryPath = {
  createIndustry: 'Industry/Add',
  getIndustries: 'Industry/Get',
  getIndustryByName: 'Industry/GetOne',
  getIndustryById: 'Industry/',
}

const createIndustry = async (token: string, industryName: string) => {
  const industryInp = {
    industryName: industryName,
  }
  return sendRequest(industryPath.createIndustry, 'POST', token, industryInp)
}

const getAllIndustries = async (token: string, industryName?: string) => {
  const industryQueryString = industryName ? `?industryName=${industryName}` : undefined
  if (!industryQueryString) {
    return sendRequest(industryPath.getIndustries, 'GET', token)
  }
  return sendRequest(industryPath.getIndustries  + industryQueryString, 'GET', token)
}

const getIndustryById = async (token: string, industryId: number) => {
  return sendRequest(industryPath.getIndustryById + industryId, 'GET', token)
}

export const industryServices = {
  createIndustry,
  getAllIndustries,
  getIndustryById
}