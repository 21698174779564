import { Box } from '@mui/material'
import AzureIcon from '../ui-icons/devicon_azure.svg'
import AWSIcon from '../ui-icons/amazon.svg'
import GoogleIcon from '../ui-icons/google-icon-logo.svg'
import MicrosfotIcon from '../ui-icons/microsoft.svg'
import DatabricksIcon from '../ui-icons/Databricks.svg'
// import { DataBricksIcon } from '../ui-icons/Databricks'

type Props = {
  serviceName: string | undefined
}

const ServiceNameWithIcon = ({ serviceName }: Props) => {
  if (!serviceName?.trim()) {
    return <></>
  }
  let serviceNameIcon = ''
  const name = serviceName?.toLowerCase()
  if (name?.includes('azure')) {
    if (name?.includes('databricks')) {
      serviceNameIcon = DatabricksIcon
    } else {
      serviceNameIcon = AzureIcon
    }
  } else if (name?.includes('aws')) {
    if (name?.includes('databricks')) {
      serviceNameIcon = DatabricksIcon
    } else {
      serviceNameIcon = AWSIcon
    }
  } else if (name?.includes('google') || name?.includes('gcp')) {
    if (name?.includes('databricks')) {
      serviceNameIcon = DatabricksIcon
    } else {
      serviceNameIcon = GoogleIcon
    }
  } else if (name?.includes('microsoft')) {
    serviceNameIcon = MicrosfotIcon
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {serviceNameIcon && (
        <Box sx={{ mr: 1 }}>
          <img src={serviceNameIcon} alt="Logo" height="20px" width="20px" />
        </Box>
      )}
      {serviceName}
    </Box>
  )
}

export default ServiceNameWithIcon
