import { useEffect, useState } from 'react'
import { DataSystem, DataSystemInput, DataSystemType } from '../businessObjects'
import { useLucidAuthContext } from '../LucidAuthContext/LucidAuthContext'
import { dataSystemServices } from '../apiServices/dataSystem'
import { applicationProfileServices } from '../apiServices/dataprofiling'
import { useRegisterDataSource } from './dataProfilingHooks'
import { AxiosError } from 'axios'

type SubmitNewDataSystem = (dataPodId: string, params: DataSystemInput) => Promise<void>
type DeleteDataSystem = (datapodId: string, datasystemInp: DataSystemInput) => Promise<void>
type GetAllDataSystemByDataPodId = (datapodId: string, recordCount?: number, pageNumber?: number) => Promise<void>
type UpdateDataSystem = (dataSystem: DataSystem, dataPodId: string) => Promise<void>
type deleteDataSystemById = (datasystemID: number) => Promise<void>

export type DataSystemUpdateParams = {
  id: number
  dataSystemName: string
  dataSystemDesc: string
  industryId: number
  subjectAreaId: number
  container: string
  dataSystemType?: number
  serviceConnectionID: number
}

export const useCreateDataSystem = (): [SubmitNewDataSystem, DataSystem | undefined, boolean | undefined] => {
  const [dataSystem, setDataSystem] = useState<DataSystem>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()

  const submitDataSystem = async (datapodId: string, datasystemInp: DataSystemInput) => {
    setLoading(true)
    try {
      const token = await getBearerToken()
      if (token) {
        const { data, status } = await dataSystemServices.createDataSystem(token, datasystemInp, datapodId)
        if (status == 200) {
          //console.log({ resp })
          setDataSystem(data)
          setLoading(false)
        }
      }
    } catch (error) {
      const err = error as AxiosError
      if (!err.isAxiosError && err.response?.status != 404) {
        console.log(err.response?.data)
        setError(true)
      }
      setLoading(false)
    }
  }
  return [submitDataSystem, dataSystem, error]
}
type Pagination = {
  updatePageCount?: (pages: number) => void
}

export const useGetAllDataSystems = ({
  updatePageCount,
}: Pagination): [GetAllDataSystemByDataPodId, DataSystem[] | undefined, boolean | undefined, boolean | undefined] => {
  const [dataSystems, setDataSystems] = useState<DataSystem[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const getDataSystems = async (
    dataPodId: string,
    subjectAreaId?: number,
    recordCount?: number,
    pageNumber?: number,
  ) => {
    setLoading(true)
    try {
      const token = await getBearerToken()
      if (token) {
        const { data, status, headers } = await dataSystemServices.getAllDataSystems(
          token,
          dataPodId,
          subjectAreaId,
          recordCount,
          pageNumber,
        )
        if (status == 200) {
          setDataSystems(data)
          setLoading(false)
          if (updatePageCount && recordCount) {
            const totalRecords = headers['x-total-count']
            const totalPages = Math.round(totalRecords / recordCount)
            updatePageCount(totalPages)
          }
        }
      }
    } catch (error) {
      const err = error as AxiosError
      if (!err.isAxiosError && err.response?.status != 404) {
        console.log(err.response?.data)
        setError(true)
      }
      setLoading(false)
    }
  }

  return [getDataSystems, dataSystems, loading, error]
}

export const useGetDataSystemById = (): [
  (dataSystemId: number) => Promise<void>,
  DataSystem[] | undefined,
  boolean | undefined,
  boolean | undefined,
] => {
  const [dataSystems, setDataSystems] = useState<DataSystem[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const getDataSystemID = async (dataSystemID: number) => {
    setLoading(true)
    try {
      const token = await getBearerToken()
      if (token) {
        const { data, status } = await dataSystemServices.getDataSystemById(token, dataSystemID)
        if (status == 200) {
          setDataSystems(data)
          setLoading(false)
        }
      }
    } catch (error) {
      const err = error as AxiosError
      if (!err.isAxiosError && err.response?.status != 404) {
        console.log(err.response?.data)
        setError(true)
      }
      setLoading(false)
    }
  }

  return [getDataSystemID, dataSystems, loading, error]
}

export const useUpdateDataSystem = (): [
  UpdateDataSystem,
  DataSystem | undefined,
  boolean | undefined,
  boolean | undefined,
] => {
  const [dataSystem, setDataSystem] = useState<DataSystem>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const saveDataSystem = async (dataSystem: DataSystem, dataPodId: string) => {
    setLoading(true)
    try {
      const token = await getBearerToken()
      if (token) {
        const dataSystemUp: DataSystemUpdateParams = {
          id: dataSystem.id,
          dataSystemName: dataSystem.dataSystemName,
          industryId: dataSystem.industryId,
          dataSystemDesc: dataSystem.dataSystemDesc,
          subjectAreaId: dataSystem.subjectAreaId,
          container: dataSystem.container,
          dataSystemType: dataSystem.dataSystemType,
          serviceConnectionID: dataSystem.serviceConnectionId,
        }
        const { data, status } = await dataSystemServices.updateDataSystem(token, dataSystemUp, dataPodId)
        if (status == 200) {
          setDataSystem(data)
          setLoading(false)
        }
      }
    } catch (error) {
      const err = error as AxiosError
      if (!err.isAxiosError && err.response?.status != 404) {
        console.log(err.response?.data)
        setError(true)
      }
      setLoading(false)
    }
  }
  return [saveDataSystem, dataSystem, loading, error]
}

export const useDeleteDataSystem = (): [
  deleteDataSystemById,
  boolean | undefined,
  boolean | undefined,
  boolean | undefined,
] => {
  const [deleteSystemStatus, setDeleteDataSystemStatus] = useState<boolean>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const softDeleteDataSystem = async (dataSystemId: number) => {
    setLoading(true)
    try {
      const token = await getBearerToken()
      if (token) {
        const { data, status } = await dataSystemServices.deleteDataSystem(token, dataSystemId)
        if (status == 200) {
          setDeleteDataSystemStatus(true)
          setLoading(false)
        }
      }
    } catch (error) {
      const err = error as AxiosError
      if (!err.isAxiosError && err.response?.status != 404) {
        console.log(err.response?.data)
        setError(true)
      }
      setLoading(false)
    }
  }
  return [softDeleteDataSystem, deleteSystemStatus, loading, error]
}

export const useDeleteDataSystemById = (): { softDeleteDataSystem: (id?: number) => Promise<boolean | undefined> } => {
  const { getBearerToken } = useLucidAuthContext()
  const softDeleteDataSystem = async (dataSystemId?: number) => {
    try {
      const token = await getBearerToken()
      if (token && dataSystemId) {
        const { status } = await dataSystemServices.deleteDataSystem(token, dataSystemId)
        if (status == 204) {
          return true
        }
      }
    } catch (error) {
      const { message } = error as AxiosError
      console.log({ message })
    }
  }
  return { softDeleteDataSystem }
}

export const useGetAllDataSystemsByPodId = (
  { updatePageCount }: Pagination,
  dataPodId?: string,
): [DataSystem[], boolean | undefined, boolean | undefined] => {
  const [dataSystems, setDataSystems] = useState<DataSystem[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const getDataSystems = async (subjectAreaId?: number, recordCount?: number, pageNumber?: number) => {
    setLoading(true)
    try {
      const token = await getBearerToken()
      if (token && dataPodId) {
        const { data, status, headers } = await dataSystemServices.getAllDataSystems(
          token,
          dataPodId,
          subjectAreaId,
          recordCount,
          pageNumber,
        )
        if (status == 200) {
          setDataSystems(data)
          setLoading(false)
          if (updatePageCount && recordCount) {
            const totalRecords = headers['x-total-count']
            const totalPages = Math.round(totalRecords / recordCount)
            updatePageCount(totalPages)
          }
        }
      }
    } catch (error) {
      const err = error as AxiosError
      if (!err.isAxiosError && err.response?.status != 404) {
        console.log(err.response?.data)
        setError(true)
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    getDataSystems()
  }, [dataPodId])

  return [dataSystems, loading, error]
}

export const useGetDataSystemByDataSystemId = (dataSystemId?: number): { dataSystemInfo?: DataSystem } => {
  const [dataSystems, setDataSystems] = useState<DataSystem[]>([])

  const { getBearerToken } = useLucidAuthContext()
  const getDataSystem = async () => {
    try {
      const token = await getBearerToken()
      if (token && dataSystemId) {
        const { data, status } = await dataSystemServices.getDataSystemById(token, dataSystemId)
        if (status == 200) {
          setDataSystems(data)
        }
      }
    } catch (error) {
      const err = error as AxiosError
      if (!err.isAxiosError && err.response?.status != 404) {
        console.log(err.response?.data)
      }
    }
  }
  useEffect(() => {
    if (dataSystemId) {
      getDataSystem()
    }
  }, [dataSystemId])

  return { dataSystemInfo: dataSystems.length ? dataSystems[0] : undefined }
}

//SparkServer - Invoke
export const useSparkPost = (
  dataSystemId?: string,
  dataPodId?: string,
): { sparkApiLoading: boolean; sparkResponse: any; injectDataParamsToSpark: () => void } => {
  const [sparkResponse, setSparkResponse] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)

  const injectDataParamsToSpark = async () => {
    setLoading(true)
    try {
      if (dataSystemId && dataPodId) {
        const { data, status } = await dataSystemServices.injectDataParamsToSpark(dataSystemId, dataPodId)
        if (status == 200) {
          setSparkResponse(data)
          setLoading(false)
        }
        console.log('data, status ==> ', data, status)
      }
    } catch (error) {
      console.log('Exception at submitAtSpark ==> ', error)
      const err = error as AxiosError
      if (!err.isAxiosError && err.response?.status != 404) {
        console.log(err.response?.data)
      }
      setLoading(false)
    }
  }

  return { sparkApiLoading: loading, sparkResponse, injectDataParamsToSpark }
}
export const useGetDataSystemTypes = (): {
  allDataSystemTypes: DataSystemType[]
} => {
  const [dataSytemType, setDataSystemType] = useState<DataSystemType[]>([])
  const { getBearerToken, accountId } = useLucidAuthContext()
  const getSystemTypes = async (): Promise<void> => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && accountId) {
        const { data, status, headers } = await dataSystemServices.getDataSystemType(bearerToken)

        if (status == 200) {
          setDataSystemType(data)
        }
      }
    } catch (error) {
      const err = error as AxiosError
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (!dataSytemType.length) {
      getSystemTypes()
    }
  }, [])

  return { allDataSystemTypes: dataSytemType }
}
