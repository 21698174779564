import { generatePath, useNavigate, useParams } from "react-router-dom"
import ResultStatus from "../../../ui-components/ResultStatus"
import { useEffect, useMemo, useState } from "react"
import ActionPage from "../../../ui-components/ActionPage"

import { Button, Card, Grid, IconButton, Link, Menu, MenuItem, TextField, Typography } from "@mui/material"
import { HomeOutlined, Insights } from "@mui/icons-material"
import { ROUTES } from "../../../Routes/constants"
import { LucidPopUp } from "../../../ui-components/LucidPopUp/LucidPopUp"
import { useGetEntity } from "../../../hooks/entityHooks"
import { useGetDataAttributesByEntityId } from "../../../hooks/dataAttrbutesHooks"
import { useAddDataQualityNB, useAddDataQualityRule, useGetActiveRules, useGetDataQualityCode, useGetDataQualityNB, useGetDataQualityRuleById, useUpdateDataQualityNB, useUpdateDataQualityRule } from "../../../hooks/dataQualityHooks"
import { CreateJobParams, CreateJobResponse, DataQualityCodeRequest, DataQualityNBRequest, DataQualityRule, JobClusterConfig, RuleSubCategory, RunStatusResponse } from "../../../businessObjects"
import { LucidJupyter } from "../../../ui-components/JupyterComponents/LucidJupyter"
import constants from "../../constants"
import { LoadingButton } from "@mui/lab"
import { usePostCreateDatabricksJob } from "../../../hooks/databricksHooks"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { filterActiveRunIds, initiateActiveRunId } from "../../../features/notification/notificationSlice"
import { useGetServiceConnection } from "../../../hooks/dataEngineeringHooks"
import { RootState } from "../../../app/store"

type DataQualityPageParams = {
  dataPodId: string
  dataSystemID: string
  entityID: string
  tableID: string
  dataQualtiyID?: string
}

type FormErrors = {
  ruleName?: string
  ruleCategory?: string
  ruleSubCategory?: string
  entity?: string
  attributes?: string
}

export const DataQuality = () => {
  const { dataPodId, entityID, dataQualtiyID } = useParams<DataQualityPageParams>()

  const currentDataQualityId = dataQualtiyID ? Number(dataQualtiyID) : undefined

  const [errorMessage, setErrorMessage] = useState<string>()
  const [errorState, setErrorState] = useState<boolean>(false)

  const [showPopUp, setShowPopUp] = useState<boolean>(false)
  const [popupPromiseResolver, setPopupPromiseResolver] = useState<((value: boolean) => void) | null>(null)

  const [commitMessage, setCommitMessage] = useState<string>(constants.initialCommitMessage)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isEntityMenuOpen, setIsEntityMenuOpen] = useState<boolean>(false)
  const [userInputEntity, setUserInputEntity] = useState<string>('')
  const [isAttrMenuOpen, setIsAttrMenuOpen] = useState<boolean>(false)
  const [userInputAttr, setUserInputAttr] = useState<string>('')
  const [notebookStr, setNotebookStr] = useState<string>()
  const [userInputRuleSubCategory, setUserInputRuleSubCategory] = useState<string>('')

  const { activeRules, isActiveRulesloading, isActiveRulesError } = useGetActiveRules()
  const { dataQualityRule, isDataQualityRuleLoading, isDataQualityRuleError } = useGetDataQualityRuleById(Number(dataQualtiyID))
  const { dataQualityNB, isGetDataQualityNBError, fetchDataQualityNB } = useGetDataQualityNB(dataPodId, Number(dataQualtiyID))

  const { isAddRuleSuccess, isAddRuleError, addDataQualityRule } = useAddDataQualityRule()
  const { isUpdateRuleSuccess, isUpdateRuleError, updateDataQualityRule } = useUpdateDataQualityRule()

  const { isAddDataQualityNBSuccess, isAddDataQualityNBError, addDataQualityNB } = useAddDataQualityNB()
  const { isUpdateDataQualityNBSuccess, isUpdateDataQualityNBError, updateDataQualityNB } = useUpdateDataQualityNB()

  const { postCreateDatabricksJob } = usePostCreateDatabricksJob()
  const { getConnections } = useGetServiceConnection()

  const {
    dataQualityCodeResponse,
    dataQualityCodeLoading,
    dataQualityCodeError,
    fetchDataQualityCode,
   } = useGetDataQualityCode()

  const [entities] = useGetEntity({}, dataPodId, undefined, undefined)
  const { attributeProfiles, refetchAttributes } = useGetDataAttributesByEntityId(Number(entityID))
  const [suggestSubRules, setSuggestSubRules] = useState<RuleSubCategory[]>([])
  const [isEditMode, setIsEditMode] = useState<boolean>(false)

  const [formState, setFormState] = useState<DataQualityRule>({
    id: currentDataQualityId || 0,
    name: '',
    ruleCategoryId: 0,
    ruleSubCategoryId: 0,
    entityId: Number(entityID),
    attributeIds: [],
    targetAttribute: '',
    promptText: '',
  })
  const [formErrors, setFormErrors] = useState<FormErrors>({})

  const activeRunIds = useAppSelector((state: RootState) => state.notification.activeRunIds)
  const [jobClusterParams, setJobClusterParams] = useState<JobClusterConfig | null>(null)

  const dispatch = useAppDispatch()

  //Type: {1: 'Transformation1', 2: 'Transformation2'}
  const ruleCategory : {[key: number] : string} = useMemo(() => {
    const result : {[key: number] : string} = {}

    if(!activeRules) {
      return result
    }

    Object.keys(activeRules).forEach((rule) => {
      const subRules = activeRules[rule]

      if(!subRules.length) {
        return
      }
      
      result[subRules[0].ruleCategoryId] = rule
    })

    return result;
  }, [activeRules])

  const navigate = useNavigate()

  useEffect(() => {
    if(dataQualityNB) {
      setNotebookStr(dataQualityNB)
    }
  }, [dataQualityNB])

  useEffect(() => {
    if(dataQualityCodeResponse) {
      setNotebookStr(dataQualityCodeResponse)
    }
  }, [dataQualityCodeResponse])

  useEffect(() => {
    if(currentDataQualityId && dataQualityRule) {
      setFormState(dataQualityRule)
      let attrNames = ''
      dataQualityRule?.attributeIds.forEach((attributeId) => {
        const attr = attributeProfiles.find(attr => attr.id == attributeId)

        attrNames = attr ? attrNames.concat(`@${attr.dataAttributeName} `)  : attrNames;
      })

      setUserInputAttr(attrNames)
      setIsEditMode(true)
    }
  },[dataQualityRule, attributeProfiles])

  useEffect(() => {
    if(entities) {
      const defaultEntity = entities.find(ele => ele.id == Number(entityID));

      if(defaultEntity) {
        setUserInputEntity(`@${defaultEntity.dataEntityName}`)
      }
    }
  },[entities, entityID])
  
  useEffect(() => {
    let result: RuleSubCategory[] = []

    if(formState.ruleCategoryId && !(activeRules == undefined))
      result = activeRules[ruleCategory[formState.ruleCategoryId]]

    setSuggestSubRules(result)
  },[formState.ruleCategoryId, activeRules])


  useEffect(() => {
    const fetchJobClusterParams = async () => {
      if (dataPodId) {
        const serviceConnection = await getConnections(dataPodId, undefined, 'Spark');

        if (serviceConnection && serviceConnection.length > 0) {
          const jsonTemplate = serviceConnection[0].serviceConnectionJsonTemplate;
          const parsedJson = JSON.parse(jsonTemplate);
          const params: JobClusterConfig = {
            url: parsedJson.workspace_Url,
            token: parsedJson.PAT_Token,
            clusterKey: parsedJson.cluster_Id,
            sparkVersion: parsedJson.sparkVersion,
            nodeTypeId: parsedJson.nodeTypeId,
          };
          setJobClusterParams(params);
        }
      }
    };

    fetchJobClusterParams();
  }, [])

  const closeErrorMessage = () => {
    setErrorState(false);
    setErrorMessage(undefined);
  }

  const onClickHome = () => {
    const dataAttributePage = generatePath(ROUTES.DataModelingCanonicalModel, {
      dataPodId: dataPodId,
    })
    navigate(dataAttributePage)
  }

  const onSelectRule = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = Number(e.target.value)

    setFormState(prevValue => ({...prevValue, ruleCategoryId: id}))
  }

  const onEntityTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    validateEntityField(value)

    setUserInputEntity(value)

    // setShowColumnList(value.endsWith('@'))
    if (value.endsWith('@')) {
      setAnchorEl(e.currentTarget)
      setIsEntityMenuOpen(true)
    } else {
      setAnchorEl(null)
      setIsEntityMenuOpen(false)
    }
  }

  const handleSelectEntity = (entityName: string, id: number) => {
    const columnStr = userInputEntity.replace(/@$/, `@${entityName}`)

    if(validateEntityField(columnStr)){
      // refetchAttributes(id)
    }

    setUserInputEntity(columnStr)
  }

  const validateEntityField = (value: string) : boolean => {
    let errorMsg = '';

    if (!value) {
      errorMsg = 'This field is required';
    } else if (/[^@]*@[^@]*@/.test(value)) {
      errorMsg = 'Only one entity is allowed.';
    } else if (!/^@[a-zA-Z]+$/.test(value)) {
      errorMsg = 'Input must start with "@" and contain only letters.';
    }

    setFormErrors(prevValue => ({
      ...prevValue,
      entity: errorMsg
    }))

    return errorMsg === '';
  }

  const transformationText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    // setIsEditMode(true)
    setUserInputAttr(value)
    // setShowColumnList(value.endsWith('@'))
    if (value.endsWith('@')) {
      setAnchorEl(e.currentTarget)
      setIsAttrMenuOpen(true)
    } else {
      setAnchorEl(null)
      setIsAttrMenuOpen(false)
    }
    // if (currentAttribeId && !isEditMode) {
    //   setIsEditMode(true)
    // }
  }


  const handleSelectAttributeMention = (columnName: string) => {
    const columnStr = userInputAttr.replace(/@$/, `@${columnName}`).concat(" ")
    setUserInputAttr(columnStr)
    // if (currentAttribeId && !isEditMode) {
    //     setIsEditMode(true)
    // }
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsEntityMenuOpen(false);
    setIsAttrMenuOpen(false);
  };

  const hasFormError = (formField: keyof FormErrors, formErrorsState: FormErrors): boolean => {
    const error = formErrorsState[formField];

    return (error !== undefined && error.length > 0)
  }

  const onClickSave = async() => {
    const attributeId_list = getUserInputAttrIds()

    if(isEditMode) {
      // Wait till user inputs commits message
      if(notebookStr) {
        const isConfirmed = await waitForPopupConfirmation()

        if(!isConfirmed) 
          return
      }

      const response = await updateDataQualityRule({...formState, attributeIds: attributeId_list})
      
      if (response) {
        await saveNotebook(formState.id)
      }
    } else {
      const response = await addDataQualityRule({...formState, attributeIds: attributeId_list, id: 0})

      if(response) {
        setFormState({...response})
        setIsEditMode(true);
        await saveNotebook(response.id)
      }
    }
  }

  const saveNotebook = async (id: number) => {
    await new Promise((resolve) => setTimeout(resolve, 0));
    
    const payload: DataQualityNBRequest = {
      dataPodId: dataPodId || "",
      dataQualityRuleId: id,
      notebookToRun: notebookStr || constants.defaultNotebookString,
      commitMessage: commitMessage
    }

    if(isEditMode && notebookStr) {
      await updateDataQualityNB(payload)
    } else {
      await addDataQualityNB(payload)
    }
  }

  const waitForPopupConfirmation = (): Promise<boolean> => {
    return new Promise((resolve) => {
      setPopupPromiseResolver(() => resolve);
      setShowPopUp(true);
    });
  };

  const handlePopupConfirm = () => {
    if (popupPromiseResolver) {
      popupPromiseResolver(true);
      setPopupPromiseResolver(null);
    }
    setShowPopUp(false);
  };

  const handlePopupCancel = (close: boolean) => {
    if (popupPromiseResolver) {
      popupPromiseResolver(close);
      setPopupPromiseResolver(null);
    }
    setShowPopUp(close);
  };

  const onGenerateCode = async () => {
    const attributeId_list = getUserInputAttrIds()

    const subRule = suggestSubRules.find((rule) => rule.id === formState.ruleSubCategoryId);
    const cleansing_rule = `${ruleCategory[formState.ruleCategoryId]} - ${subRule?.name}:${subRule?.description}`;
    const payload: DataQualityCodeRequest ={
      dataPodId: dataPodId || '',
      entityId: formState.entityId,
      attributeId_list,
      cleansing_rule,
      promptText: formState.promptText,
      targetAttributeName: formState.targetAttribute || '',
      language: "pyspark",
    }

    await fetchDataQualityCode(payload)
  }

  const getUserInputAttrIds = () => {
    const attributeIds: number[] = []
    const attrNames = userInputAttr.split('@').map(attrName => attrName.trim());
    attrNames.forEach(attrName => {
      const attr = attributeProfiles.find(attr => attr.dataAttributeName === attrName)
      if(attr) {
        attributeIds.push(attr.id)
      }
    })

    setFormState(prevValue => ({...prevValue, attributeIds}))

    return attributeIds
  }

  const executeNotebook = async () => {
    if (dataPodId && formState.id && jobClusterParams) {
      const params: CreateJobParams = {
        dataPodId,
        dataQualityRuleId: formState.id,
        jobClusterConfig: jobClusterParams,
      }
      const result = await postCreateDatabricksJob(params)

      if(result) {
        updateRunIdState(result)
      }
      else {
        setErrorMessage("Failed to create Databricks Job");
        setErrorState(true)
      }
    }
  }

  const updateRunIdState = (result: CreateJobResponse) => {
    const activeRunId = activeRunIds.find((element) => element.run_id === result.runId);
  
    if(!activeRunId){
      const newRunId: RunStatusResponse = {
        run_id: result.runId,
        state: {
          life_cycle_state: "INITIATED",
          result_state: 'LOADING',
        },
        params: {
          run_id: result.runId,
          url: result.url,
          token: result.token
        }
      }

      dispatch(filterActiveRunIds());
      dispatch(initiateActiveRunId(newRunId));
    }
  }

  return (
    <>
    {errorMessage && (
      <ResultStatus
        showStatus={errorState}
        alertMessage={errorMessage}
        severtiy="error"
        closeStatus={() => closeErrorMessage()}
      />
    )}
    <ActionPage>
      <Grid item xs={12} container alignItems="center" spacing={2}>
        <Grid>
          <IconButton onClick={onClickHome} size="small" title="Data Mapping Attributes ">
            <HomeOutlined />
          </IconButton>
        </Grid>
        <Grid>
          <Typography variant="h3" color="primary" gutterBottom>
            Data Quality
          </Typography>
        </Grid>
      </Grid>

      <Grid display={'flex'}>
        <Grid container xs={6} sx={{ pl: 2, flex: 2 }}>
          <Grid item xs={12} alignItems={'stretch'}>
            <Grid item xs={10}>
              <Typography variant="h5" color="primary" gutterBottom>
                Rule Name <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="outlined-select-industry"
                color="secondary"
                size="small"
                value={formState.name}
                onChange={({ target }) => setFormState(prevValue => ({...prevValue, name: target.value}))}
                required
              />
            </Grid>
            <br></br>
            <Grid item xs={10}>
              <Typography variant="h5" color="primary" gutterBottom>
                Rule Category <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="outlined-select-ruleCategory"
                color="secondary"
                size="small"
                select
                value={formState.ruleCategoryId || ''}
                onChange={onSelectRule}
                required
              >
                {Object.keys(ruleCategory).length > 0 && Object.keys(ruleCategory).map(id => (
                  <MenuItem key={id} value={Number(id)}>
                    {ruleCategory[Number(id)]}
                  </MenuItem>
                ))}
                </TextField>
            </Grid>
            <br></br>
            <Grid item xs={10}>
              <Typography variant="h5" color="primary" gutterBottom>
                Rule Sub-Category <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="outlined-select-ruleSubCategory"
                color="secondary"
                size="small"
                select
                value={formState.ruleSubCategoryId || ''}
                // error={!isValidEntry(newAttibute.dataAttributeName)}
                onChange={({ target }) => setFormState(prevValue => ({...prevValue, ruleSubCategoryId: Number(target.value)}))}
                required
              >
                {suggestSubRules.length > 0 && suggestSubRules.map((subRule) => (
                  <MenuItem key={subRule.id} value={subRule.id}>
                    {subRule.name}
                  </MenuItem>
                ))}
                </TextField>
            </Grid>
            <br></br>
            <Grid item xs={10}>
              <Typography variant="h5" color="primary" gutterBottom>
                Entities <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="outlined-select-entity"
                color="secondary"
                size="small"
                rows={1}
                disabled={true}
                error={hasFormError('entity', formErrors)}
                helperText={hasFormError('entity', formErrors) ? formErrors.entity : ''}
                multiline
                value={userInputEntity}
                onChange={onEntityTextChange}
                required
              />
              <Menu anchorEl={anchorEl} open={isEntityMenuOpen} onClose={handleMenuClose}>
                {entities
                  .filter((cm) => !userInputEntity.split('@').includes(cm.dataEntityName))
                  ?.map((ea, ix) => (
                    <MenuItem
                      key={ea.id + ix + ea.dataEntityName}
                      onClick={() => {
                        handleSelectEntity(ea.dataEntityName, ea.id)
                        handleMenuClose()
                      }}
                    >
                      {ea.dataEntityName}
                    </MenuItem>
                ))}
              </Menu>
            </Grid>
            <br></br>
            <Grid item xs={10}>
              <Typography variant="h5" color="primary" gutterBottom>
                Attributes <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="outlined-select-attribute"
                color="secondary"
                size="small"
                rows={2}
                helperText={'Type @ to mention'}
                multiline
                value={userInputAttr}
                onChange={transformationText}
                required
              />
              <Menu anchorEl={anchorEl} open={isAttrMenuOpen} onClose={handleMenuClose}>
                {attributeProfiles
                  .filter((cm) => !userInputAttr.split('@').includes(cm.dataAttributeName))
                  ?.map((ea, ix) => (
                    <MenuItem
                      key={ea.id + ix + ea.dataAttributeName}
                      onClick={() => {
                        handleSelectAttributeMention(ea.dataAttributeName)
                        handleMenuClose()
                      }}
                    >
                      {ea.dataAttributeName}
                    </MenuItem>
                ))}
                </Menu>
            </Grid>
            <br></br>
            <Grid item xs={10}>
              <Typography variant="h5" color="primary" gutterBottom>
                Target attribute
              </Typography>
              <TextField
                fullWidth
                id="outlined-text-attribute"
                color="secondary"
                size="small"
                value={formState.targetAttribute}
                onChange={({ target }) => setFormState(prevValue => ({...prevValue, targetAttribute: target.value}))}
                required
              />
            </Grid>
            <br></br>
            <Grid item xs={10} sx={{mb: 2}}>
              <Typography variant="h5" color="primary" gutterBottom>
                <span style={{ color: 'red' }}>*</span> Enter Prompt Text
              </Typography>
              <TextField
                multiline
                rows={5}
                fullWidth
                value={formState.promptText}
                onChange={({ target }) => setFormState(prevValue => ({...prevValue, promptText: target.value}))}
              />
            </Grid>
            <br></br>
            <Grid item xs={10} container spacing={4} sx={{justifyContent: 'space-between'}}>
              <Grid item xs={5}>
                <Button variant="contained" fullWidth onClick={onClickHome}>
                  Cancel
                </Button>
              </Grid>

              <Grid item xs={5}>
                <Button variant="contained" fullWidth onClick={onClickSave}>
                  Save
                </Button>
              </Grid>
          </Grid>
          </Grid>
        </Grid>

        <Grid container xs={10} sx={{ flex: 2 }}>
          <Grid item xs={12} sx={{mb: 2}} alignItems={'stretch'}>
            <Typography variant="h5" color="primary" gutterBottom>
              Code Editor
            </Typography>
            <Card sx={{ width: '100%', height: '90%', maxHeight: "100%", overflowX: "scroll", overflowY: "scroll" }}>
              {/* <AnimatedLoader height="100%" width="100%" loading={dataQualityCodeLoading} /> */}
                <Grid item justifyContent={'end'} display={'flex'} sx={{ mt: 2, mb: 1, mr: 2 }}>
                  <>
                    <LoadingButton
                        loading={dataQualityCodeLoading}
                        variant="contained"
                        color="primary"
                        // fullWidth
                        onClick={onGenerateCode}
                        >
                        Generate code
                    </LoadingButton>
                  </>
                </Grid>
                <Grid item xs={12}>
                  <LucidJupyter
                    executeNotebook={executeNotebook}
                    noteBookString={notebookStr || constants.defaultNotebookString}
                    headingText={constants.codeHeaderText}
                    updateNoteBookString={setNotebookStr}
                    commitMessage={commitMessage}
                    updateCommitMessage={setCommitMessage}
                  />
                </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <LucidPopUp
        showCloseIcon
        closePopup={handlePopupCancel}
        openPopUp={showPopUp}
        onConfirm={handlePopupConfirm}
        headingText=""
        confirmText="Commit"
      >
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <Link href={'https://github.com/Lucid-Data-Hub/gen-datalake-dev'} target="blank">
              Git Hub Repository
            </Link>
          </Grid>
          <Grid item xs={12}>
            <TextField
              sx={{ fontSize: 12 }}
              fullWidth
              id="outlined-basic"
              label="Enter Commit Message"
              variant="outlined"
              color="secondary"
              required
              rows={2}
              multiline
              value={commitMessage}
              onChange={({ target }) => setCommitMessage(target.value)}
            />
          </Grid>
        </Grid>
      </LucidPopUp>
    </ActionPage>
    </>
  )
}