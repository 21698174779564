import {
  Grid,
  IconButton,
  Box,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { cardContainer } from '../../../styles/globalStyles'
import ActionPage from '../../../ui-components/ActionPage'
import { green } from '@mui/material/colors'
import {
  AppProfileEntity,
  AppProfileAttribute,
  RelationShipResponse,
  TableRelationShips,
  EntityReconData,
  NewEntityRelationShip,
  EntityRelationship,
  Entity,
} from '../../../businessObjects'
import { DataTableRow } from '../../../ui-components/DataTable/DataTableTypes'
import ShadowTable, { ShadowTableAction } from '../../../ui-components/ShadowTable/ShadowTable'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../../Routes/constants'
import { useGetDataPodInfo } from '../../../hooks/datapodHooks'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  useAddEntityRelationship,
  useDeleteEntityRelation,
  useGetEntityRelationships,
  useUpdateEntityRelationship,
} from '../../../hooks/applicationHooks'
import { AddOutlined, Cancel } from '@mui/icons-material'
import { useGetDataAttributesByEntityId, useGetDataReconcilationByEntityId } from '../../../hooks/dataAttrbutesHooks'
import { LucidPopUp } from '../../../ui-components/LucidPopUp/LucidPopUp'
import { useGetEntityByDataPodId } from '../../../hooks/entityHooks'
import { DeletePopUp } from '../DataMapping/DataMappingPopUps'
import { ProgressBar } from '../../../ui-components/ProgressBar'
import ResultStatus from '../../../ui-components/ResultStatus'
import { useGetGenAiRelations } from '../../../hooks/genAIApiHooks'
import AnimatedLoader from '../../../ui-components/AnimatedLoader'

type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
}

export const EntityRelationships = (props: Props) => {
  const { dataPodId } = useParams<ViewResultsParams>()
  //const [tableView, setTableView] = useState<boolean>(true)
  const [openRelationPopUp, setOpenRelationPopup] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>()
  const [newEntityRelation, setNewEntityRelation] = useState<NewEntityRelationShip>()
  const { entityRelationShips, refreshEntityRelations } = useGetEntityRelationships(dataPodId, searchText)
  const [showButton, setShowButton] = useState<boolean>(true)
  const [generatingData, setGeneratingData] = useState<boolean>(false)
  const { fetchRelations } = useGetGenAiRelations()
  const { reconData } = useGetDataReconcilationByEntityId(dataPodId)
  const { addEntityRelation } = useAddEntityRelationship(dataPodId)
  const [allEntities] = useGetEntityByDataPodId(dataPodId)
  const { attributeProfiles: leftAttributesData } = useGetDataAttributesByEntityId(newEntityRelation?.dataCoreEntityId)
  const { attributeProfiles: rightAttributesData } = useGetDataAttributesByEntityId(
    newEntityRelation?.dataSystemEntityId,
  )
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false)
  const [selectedCoreEntityId, setSelectedCoreEntityId] = useState<number>()
  const [selectedCoreEntityName, setSelectedCoreEntityName] = useState<string>()

  const navigate = useNavigate()

  useEffect(() => {
    // if (entityRelationShips.length) {
    //   console.log("it has the data now")
    //   setShowButton(true)
    //   setGeneratingData(false)
    // } else {
    //   console.log("there is no entity relation data")
    //   setShowButton(false)
    // }
    if (selectedCoreEntityId) {
      setShowButton(false)
      setGeneratingData(false)
    } else {
      setShowButton(true)
    }
  }, [selectedCoreEntityId, entityRelationShips])
  const { dataPodInfo } = useGetDataPodInfo(dataPodId)
  // const onClickHome = () => {
  //   const dataProfilingRoute = generatePath(ROUTES.Overview, { dataPodId: dataPodId })
  //   navigate(dataProfilingRoute)
  // }

  const onClickDiscoverRelationships = () => {
    const discoverRelationships = generatePath(ROUTES.Overview, { dataPodId: dataPodId })
    navigate(discoverRelationships)
  }
  const onClickDataModel = () => {
    const enterpriseDataModels = generatePath(ROUTES.DataModelingEnterpriseModels, { dataPodId: dataPodId })
    navigate(enterpriseDataModels)
  }

  const closeAddPopUp = () => {
    setOpenRelationPopup(false)
  }

  const saveNewEntityRelation = async () => {
    if (newEntityRelation) {
      const enRelation = await addEntityRelation({
        ...newEntityRelation,
        similarityScoreByData: 1,
        similarityScoreByName: 1,
      })
      if (enRelation) {
        refreshEntityRelations()
      }
      setOpenRelationPopup(false)
      setUpdateSuccess(true)
    }
  }
  const handleEntityRelationInp = (name: string, value: unknown) => {
    setNewEntityRelation(
      (prv) =>
        ({
          ...prv,
          [name]: value,
        } as NewEntityRelationShip),
    )
  }

  const leftAttributes = useMemo(() => {
    if (!leftAttributesData.length) return []
    return leftAttributesData
  }, [leftAttributesData])

  const rightAttributes = useMemo(() => {
    if (!leftAttributesData.length) return []
    return leftAttributesData
  }, [rightAttributesData])

  const entitiesInfo = useMemo(() => {
    if (!allEntities.length) return []
    return allEntities
  }, [allEntities])

  const handleGenerateRelations = async () => {
    if (dataPodId && selectedCoreEntityId) {
      setGeneratingData(true)
      const genAIRelations = await fetchRelations(dataPodId, selectedCoreEntityId)
      if (genAIRelations) {
        for (const obj of genAIRelations?.data) {
          const newRelationBody = {
            dataCoreEntityId: obj.dataCoreEntityId,
            dataSystemEntityId: obj.dataSystemEntityId,
            joinCoreAttributeId: obj.joinCoreAttributeId,
            joinSystemAttributeId: obj.joinSystemAttributeId,
            similarityScoreByName: obj.similarityScoreByName,
            similarityScoreByData: obj.similarityScoreByData,
            runId: obj.run_id,
            profileName: obj.profileName,
            cardinality: obj.cardinality,
          }
          const response = await addEntityRelation(newRelationBody)
          if (response?.id) {
            // console.log("it was success")
          }
        }
        refreshEntityRelations()
      }
    }
  }
  const handleSelectedEntityRelationUpdate = (updatedRelation: any) => {
    // console.log("Inside handle selected Entity Relation")
    console.log({ updatedRelation })
    setSelectedCoreEntityId(updatedRelation)
    console.log(allEntities)
    console.log({ reconData })
    const ent = reconData.find((d) => d.dataCoreEntityId === updatedRelation)?.parent
    console.log({ ent })
    setSelectedCoreEntityName(reconData.find((d) => d.dataCoreEntityId === updatedRelation)?.parent)
  }
  return (
    <ActionPage>
      <LucidPopUp
        headingText={<Typography variant="h4">Add New Relationship</Typography>}
        openPopUp={openRelationPopUp}
        onConfirm={saveNewEntityRelation}
        closePopup={closeAddPopUp}
        confirmText={'Save'}
        solidCancelButton
        cancelText="Cancel"
      >
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="h5">Core Entity Name :</Typography>
          </Grid>
          <Grid item xs={6} textAlign={'center'}>
            <Typography variant="h6">{selectedCoreEntityName}</Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="h5">Left</Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-select-dataSource"
                  variant="outlined"
                  value={newEntityRelation?.dataCoreEntityId ?? ''}
                  required
                  onChange={({ target }) => handleEntityRelationInp('dataCoreEntityId', target.value)}
                  label="Left Entity"
                  size="small"
                  select
                >
                  {entitiesInfo.map((en) => (
                    <MenuItem key={en.id} value={en.id}>
                      {en.dataEntityName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-select-dataSource"
                  variant="outlined"
                  value={newEntityRelation?.joinCoreAttributeId ?? 0}
                  onChange={({ target }) => handleEntityRelationInp('joinCoreAttributeId', target.value)}
                  required
                  size="small"
                  label="left Attribute"
                  select
                >
                  {leftAttributes.map((ra) => (
                    <MenuItem key={ra.id} value={ra.id}>
                      {ra.dataAttributeName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="h5">Right</Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-select-dataSource"
                  variant="outlined"
                  value={newEntityRelation?.dataSystemEntityId ?? 0}
                  onChange={({ target }) => handleEntityRelationInp('dataSystemEntityId', target.value)}
                  required
                  size="small"
                  label="Right Entity"
                  select
                >
                  {entitiesInfo.map((en) => (
                    <MenuItem key={en.id} value={en.id}>
                      {en.dataEntityName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-select-dataSource"
                  variant="outlined"
                  value={newEntityRelation?.joinSystemAttributeId ?? 0}
                  onChange={({ target }) => handleEntityRelationInp('joinSystemAttributeId', target.value)}
                  required
                  size="small"
                  label="Right Attribute"
                  select
                >
                  {rightAttributes.map((ra) => (
                    <MenuItem key={ra.id} value={ra.id}>
                      {ra.dataAttributeName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-select-dataSource"
                  variant="outlined"
                  value={newEntityRelation?.cardinality ?? ''}
                  onChange={({ target }) => handleEntityRelationInp('cardinality', target.value)}
                  required
                  size="small"
                  label="cardinality"
                  select
                >
                  <MenuItem></MenuItem>
                  {['one-to-many', 'one-to-one', 'many-to-one'].map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            {/* <Grid item container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Validation"
                  id="outlined-select-dataSource"
                  variant="outlined"
                  value={newEntityRelation?. ?? ''}
                  onChange={({ target }) => handleEntityRelationInp('joinSystemAttributeId', target.value)}
                  size="small"
                  required
                />
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
      </LucidPopUp>
      <Grid item container spacing={2}>
        <Grid item xs={2}>
          <IconButton onClick={onClickDiscoverRelationships}>
            <ArrowBackIcon />
            <Typography>Back</Typography>
          </IconButton>
        </Grid>
      </Grid>
      <Box style={cardContainer}>
        {/* <Grid container spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={4} alignItems={'center'}>
                  <Grid item container xs={15} spacing={1}>
                    <Grid item xs={6}>
                      <Typography component="span" variant="subtitle1" sx={{ fontSize: 14 }}>
                        Data Pod Name
                      </Typography>
                    </Grid>
                    <Grid item />

                    <Grid item xs={5}>
                      <Typography component="span" variant="subtitle1" sx={{ fontSize: 14 }}>
                        Data pod ID
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={15} />

                    <Grid item container xs={15} spacing={1}>
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          id="outlined-select-dataSource"
                          variant="outlined"
                          value={dataPodInfo?.name}
                          required
                          size="small"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={1} />
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          id="outlined-select-dataSource"
                          variant="outlined"
                          value={dataPodInfo?.id}
                          required
                          size="small"
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12} />
          </Grid>
        </Grid> */}
        <Grid item container spacing={2}>
          <Grid item container alignItems={'center'} spacing={2}>
            {/* <Grid item xs={1}>
              <IconButton onClick={onClickHome}>
                <HomeOutlinedIcon />
              </IconButton>
            </Grid> */}
            <Grid item xs={3}>
              <TextField
                placeholder="Search"
                //value={searchText}
                //onChange={({ target }) => handleSearch(target.value)}
                color="primary"
                margin="dense"
                size="small"
                type="search"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: !!searchText ? (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearchText('')}>
                        <Cancel fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <></>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" fullWidth onClick={() => handleGenerateRelations()} disabled={showButton}>
                Generate Relations
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" fullWidth onClick={onClickDataModel}>
                Show Data Model
              </Button>
            </Grid>

            <Grid item xs={3}>
              <Button
                variant="contained"
                fullWidth
                startIcon={<AddOutlined />}
                onClick={() => setOpenRelationPopup(true)}
              >
                New Entity Relationship
              </Button>
            </Grid>
          </Grid>
          <Grid item md={12} />
          <Grid item xs={12} container>
            <EntityRelationshipsTable
              entityRelationShips={entityRelationShips}
              refreshEntityRelations={refreshEntityRelations}
              selectedCoreEntity={selectedCoreEntityId}
              selectedCoreEntityName={selectedCoreEntityName}
              entityReconData={reconData}
              dataPodId={dataPodId}
              allEntities={entitiesInfo}
              generatingData={generatingData}
              onEntityRelationUpdate={handleSelectedEntityRelationUpdate}
            />
          </Grid>
        </Grid>
      </Box>

      <ResultStatus
        severtiy="success"
        showStatus={updateSuccess}
        closeStatus={setUpdateSuccess}
        alertMessage={`SUCCESS: Entity Relationship has been Saved !`}
      />
    </ActionPage>
  )
}

type EntityRelationshipsTableProps = {
  entityRelationShips: RelationShipResponse[]
  selectedCoreEntity: number | undefined
  selectedCoreEntityName: string | undefined
  entityReconData: EntityReconData[]
  dataPodId?: string
  refreshEntityRelations: () => void
  allEntities: Entity[]
  generatingData: boolean
  onEntityRelationUpdate: (updatedRelation: Number | undefined) => void
}

type ParentChild = {
  dataCoreEntityId: number
  parentName: string
  childrenData: {
    dataSystemEntityId: number
    child: string
  }[]
}
export const EntityRelationshipsTable = ({
  entityRelationShips,
  selectedCoreEntity,
  selectedCoreEntityName,
  entityReconData,
  refreshEntityRelations,
  dataPodId,
  allEntities,
  generatingData,
  onEntityRelationUpdate,
}: EntityRelationshipsTableProps) => {
  const [entityRelationData, setEntityRelationData] = useState<RelationShipResponse[]>([])
  //const [selectedTableIDs, setSelectedTableID] = useState<number[]>([])
  const [selectedParent, setSelectedParent] = useState<number>()
  const [tableRows, setTableRows] = useState<DataTableRow[]>([])
  const [tableFilteredRows, setTableFilteredRows] = useState<DataTableRow[]>([])
  const [selectedEntityRelation, setSelectedEntityRelation] = useState<EntityRelationship>()
  const [showEditEntityRelation, setShowEditEntityRelation] = useState<boolean>(false)
  const [processingData, setProcessingData] = useState<boolean>(false)
  const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false)
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false)
  const [deleteDataCoreEntityId, setDeleteDataCoreEntityId] = useState<number[]>()
  const { attributeProfiles: leftAttributesData } = useGetDataAttributesByEntityId(
    selectedEntityRelation?.dataCoreEntityId,
  )
  const { attributeProfiles: rightAttributesData } = useGetDataAttributesByEntityId(
    selectedEntityRelation?.dataSystemEntityId,
  )

  const { updateEntityRelation } = useUpdateEntityRelationship(dataPodId)

  const { confirmDeleteFunc } = useDeleteEntityRelation()

  const reconDataFiltered = useMemo(() => {
    if (entityReconData.length) {
      const reconTemp = entityReconData.filter((rd, ix) => {
        return entityReconData.findIndex((cr) => cr.dataCoreEntityId === rd.dataCoreEntityId) === ix
      })
      const newData = reconTemp.map((cp) => ({
        dataCoreEntityId: cp.dataCoreEntityId,
        parentName: cp.parent,
        childrenData: entityReconData
          .filter((rd) => rd.dataCoreEntityId === cp.dataCoreEntityId)
          .map((cx) => ({ dataSystemEntityId: cx.dataSystemEntityId, child: cx.child })),
      }))
      return newData
    }
    return []
  }, [entityReconData])

  const leftAttributes = useMemo(() => {
    if (!leftAttributesData.length) return []
    return leftAttributesData
  }, [leftAttributesData])

  const rightAttributes = useMemo(() => {
    if (!rightAttributesData.length) return []
    return rightAttributesData
  }, [rightAttributesData])

  const rightTableColumns = useMemo(() => {
    return [
      {
        label: 'Left Entity',
        sortableColumn: true,
      },
      {
        label: 'Left Attribute',
        sortableColumn: true,
      },
      {
        label: 'Right Entity',
        sortableColumn: true,
      },
      {
        label: 'Right Attribute',
        sortableColumn: true,
      },
      {
        label: 'Cardinality',
      },
      {
        label: 'Edit ',
      },
      {
        label: 'Delete ',
      },
    ]
  }, [])

  // const getTableColumns = (id: number) => {
  //   const tbID = Number(id)
  //   const tbIds = selectedTableIDs.includes(tbID)
  //     ? selectedTableIDs.filter((tid) => tid != tbID)
  //     : [...selectedTableIDs, tbID]

  //   setSelectedTableID(tbIds)
  // }
  useEffect(() => {
    if (entityRelationShips.length && !entityRelationData.length && selectedCoreEntity) {
      setEntityRelationData(entityRelationShips)
      const tRows = entityRelationShips.map((er) => ({
        id: String(er.id),
        values: [
          er.leftEntityName,
          er.leftAttributeName,
          er.rightEntityName,
          er.rightAttributeName,
          er.cardinality,
          'EDIT',
          'DELETE',
        ] as string[],
      })) as DataTableRow[]
      setTableRows(tRows)
      setTableFilteredRows(tRows)
    }
  }, [entityRelationShips, selectedCoreEntity, entityRelationShips.length])

  const onSelectChange = (id: string) => {
    const selectedParentId = Number(id)
    setSelectedParent(selectedParentId)
    onEntityRelationUpdate(selectedParentId)
    const foundChild = reconDataFiltered.find((d) => d.dataCoreEntityId === selectedParentId)?.childrenData
    const filteredRows = entityRelationData.filter((ed) =>
      foundChild?.some((fc) => fc.dataSystemEntityId === ed.leftEntityId || fc.dataSystemEntityId === ed.rightEntityId),
    )
    const tRows = filteredRows.map((er) => ({
      id: String(er.id),
      values: [
        er.leftEntityName,
        er.leftAttributeName,
        er.rightEntityName,
        er.rightAttributeName,
        er.cardinality,
        'EDIT',
        'DELETE',
      ] as string[],
    })) as DataTableRow[]
    setTableFilteredRows(tRows)
  }

  const updateEntityRelationFn = async () => {
    if (selectedEntityRelation && dataPodId) {
      const updatedEntity = await updateEntityRelation({
        ...selectedEntityRelation,
        datapodId: dataPodId,
      })
      if (updatedEntity) {
        refreshEntityRelations()
        closeEntityRelation()
      }
    }
  }

  const closeEntityRelation = () => {
    setSelectedEntityRelation(undefined)
    setShowEditEntityRelation(false)
  }

  const handleEditEntityRelation = (name: string, value: unknown) => {
    setSelectedEntityRelation(
      (prv) =>
        ({
          ...prv,
          [name]: value,
        } as EntityRelationship),
    )
  }
  const onButtonClick = (rowId: string, actionType: ShadowTableAction) => {
    const selEntity = entityRelationData.find((ed) => ed.id === Number(rowId))
    if (actionType == ShadowTableAction.Editable && selEntity) {
      setSelectedEntityRelation({
        id: selEntity.id,
        cardinality: selEntity.cardinality,
        dataCoreEntityId: selEntity.leftEntityId,
        dataSystemEntityId: selEntity.rightEntityId,
        datapodId: selEntity.dataPodId,
        similarityScoreByData: 1,
        similarityScoreByName: 1,
        joinCoreAttributeId: selEntity.leftAttributeId,
        joinSystemAttributeId: selEntity.rightAttributeId,
        runId: 0,
        profileName: 'manual-updated',
      })
      setShowEditEntityRelation(true)
    }
    if (actionType === ShadowTableAction.Deletable && selEntity) {
      setSelectedEntityRelation({
        id: selEntity.id,
        cardinality: selEntity.cardinality,
        dataCoreEntityId: selEntity.leftEntityId,
        dataSystemEntityId: selEntity.rightEntityId,
        datapodId: selEntity.dataPodId,
        similarityScoreByData: 1,
        similarityScoreByName: 1,
        joinCoreAttributeId: selEntity.leftAttributeId,
        joinSystemAttributeId: selEntity.rightAttributeId,
        runId: 0,
        profileName: 'manual-updated',
      })
      setDeleteDataCoreEntityId([selEntity?.leftEntityId])
      // if(selectedCoreEntity){
      // deleteDataCoreEntityId?.push(selectedCoreEntity)
      // }
      setShowDeleteWarning(true)
    }
  }

  const closeDeleteWarning = () => {
    setDeleteDataCoreEntityId(undefined)
    setShowDeleteWarning(false)
    setSelectedEntityRelation(undefined)
  }

  const onConfirmDelete = async () => {
    setProcessingData(true)
    if (deleteDataCoreEntityId) {
      const deleteMapSuccess = await confirmDeleteFunc(deleteDataCoreEntityId)
      if (deleteMapSuccess && selectedEntityRelation?.id) {
        const filteredRows = tableRows?.filter((r) => r.id !== String(selectedEntityRelation?.id))
        setTableRows(filteredRows)
        closeDeleteWarning()
      }
    }
  }
  return (
    <>
      <Grid item container xs={16} md={12} spacing={2}>
        <ResultStatus
          severtiy="success"
          showStatus={deleteSuccess}
          closeStatus={setDeleteSuccess}
          alertMessage={`SUCCESS: Deleted Entity Relationship`}
        />
        <DeletePopUp
          showDeleteWarning={showDeleteWarning}
          confirmDeleteMap={onConfirmDelete}
          closeDeleteWarning={closeDeleteWarning}
          itemToDelete={''}
          relationToDelete={entityRelationData.find((ed) => deleteDataCoreEntityId?.includes(ed.leftEntityId))}
        />
        <LucidPopUp
          headingText={<Typography variant="h4">Edit New Relationship</Typography>}
          openPopUp={showEditEntityRelation}
          onConfirm={updateEntityRelationFn}
          closePopup={closeEntityRelation}
          confirmText={'Save'}
          solidCancelButton
          cancelText="Cancel"
        >
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="h5">Core Entity Name :</Typography>
            </Grid>
            <Grid item xs={6} textAlign={'center'}>
              <Typography variant="h6">{selectedCoreEntityName}</Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item>
                <Typography variant="h5">Left</Typography>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="outlined-select-dataSource"
                    variant="outlined"
                    value={selectedEntityRelation?.dataCoreEntityId ?? 0}
                    required
                    onChange={({ target }) => handleEditEntityRelation('dataCoreEntityId', target.value)}
                    label="Left Entity"
                    size="small"
                    select
                  >
                    <MenuItem></MenuItem>

                    {allEntities.map((en) => (
                      <MenuItem key={en.id} value={en.id}>
                        {en.dataEntityName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="outlined-select-dataSource"
                    variant="outlined"
                    value={selectedEntityRelation?.joinCoreAttributeId ?? 0}
                    onChange={({ target }) => handleEditEntityRelation('joinCoreAttributeId', target.value)}
                    required
                    size="small"
                    label="Left Attribute"
                    select
                  >
                    {leftAttributes.map((rd, ix) => (
                      <MenuItem key={rd.id + ix} value={rd.id}>
                        {rd.dataAttributeName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item>
                <Typography variant="h5">Right</Typography>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="outlined-select-dataSource"
                    variant="outlined"
                    value={selectedEntityRelation?.dataSystemEntityId ?? 0}
                    onChange={({ target }) => handleEditEntityRelation('dataSystemEntityId', target.value)}
                    required
                    size="small"
                    label="Right Entity"
                    select
                  >
                    {allEntities.map((en) => (
                      <MenuItem key={en.id} value={en.id}>
                        {en.dataEntityName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="outlined-select-dataSource"
                    variant="outlined"
                    value={selectedEntityRelation?.joinSystemAttributeId ?? 0}
                    onChange={({ target }) => handleEditEntityRelation('joinSystemAttributeId', target.value)}
                    required
                    size="small"
                    select
                    label="Right Attribute"
                  >
                    {rightAttributes.map((rd, ix) => (
                      <MenuItem key={rd.id + ix} value={rd.id}>
                        {rd.dataAttributeName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="outlined-select-dataSource"
                    variant="outlined"
                    value={selectedEntityRelation?.cardinality ?? ''}
                    onChange={({ target }) => handleEditEntityRelation('cardinality', target.value)}
                    required
                    size="small"
                    label="cardinality"
                    select
                  >
                    <MenuItem></MenuItem>
                    {['one-to-many', 'one-to-one', 'many-to-one'].map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              {/* <Grid item container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Validation"
                  id="outlined-select-dataSource"
                  variant="outlined"
                  value={newEntityRelation?. ?? ''}
                  onChange={({ target }) => handleEntityRelationInp('joinSystemAttributeId', target.value)}
                  size="small"
                  required
                />
              </Grid>
            </Grid> */}
            </Grid>
          </Grid>
        </LucidPopUp>
        <Grid item xs={4}>
          <TextField
            select
            fullWidth
            value={selectedParent}
            size="small"
            label="Select Core Entity"
            onChange={({ target }) => onSelectChange(target.value)}
          >
            <MenuItem></MenuItem>
            {reconDataFiltered.map((rd) => (
              <MenuItem key={rd.dataCoreEntityId} value={rd.dataCoreEntityId}>
                {rd.parentName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item container xs={12}>
          {!generatingData && (
            <ShadowTable
              rows={tableFilteredRows.length ? tableFilteredRows : tableRows}
              columns={rightTableColumns}
              tableActionParams={{ onButtonClick: onButtonClick, actions: [ShadowTableAction.Editable] }}
            />
          )}
        </Grid>
      </Grid>
      <Grid container xs={16} md={12} spacing={2}>
        <Grid item xs={14} pt={'5%'} alignItems={'center'}>
          {/* <ProgressBar loading={processingData || generatingData} /> */}
          <AnimatedLoader height="50%" width="40%" loading={processingData || generatingData} />
        </Grid>
      </Grid>
    </>
  )
}
