import {
  RunEntityBody,
  NewEntityMapBody,
  UpdateEntityNameBody,
  UpdateEntityMapBody,
  NewEntity,
  NewEntityData,
  GenAIReconcilationBody,
} from '../businessObjects'
import { sendRequest } from '../requestManager/sendRequest'
import { sendGenApiRequest } from '../requestManager/sendGenApiRequest'
const entitiesPath = {
  entitiesRunProfile: 'Entities/runProfile',
  addEntityMap: 'Entities/AddNewEntityMap',
  updateEntityName: 'Entities/UpdateEntityName',
  updateEntityMap: 'Entities/UpdateEntityMap',
  getEntityProfileStatus: 'Entities/getProfileStatus',
  getEntityProfileOutput: 'Entities/getProfileOutput',
  getEntity: 'Entities/getEntity',
  getEntityCore: 'Entities/getCoreEntityOnly',
  getEntityMapping: 'Entities/getMapping',
  addNewEntity: 'Entities/AddNewEntity',
  deleteEntityMapId: 'Entities/deleteEntityMap',
  getDataLakeZones: 'Entities/getDatalakeZoneForEntity',
  deleteEntity: 'Entities/deleteEntity',
  saveEntityNB: 'Entities/CustomEntityNB',
  updateEntityNB: 'Entities/UpdateCustomEntityNB',
  getEntityNB: 'Entities/GetCustomEntityNB',
  getGenAiReconcilation: 'EntityReconciliation',
  getEntityName: 'Entities/getEntityName',
}
export const runProfile = async (token: string, datapodId: string, body: RunEntityBody) => {
  return sendRequest(entitiesPath.entitiesRunProfile + `?datapodId=${datapodId}`, 'POST', token, body)
}

export const addEntityMap = async (token: string, datapodId: string, newEntityMap: NewEntityMapBody) => {
  return sendRequest(entitiesPath.addEntityMap + `?datapodId=${datapodId}`, 'POST', token, newEntityMap)
}
export const updateEntityName = async (token: string, updateEntityName: UpdateEntityNameBody) => {
  return sendRequest(entitiesPath.updateEntityName, 'PUT', token, {
    ...updateEntityName,
    entityType: `${updateEntityName.entityType}`,
  })
}
export const updateEntityMap = async (token: string, updateEntityMap: UpdateEntityMapBody) => {
  return sendRequest(entitiesPath.updateEntityMap, 'PUT', token, updateEntityMap)
}
export const getEntityProfileStatus = async (token: string, datapodId: string, runId: number) => {
  return await sendRequest(entitiesPath.getEntityProfileStatus + `?runId=${runId}&datapodId=${datapodId}`, 'GET', token)
}
export const getEntityProfileOutput = async (token: string, datapodId: string, runId: number) => {
  return await sendRequest(entitiesPath.getEntityProfileOutput + `?runId=${runId}&datapodId=${datapodId}`, 'GET', token)
}
export const getEntity = async (
  token: string,
  entityId?: number,
  entityName?: string,
  datapodId?: string,
  records?: number,
  pageNumber?: number,
  dataEntityType?: number,
  isCoreEntity?: boolean,
) => {
  //const config = getAxiosRequestConfig(token)
  const paginationParams = records && pageNumber ? `&RecordsPerPage=${records}&PageNumber=${pageNumber}` : null
  let url = entitiesPath.getEntity
  if (entityName && datapodId) {
    url += `?entityName=${entityName}&datapodID=${datapodId}`
  }
  if (entityId && datapodId) {
    url += `?entityID=${entityId}&datapodID=${datapodId}`
  }
  if (datapodId && !entityId) {
    url += `?datapodID=${datapodId}`
  }
  if (dataEntityType) {
    url += `&DataEntityType=${dataEntityType}`
  }
  if (isCoreEntity) {
    url += `&CoreEntity=${isCoreEntity}`
  }
  url = paginationParams ? url + paginationParams : url
  return await sendRequest(url, 'GET', token)
}

export const getCoreEntity = async (token: string, datapodId?: string) => {
  let url = entitiesPath.getEntityCore
  if (datapodId) {
    url += `?datapodID=${datapodId}`
  }
  return await sendRequest(url, 'GET', token)
}

export const getEntityMapping = async (
  token: string,
  dataTableName?: string,
  dataEntityName?: string,
  datapodID?: string,
  dataentityId?: number,
  dataTableID?: number,
  records?: number,
  pageNumber?: number,
) => {
  const paginationParams = records && pageNumber ? `&RecordsPerPage=${records}&PageNumber=${pageNumber}` : null
  const queryParams: string[] = []
  if (dataTableName) {
    queryParams.push(`dataTableName=${dataTableName}`)
  }

  if (dataEntityName) {
    queryParams.push(`dataEntityName=${dataEntityName}`)
  }
  if (datapodID) {
    queryParams.push(`datapodID=${datapodID}`)
  }
  if (dataentityId) {
    queryParams.push(`dataentityId=${dataentityId}`)
  }

  const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
  const url = entitiesPath.getEntityMapping + queryString
  const endPoint = paginationParams ? url + paginationParams : url

  return sendRequest(endPoint, 'GET', token)
}

export const addNewEntity = async (token: string, dataPodId: string, entity: NewEntity) => {
  const endPoint = entitiesPath.addNewEntity + `?dataPodId=${dataPodId}`

  return sendRequest(endPoint, 'POST', token, entity)
}

const deleteEntityMapId = async (token: string, entityMapId: number) => {
  const endPoint = entitiesPath.deleteEntityMapId + `?DataEntityMapId=${entityMapId}`

  return sendRequest(endPoint, 'DELETE', token)
}

const getEntityMappingByPodIdAndEntityID = async (
  token: string,
  dataPodId: string,
  entityId?: number,
  tableId?: number,
) => {
  let queryString = `?datapodID=${dataPodId}`
  if (entityId) {
    queryString = queryString.concat(`&dataentityId=${entityId}`)
  }
  if (tableId) {
    queryString = queryString.concat(`&dataTableID=${tableId}`)
  }
  const endPoint = entitiesPath.getEntityMapping + queryString
  return await sendRequest(endPoint, 'GET', token)
}
const deleteEntity = async (token: string, entityIds: number[]) => {
  const endPoint = entitiesPath.deleteEntity
  return sendRequest(endPoint, 'DELETE', token, entityIds)
}

const getDataLakeZones = async (token: string) => {
  return sendRequest(entitiesPath.getDataLakeZones, 'GET', token)
}
export const addNewCustomEntity = async (token: string, dataPodId: string, entity: NewEntityData) => {
  const endPoint = entitiesPath.addNewEntity + `?dataPodId=${dataPodId}`

  return sendRequest(endPoint, 'POST', token, { ...entity, entityType: String(entity.entityType) })
}
const saveEntityNotebook = async (
  token: string,
  dataPodId: string,
  customEntityId: number,
  notebookToRun: string,
  commitMessage: string,
) => {
  const reqBody = {
    dataPodId: dataPodId,
    customentityID: customEntityId,
    notebookToRun: notebookToRun,
    commitMessage,
  }
  return sendRequest(entitiesPath.saveEntityNB, 'POST', token, reqBody)
}
const getEntityNotebook = async (token: string, dataPodId: string, customEntityId: number) => {
  const endPoint = entitiesPath.getEntityNB + `?datapodId=${dataPodId}&customentityID=${customEntityId}`
  return sendRequest(endPoint, 'GET', token)
}

const updateEntityNotebook = async (
  token: string,
  dataPodId: string,
  customEntityId: number,
  notebookToRun: string,
  commitMessage: string,
) => {
  const reqBody = {
    dataPodId: dataPodId,
    customentityID: customEntityId,
    notebookToRun: notebookToRun,
    commitMessage,
  }
  return sendRequest(entitiesPath.updateEntityNB, 'PUT', token, reqBody)
}

export const getGenAiReconcilation = async (token: string, dataPodId: string) => {
  const body: GenAIReconcilationBody = {
    dataPodId: dataPodId,
  }
  const endPoint = entitiesPath.getGenAiReconcilation
  return await sendGenApiRequest(endPoint, 'POST', token, body)
}
export const getEntityName = async (
  token: string,
  entityId?: number,
  entityName?: string,
  fullEntityName?: string,
  datapodId?: string,
  records?: number,
  pageNumber?: number,
  dataEntityType?: number,
  isCoreEntity?: boolean,
) => {
  //const config = getAxiosRequestConfig(token)
  const paginationParams = records && pageNumber ? `&RecordsPerPage=${records}&PageNumber=${pageNumber}` : null
  let url = entitiesPath.getEntityName
  if (entityName && datapodId) {
    url += `?entityName=${entityName}&datapodID=${datapodId}`
  }
  if (entityId && datapodId) {
    url += `?entityID=${entityId}&datapodID=${datapodId}`
  }
  if (datapodId && !entityId && !entityName && !fullEntityName) {
    url += `?datapodID=${datapodId}`
  }
  if (fullEntityName && datapodId) {
    url += `?FullEntityName=${fullEntityName}&datapodID=${datapodId}`
  }
  if (dataEntityType) {
    url += `&DataEntityType=${dataEntityType}`
  }
  if (isCoreEntity) {
    url += `&CoreEntity=${isCoreEntity}`
  }
  url = paginationParams ? url + paginationParams : url
  return await sendRequest(url, 'GET', token)
}
export const entityServices = {
  deleteEntityMapId,
  getEntityMappingByPodIdAndEntityID,
  getDataLakeZones,
  deleteEntity,
  addNewCustomEntity,
  saveEntityNotebook,
  getEntityNotebook,
  updateEntityNotebook,
  addNewEntity,
  addEntityMap,
  getGenAiReconcilation,
  getEntityName,
}
