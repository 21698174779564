import { useNavigate, useLocation, Route, Routes } from 'react-router-dom'

// MSAL imports
import { MsalProvider, useIsAuthenticated, useMsal } from '@azure/msal-react'
import { IPublicClientApplication } from '@azure/msal-browser'
import { CustomNavigationClient } from './utils/NavigationClient'

import LucidHomePage from './ui-components/LucidHomePage'
import { useCallback, useEffect, useMemo } from 'react'

import LucidDataDrawer from './ui-components/Drawer/LucidDataDrawer'
import { entries } from './ui-components/Drawer/NavigationDrawerStyles'
import { PageLayout } from './ui-components/PageLayout'
import { Toolbar, Grid, Typography, Box, CardContent } from '@mui/material'
import { ROUTES } from './Routes/constants'

import MainLayout from './ui-components/MainLayout'

import { DataContextHome } from './pages/DataContext/DataContextHome'
import { DataPodContext } from './pages/DataContext/DataPodContext'
import LucidAuthContextProvider from './LucidAuthContext/LucidAuthContext'

import { innerPageContainer, mainPageContainer, outerPageContainer } from './styles/globalStyles'
import { AccountHome } from './pages/Accounts/AccountHome'
import { AccountLayout } from './pages/Accounts/AccountLayout'
import Overview from './pages/Overview/Overview'
import { SourceDataModels } from './pages/DataProcess/SourceDataModels'
import { DataProfilingProcess } from './pages/DataProcess/DataProfilingProcess'
import { DataProfilingViewResults } from './pages/DataProcess/DataProfilingViewResults'
import { DataMappingResults } from './pages/DataProcess/DataMapping/DataMappingResults'
import { DataMappingAttributes } from './pages/DataProcess/DataMapping/DataMappingAttributes'
import { DiscoverRelationships } from './pages/DataProcess/DataModeling/DiscoverRelationships'
import { DataModelingEnterpriseModels } from './pages/DataProcess/DataModeling/DataModelingEnterpriseModels'
import { EntityRelationships } from './pages/DataProcess/DataModeling/EntityRelationships'
import { EntityReconciliation } from './pages/DataProcess/DataModeling/EntityReconciliation'
import { CanonicalModel } from './pages/DataProcess/DataModeling/CanonicalModel'

// Data Engineering
import { DataEngineeringResults } from './pages/DataProcess/DataEngineering/DataEngineeringResults'
import { AllTransformers } from './pages/DataProcess/DataModeling/AllTransformers'
import { CanonicalModelAttributeDetails } from './pages/DataProcess/DataModeling/CanonicalModelAttributeDetails'
import { DataMapping } from './pages/DataProcess/DataMapping/DataMapping'
import { DataProfiling } from './pages/DataProcess/DataProfiling'
import CustomEnrichedModel from './pages/DataProcess/DataModeling/CustomEnrichedModel'
import { SourceToTargetMapping } from './pages/DataProcess/GenerativeIntegrations/SourceToTargetMapping'
import { DataModeling } from './pages/DataProcess/DataModeling/DataModeling'

import { DataEngineeringPipelineMonitoring } from './pages/DataProcess/DataEngineering/DataEngineeringPipelineMonitoring'
import { DataEngineeringPipelineSchedules } from './pages/DataProcess/DataEngineering/DataEngineeringPipelineSchedules'

// Data Engineering
import DataLakeHome from './pages/DataProcess/DataLake'
import { DataLakeResults } from './pages/DataProcess/DataLake/DataLakeResults'

// Data Analytics
import DataAnalyticsHome from './pages/DataAnalytics'
import AnalyticsGroupResults from './pages/DataAnalytics/AnalyticsGroupResults'
import AnalyticsMeasureResults from './pages/DataAnalytics/AnalyticsMeasureResults'
import AddAnalyticsMeasure from './pages/DataAnalytics/AddAnalyticsMeasure'

//Settings
import SettingsHome from './pages/Settings'
import ListServiceConnection from './pages/Settings/ServiceConnection'
import ManageRoles from './pages/Settings/Roles/ManageRoles'
import DataEngineeringManageSchedules from './pages/DataProcess/DataEngineering/DataEngineeringManageSchedules'
import { CusomAttribute } from './pages/DataProcess/DataModeling/CustomAttribute/CustomAttribute'
import CustomEnrichedModelTable from './pages/DataProcess/DataModeling/CustomEnrichedModelTable'
import ManageConfiguration from './pages/Settings/Configurations/ManageConfiguration'
import { useGetSessionPodInfo } from './hooks/getSessionPodInfo'
import DataPodDetailRibbon from './ui-components/DataPodDetailRibbon/DataPodDetailRibbon'
import { DatapodDetails } from './ui-components/DatapodDetails'
import { DataQuality } from './pages/DataProcess/DataModeling/DataQualtiy'
import SignUpHome from 'pages/Authentication/SignUp/SIgnUpHome'
import { useGetAllLucidAccountByTenant } from 'hooks/lucidAccountHooks'

type AppProps = {
  pca: IPublicClientApplication
}

function App({ pca }: AppProps) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate()
  const navigationClient = new CustomNavigationClient(navigate)
  pca.setNavigationClient(navigationClient)

  return (
    <MsalProvider instance={pca}>
      <LucidAuthContextProvider>
        <Pages />
      </LucidAuthContextProvider>
    </MsalProvider>
  )
}

function Pages() {
  const { instance, accounts } = useMsal()
  const [getLucidAccounts, lucidAccount] = useGetAllLucidAccountByTenant()
  const isAuthenticated = useIsAuthenticated()
  // const { instance, accounts } = useMsal()
  const location = useLocation()
  const navigate = useNavigate()
  const activeTab: string = useMemo(() => {
    const selected = entries.filter((drawerItem) => location.pathname.includes(drawerItem.link))
    return selected.length ? selected[selected.length - 1].link : ''
  }, [location])

  const appItemClick = useCallback(
    (path: string): void => {
      navigate('/' + path)
    },
    [navigate],
  )

  useEffect(() => {
    const fetchLucidAccounts = async () => {
      if (accounts.length) {
        await getLucidAccounts(accounts[0].tenantId)
        console.log(accounts[0])
      }
    }
    fetchLucidAccounts();
  }, [accounts])

  // if (!isAuthenticated ) {
  if (!isAuthenticated || !lucidAccount) {
    return (
      <>
        <LucidHomePage />
        {/* <SignUpHome /> */}
      </>
    )
  }

  return ( 
    <>
    <PageLayout>
      <LucidDataDrawer onClick={appItemClick} activeLink={activeTab} entries={entries} />
      <MainLayout>
        <div style={outerPageContainer}>
          <Toolbar />

          <Box style={innerPageContainer} sx={{ boxShadow: 2 }}>
            <div style={mainPageContainer}>
              <Routes>
                <Route path={ROUTES.Account} element={<AccountHome />} />
                <Route path={ROUTES.AccountLayout} element={<AccountLayout />} />

                <Route path={ROUTES.DataPods} element={<DataContextHome />} />
                <Route path={ROUTES.AddDataPod} element={<DataPodContext />} />

                <Route path={ROUTES.Overview} element={<Overview />} />
                <Route path={ROUTES.overviewSession} element={<Overview />} />

                <Route
                  path={ROUTES.DataSystemProfiling}
                  element={
                    <>
                      <DatapodDetails />
                      <DataProfilingProcess />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataSourceModels}
                  element={
                    <>
                      <DatapodDetails />
                      <SourceDataModels />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataProfiling}
                  element={
                    <>
                      <DatapodDetails />
                      <DataProfiling />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataMapping}
                  element={
                    <>
                      <DatapodDetails />
                      <DataMapping />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataModeling}
                  element={
                    <>
                      <DatapodDetails />
                      <DataModeling />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataProfilingViewResults}
                  element={
                    <>
                      <DatapodDetails />
                      <DataProfilingViewResults />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataMappingResults}
                  element={
                    <>
                      <DatapodDetails />
                      <DataMappingResults />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataMappingAttribues}
                  element={
                    <>
                      <DatapodDetails />
                      <DataMappingAttributes />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataModelingCanonicalModelAddAttribute}
                  element={
                    <>
                      <DatapodDetails />
                      <CusomAttribute />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataModelingCanonicalModelEditCustomAttribute}
                  element={
                    <>
                      <DatapodDetails />
                      <CusomAttribute />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataModelingCanonicalModelAddDataQuality}
                  element={
                    <>
                      <DatapodDetails />
                      <DataQuality />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataModelingCanonicalModelEditDataQuality}
                  element={
                    <>
                      <DatapodDetails />
                      <DataQuality />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataModelingResults}
                  element={
                    <>
                      <DatapodDetails />
                      <DiscoverRelationships />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataModelingEnterpriseModels}
                  element={
                    <>
                      <DatapodDetails />
                      <DataModelingEnterpriseModels />
                    </>
                  }
                />
                <Route
                  path={ROUTES.CustomEnrichedModel}
                  element={
                    <>
                      <DatapodDetails />
                      <CustomEnrichedModel />
                    </>
                  }
                />
                <Route
                  path={ROUTES.EditCustomEnrichedModel}
                  element={
                    <>
                      <DatapodDetails />
                      <CustomEnrichedModel />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataModelingEnterpriseEnrichedTables}
                  element={
                    <>
                      <DatapodDetails />
                      <CustomEnrichedModelTable />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataModelingEntityRelationships}
                  element={
                    <>
                      <DatapodDetails />
                      <EntityRelationships />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataModelingEntityReconciliation}
                  element={
                    <>
                      <DatapodDetails />
                      <EntityReconciliation />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataModelingCanonicalModel}
                  element={
                    <>
                      <DatapodDetails />
                      <CanonicalModel />
                    </>
                  }
                />
                <Route
                  path={ROUTES.SourceToTargetMapping}
                  element={
                    <>
                      <DatapodDetails />
                      <SourceToTargetMapping />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataModelingCanonicalModelAttributeDetails}
                  element={
                    <>
                      <DatapodDetails />
                      <CanonicalModelAttributeDetails />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataModelingAllTransformers}
                  element={
                    <>
                      <DatapodDetails />
                      <AllTransformers />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataEngineeringResults}
                  element={
                    <>
                      <DatapodDetails />
                      <DataEngineeringResults />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataEngineering}
                  element={
                    <>
                      <DatapodDetails />
                      <DataEngineeringResults />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataEngineeringPipelineSchedules}
                  element={
                    <>
                      <DatapodDetails />
                      <DataEngineeringPipelineSchedules />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataEngineeringManageSchedules}
                  element={
                    <>
                      <DatapodDetails />
                      <DataEngineeringManageSchedules />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataEngineeringPipelineMonitoring}
                  element={
                    <>
                      <DatapodDetails />
                      <DataEngineeringPipelineMonitoring />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataLakeHome}
                  element={
                    <>
                      <DatapodDetails />
                      <DataLakeHome />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataLakeResults}
                  element={
                    <>
                      <DatapodDetails />
                      <DataLakeResults />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataAnalyticsHome}
                  element={
                    <>
                      <DatapodDetails />
                      <DataAnalyticsHome />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataAnalyticsGroupResults}
                  element={
                    <>
                      <DatapodDetails />
                      <AnalyticsGroupResults />
                    </>
                  }
                />
                <Route
                  path={ROUTES.DataAnalyticsMeasuresResults}
                  element={
                    <>
                      <DatapodDetails />
                      <AnalyticsMeasureResults />
                    </>
                  }
                />
                <Route
                  path={ROUTES.AddDataAnalyticsMeasuresResults}
                  element={
                    <>
                      <DatapodDetails />
                      <AddAnalyticsMeasure />
                    </>
                  }
                />
                <Route
                  path={ROUTES.Documentation}
                  element={
                    <Grid item container alignItems="center">
                      {/* <ProfileContent onClick={() => {}} /> */}
                      <Typography gutterBottom>Documentation</Typography>
                    </Grid>
                  }
                />
                <Route
                  path={ROUTES.Milestoneplan}
                  element={
                    <Grid item container alignItems="center">
                      <Typography gutterBottom>Milestoneplan</Typography>
                    </Grid>
                  }
                />
                <Route
                  path={ROUTES.SystemAnaylsis}
                  element={
                    <Grid item xs={12} container alignItems="center">
                      {/* <Grid item xs={12}>
                        {' '}
                        <Typography variant="h1" gutterBottom>
                          Headline1
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="h2" gutterBottom>
                          Headline2
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="h3" gutterBottom>
                          Headline3
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="h4" gutterBottom>
                          Headline4
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="h5" gutterBottom>
                          Headline5
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="h6" gutterBottom>
                          Headline6
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="subtitle1" gutterBottom>
                          Subtitle1
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="subtitle2" gutterBottom>
                          Subtitel2
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="body1" gutterBottom>
                          Body1
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="body2" gutterBottom>
                          Body2
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="button" gutterBottom>
                          Button
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="caption" gutterBottom>
                          Caption
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="overline" gutterBottom>
                          Overline
                        </Typography>
                      </Grid> */}
                    </Grid>
                  }
                />
                <Route path={ROUTES.Settings} element={<SettingsHome />} />
                <Route path={ROUTES.ListServiceConnection} element={<ListServiceConnection />} />
                <Route path={ROUTES.ManageConfiguration} element={<ManageConfiguration />} />
                <Route path={ROUTES.ManageRoles} element={<ManageRoles />} />
              </Routes>
            </div>
          </Box>
        </div>
      </MainLayout>
    </PageLayout>
    </>
  )
}

export default App
