import React, { useEffect, useMemo, useState } from 'react'
import ActionPage from '../../../ui-components/ActionPage'
import {
  Grid,
  IconButton,
  Box,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  Button,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Chip,
  Card,
  CardContent,
  Container,
} from '@mui/material'
import { cardContainer } from '../../../styles/globalStyles'
import Cron, { CronError } from 'react-js-cron'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../../Routes/constants'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import { useGetDataPodInfo } from '../../../hooks/datapodHooks'
import {
  ActionTypes,
  GridColActions,
  GridColNumberAction,
  GridColSelectAction,
  GridColStatus,
  LucidDataGrid,
} from '../../../ui-components/LucidDataTable/LucidDatatable'
import { GridColDef, GridRowSelectionModel, GridValidRowModel } from '@mui/x-data-grid'
import { LucidPopUp } from '../../../ui-components/LucidPopUp/LucidPopUp'

import {
  useAddDataPipeline,
  useGetDataPipelineExecutions,
  useGetPipeline,
  useGetPipelineSchedules,
  useGetServiceConnection,
  useUpdateDataPipelines,
} from '../../../hooks/dataEngineeringHooks'
import {
  ServiceConnections,
  Pipeline,
  PipelineExtecutionStatus,
  NewDataPipeline,
  DataPipeline,
  PipelineSchedule,
  Configuration,
} from '../../../businessObjects'
import CancelIcon from '@mui/icons-material/Cancel'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import DataPodDetailRibbon from '../../../ui-components/DataPodDetailRibbon/DataPodDetailRibbon'
import CronScheduler from '../../../ui-components/JobScheduler/CronScheduler'
import { CronFrequency, defCron, defaultTimeOptions } from '../../../ui-components/JobScheduler/SchedulerConstants'
import { SaveOutlined, ScheduleOutlined } from '@mui/icons-material'
import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined'
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined'
import DataPipelineTable from './DataPipelineTable'
import ResultStatus from '../../../ui-components/ResultStatus'
import { useGetConfigurations } from '../../../hooks/lucidConfigurationHooks'

type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const defaultDeployPipeline: NewDataPipeline = {
  entitiesWithOrders: [],
  dataLakeZoneId: 0,
  serviceConnectionId: 0,
  dataPipelineScheduleId: 0,
  action: 'Save',
}

const convertPipelineToTableRows = (dataPipeline: Pipeline[], pipelineSchedules?: PipelineSchedule[]) => {
  const tableRows = dataPipeline.map((pd, index) => ({
    // id: pd.entityid,
    entityId: pd.entityid,
    entityName: pd.dataEntityName,
    dataLakeZone: pd.dataLakeZone === 1 ? 'Canonical' : 'Enriched',
    pipelineID: pd.dataPipelineId,

    pipelineName: pd.pipelinename,
    //scheduleName: pipelineSchedules?.find((ps) => ps.id === pd.dataPipelineScheduleId)?.name ?? '',
    scheduleOrder: pd.scheduleOrder,
    //dataPipelineScheduleId: pd.dataPipelineScheduleId,
    deploymentStatus: pd.pipelineStatus,
  }))
  return tableRows
}

// const sortedPipelinesByOrder = (dataPipeline: Pipeline[]) => {
//   return dataPipeline.sort((p1, p2) => (p1.scheduleOrder > p2.scheduleOrder ? 1 : -1))
// }

// const swapPipelinesByOrder = (dataPipeline: Pipeline[], pipeline1: Pipeline, pipelineToSwap: Pipeline) => {}

export const DataEngineeringPipelineSchedules = (props: Props) => {
  const { dataPodId } = useParams<ViewResultsParams>()
  // const [tRows, setTRows] = useState<GridValidRowModel[]>([])
  // const [selectedSchedule, setSelectedSchedule] = useState<PipelineExtecutionStatus>()
  // const [selectedScheduleCron, setSelectedScheduleCron] = useState<CronFrequency>()
  const [serviceConnections, setSerivceConnections] = useState<ServiceConnections[]>([])
  const [showServiceConnectionPopup, setShowServiceConnectionPopup] = useState(false)
  //const [piplineServiceName, setPiplineServiceName] = React.useState<number>()
  const [searchText, setSearchText] = useState<string>()
  // const [pipelineToEdit, setPipelineToEdit] = useState<DataPipeline>()
  const [selectedEntityType, setSelectedEntityType] = useState<number>(1)
  //const [serviceConnectionId, setServiceConnectionId] = useState<number>()
  //const [pipelineInfo, setPipelineInfo] = useState<Pipeline[]>([])
  //const [entityIds, setEntityIds] = useState<number[]>([])
  const [showPopUp, setShowPopUp] = useState<boolean>(false)
  const [deployPipelines, setDeploypipelines] = useState<NewDataPipeline>(defaultDeployPipeline)
  // const { dataPodInfo } = useGetDataPodInfo(dataPodId)
  const navigate = useNavigate()
  const { getConnections } = useGetServiceConnection()
  const { pipelineData, refreshDeployments } = useGetPipeline(
    dataPodId,
    undefined,
    undefined,
    selectedEntityType,
    searchText,
  )
  // const { refreshStatus } = useGetDataPipelineExecutions(dataPodId)
  const { addPipeline, addPipelineSuccess, postError } = useAddDataPipeline(dataPodId)
  // const { updatePipeline } = useUpdateDataPipelines(dataPodId)
  const { pipelineSchedules } = useGetPipelineSchedules()
  const [addedSuccess, setAddedSuccess] = useState<boolean>(false)

  const { getConfigurations } = useGetConfigurations();
  const [configurations, setConfigurations] = useState<Configuration[]>([])
  

  const onClickHome = () => {
    const dataProfilingRoute = generatePath(ROUTES.Overview, { dataPodId: dataPodId })
    navigate(dataProfilingRoute)
  }

  useEffect(() => {
    const fetchData = async () => {
      const result = await getConfigurations();
      setConfigurations(result);
    }

    fetchData();
  }, [])

  useEffect(() => {
    if (postError) {
      //Add/Edit - handling error message.
      setAddedSuccess(true)
    }
  }, [postError])

  useEffect(() => {
    if (addPipelineSuccess) {
      //Add/Edit - handling error message.
      setAddedSuccess(true)
      refreshDeployments()
    }
  }, [addPipelineSuccess])

  useEffect(() => {
    if (dataPodId && !serviceConnections.length) {
      getConnections(dataPodId, undefined, 'DP')
        .then((response) => {
          if (response?.length) {
            setSerivceConnections(response)
            setDeploypipelines((prv) => ({
              ...prv,
              serviceConnectionId: response[0].id,
            }))
            //console.log({ response })
          }
        })
        .catch((error) => console.log(error))
    }
  }, [dataPodId])

  useEffect(() => {
    if (pipelineData.length) {
      //setPipelineInfo(pipelineData)
      setDeploypipelines((prev) => ({
        ...prev,
        entitiesWithOrders: pipelineData.map((p, ix) => ({
          scheduleOrder: ix,
          entityID: p.entityid,
          dependsOn: p.dependsOn || [],
        })),
        dataPipelineScheduleId: pipelineData[0].dataPipelineScheduleId,
      }))

      // setTRows(convertPipelineToTableRows(pipelineData, pipelineSchedules))
    }
    // if (!pipelineData.length) {
    //   setTRows([])
    // }
  }, [pipelineData, selectedEntityType, pipelineSchedules])

  // const pipelineInfo = useMemo(() => {
  //   if (!pipelineData.length) return []
  //   return pipelineData.map((pd) => ({
  //     id: pd.entityid,
  //     entityId: pd.entityid,
  //     entityName: pd.dataEntityName,
  //     dataLakeZone: pd.dataLakeZone === 1 ? 'Canonical' : 'Enriched',
  //     pipelineID: pd.dataPipelineId,
  //     scheduleOrderID: pd.scheduleOrder,
  //     pipelineName: pd.pipelinename,
  //     scheduleId: pd.scheduledId,
  //     deploymentStatus: pd.pipelineStatus,
  //   }))
  // }, [pipelineData, selectedEntityType, searchText])

  // const pscheduleOptions = useMemo(() => {
  //   if (!pipelineSchedules.length) return []
  //   return pipelineSchedules.map((psc) => ({
  //     id: Number(psc.id),
  //     value: psc.name,
  //   }))
  // }, [pipelineSchedules.length])

  // const handleChange = (selectedId: number) => {
  //   setPiplineServiceName(selectedId)
  // }

  // const handleEnityChange = (selectedId: number) => {
  //   setPiplineServiceName(selectedId)
  // }
  // const onClick = <T, K>(id: T, cellValue: K) => {
  //   console.log({ id, cellValue })
  //   const selCron = String(cellValue)
  //   const selPipelineId = Number(id)
  //   dataPodId &&
  //     setSelectedSchedule({
  //       id: selPipelineId,
  //       dataPodId: dataPodId,
  //       pipelineId: selPipelineId,
  //       pipelineRunId: 0,
  //       pipelineCronString: selCron.length ? selCron : defCron,
  //       pipelineRunStatus: '',
  //       startTime: defaultTimeOptions.startDate.toDateString(),
  //       endTime: '',
  //       timeZoneFormat: defaultTimeOptions.tz,
  //     })
  //   setSelectedSchedule(undefined)
  //   setShowPopUp(true)
  // }

  // const updateOrderValue = <T, K>(id: T, cellValue: K) => {
  //   const entityId = Number(id)
  //   const updo = pipelineInfo.map((pd) =>
  //     pd.entityid === entityId ? { ...pd, scheduleOrderID: Number(cellValue) } : { ...pd },
  //   )
  //   console.log({ entityId }, { cellValue })
  //   setTRows(convertPipelineToTableRows(updo))
  // }

  // const updateScheduleID = <T, K>(id: T, cellValue: K) => {
  //   const entityId = Number(id)
  //   const updSch = pipelineInfo.map((pd) =>
  //     pd.entityid === entityId ? { ...pd, dataPipelineScheduleId: Number(cellValue) } : { ...pd },
  //   )
  //   console.log({ entityId }, { cellValue })
  //   setTRows(convertPipelineToTableRows(updSch))
  // }

  // const tableColumns: GridColDef[] = useMemo(() => {
  //   return [
  //     {
  //       field: 'entityId',
  //       headerName: 'Entity ID',
  //       align: 'center',
  //       width: 100,
  //     },
  //     {
  //       field: 'entityName',
  //       headerName: 'Entity Name',
  //       align: 'left',
  //       width: 150,
  //     },
  //     {
  //       field: 'dataLakeZone',
  //       headerName: 'Data Lake Zone',
  //       align: 'left',
  //       width: 150,
  //     },

  //     {
  //       field: 'pipelineName',
  //       headerName: 'Pipeline Name',
  //       align: 'left',
  //       width: 150,
  //     },
  //     // {
  //     //   field: 'scheduleName',
  //     //   headerLabel: 'Schedule Name',
  //     //   align: 'left',
  //     //   width: 200,
  //     // },
  //     {
  //       field: 'pipelineID',
  //       headerName: 'PipelineID',
  //       align: 'center',
  //       width: 150,
  //     },
  //     {
  //       field: 'scheduleOrder',
  //       headerLabel: 'scheduleOrder',
  //       align: 'center',
  //       width: 150,
  //     },
  //     GridColStatus({
  //       valueOptions: ['Deployed', 'ReDeploy', 'Not Deployed', 'Saved', 'Not deployed'],
  //       fieldStatus: 'deploymentStatus',
  //       headerLabel: 'Deployment Status',
  //     }),
  //     // {
  //     //   field: 'Move',
  //     //   headerLabel: 'Move',
  //     //   align: 'center',
  //     //   width: 100,
  //     // },
  //   ]
  // }, [pipelineSchedules.length])

  const entityTypes = [
    { id: 1, value: 'Canonical' },
    { id: 2, value: 'Enriched' },
    { id: 3, value: 'Custom Enriched' },
  ]
  const serviceCon = serviceConnections.map((name) => name.connectionName)

  // const confirmUpdated = async () => {
  //   if (pipelineToEdit) {
  //     const success = await updatePipeline(pipelineToEdit)
  //     if (success && success) {
  //       console.log('edit pipeline success:', { pipelineToEdit })
  //     }
  //   }
  // }
  const onSubmit = () => {
    setShowServiceConnectionPopup(!showServiceConnectionPopup)
  }
  // const saveCronDetails = (freq: CronFrequency, upschedul: PipelineExtecutionStatus) => {
  //   const updateTrows = tRows.map((tr) => (tr.id == selectedSchedule?.id ? { ...tr, executionFrequency: freq } : tr))
  //   console.log({ upschedul }, { freq })
  //   setSelectedScheduleCron(freq)
  //   setTRows(updateTrows)
  // }

  const toManageSchedules = () => {
    navigate(generatePath(ROUTES.DataEngineeringManageSchedules, { dataPodId }))
  }

  // const onRowSelection = (rowIDs: GridRowSelectionModel) => {
  //   const entIds = rowIDs as number[]
  //   setEntityIds(entIds)
  //   setDeploypipelines((prv) => ({ ...prv, entityids: entIds }))
  // }

  // const updateSelectedPipeline = (actionType: ActionTypes, paramId: string) => {
  //   switch (actionType) {
  //     case 'save':
  //       const selRowToUpdate = tRows.find((tr) => tr.id == paramId)
  //       console.log({ selRowToUpdate }, { paramId })
  //       if (selRowToUpdate) {
  //         setPipelineToEdit({
  //           id: selRowToUpdate.dataPipelineId,
  //           entityid: selRowToUpdate.entityid,
  //           pipelinename: selRowToUpdate.pipelinename,
  //           scheduledId: 0,
  //           scheduleOrder: selRowToUpdate.scheduleOrder,
  //           dataPodId: dataPodId ?? '',
  //           serviceConnectionId: selectedEntityType ?? '',
  //           dataPipelineScheduleId: selRowToUpdate.dataPipelineScheduleId,
  //           pipelineStatus: selRowToUpdate.pipelineStatus,
  //           //confirmUpdated()
  //         })
  //       }
  //       break
  //   }
  // }

  const confirmSavePipelines = async () => {
    if (deployPipelines.entitiesWithOrders.length) {
      let serviceTypeName: string | undefined = ''
      if (serviceConnections.length) {
        serviceTypeName = serviceConnections[0].serviceTypeName
      }
      const redirectUri = configurations.find(config => config.key == 'FabricRedirectURL')?.value
      const deployed = await addPipeline(
        {
          ...deployPipelines,
          dataPipelineScheduleId: deployPipelines.dataPipelineScheduleId,
          dataLakeZoneId: selectedEntityType,
        },
        serviceTypeName,
        redirectUri
      )
      if (deployed) {
        await refreshDeployments()
        // const tp = tRows.map((pd) =>
        //   deployPipelines.entityids.includes(pd.id)
        //     ? {
        //         ...pd,
        //         pipelineID: deployed?.id,
        //         scheduleOrderID: deployed.scheduleOrder,
        //         dataPipelineScheduleId: deployPipelines.dataPipelineScheduleId,
        //         deploymentStatus: deployed.pipelineStatus,
        //       }
        //     : pd,
        // )
        // setTRows(tp)
        //setShowPopUp(false)
      }
    }
  }
  const confirmDeployPipelines = async () => {
    if (deployPipelines.entitiesWithOrders.length) {
      let serviceTypeName: string | undefined = ''
      if (serviceConnections.length) {
        serviceTypeName = serviceConnections[0].serviceTypeName
      }
      const redirectUri = configurations.find(config => config.key == 'FabricRedirectURL')?.value
      const deployed = await addPipeline(
        {
          ...deployPipelines,
          dataPipelineScheduleId: deployPipelines.dataPipelineScheduleId,
          dataLakeZoneId: selectedEntityType,
          action: 'Deploy',
        },
        serviceTypeName,
        redirectUri
      )
      if (deployed) {
        await refreshDeployments()
        // const tp = tRows.map((pd) =>
        //   deployPipelines.entityids.includes(pd.id)
        //     ? {
        //         ...pd,
        //         pipelineID: deployed?.id,
        //         scheduleOrderID: deployed.scheduleOrder,
        //         dataPipelineScheduleId: deployPipelines.dataPipelineScheduleId,
        //         deploymentStatus: deployed.pipelineStatus,
        //       }
        //     : pd,
        // )
        // setTRows(tp)
        //setShowPopUp(false)
      }
    }
  }

  // const handleRowClick = (action: string, rowId: string) => {
  //   const rows = convertPipelineToTableRows(pipelineData, pipelineSchedules)
  //   if (action === 'move' && rows.length && rowId) {
  //     // const up = rowId?.split('_').pop()?.includes('Upward')
  //     // const down = rowId?.split('_').pop()?.includes('Downward')
  //     const upOrDown = rowId?.split('_').pop()
  //     const scheduleOrder = rows.find((t) => t.id === parseInt(rowId?.split('_')[0]))?.scheduleOrder
  //     // const rowIndex = rows.findIndex((t) => t.id === parseInt(rowId))
  //     // let index = tRows.findIndex((t) => t.id === rowId)
  //     // if (rowIndex > 0) {
  //     //   let el = tRows[index]
  //     //   tRows[index].scheduleOrder = tRows[index - 1].scheduleOrder
  //     //   tRows[index - 1].scheduleOrder = el.scheduleOrder
  //     // scheduleOrder = -1
  //     // }

  //     // if (rowIndex !== -1 && rowIndex < rows.length - 1) {
  //     //   let el = tRows[index]
  //     //   tRows[index].scheduleOrder = tRows[index + 1].scheduleOrder
  //     //   tRows[index + 1].scheduleOrder = el.scheduleOrder
  //     // scheduleOrder = +1
  //     // }

  //     // let modif
  //     for (const row of rows) {
  //       // if (scheduleOrder && scheduleOrder > 0) {
  //       //   row.scheduleOrder = row?.scheduleOrder - 1
  //       // } else if (scheduleOrder && scheduleOrder !== -1 && scheduleOrder < rows.length - 1) {
  //       //   row.scheduleOrder = row?.scheduleOrder + 1
  //       // }

  //       if (upOrDown === 'Up') {
  //         row.scheduleOrder = row?.scheduleOrder + 1
  //       }

  //       if (upOrDown === 'Down') {
  //         row.scheduleOrder = row?.scheduleOrder - 1
  //       }
  //     }

  //     setTRows(rows) //.sort((p1, p2) => (p1.scheduleOrder > p2.scheduleOrder ? 1 : -1)))
  //   }
  // }

  return (
    <ActionPage>
      <ResultStatus
        severtiy={postError?.message ? 'error' : 'success'}
        showStatus={addedSuccess}
        closeStatus={() => {
          setAddedSuccess(false)
        }}
        alertMessage={postError?.message || 'Saved data pipeline !'}
      />
      <LucidPopUp
        showCloseIcon
        openPopUp={showPopUp}
        closePopup={setShowPopUp}
        confirmText="Deploy"
        onConfirm={confirmDeployPipelines}
        headingText={
          <Typography>Deploy {entityTypes.find((et) => et.id === selectedEntityType)?.value ?? ''} Pipeline</Typography>
        }
      >
        <Grid item xs={12} container spacing={2}>
          {/* <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>
              Schedule Order
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              type="number"
              size="small"
              fullWidth
              error={deployPipelines.scheduleOrder < 0}
              value={deployPipelines?.scheduleOrder ?? 0}
              onChange={({ target }) =>
                setDeploypipelines((prev) => ({
                  ...prev,
                  scheduleOrder: Number(target.value),
                }))
              }
            />
          </Grid> */}
          <Grid item xs={12} />
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>
              Pipeline Schedule
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              select
              size="small"
              fullWidth
              value={deployPipelines.dataPipelineScheduleId}
              onChange={({ target }) =>
                setDeploypipelines((prev) => ({
                  ...prev,
                  dataPipelineScheduleId: Number(target.value),
                }))
              }
            >
              {pipelineSchedules.map((schedule) => (
                <MenuItem key={schedule.id} value={schedule.id}>
                  {schedule.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>
              Service Connection
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              select
              size="small"
              fullWidth
              value={deployPipelines.serviceConnectionId}
              onChange={({ target }) =>
                setDeploypipelines((prev) => ({
                  ...prev,
                  serviceConnectionId: Number(target.value),
                }))
              }
            >
              {serviceConnections.map((con) => (
                <MenuItem key={con.id} value={con.id}>
                  {con.connectionName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </LucidPopUp>
      <LucidPopUp
        openPopUp={showServiceConnectionPopup}
        onConfirm={onSubmit}
        closePopup={setShowServiceConnectionPopup}
        headingText={<Typography variant="h4">Service Connections</Typography>}
        disabledConfirm
        showCloseIcon
      >
        <Container maxWidth="lg">
          <Grid item container spacing={2}>
            <Grid item xs={9}>
              <Typography variant="h6" color="primary">
                {serviceCon}
              </Typography>
              <pre style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(serviceConnections, null, 2)}</pre>
            </Grid>
          </Grid>
        </Container>
      </LucidPopUp>
      <Grid item container spacing={2} justifyContent="space-between">
        <Grid item xs={2}>
          <Typography variant="h4" gutterBottom color="primary">
            Data Pipelines
          </Typography>
        </Grid>
      </Grid>
      <Card sx={{ backgroundColor: 'white', boxShadow: 3, borderRadius: 3 }}>
        <CardContent>
          <Grid item container spacing={1} sx={{ pb: 2 }}>
            <Grid item xs={0.5}>
              <IconButton onClick={onClickHome}>
                <HomeOutlinedIcon />
              </IconButton>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                placeholder="Search"
                value={searchText ?? ''}
                onChange={({ target }) => setSearchText(target.value)}
                color="primary"
                size="small"
                type="search"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: !!searchText ? (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearchText('')}>
                        <CancelIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <></>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={2}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-multiple-chip-label">Data Lake Zone</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  value={selectedEntityType}
                  fullWidth
                  size="small"
                  onChange={({ target }) => setSelectedEntityType(target.value as number)}
                  input={<OutlinedInput id="select-multiple-chip" label="Data Lake Zone" />}
                  renderValue={(selected) => (
                    <Chip size="small" label={entityTypes.find((et) => et.id === selected)?.value} />
                  )}
                  MenuProps={MenuProps}
                >
                  {entityTypes.map((name, ix) => (
                    <MenuItem key={name.id} value={name.id}>
                      {name.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                select
                size="small"
                fullWidth
                label="Schedule"
                value={deployPipelines.dataPipelineScheduleId}
                InputProps={{
                  startAdornment: (
                    <ScheduleOutlined color="info" fontSize="small" titleAccess="Schedule" sx={{ mr: 2 }} />
                  ),
                }}
                onChange={({ target }) =>
                  setDeploypipelines((prev) => ({
                    ...prev,
                    dataPipelineScheduleId: Number(target.value),
                  }))
                }
              >
                {pipelineSchedules.map((schedule) => (
                  <MenuItem key={schedule.id} value={schedule.id}>
                    {schedule.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={15} />

            <Grid item container spacing={1}>
              <Grid item xs={1} />

              <Grid item xs={2}>
                <Button color="success" variant="contained" onClick={() => setShowServiceConnectionPopup(true)}>
                  Service Connection Details
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  color="primary"
                  startIcon={<EventNoteOutlinedIcon fontSize="small" titleAccess="Manage" />}
                  variant="contained"
                  onClick={toManageSchedules}
                >
                  Manage Schedules
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveOutlined fontSize="small" titleAccess="Save" />}
                  onClick={confirmSavePipelines}
                >
                  Save Pipelines
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="primary"
                  //disabled={}
                  startIcon={<ScheduleSendOutlinedIcon titleAccess="Deploy" fontSize="small" />}
                  onClick={confirmDeployPipelines}
                >
                  Deploy Pipelines
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container xs={15} />
          <Grid item xs={12} container spacing={1} alignItems={'stretch'}>
            <DataPipelineTable rows={pipelineData} setDeploypipelines={setDeploypipelines} />
          </Grid>
          <Grid item xs={12} container spacing={1} alignItems={'stretch'}>
            {/* <LucidDataGrid
              rows={tRows}
              columns={tableColumns}
              actions={{ movable: true }}
              //onRowSelectionChanged={onRowSelection}
              onClickAction={handleRowClick}
            /> */}
          </Grid>
        </CardContent>
      </Card>
    </ActionPage>
  )
}
