import { Dialog, DialogTitle, Grid, DialogContent, DialogActions, Button, IconButton } from '@mui/material'
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined'
import React, { ReactNode } from 'react'
import JobRunIcon from '../../ui-icons/JobRun.svg'

type Props = {
  onConfirm?: () => void
  openPopUp: boolean
  headingText: React.ReactNode
  closePopup: (close: boolean) => void
  children?: ReactNode
  confirmText?: string
  cancelText?: string
  showCloseIcon?: boolean
  disabledConfirm?: boolean
  jobsRunning?: boolean
  midButtonAction?: () => void
  midButtonText?: string
  solidCancelButton?: boolean
  solidMidButton?: boolean
}

export const LucidPopUp = ({
  headingText,
  onConfirm,
  openPopUp,
  closePopup,
  confirmText,
  cancelText,
  children,
  midButtonAction,
  midButtonText,
  showCloseIcon,
  disabledConfirm: diabledConfirm,
  solidCancelButton,
  solidMidButton,
}: Props) => {
  return (
    <Dialog
      open={openPopUp}
      onClose={closePopup}
      fullWidth
      //sx={{ maxWidth: '731px' }}
      disableEscapeKeyDown
      aria-labelledby="form-dialog-title"
      aria-describedby="form-dialog-description"
    >
      <Grid item container justifyContent={'center'} alignItems={'stretch'} xs={11} spacing={3}>
        <Grid item xs={11} />
        <Grid item container justifyContent={'center'} alignItems={'stretch'} xs={9}>
          {headingText}
        </Grid>

        {openPopUp && showCloseIcon ? (
          <Grid item xs={1}>
            <IconButton
              aria-label="close"
              color="primary"
              onClick={() => closePopup(!openPopUp)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                //color: (theme) => theme.palette.primary,
              }}
            >
              <CancelPresentationOutlinedIcon />
            </IconButton>
          </Grid>
        ) : null}
      </Grid>

      <DialogContent>
        {/* <Grid container justifyContent={'center'} alignItems={'center'} spacing={2}>
          <Grid item>{jobsRunning && <img src={JobRunIcon} alt="Logo" />}</Grid>
        </Grid> */}
        <Grid container justifyContent="space-between" alignItems={'baseline'}>
          {children}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container justifyContent="space-between" alignContent="baseline" spacing={2}>
          <Grid item xs={6} md={4}>
            {cancelText ? (
              <Button
                fullWidth
                variant={solidCancelButton ? 'contained' : 'text'}
                onClick={() => closePopup(!openPopUp)}
              >
                {cancelText}
              </Button>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={6} md={4}>
            {midButtonText && midButtonAction ? (
              <Button fullWidth variant={solidMidButton ? 'contained' : 'text'} onClick={midButtonAction}>
                {midButtonText}
              </Button>
            ) : (
              <></>
            )}
          </Grid>

          {confirmText && (
            <Grid item xs={3} md={4}>
              <Button variant="contained" fullWidth color="primary" disabled={diabledConfirm} onClick={onConfirm}>
                {confirmText}
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
