// import { IJupyterReactState, Jupyter, createInjectableStore, createReduxEpicStore } from '@datalayer/jupyter-react'
import React, { useEffect, useMemo, useState } from 'react'
// import CellComponents from './examples/cell/CellComponents'
import { ProgressBar } from '../ProgressBar'
import { Box, Grid, Link, TextField, Typography } from '@mui/material'
import { LucidPopUp } from '../LucidPopUp/LucidPopUp'
import { useUpdateCustomAttribeNotebook } from '../../hooks/dataAttrbutesHooks'
import LucidEditor from '../LucidSparkEditor/LucidEditor'
 
type Props = {
  headingText: string
  noteBookString?: string
  updateNoteBookString?: (txt: string) => void
  commitMessage?: string
  updateCommitMessage?: React.Dispatch<React.SetStateAction<string>>
  executeNotebook?: () => Promise<void>
}
 
// const jupyterStore = createReduxEpicStore()
// const injectableStore = createInjectableStore(jupyterStore)
 
// injectableStore.inject('init', (state: IJupyterReactState, _action: any) => {
//   return state || {}
// })
 
export const LucidJupyter = ({ headingText, noteBookString, updateNoteBookString, commitMessage, updateCommitMessage, executeNotebook}: Props) => {
  const {updateAttributeNoteBook} = useUpdateCustomAttribeNotebook()
  
  //commenting below setState, this is conflicting with prop.updateNoteBookString.
  // const [pyCode, setPyCode] = useState<string>()
 
  // useEffect(() => {
  //   if (noteBookString && !pyCode) {
  //     setPyCode(noteBookString)
  //   }
  // }, [noteBookString])
 
  const handleChange = (nbstring: string) => {
    updateNoteBookString && updateNoteBookString(nbstring)
    // setPyCode(pyCode)
  }
  return (
    <>
    <LucidEditor headingText={headingText} noteBookString={noteBookString} updateNoteBookString={updateNoteBookString} commitMessage={commitMessage} updateCommitMessage={updateCommitMessage} executeNotebook={executeNotebook}/>
    </>
  )
}