import { Card, CardContent, CardHeader, Grid, Tooltip, Typography } from '@mui/material'
import { useGetAllDataColumns } from '../../hooks/dataColumnHooks'
import { useMemo } from 'react'
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined'

type Props = {
  tableName: string
  tableId: number
  searchText?: string
}
export const TableColumnCard = ({ tableName, tableId, searchText }: Props) => {
  const { dataColumns } = useGetAllDataColumns(tableId, searchText)
  const tableColumns = useMemo(() => {
    if (!dataColumns) return []
    return dataColumns
  }, [dataColumns])
  return (
    <Card sx={{ maxWidth: 300, borderRadius: 0.5 }}>
      <CardHeader
        sx={{ backgroundColor: '#316896' }}
        title={<Typography sx={{ color: '#FFFFFF', fontSize: 14 }}>{tableName}</Typography>}
      />

      <CardContent sx={{ backgroundColor: '#F2F2F2' }}>
        <Grid item container spacing={2}>
          {tableColumns.map((c) => (
            <Grid item container key={c.id} direction={'row'}>
              <Grid item xs={0.5} />
              <Grid item xs={6}>
              {c.dataColumnName.length > 15 ? (
                    <Tooltip title={<Typography variant="body2">{c.dataColumnName}</Typography>} placement="top">
                      <Typography sx={{ fontSize: 12 }}>{c.dataColumnName.slice(0, 15) + '...'}</Typography>
                    </Tooltip>
                  ) : (
                    <Typography sx={{ fontSize: 12 }}>{c.dataColumnName}</Typography>
                  )}
               
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={3}>
                
                <Typography sx={{ fontSize: 12 }}>{c.datatype}</Typography>
              </Grid>
              <Grid item> {c.isPrimary && <KeyOutlinedIcon fontSize="small" titleAccess='Primary' />}</Grid>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  )
}
