import { useEffect, useState } from 'react'
import { NewSubjectArea, SubjectArea } from '../businessObjects'
import { useLucidAuthContext } from '../LucidAuthContext/LucidAuthContext'
import { subjectAreaServices } from '../apiServices/subjectAreaDetails'
import { AxiosError } from 'axios'
type GetSubjectAreaFunc = (datapodId: string, recordCount?: number, pageNumber?: number) => Promise<void>
type UpdateSubjectAreaFunc = (dataPodID: string, subjectArea: SubjectArea) => Promise<void>

type SaveSubjectAreaFunc = (dataPodId: string, subjectAreaInp: NewSubjectArea) => Promise<void>

type Pagination = {
  updatePageCount?: (pages: number) => void
}

export const useGetSubjectAreas = ({
  updatePageCount,
}: Pagination): [GetSubjectAreaFunc, SubjectArea[] | undefined, boolean | undefined, boolean | undefined] => {
  const [subjectAreas, setSubjectAreas] = useState<SubjectArea[]>()
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const getSubjectAreasByPodId = async (dataPodId: string, recordCount?: number, pageNumber?: number) => {
    setLoading(true)
    try {
      const token = await getBearerToken()
      if (token) {
        const { data, status, headers } = await subjectAreaServices.getSubjectAreasByDataPodId(
          token,
          dataPodId,
          recordCount,
          pageNumber,
        )
        if (status == 200) {
          setSubjectAreas(data)
          setLoading(false)
          if (updatePageCount && recordCount) {
            const totalRecords = headers['x-total-count']
            const totalPages = Math.round(totalRecords / recordCount)
            updatePageCount(totalPages)
          }
        }
      }
    } catch (error) {
      setError(true)

      setLoading(false)
      return
    }
  }
  return [getSubjectAreasByPodId, subjectAreas, loading, error]
}

export const useAddSubjectArea = (): [
  SaveSubjectAreaFunc,
  SubjectArea | undefined,
  boolean | undefined,
  boolean | undefined,
] => {
  const [subjectArea, setSubjectArea] = useState<SubjectArea>()
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()

  const submitSubjectArea = async (dataPodId: string, subjectAreaInp: NewSubjectArea) => {
    setLoading(true)
    try {
      const token = await getBearerToken()
      if (token) {
        const { data, status } = await subjectAreaServices.addNewSubjectArea(token, dataPodId, subjectAreaInp)
        if (status == 200) {
          setSubjectArea(data)
          setLoading(false)
        }
      }
    } catch (error) {
      setError(true)

      setLoading(false)
      return
    }
  }
  return [submitSubjectArea, subjectArea, loading, error]
}

export const useGetSubjectAreaById = () => {
  const [subjectArea, setSubjectArea] = useState<SubjectArea>()
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()

  const getSubjAreaByID = async (subjectID: number) => {
    setLoading(true)
    try {
      const token = await getBearerToken()
      if (token) {
        const { data, status } = await subjectAreaServices.getSubjectAreaById(token, subjectID)
        if (status == 200) {
          setSubjectArea(data)
          setLoading(false)
        }
      }
    } catch (error) {
      setError(true)

      setLoading(false)
      return
    }
  }
  return [getSubjAreaByID, subjectArea, loading, error]
}
export const useUpdateSubjectArea = (): [
  UpdateSubjectAreaFunc,
  SubjectArea | undefined,
  boolean | undefined,
  boolean | undefined,
] => {
  const [subjectArea, setSubjectArea] = useState<SubjectArea>()
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()

  const updateSubjAreaByID = async (dataPodID: string, subjectArea: SubjectArea) => {
    setLoading(true)
    try {
      const token = await getBearerToken()
      if (token) {
        const { data, status } = await subjectAreaServices.updateSubjectArea(token, dataPodID, subjectArea)
        if (status == 200) {
          setSubjectArea(data)
          setLoading(false)
        }
      }
    } catch (error) {
      const err = error as AxiosError
      if (!err.isAxiosError && err.response?.status != 404) {
        console.log(err.response?.data)
        setError(true)
      }
      setLoading(false)
    }
  }
  return [updateSubjAreaByID, subjectArea, loading, error]
}

export const useDeleteSubjectArea = () => {
  const [deleteStatus, setDeleteStatus] = useState<boolean>()
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()

  const deleteSubjAreaID = async (subjectID: number) => {
    setLoading(true)
    try {
      const token = await getBearerToken()
      if (token) {
        const { status } = await subjectAreaServices.deleteSubjectArea(token, subjectID)
        if (status == 200) {
          setDeleteStatus(true)
          setLoading(false)
        }
      }
    } catch (error) {
      setError(true)

      setLoading(false)
      return
    }
  }
  return [deleteSubjAreaID, deleteStatus, loading, error]
}

export const useDeleteSubjectAreaByID = (): { deleteSubjAreaID: (id?: number) => Promise<boolean | undefined> } => {
  const { getBearerToken } = useLucidAuthContext()

  const deleteSubjAreaID = async (subjectID?: number) => {
    try {
      const token = await getBearerToken()
      if (token && subjectID) {
        const { status } = await subjectAreaServices.deleteSubjectArea(token, subjectID)
        if (status == 204) {
          return true
        }
      }
    } catch (error) {
      const { message } = error as AxiosError
      console.error({ message })
    }
  }
  return { deleteSubjAreaID }
}

export const useGetSubjectArea = (subjectAreaId?: number): { subjectAreaInfo?: SubjectArea } => {
  const [subjectArea, setSubjectArea] = useState<SubjectArea[]>()

  const { getBearerToken } = useLucidAuthContext()

  const getSubjArea = async () => {
    try {
      const token = await getBearerToken()
      if (token && subjectAreaId) {
        const { data, status } = await subjectAreaServices.getSubjectAreaById(token, subjectAreaId)
        if (status == 200) {
          setSubjectArea(data)
        }
      }
    } catch (error) {
      return
    }
  }
  useEffect(() => {
    if (subjectAreaId) {
      getSubjArea()
    }
  }, [subjectAreaId])
  return { subjectAreaInfo: subjectArea?.length ? subjectArea[0] : undefined }
}

export const useGetAllSubjectAreas = (dataPodId?: string): { subjectAreas: SubjectArea[]; refetch: () => void } => {
  const [subjectAreas, setSubjectAreas] = useState<SubjectArea[]>([])
  const [loading, setLoading] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const getSubjectAreasByPodId = async () => {
    setLoading(true)
    try {
      const token = await getBearerToken()
      if (token && dataPodId) {
        const { data, status } = await subjectAreaServices.getSubjectAreasByDataPodId(token, dataPodId)
        if (status == 200) {
          setSubjectAreas(data)
          setLoading(false)
        }
      }
    } catch (e) {
      console.log({ e })
      setLoading(false)
    }
  }
  useEffect(() => {
    getSubjectAreasByPodId()
  }, [dataPodId])
  return { subjectAreas: subjectAreas, refetch: getSubjectAreasByPodId }
}
