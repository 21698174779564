import axios from 'axios'
import { environment } from '../utils/environment'
import { getAxiosRequestConfig } from './apiserviceHelpers'
import { sendRequest } from '../requestManager/sendRequest'
import { DataTransformer, NewDataTransformer } from '../businessObjects'

const dataTransformerPath = {
  addNewDataTransformer: 'DataTransformer/addnew',
  updateDataTransformer: 'DataTransformer/Update',
  getDataTransformerById: 'DataTransformer/GetById',
  getDetailDataTransformer: 'DataTransformer/GetDetailDataTransformer',
}

// const applicationPath = {
//   dataEntityRelationships: 'Application/getEntityRelationShips',
//   dataTableRelationships: 'Application/GetdataTableRelationships',
//   newTableRelationships: 'Application/AddDataTableRelationships',
//   newEntityRelationship: 'Application/AddEntityRelationship',
//   updateEntityRelationship: 'Application/UpdateEntityRelationship',
//   updateTableRelationship: 'Application/UpdateDataTableRelationships',
//   entityReconPath: 'Application/GetEntityRecon',
//   addEntityRecon: 'Application/AddEntityRecon',
//   updateEntityRecon: 'Application/UpdateEntityRecon',
// }

export const addNewDataTransformer = async (token: string, dataTransformer: NewDataTransformer, dataPodId: string) => {
  const url = dataTransformerPath.addNewDataTransformer + `?datapodID=${dataPodId}`
  return await sendRequest(url, 'POST', token, dataTransformer)
}

export const updateDataTransformer = async (token: string, dataTransformer: DataTransformer, dataPodId: string) => {
  const body = {
    transformerType: dataTransformer.transformerTypeName,
    id: dataTransformer.id,
    dataAttributeMapId: dataTransformer.dataAttributeMapId,
    transformerText: dataTransformer.transformerText,
    transformerQuery: dataTransformer.transformerQuery,
    dataEntityId: dataTransformer.dataEntityId,
  }
  const url = dataTransformerPath.updateDataTransformer + `?datapodID=${dataPodId}`
  return await sendRequest(url, 'PUT', token, body)
}

// export const updateDataTransformer = async (
//   token: string,
//   id: number,
//   dataAttributeMapId: number,
//   transformerText: string,
//   transformerQuery: string,
// ) => {
//   const body = { id, dataAttributeMapId, transformerText, transformerQuery }
//   const config = getAxiosRequestConfig(token)
//   const url = dataTransformerPath.updateDataTransformer
//   return await axios.put(url, body, config)
// }

export const getDetailDataTransformer = async (
  token: string,
  datapodID: string,
  dataAttributeId?: number,
  dataEntityId?: number,
  records?: number,
  pageNumber?: number,
) => {
  const paginationParams = records && pageNumber ? `&RecordsPerPage=${records}&PageNumber=${pageNumber}` : null
  let url = dataTransformerPath.getDetailDataTransformer + `?datapodID=${datapodID}`

  if (dataAttributeId) {
    url += `&dataAttributeId=${dataAttributeId}`
  }
  if (dataEntityId) {
    url += `&dataEntityId=${dataEntityId}`
  }
  //return await axios.get(paginationParams ? url + paginationParams : url, config)
  return await sendRequest(paginationParams ? url + paginationParams : url, 'GET', token)
}
