import {
  NewDataPipeline,
  NewPipelineExecutionStatus,
  NewPipelineSchedule,
  PipelineSchedule,
  UpdatePipelineExecutionstatus,
  addDataLakeBody,
  UpdateDataPipelinesParams
} from '../businessObjects'
import { sendRequest } from '../requestManager/sendRequest'


const dataEngineeringPath = {
  getServiceConnection: 'DataEngineering/GetServiceConnection',
  getPipeline: 'DataEngineering/GetPipeline',
  updateDataPiplines: 'DataEngineering/UpdatedataPipelines',
  addDataLake: 'DataEngineering/AdddataLake',
  addDataPipeline: 'DataEngineering/AdddataPipelines',
  getDataPipelineExecution: 'DataEngineering/GetDataPipelineExecutionStatus',
  addDataPipelineExecution: 'DataEngineering/AddDataPipelineExecutionStatus',
  updateDataPipelineExecution: 'DataEngineering/UpdateDataPipelineExecutionStatus',
  getPipelineSchedules: 'DataEngineering/GetDataPipelineSchedule',
  addPipelineSchedules: 'DataEngineering/AddDataPipelineSchedule',
  updatePipelineSchedules: 'DataEngineering/UpdateDataPipelineSchedule',
}

const getServiceConnection = async (
  token: string,
  datapodId?: string,
  connectionId?: number,
  serviceConnectionTypeID?: string,
  serviceTypeID?: number,
) => {
  let endPoint = dataEngineeringPath.getServiceConnection
  if (datapodId) {
    endPoint += `?dataPodId=${datapodId}`
  } else if (datapodId && connectionId && !serviceConnectionTypeID && !serviceTypeID) {
    endPoint += `?dataPodId=${datapodId}&id=${connectionId}`
  } else if (datapodId && connectionId && serviceConnectionTypeID && !serviceTypeID) {
    endPoint += `?dataPodId=${datapodId}&id=${connectionId}&serviceConnectionTypeID=${serviceConnectionTypeID}`
  } else if (datapodId && connectionId && serviceConnectionTypeID && serviceTypeID) {
    endPoint += `?dataPodId=${datapodId}&id=${connectionId}&serviceConnectionTypeID=${serviceConnectionTypeID}&serviceTypeID=${serviceTypeID}`
  } else if (datapodId && serviceConnectionTypeID) {
    endPoint += `?dataPodId=${datapodId}&serviceConnectionTypeID=${serviceConnectionTypeID}`
  }

  if (serviceConnectionTypeID) {
    endPoint += `&serviceConnectionTypeID=${serviceConnectionTypeID}`
  }

  if (serviceTypeID) {
    endPoint += `&serviceTypeID=${serviceTypeID}`
  }

  return sendRequest(endPoint, 'GET', token)
}

const getPipeline = async (
  token: string,
  datapodId: string,
  pipelineId?: number,
  entityID?: number,
  dataLakeZoneId?: number,
  searchText?: string,
  records?: number,
  pageNumber?: number,
) => {
  let endPoint = dataEngineeringPath.getPipeline
  if (datapodId && records && pageNumber) {
    endPoint += `?dataPodId=${datapodId}&RecordsPerPage=${records}&PageNumber=${pageNumber}`
  } else if (datapodId && pipelineId && !entityID && !dataLakeZoneId && records && pageNumber) {
    endPoint += `?dataPodId=${datapodId}&id=${pipelineId}&RecordsPerPage=${records}&PageNumber=${pageNumber}`
  } else if (datapodId && pipelineId && entityID && !dataLakeZoneId && records && pageNumber) {
    endPoint += `?dataPodId=${datapodId}&id=${pipelineId}&entityID=${entityID}&RecordsPerPage=${records}&PageNumber=${pageNumber}`
  }
  if (dataLakeZoneId) {
    endPoint += `&dataLakeZoneId=${dataLakeZoneId}`
  }
  if (searchText) {
    endPoint += `&SearchText=${searchText}`
  }
  return sendRequest(endPoint, 'GET', token)
}

const updateDataPipelines = async (token: string, dataSystem: UpdateDataPipelinesParams, dataPodId: string) => {
  const endPoint = dataEngineeringPath.updateDataPiplines + `?dataPodId=${dataPodId}`
  return await sendRequest(endPoint, 'PUT', token, dataSystem)
}

const getDataPipelineExecutionStatus = async (
  token: string,
  datapodId: string,
  scheduledId?: number,
  pipelineId?: number,
) => {
  let endPoint = dataEngineeringPath.getDataPipelineExecution + `?dataPodId=${datapodId}`
  if (scheduledId) {
    endPoint += `&id=${scheduledId}`
  }
  if (pipelineId) {
    endPoint += `&pipelineid=${pipelineId}`
  }
  return await sendRequest(endPoint, 'GET', token)
}
const addDataPipeline = async (token: string, dataPodId: string, newDataPipeline: NewDataPipeline, fabricToken: string) => {
  let endPoint = dataEngineeringPath.addDataPipeline + `?dataPodId=${dataPodId}`

  if (fabricToken) {
    endPoint += `&bearerToken=${fabricToken}`
  }
  
  return await sendRequest(endPoint, 'POST', token, newDataPipeline)
}
const addDataPipelineExecution = async (
  token: string,
  dataPodId: string,
  pipelineExection: NewPipelineExecutionStatus,
) => {
  let endPoint = dataEngineeringPath.addDataPipelineExecution + `?dataPodId=${dataPodId}`

  return await sendRequest(endPoint, 'POST', token, pipelineExection)
}
const updateDataPipelineExecution = async (
  token: string,
  dataPodId: string,
  updatePipelinesExection: UpdatePipelineExecutionstatus,
) => {
  let endPoint = dataEngineeringPath.updateDataPipelineExecution + `?dataPodId=${dataPodId}`
  return await sendRequest(endPoint, 'POST', token, updatePipelinesExection)
}

const getDataPipelineSchedule = async (token: string, scheduleId?: number, searchText?: string) => {
  let endPoint = dataEngineeringPath.getPipelineSchedules
  if (scheduleId && !searchText) {
    endPoint += `?id=${scheduleId}`
  }
  if (!scheduleId && searchText) {
    endPoint += `?SearchText=${searchText}`
  }
  return await sendRequest(endPoint, 'GET', token)
}
const addDataPipelineSchedule = async (token: string, newPipelineSchedule: NewPipelineSchedule) => {
  let endPoint = dataEngineeringPath.addPipelineSchedules
  return await sendRequest(endPoint, 'POST', token, newPipelineSchedule)
}
const updateDataPipelineSchedule = async (token: string, pipelineSchedule: PipelineSchedule) => {
  let endPoint = dataEngineeringPath.updatePipelineSchedules
  return await sendRequest(endPoint, 'PUT', token, pipelineSchedule)
}

const addDataLake = async (token: string, dataLakeBody: addDataLakeBody, dataPodId: string) => {
  const endPoint = dataEngineeringPath.addDataLake + `?dataPodId=${dataPodId}`
  return await sendRequest(endPoint, 'POST', token, dataLakeBody)
}


export const dataEngineeringServices = {
  getServiceConnection,
  getPipeline,
  updateDataPipelines,
  getDataPipelineExecutionStatus,
  addDataPipeline,
  addDataPipelineExecution,
  updateDataPipelineExecution,
  getDataPipelineSchedule,
  addDataPipelineSchedule,
  updateDataPipelineSchedule,
  addDataLake
}
