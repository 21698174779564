import React from 'react'

import { useEffect, useMemo, useState } from 'react'
import ActionPage from '../../ui-components/ActionPage'
import { Box, Button, Card, CardContent, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CancelIcon from '@mui/icons-material/Cancel'

import { cardContainer } from '../../styles/globalStyles'

import OutputIcon from '../../ui-icons/Output.svg'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import { useGetJobStatus, useGetProfileInputs, useRunJob } from '../../hooks/dataProfilingHooks'
import { useGetQueryString } from '../../hooks/queryStringHook'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useGetAllDataPods, useGetDataPodInfo } from '../../hooks/datapodHooks'
import { GridColDef, GridFilterModel, GridRowSelectionModel, GridRowsProp } from '@mui/x-data-grid'
import { ActionTypes, GridColStatus, LucidDataGrid } from '../../ui-components/LucidDataTable/LucidDatatable'
import { JobName, JobStatus, jobNames } from '../../hooks/constants'
import { LucidPopUp } from '../../ui-components/LucidPopUp/LucidPopUp'
import OutputOutlinedIcon from '@mui/icons-material/OutputOutlined'
import { ROUTES } from '../../Routes/constants'
import { ProcessVerticalFlow } from '../Overview/ProcessVerticalFlow'

type Props = {}
export type TriggerState = {
  triggerId: string | number
  status: boolean
}
export type TriggeredJobState = {
  jobName: JobName
  isFinished: boolean
  isRunning: boolean
}
export const DataProfilingProcess = (props: Props) => {
  const { dataPodId } = useParams<{ dataPodId: string }>()

  const navigate = useNavigate()
  const { dataPodInfo } = useGetDataPodInfo(dataPodId)

  const valueOptions: JobStatus[] | string[] = ['FAILED', 'SUCCESS', 'CANCELED', 'PENDING']
  // const scanStatus = GridColStatus({
  //   valueOptions: valueOptions,
  //   headerLabel: 'Scan Status',
  //   fieldStatus: 'scanStatus',
  // })
  // const mappingStatus = GridColStatus({
  //   valueOptions: valueOptions,
  //   headerLabel: 'Mapping Status',
  //   fieldStatus: 'mapStatus',
  // })
  // const tableCols: GridColDef[] = [
  //   {
  //     field: 'dataSystem',
  //     headerName: 'Data System',
  //     type: 'string',
  //     width: 150,
  //   },
  //   {
  //     field: 'dataSytemDesc',
  //     headerName: 'Description',
  //     type: 'string',
  //     width: 250,
  //     hideable: true,
  //   },
  //   {
  //     field: 'subjectArea',
  //     headerName: 'Subject Area',
  //     type: 'string',
  //     width: 150,
  //   },
  //   {
  //     field: 'subjectAreaDesc',
  //     headerName: 'Description',
  //     type: 'string',
  //     width: 250,
  //   },
  //   scanStatus,
  //   mappingStatus,
  // ]

  const onClickBack = () => {
    const dataProfilingRoute = generatePath(ROUTES.SytemProfiling)
    navigate(dataProfilingRoute)
  }
  return (
    <ActionPage>
      <Box>
        <Grid container alignItems="baseline">
          <Grid item xs={0}>
            <Button
              variant="outlined"
              onClick={onClickBack}
              startIcon={<KeyboardBackspaceOutlinedIcon fontSize="small" />}
            >
              Back
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Grid container spacing={2}>
          <Grid item xs={14}>
            <ProcessVerticalFlow dataPod={dataPodInfo} />
          </Grid>
        </Grid>
      </Box>
    </ActionPage>
  )
}
