import { useEffect, useState } from 'react'
import { useLucidAuthContext } from '../LucidAuthContext/LucidAuthContext'
import { attributeProfileServices } from '../apiServices/attributeProfile'
import { dataAttributesServices, getDataAttributesByEntityId } from '../apiServices/dataAttributes'
import { entityServices } from '../apiServices/entities'
import { genServices } from '../apiServices/lucidGenAIServices'
import {
  CustomEntityData,
  DataAttribute,
  DataColumn,
  GenAiGenerateMappingResponse,
  NewAttribute,
  NewAttributeMapBody,
  NewEntityData,
  NewEntityMap,
  NewEntityMapBody,
} from '../businessObjects'
import { getDataColumnByName } from '../apiServices/dataColumn'

export const useScanAndGenerateMappings = (dataPodId?: string, dataSystemId?: number) => {
  const [generating, setGenerating] = useState<boolean>(false)
  const [generatingError, setGeneratingError] = useState<boolean>(false)
  const [generateSuccess, setGenerateSuccess] = useState<boolean>(false)
  const [entityReused, setEntityResused] = useState<boolean>(false)
  const { getBearerToken } = useLucidAuthContext()

  const scanAndGen = async (tableIds: number[]) => {
    try {
      const token = await getBearerToken()
      if (token && dataPodId && dataSystemId && tableIds.length) {
        scanAndGenerateSelectedTableId(
          tableIds,
          dataPodId,
          dataSystemId,
          token,
          setGenerateSuccess,
          setGenerating,
          setGeneratingError,
        )
      }
    } catch (error) {}
  }

  // const scanAndGen = async (tableId: number) => {
  //   try {
  //     const token = await getBearerToken()
  //     if (token && dataPodId && dataSystemId && tableId) {
  //       setGenerating(true)
  //       const { data, status } = await genServices.generateMappings(dataPodId, dataSystemId, tableId)
  //       if (status == 200) {
  //         const genMapp: GenAiGenerateMappingResponse = data
  //         if (genMapp.data.length) {
  //           let allEntitySuccess: number[] = []
  //           let allAttributesSuccess: number[] = []
  //           const totalEntites = genMapp.data.length
  //           let totalAttributes = 0
  //           for (let i = 0; i < genMapp.data.length; i++) {
  //             const nEntity = genMapp.data[i]
  //             let Response: CustomEntityData
  //             let status: any = 0
  //             let entityName: string = nEntity.entity_name
  //             entityName = entityName.replace(/\s/g, '')
  //             const { data: dataEnt } = await entityServices.getEntityName(token, undefined, entityName, dataPodId)
  //             if (dataEnt.message.includes('No Records Found') || dataEnt.dataEntities === null) {
  //               const newEntity: NewEntityData = {
  //                 dataEntityName: entityName,
  //                 description: nEntity.entity_description,
  //                 coreEntity: false,
  //                 entityType: nEntity.entity_type,
  //                 profileName: 'generated',
  //                 dataLakeZone: 1,
  //               }
  //               const { data: entityRes, status: newEntityStatus } = await entityServices.addNewCustomEntity(
  //                 token,
  //                 dataPodId,
  //                 newEntity,
  //               )
  //               Response = entityRes
  //               status = newEntityStatus
  //               setEntityResused(false)
  //             } else {
  //               Response = dataEnt.dataEntities[0]
  //               setEntityResused(true)
  //             }
  //             if (Response) {
  //               const newEnt: CustomEntityData = Response
  //               const nEntMap: NewEntityMapBody = {
  //                 dataEntityId: newEnt.id,
  //                 dataTableId: tableId,
  //                 runId: 0,
  //                 profileName: 'generated',
  //               }
  //               const { data: mapRes, status: mapStatus } = await entityServices.addEntityMap(token, dataPodId, nEntMap)
  //               if (
  //                 mapStatus == 200 &&
  //                 nEntity.attributes.length === nEntity.attribute_description.length &&
  //                 nEntity.attributes.length === nEntity.columns.length
  //               ) {
  //                 const entMap: NewEntityMap = mapRes
  //                 allEntitySuccess = [...allEntitySuccess, newEnt.id]
  //                 totalAttributes = nEntity.attributes.length

  //                 for (let ati = 0; ati < nEntity.attributes.length; ati++) {
  //                   let attributeName: string = nEntity.attributes[ati]
  //                   attributeName = attributeName.replace(/\s/g, '')
  //                   const attributeDetails: NewAttribute = {
  //                     dataEntityId: newEnt.id,
  //                     dataAttributeName: attributeName,
  //                     dataAttributeDataType: 'string',
  //                     description: nEntity.attribute_description[ati],
  //                     isBusinessKey: false,
  //                     dataClassification: nEntity.dataClassification[ati],
  //                     dataAttributeType: 'simple',
  //                   }
  //                   const { data: columData, status: colStatus } = await getDataColumnByName(
  //                     token,
  //                     nEntity.columns[ati],
  //                     dataPodId,
  //                     tableId,
  //                   )
  //                   const dataTableColumn: DataColumn[] = columData
  //                   const { data: exDataAttributeList, status: foundAttributeStatus } =
  //                     await getDataAttributesByEntityId(token, newEnt.id)
  //                   const foundDataAttributes: DataAttribute[] = exDataAttributeList

  //                   if (colStatus == 200 && dataTableColumn.length) {
  //                     if (
  //                       !foundDataAttributes.some((at) => at.dataAttributeName === attributeDetails.dataAttributeName)
  //                     ) {
  //                       let attributeResponse: DataAttribute
  //                       const { data: attr } = await dataAttributesServices.getDataAttributeName(
  //                         token,
  //                         dataPodId,
  //                         attributeDetails.dataAttributeName,
  //                         attributeDetails.dataEntityId,
  //                       )
  //                       if (entityReused) {
  //                         if (attr.message.includes('No Records found') || attr.dataAttributes === null) {
  //                           const { data: attrResp, status: attribStatus } =
  //                             await dataAttributesServices.createDataAttribute(token, attributeDetails, dataPodId)
  //                           attributeResponse = attrResp
  //                         } else {
  //                           attributeResponse = attr.dataAttributes[0]
  //                         }
  //                       } else {
  //                         const { data: attrResp, status: attribStatus } =
  //                           await dataAttributesServices.createDataAttribute(token, attributeDetails, dataPodId)
  //                         attributeResponse = attrResp
  //                       }
  //                       if (attributeResponse) {
  //                         const dAtt: DataAttribute = attributeResponse
  //                         const attrMap: NewAttributeMapBody = {
  //                           dataTableId: tableId,
  //                           dataEntityId: newEnt.id,
  //                           dataAttributeId: dAtt.id,
  //                           dataColumnId: dataTableColumn[0].id,
  //                           dataEntityMapId: entMap.id,
  //                           runid: 0,
  //                         }
  //                         const { data: attrMapRes, status: attrMapStatus } =
  //                           await attributeProfileServices.addNewAttributeMap(token, dataPodId, attrMap)
  //                         if (attrMapStatus == 200) {
  //                           allAttributesSuccess = [...allAttributesSuccess, dAtt.id]
  //                         }
  //                       }
  //                     } else {
  //                       const exAttrb = foundDataAttributes.find(
  //                         (at) => at.dataAttributeName === attributeDetails.dataAttributeName,
  //                       )
  //                       if (exAttrb?.id) {
  //                         const exAttrMap: NewAttributeMapBody = {
  //                           dataTableId: tableId,
  //                           dataEntityId: newEnt.id,
  //                           dataAttributeId: exAttrb.id,
  //                           dataColumnId: dataTableColumn[0].id,
  //                           dataEntityMapId: entMap.id,
  //                           runid: 0,
  //                         }
  //                         const { data: attrMapRes, status: attrMapStatus } =
  //                           await attributeProfileServices.addNewAttributeMap(token, dataPodId, exAttrMap)
  //                         if (attrMapStatus == 200) {
  //                           allAttributesSuccess = [...allAttributesSuccess, foundDataAttributes[0].id]
  //                         }
  //                       }
  //                     }
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //           if (totalEntites === allEntitySuccess.length && totalAttributes === allAttributesSuccess.length) {
  //             setGenerateSuccess(true)
  //             setGenerating(false)
  //           }
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     setGeneratingError(true)
  //     setGenerating(false)
  //   }
  // }

  return { generatingError, generating, generateSuccess, scanAndGen }
}

export const scanAndGenerateSelectedTableId = async (
  tableIds: number[],
  dataPodId: string,
  dataSystemId: number,
  token: string,
  updateSuccess: (success: boolean) => void,
  updateGenerating: (inProgress: boolean) => void,
  updateError: (err: boolean) => void,
): Promise<void> => {
  try {
    let allTableIdSuccess: number[] = []
    updateGenerating(true)
    for (let i = 0; i < tableIds.length; i++) {
      const tableId = tableIds[i]
      if (token && dataPodId && dataSystemId && tableId) {
        const { data, status } = await genServices.generateMappings(dataPodId, dataSystemId, tableId)
        if (status == 200) {
          const genMapp: GenAiGenerateMappingResponse = data
          if (genMapp.data.length) {
            let allEntitySuccess: number[] = []
            let allAttributesSuccess: number[] = []
            const totalEntites = genMapp.data.length
            let totalAttributes = 0
            let isEntityReused = false
            for (let i = 0; i < genMapp.data.length; i++) {
              const nEntity = genMapp.data[i]
              let Response: CustomEntityData
              let status: any = 0
              let entityName: string = nEntity.entity_name
              entityName = entityName.replace(/\s/g, '')
              console.log(entityName)
              const { data: dataEnt } = await entityServices.getEntityName(
                token,
                undefined,
                undefined,
                entityName,
                dataPodId,
                undefined,
                undefined,
                undefined,
                undefined,
              )
              if (dataEnt.message.includes('No Records Found') || dataEnt.dataEntities === null) {
                console.log("inside if")
                const newEntity: NewEntityData = {
                  dataEntityName: entityName,
                  description: nEntity.entity_description,
                  coreEntity: false,
                  entityType: nEntity.entity_type,
                  profileName: 'generated',
                  dataLakeZone: 1,
                }
                const { data: entityRes, status: newEntityStatus } = await entityServices.addNewCustomEntity(
                  token,
                  dataPodId,
                  newEntity,
                )
                Response = entityRes
                status = newEntityStatus
                isEntityReused = false
              } else {
                console.log("Inside else")
                Response = dataEnt.dataEntities[0]
                isEntityReused = true
              }
              if (Response) {
                const newEnt: CustomEntityData = Response
                const nEntMap: NewEntityMapBody = {
                  dataEntityId: newEnt.id,
                  dataTableId: tableId,
                  runId: 0,
                  profileName: 'generated',
                }
                const { data: mapRes, status: mapStatus } = await entityServices.addEntityMap(token, dataPodId, nEntMap)
                if (
                  mapStatus == 200 &&
                  nEntity.attributes.length === nEntity.attribute_description.length &&
                  nEntity.attributes.length === nEntity.columns.length
                ) {
                  const entMap: NewEntityMap = mapRes
                  allEntitySuccess = [...allEntitySuccess, newEnt.id]
                  totalAttributes = nEntity.attributes.length

                  for (let ati = 0; ati < nEntity.attributes.length; ati++) {
                    let attributeName: string = nEntity.attributes[ati]
                    attributeName = attributeName.replace(/\s/g, '')
                    const attributeDetails: NewAttribute = {
                      dataEntityId: newEnt.id,
                      dataAttributeName: attributeName,
                      dataAttributeDataType: 'string',
                      description: nEntity.attribute_description[ati],
                      isBusinessKey: false,
                      dataClassification: nEntity.dataClassification[ati],
                      dataAttributeType: 'simple',
                    }
                    const { data: columData, status: colStatus } = await getDataColumnByName(
                      token,
                      nEntity.columns[ati],
                      dataPodId,
                      tableId,
                    )
                    const dataTableColumn: DataColumn[] = columData
                    const { data: exDataAttributeList, status: foundAttributeStatus } =
                      await getDataAttributesByEntityId(token, newEnt.id)
                    const foundDataAttributes: DataAttribute[] = exDataAttributeList

                    if (colStatus == 200 && dataTableColumn.length) {
                      if (
                        !foundDataAttributes.some((at) => at.dataAttributeName === attributeDetails.dataAttributeName)
                      ) {
                        let attributeResponse: DataAttribute
                        const { data: attr } = await dataAttributesServices.getDataAttributeName(
                          token,
                          dataPodId,
                          attributeDetails.dataAttributeName,
                          attributeDetails.dataEntityId,
                        )
                        if (isEntityReused) {
                          if (attr.message.includes('No Records found') || attr.dataAttributes === null) {
                            const { data: attrResp, status: attribStatus } =
                              await dataAttributesServices.createDataAttribute(token, attributeDetails, dataPodId)
                            attributeResponse = attrResp
                          } else {
                            attributeResponse = attr.dataAttributes[0]
                          }
                        } else {
                          const { data: attrResp, status: attribStatus } =
                            await dataAttributesServices.createDataAttribute(token, attributeDetails, dataPodId)
                          attributeResponse = attrResp
                        }
                        if (attributeResponse) {
                          const dAtt: DataAttribute = attributeResponse
                          const attrMap: NewAttributeMapBody = {
                            dataTableId: tableId,
                            dataEntityId: newEnt.id,
                            dataAttributeId: dAtt.id,
                            dataColumnId: dataTableColumn[0].id,
                            dataEntityMapId: entMap.id,
                            runid: 0,
                          }
                          const { data: attrMapRes, status: attrMapStatus } =
                            await attributeProfileServices.addNewAttributeMap(token, dataPodId, attrMap)
                          if (attrMapStatus == 200) {
                            allAttributesSuccess = [...allAttributesSuccess, dAtt.id]
                          }
                        }
                      } else {
                        const exAttrb = foundDataAttributes.find(
                          (at) => at.dataAttributeName === attributeDetails.dataAttributeName,
                        )
                        if (exAttrb?.id) {
                          const exAttrMap: NewAttributeMapBody = {
                            dataTableId: tableId,
                            dataEntityId: newEnt.id,
                            dataAttributeId: exAttrb.id,
                            dataColumnId: dataTableColumn[0].id,
                            dataEntityMapId: entMap.id,
                            runid: 0,
                          }
                          const { data: attrMapRes, status: attrMapStatus } =
                            await attributeProfileServices.addNewAttributeMap(token, dataPodId, exAttrMap)
                          if (attrMapStatus == 200) {
                            allAttributesSuccess = [...allAttributesSuccess, foundDataAttributes[0].id]
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            if (totalEntites === allEntitySuccess.length && totalAttributes === allAttributesSuccess.length) {
              allTableIdSuccess = [...allTableIdSuccess, tableId]
            }
          }
        }
      }
    }
    if (allTableIdSuccess.length === tableIds.length) {
      updateSuccess(true)
      updateError(false)
      updateGenerating(false)
    }
  } catch (error) {
    updateError(true)
    updateSuccess(false)
    updateGenerating(false)
  }
}
