import { useState } from 'react'
import { Industry } from '../businessObjects'
import { useLucidAuthContext } from '../LucidAuthContext/LucidAuthContext'
import {industryServices} from '../apiServices/industry'

type SubmitIndusty = (industryName: string) => Promise<void>
type IndustryName = string
type IndustryId = number
type IndustryParam = IndustryName | IndustryId
type GetIndustry = (param?: IndustryParam) => Promise<void>
type CreateIndustry = (industryName: string) => Promise<void>

export const useGetIndustries = (): [GetIndustry, Industry[] | undefined, boolean | undefined, boolean | undefined] => {
  const [industries, setIndustries] = useState<Industry[]>()
  const [loading, setLoading] = useState<boolean>()
  const [errorFetching, setErrorFetching] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const getIndustries = async (param?: IndustryParam) => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const name = typeof param == 'string' ? param : undefined
        const { data, status } = await industryServices.getAllIndustries(bearerToken, name)
        if (status == 200) {
          setIndustries(data)
          setLoading(false)
        }
      }
    } catch (error) {
      setErrorFetching(true)
      setLoading(false)
    }
  }
  return [getIndustries, industries, loading, errorFetching]
}

export const useCreateIndustry = (): [
  CreateIndustry,
  Industry | undefined,
  boolean | undefined,
  boolean | undefined,
] => {
  const [newIndustry, setNewIndustry] = useState<Industry>()
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const submitIndustry = async (industryName: IndustryName) => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await industryServices.createIndustry(bearerToken, industryName)
        if (status == 200) {
          setNewIndustry(data)
          setLoading(false)
        }
      }
    } catch (error) {
      setError(true)
      setLoading(false)
    }
  }
  return [submitIndustry, newIndustry, loading, error]
}
