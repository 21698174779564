import React, { memo } from 'react'
import { Handle, NodeProps, Position } from 'reactflow'
const style = {
  padding: 15,
  right: 10,
  background: '#F2F2F2',
  fontFamily: 'inter',
  borderBottom: '1px solid #ddd',
}

const BiDirectionalNode = ({ data }: NodeProps) => {
  const isSourceOrTarget = data.source || data.target
  return (
    <div style={style}>
      {data.source && <Handle type="source" position={Position.Right} id="right"></Handle>}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ fontWeight: isSourceOrTarget ? 'bold' : 'normal' }}>{data.label}</div>
        <div style={{ fontWeight: isSourceOrTarget ? 'bold' : 'normal' }}>{data.type}</div>
      </div>
      {data.target && <Handle type="target" position={Position.Left} id="left"></Handle>}
    </div>
  )
}

export default memo(BiDirectionalNode)
