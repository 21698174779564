import { useGetDetailDataTransformer } from '../../../hooks/dataTransformerHooks'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'

import ShadowTable from '../../../ui-components/ShadowTable/ShadowTable'
import { useEffect, useMemo, useState } from 'react'
import { DataTransformerDetail, Entity } from '../../../businessObjects'
import { DataTableRow } from '../../../ui-components/DataTable/DataTableTypes'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useGetEntity } from '../../../hooks/entityHooks'
import CancelIcon from '@mui/icons-material/Cancel'
import {
  Grid,
  Typography,
  Card,
  Divider,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CardHeader,
  Box,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material'
import { green } from '@mui/material/colors'
import { useGetDataPodInfo } from '../../../hooks/datapodHooks'
import ActionPage from '../../../ui-components/ActionPage'
import { ROUTES } from '../../../Routes/constants'
import { cardContainer } from '../../../styles/globalStyles'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'

export const AllTransformers = () => {
  const { dataPodId } = useParams<string>()
  const { dataPodInfo } = useGetDataPodInfo(dataPodId)
  const [entities] = useGetEntity({}, dataPodId)
  const [searchText, setSearchText] = useState<string>('')
  const [filteredEntities, setFilteredEntities] = useState<Entity[]>(entities)
  const navigate = useNavigate()
  const onClickHome = () => {
    const dataProfilingRoute = generatePath(ROUTES.DataModelingCanonicalModel, { dataPodId: dataPodId })
    navigate(dataProfilingRoute)
  }

  useEffect(() => {
    if (searchText.trim() === '') {
      setFilteredEntities(entities)
    } else {
      setFilteredEntities(
        entities?.filter((entity) => entity.dataEntityName.toLowerCase().includes(searchText.toLowerCase())),
      )
    }
  }, [searchText])

  const allEntities = useMemo(() => {
    if (searchText === '') {
      return entities.sort((p1, p2) => (p1.dataEntityName > p2.dataEntityName ? 1 : -1))
    }
    return filteredEntities.sort((p1, p2) => (p1.dataEntityName > p2.dataEntityName ? 1 : -1))
  }, [entities?.length, filteredEntities?.length])

  return (
    <ActionPage>
      <Grid item container spacing={2}>
        <Grid item xs={2}>
          <IconButton onClick={onClickHome}>
            <HomeOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box style={cardContainer}>
        <Grid container spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={4} alignItems={'center'}>
                  <Grid item container xs={15} spacing={1}>
                    <Grid item xs={6}>
                      <Typography component="span" variant="subtitle1" sx={{ fontSize: 14 }}>
                        Data Pod Name
                      </Typography>
                    </Grid>
                    <Grid item />

                    <Grid item xs={5}>
                      <Typography component="span" variant="subtitle1" sx={{ fontSize: 14 }}>
                        Data Pod ID
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={15} />

                    <Grid item container xs={15} spacing={1}>
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          id="outlined-select-dataSource"
                          variant="outlined"
                          value={dataPodInfo?.name}
                          required
                          size="small"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={1} />
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          id="outlined-select-dataSource"
                          variant="outlined"
                          value={dataPodInfo?.id}
                          required
                          size="small"
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} container alignItems={'center'}>
            <Grid item xs={3}>
              <TextField
                placeholder="Search"
                value={searchText}
                onChange={({ target }) => setSearchText(target.value)}
                color="primary"
                margin="dense"
                size="small"
                type="search"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: !!searchText ? (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearchText('')}>
                        <CancelIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <></>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} />
          <Grid item container xs={14}>
            <TransformerTable allEntities={allEntities} dataPodId={dataPodId} />
          </Grid>
        </Grid>
      </Box>
    </ActionPage>
  )
}

type TransformerTableProps = {
  allEntities: Entity[]
  dataPodId: string | undefined
}

export const TransformerTable = ({ allEntities, dataPodId }: TransformerTableProps) => {
  const { getDataTransformers, dataTransformers } = useGetDetailDataTransformer()
  const [transformersByEntity, setTransformersByEntity] = useState<DataTableRow[]>([])
  const [selectedEntityIDs, setSelectedEntityID] = useState<number[]>([])

  useEffect(() => {
    getDataTransformers(dataPodId)
  }, [])

  useEffect(() => {
    if (dataTransformers) {
      const filteredDataTransformers = dataTransformers?.map((dt: DataTransformerDetail) => ({
        id: `${dt.id}`,
        values: [dt.dataSystemName, dt.dataTableName, dt.dataColumnName, dt.transformerText, dt.transformerQuery],
      })) as DataTableRow[]
      setTransformersByEntity(filteredDataTransformers)
    }
  }, [dataTransformers])

  useEffect(() => {
    if (!selectedEntityIDs.length) {
      const filteredDataTransformers = dataTransformers?.map((dt: DataTransformerDetail) => ({
        id: `${dt.id}`,
        values: [dt.dataSystemName, dt.dataTableName, dt.dataColumnName, dt.transformerText, dt.transformerQuery],
      })) as DataTableRow[]
      setTransformersByEntity(filteredDataTransformers)
    } else {
      const filteredDataTransformers = dataTransformers
        ?.filter((dt) => {
          return selectedEntityIDs.includes(dt.dataEntityId)
        })
        .map((dt: DataTransformerDetail) => ({
          id: `${dt.id}`,
          values: [dt.dataSystemName, dt.dataTableName, dt.dataColumnName, dt.transformerText, dt.transformerQuery],
        })) as DataTableRow[]
      setTransformersByEntity(filteredDataTransformers)
    }
  }, [selectedEntityIDs])

  const getEntityColumns = (id: number) => {
    const entityID = Number(id)
    const entityIds = selectedEntityIDs.includes(entityID)
      ? selectedEntityIDs.filter((id) => id != entityID)
      : [...selectedEntityIDs, entityID]
    setSelectedEntityID(entityIds)
  }

  const transformerModalColumns = useMemo(() => {
    return [
      { label: 'Data System Name', sortableColumn: true },
      { label: 'Data Table Name', sortableColumn: true },
      { label: 'Column Name', sortableColumn: true },
      { label: 'Transformer Text', sortableColumn: true },
      { label: 'Transformer Query', sortableColumn: true },
    ]
  }, [])

  return (
    <Grid container xs={12} direction={'row'} spacing={2}>
      <Grid item xs={3}>
        <Card sx={{ backgroundColor: 'white' }}>
          <CardHeader
            disableTypography
            sx={{ backgroundColor: 'grey' }}
            title={<Typography sx={{ fontSize: 15, align: 'right' }}>{'List of Entities'}</Typography>}
          />
          <Divider />
          <List sx={{ width: '100%', maxWidth: 360, marginTop: -2 }} dense>
            {allEntities.map((p, ix) => (
              <ListItem key={ix}>
                <ListItemButton onClick={() => getEntityColumns(p.id)}>
                  <ListItemIcon>
                    <Checkbox
                      sx={{
                        color: 'green',
                        '&.Mui-checked': {
                          color: green[600],
                        },
                      }}
                      edge="start"
                      tabIndex={-1}
                      checked={selectedEntityIDs.includes(Number(p.id))}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={p.dataEntityName} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Card>
      </Grid>
      <Grid item xs={9}>
        <ShadowTable rows={transformersByEntity} columns={transformerModalColumns} />
      </Grid>
    </Grid>
  )
}
