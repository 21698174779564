import { useEffect, useState } from 'react'
import { useSearchParams, useNavigate, useParams, generatePath } from 'react-router-dom'
import { useGetQueryString } from '../../hooks/queryStringHook'
import DataPodDetailRibbon from '../../ui-components/DataPodDetailRibbon/DataPodDetailRibbon'
import { isValidDescription, isValidEntry } from '../../utils/constants'
import ActionPage from '../../ui-components/ActionPage'
import {
  CardHeader,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Stack,
  MenuItem,
  TextField,
  Select,
  Skeleton,
  Menu,
  Tooltip,
} from '@mui/material'
import { AnalyticsMeasure, AnalyticsMeasureType, AnalyticsGroup, DimensionData } from '../../businessObjects'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import { useGetDataPodInfo } from '../../hooks/datapodHooks'
import { useGetAnalyticGroups } from '../../hooks/analyticsGroupHooks'
import {
  useGetAnalyticMeasureByGroupMeasureId,
  useGetAnalyticMeasure,
  useGetGenerateKPI,
  useSynchronizeMeasureDimensions,
  useGetMeasureDimensions,
} from '../../hooks/analyticsMeasureHooks'
import { ProgressBar } from '../../ui-components/ProgressBar'
import ResultStatus from '../../ui-components/ResultStatus'
import { ROUTES } from '../../Routes/constants'
import GroupEntities from './GroupEntities'
import MeasureDetailCard from './MeasureDetailCard'
import GenerateMeasureNotebook from './GenerateMeasureNotebook'
import AnimatedLoader from '../../ui-components/AnimatedLoader'

type ViewResultsParams = {
  dataPodId: string
}

const normalizeMeasureId = (measureId: string | null) => {
  return measureId && measureId.length > 0 ? Number(measureId) : undefined
}

const AddAnalyticsMeasure = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const groupId = useGetQueryString('groupId')
  const measureId = useGetQueryString('measureId')
  const isEdit = useGetQueryString('action')
  const { dataPodId } = useParams<ViewResultsParams>()
  const { dataPodInfo } = useGetDataPodInfo(dataPodId)

  const [selectedGroup, selectGroup] = useState<string>('')
  const [measureName, setMeasureName] = useState<string>('')
  const [measureDescription, setMeasureDescription] = useState<string>('')

  const { analyticsGroupList } = useGetAnalyticGroups(dataPodId)

  const { analyticsMeasureList, analyticsGroupMeasureLoading } = useGetAnalyticMeasureByGroupMeasureId(
    dataPodId,
    groupId,
    measureId,
  )

  const { analyticsMeasureLoading, updateAnalyticsMeasure, addAnalyticsMeasure, postMeasure, measureError } =
    useGetAnalyticMeasure(dataPodId)

  const [addedSuccess, setAddedSuccess] = useState<boolean>(false)
  const [errorState, setErrorState] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [selectedAnalyticMeasure, setSelectAnalyticMeasure] = useState<AnalyticsMeasure>()

  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null)
  const [inputValue, setInputValue] = useState<string>('')
  const [suggestions, setSuggestions] = useState<string[] | null>([])
  const [dimensionData, setDimensionData] = useState<DimensionData[]>([])

  const { generateKPIResponse, generateKPILoading, generateKPIError, generateKPI } = useGetGenerateKPI(
    dataPodId,
    selectedAnalyticMeasure?.analyticsMeasureId,
    measureDescription.length > 0 ? measureDescription : undefined,
  )

  const { syncDimensionsError, synchronizeDimensions } = useSynchronizeMeasureDimensions(
    dataPodId,
    normalizeMeasureId(measureId),
  )

  const { getDimensionsResponse, getDimensionsError, getDimensionsLoading } = useGetMeasureDimensions(
    dataPodId,
    normalizeMeasureId(measureId),
  )

  useEffect(() => {
    if (measureError) {
      setErrorState(true)
      setErrorMessage(measureError?.message)
    } else if (generateKPIError) {
      setErrorState(true)
      setErrorMessage(generateKPIError?.message)
    } else if (syncDimensionsError) {
      setErrorState(true)
      setErrorMessage(syncDimensionsError?.message)
    } else if (getDimensionsError) {
      setErrorState(true)
      setErrorMessage(getDimensionsError?.message)
    }
  }, [measureError, generateKPIError])

  useEffect(() => {
    if (measureId && analyticsMeasureList.length) {
      const { analyticsMeasureName, analyticsMeasureDescription, analyticsGroupId } = analyticsMeasureList[0]
      setSelectAnalyticMeasure(analyticsMeasureList[0])
      selectGroup(analyticsGroupId?.toString())
      setMeasureDescription(analyticsMeasureDescription)
      setMeasureName(analyticsMeasureName)
    }
  }, [analyticsMeasureList])

  useEffect(() => {
    if (groupId && analyticsGroupList.length) {
      selectGroup(groupId)
    }
  }, [groupId, analyticsGroupList])

  useEffect(() => {
    if (postMeasure?.id && groupId) {
      setAddedSuccess(true)
      //below selects mesaure details + group details
      setSelectAnalyticMeasure({ ...selectedAnalyticMeasure, ...postMeasure, analyticsMeasureId: postMeasure?.id })
      setSearchParams({ groupId, measureId: postMeasure?.id, action: 'edit' })
    }
  }, [postMeasure])

  useEffect(() => {
    if (getDimensionsResponse) {
      setDimensionData(getDimensionsResponse)

      const dimensionsString = castDimensionalDataToString(getDimensionsResponse)
      setInputValue(dimensionsString)
    }
  }, [getDimensionsResponse])

  const addNewMeasure = async () => {
    const payload: AnalyticsMeasureType = {
      measureGroupId: parseInt(selectedGroup),
      analyticsMeasureName: measureName,
      analyticsMeasureDescription: measureDescription,
      runId: 0,
      measureQuery: '',
    }
    if (selectedAnalyticMeasure?.analyticsMeasureId) {
      updateAnalyticsMeasure({
        id: selectedAnalyticMeasure?.analyticsMeasureId,
        ...payload,
      })
    } else {
      await addAnalyticsMeasure(payload)
    }

    callSyncDimensions()
  }

  const callSyncDimensions = () => {
    const result = castStringToDimensionalData(inputValue)

    if (result) {
      setDimensionData(result)
      synchronizeDimensions(result)
    }
  }

  const callGenerateKPI = () => {
    const result = castStringToDimensionalData(inputValue)

    if (result) {
      setDimensionData(result)
      generateKPI(result)
    }
  }

  const onClickBack = () => {
    if (isEdit) {
      let measurePage = generatePath(ROUTES.DataAnalyticsMeasuresResults, {
        dataPodId: dataPodId,
      })

      if (groupId) {
        measurePage = measurePage + '?groupId=' + groupId
      }

      if (measureId) {
        measurePage = measurePage + '&measureId=' + measureId
      }

      navigate(measurePage)
    } else {
      let groupPage = generatePath(ROUTES.DataAnalyticsGroupResults, {
        dataPodId: dataPodId,
      })

      if (groupId) {
        groupPage = groupPage + '?groupId=' + groupId
      }

      navigate(groupPage)
    }
  }

  const handleSelectGroup = (groupVal: any) => {
    setSearchParams({ measureId: groupVal })
    selectGroup(groupVal)
  }

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value

    setInputValue(newValue)

    setAnchorEl(event.currentTarget)

    const newSuggestions = getSuggestions(newValue)
    setSuggestions(newSuggestions)
  }

  const getSuggestions = (value: string) => {
    const entitySuggestions = generateKPIResponse?.dimensionData || []
    const lastPart = value.split(' ').pop()

    if (!lastPart) return null

    if (lastPart.endsWith('@')) {
      return entitySuggestions.map((entity) => `@${entity.entityName}`)
    } else if (lastPart.startsWith('@') && !lastPart.includes('.')) {
      // Suggest entities, handling duplicates
      const filteredEntities = entitySuggestions.filter((entity) =>
        entity.entityName.toLowerCase().includes(lastPart.substring(1).toLowerCase()),
      )
      return filteredEntities.map((entity, index) => `@${entity.entityName}`)
      // Append a number to distinguish duplicates
    } else if (lastPart.includes('.')) {
      // Suggest attributes
      const parts = lastPart.split('.')
      const entityName = parts[0].substring(1)
      const attributePart = parts[1]
      const matchingEntities = entitySuggestions.filter((e) => e.entityName === entityName)
      const allSuggestions = matchingEntities.flatMap((entity, entityIndex) =>
        entity.attributes
          .filter((attr) => attr.toLowerCase().includes(attributePart.toLowerCase()))
          .map((attr) => `@${entityName}.${attr}`),
      )
      return allSuggestions
    } else {
      return null
    }
  }

  const handleSuggestionClick = (suggestion: string) => {
    const lastPart = inputValue?.split(' ').pop() || ''
    let updatedValue = inputValue?.substring(0, inputValue?.length - lastPart.length) + suggestion

    if (lastPart.includes('.')) {
      updatedValue += ' '
    }

    setInputValue(updatedValue)
    setAnchorEl(null)
  }

  const castStringToDimensionalData = (input: string): DimensionData[] => {
    if (!input.length) return []

    const tempDimData: DimensionData[] = []

    input.split('@').forEach((element) => {
      if (element.length == 0) {
        return
      }

      const [entityName, attribute] = element.trim().split('.')

      const index = tempDimData.findIndex((entity) => entity.entityName === entityName)

      if (index == -1) {
        tempDimData.push({ entityName, attributes: [attribute] })
      } else {
        tempDimData[index].attributes.push(attribute)
      }
    })

    return tempDimData
  }

  const castDimensionalDataToString = (dimensions: DimensionData[]) => {
    return dimensions.reduce((acc, dim) => {
      let tempAcc = acc
      dim.attributes.forEach((attr) => {
        tempAcc += `@${dim.entityName}.${attr} `
      })

      return tempAcc
    }, '')
  }

  const closeErrorMessage = () => {
    setErrorState(false)
    setErrorMessage(undefined)
  }

  return (
    <ActionPage>
      {/* <ProgressBar loading={analyticsMeasureLoading} /> */}
      <AnimatedLoader height="50%" width="40%" loading={analyticsMeasureLoading} />
      <CardHeader
        disableTypography
        title={
          <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant="h4" color="primary">
              {measureId ? 'Update Analytics Measure' : 'Add Analytics Measure'}
            </Typography>

            <Button
              variant="outlined"
              onClick={onClickBack}
              startIcon={<KeyboardBackspaceOutlinedIcon fontSize="small" />}
            >
              Back
            </Button>
          </Stack>
        }
      />
      {/* <CardContent>
        <DataPodDetailRibbon
          dataPodId={dataPodId}
          dataPodName={dataPodInfo?.name}
          dataPodIndustryName={dataPodInfo?.industryName}
        />
      </CardContent> */}

      <Grid item container justifyContent={'center'} xs={14} spacing={2}>
        <Grid item xs={12}>
          <>
            {errorMessage && (
              <ResultStatus
                showStatus={errorState}
                alertMessage={errorMessage}
                severtiy="error"
                closeStatus={() => closeErrorMessage()}
              />
            )}
            <ResultStatus
              severtiy="success"
              showStatus={addedSuccess}
              closeStatus={() => setAddedSuccess(false)}
              alertMessage={`SUCCESS: ${
                selectedAnalyticMeasure?.analyticsMeasureId
                  ? 'Updated measure successfully !'
                  : 'Added measure successfully !'
              }`}
            />
          </>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ p: 2 }}>
            <CardHeader
              sx={{ p: 0, mb: 2 }}
              title={
                <Typography variant="h5">
                  {selectedAnalyticMeasure?.analyticsMeasureId
                    ? 'Update Analytics Measure'
                    : 'Add New Analytics Measure'}
                </Typography>
              }
            />
            {analyticsGroupMeasureLoading ? (
              <>
                <Skeleton variant="rectangular" height={30} sx={{ p: 1, mb: 1 }} />
                <Skeleton variant="rectangular" height={30} sx={{ p: 1, mb: 1 }} />
                <Skeleton variant="rectangular" height={50} sx={{ p: 1, mb: 1 }} />
              </>
            ) : (
              <>
                <Grid item xs={12} sx={{ lineHeight: 3 }}>
                  <Typography component="span" variant="subtitle1">
                    Measure Group
                  </Typography>
                  <Select
                    fullWidth
                    id="measure-groupId"
                    value={selectedGroup}
                    size="small"
                    onChange={({ target }) => handleSelectGroup(target?.value as any)}
                  >
                    {analyticsGroupList?.map((data: AnalyticsGroup) => (
                      <MenuItem key={data?.id} value={data?.id?.toString()}>
                        {data?.analyticsGroupName}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sx={{ lineHeight: 3 }}>
                  <Typography component="span" variant="subtitle1">
                    Measure (KPI) Name
                  </Typography>
                  <TextField
                    rows={4}
                    fullWidth
                    id="measure-name"
                    variant="outlined"
                    size="small"
                    value={measureName}
                    onChange={({ target }) => setMeasureName(target.value)}
                  />
                </Grid>
                <Grid item xs={12} sx={{ lineHeight: 3 }}>
                  <Typography component="span" variant="subtitle1">
                    Prompt Text (Measure Description)
                  </Typography>
                  <TextField
                    multiline
                    rows={4}
                    fullWidth
                    id="measure-description"
                    variant="outlined"
                    size="small"
                    value={measureDescription}
                    onChange={({ target }) => setMeasureDescription(target.value)}
                  />
                </Grid>
                <Grid item xs={12} sx={{ lineHeight: 3 }}>
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          textAlign: 'justify',
                        },
                      },
                    }}
                    title="This measure is calculated against the following dimensions."
                    placement={'right'}
                  >
                    <Typography component="span" variant="subtitle1">
                      Measure Dimensions
                    </Typography>
                  </Tooltip>
                  <>
                    <TextField
                      value={inputValue}
                      helperText={!inputValue ? 'Type @ to mention' : ''}
                      onChange={handleToggle}
                      placeholder="Code generation pending. Please click 'Generate Code' to automatically identify the relevant dimensions for this measure."
                      fullWidth
                      multiline
                      rows={4}
                      inputProps={{ style: { textAlign: 'justify' } }}
                    />
                    {suggestions && suggestions.length > 0 && (
                      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
                        {suggestions.map((suggestion, index) => (
                          <MenuItem key={index + suggestion} onClick={() => handleSuggestionClick(suggestion)}>
                            {suggestion}
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  </>
                </Grid>
                <Grid item xs={12} sx={{ lineHeight: 3 }} textAlign={'end'}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={addNewMeasure}
                    disabled={!selectedGroup || !isValidEntry(measureName) || !isValidDescription(measureDescription)}
                  >
                    Save
                  </Button>
                </Grid>
              </>
            )}
          </Card>
        </Grid>

        <Grid item xs={8}>
          <Grid item xs={12}>
            <GenerateMeasureNotebook
              measureId={selectedAnalyticMeasure?.analyticsMeasureId}
              measureDescription={measureDescription}
              callSyncDimensions={callSyncDimensions}
              generateKPI={callGenerateKPI}
              generateKPILoading={generateKPILoading}
              generateKPIResponse={generateKPIResponse}
              isEditOrAddMode={true}
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <GroupEntities />
          </Grid>
        </Grid>
      </Grid>
    </ActionPage>
  )
}

export default AddAnalyticsMeasure
