import {
  AppProfileAttribute,
  AttributeProfile,
  DataColumn,
  DataSourceToTargetAtrributeMap,
  DataTable,
  Entity,
  EntityMapping,
} from '../../../businessObjects'

export type NodeData = {
  attributes: {
    attributeId: number
    attributeName: string
    type: string
  }[]
  entityId: number
  entityName: string
  dataSystemName: string
}

export const processTableNodeData = async (
  nodeData: NodeData[],
  edgesData: {}[],
  entityData: Entity[],
  tableData: DataTable[],
  dataSourceToTargetAttributeMap: DataSourceToTargetAtrributeMap[],
  srcTargetAttributes: DataSourceToTargetAtrributeMap[],
  fetchEntityMapping: (
    entityId?: number | undefined,
    tableId?: number | undefined,
  ) => Promise<EntityMapping[] | undefined>,
  getAttribute: (params: number) => Promise<AttributeProfile[] | undefined>,
  getDataColumns: (id: number) => Promise<DataColumn[] | undefined>,
  getProfileAttributes: (dataEntityID?: number, tableID?: number) => Promise<AppProfileAttribute[] | undefined>,
) => {
  console.log({ srcTargetAttributes }, { dataSourceToTargetAttributeMap }, { tableData })
  for (let i: number = 0; i < srcTargetAttributes?.length; i++) {
    const srcTargetObj = srcTargetAttributes[i]

    // source Table node
    const srcTable = {
      entityId: srcTargetObj.sourceTableId,
      entityName: srcTargetObj.sourceTableName + ' [Source Table]',
      dataSystemName: '',
    }
    const srcEntityMapping = await fetchEntityMapping(undefined, srcTable.entityId)
    if (srcEntityMapping?.length) {
      const dsName = srcEntityMapping[0].dataSystemName
      console.log({ dsName })
      srcTable.dataSystemName = dsName
    }
    //  fetch TableColumnsBy TableID
    const srcTableColumns = await getDataColumns(srcTable.entityId)
    console.log({ srcTableColumns })
    if (srcTableColumns?.length) {
      let srcNode = {
        ...srcTable,
        attributes: srcTableColumns.map((tablecol) => ({
          attributeId: tablecol.id,
          attributeName: tablecol.dataColumnName,
          type: tablecol.datatype,
        })),
      }
      nodeData.push(srcNode)
    }

    // target table node
    const targetTable = {
      entityId: srcTargetObj.targetTableId,
      entityName: srcTargetObj.targetTableName + ' [Target Table]',
      dataSystemName: '',
    }
    const tEntityMapping = await fetchEntityMapping(undefined, targetTable.entityId)
    if (tEntityMapping?.length) {
      const tdsName = tEntityMapping[0].dataSystemName
      //  console.log({ tdsName })
      targetTable.dataSystemName = tdsName
    }
    const targetTableData = await getDataColumns(targetTable.entityId)
    if (targetTableData?.length) {
      let targetNode = {
        ...targetTable,
        attributes: targetTableData.map((tablecol) => ({
          attributeId: tablecol.id,
          attributeName: tablecol.dataColumnName,
          type: tablecol.datatype,
        })),
      }
      nodeData.push(targetNode)
    }
    let srcEntityList: {
      entityId: number
      entityName: string
      attributes: {
        attributeId: number
        attributeName: string
        type: string
      }[]
      dataSystemName: string
    }[] = []
    let targetEntityList: {
      entityId: number
      entityName: string
      attributes: {
        attributeId: number
        attributeName: string
        type: string
      }[]
      dataSystemName: string
    }[] = []

    // source Table Entities
    const srcEntityData = await getProfileAttributes(undefined, srcTable.entityId)
    let sourceEdgeData: {}[] = []
    if (srcEntityData?.length) {
      const srcTableData = srcEntityData.map((at) => ({
        entityId: at.dataEntityID,
        entityName: at.dataEntityName + ' [Source Entity]',
        dataSystemName: at.dataSystemName,
      }))
      sourceEdgeData = srcEntityData.map((te) => ({
        table: te.dataEntityName,
        tableId: te.dataEntityID,
        id: te.dataColumnId + te.dataTableID,
        leftEntityId: te.dataEntityID,
        leftEntity: te.dataEntityName,
        leftAttributeId: te.dataAttributeID,
        leftAttributeName: te.dataAttributeName,
        rightEntityId: srcTable.entityId,
        rightEntity: srcTable.entityName,
        rightAttributeId: te.dataColumnId,
        rightAttributeName: te.dataColumnName,
        edgeType: 'attribute',
      }))

      const srcEntityAttributes = srcEntityData.map((at) => ({
        attributeId: at.dataAttributeID,
        tableID: at.dataEntityID,
        attributeName: at.dataAttributeName,
        type: at.dataAttributeDataType,
      }))
      // //  console.log({ srcTableColumns })
      // filter src unique table columns
      const srcEntityAttributesList = srcEntityAttributes
        .filter(
          (sr, ix) =>
            srcEntityAttributes.findIndex((ed) => ed.attributeId === sr.attributeId && ed.tableID === sr.tableID) == ix,
        )
        .map((src) => ({
          attributeId: src.attributeId,
          attributeName: src.attributeName,
          type: src.type,
        }))

      //  filter src unique tables
      srcEntityList = srcTableData
        .filter((sr, ix) => srcTableData.findIndex((ed) => ed.entityId === sr.entityId) == ix)
        .map((d) => ({
          ...d,
          attributes: srcEntityAttributesList,
        }))
    }
    let targetEdgeData: {}[] = []
    // target Table Entities
    const targetEntityData = await getProfileAttributes(undefined, targetTable.entityId)
    if (targetEntityData?.length) {
      const targetTableData = targetEntityData.map((at) => ({
        entityId: at.dataEntityID,
        entityName: at.dataEntityName + ' [Target Entity]',
        dataSystemName: at.dataSystemName,
      }))
      targetEdgeData = targetEntityData.map((te) => ({
        table: targetTable.entityName,
        tableId: targetTable.entityId,
        id: te.dataColumnId + te.dataTableID,
        leftEntityId: targetTable.entityId,
        leftEntity: targetTable.entityName,
        leftAttributeId: te.dataAttributeID,
        leftAttributeName: te.dataAttributeID,
        rightEntityId: te.dataTableID,
        rightEntity: te.dataTableName,
        rightAttributeId: te.dataColumnId,
        rightAttributeName: te.dataColumnName,
        edgeType: 'attribute',
      }))
      const tarEntityAttributes = targetEntityData.map((at) => ({
        attributeId: at.dataAttributeID,
        tableID: at.dataEntityID,
        attributeName: at.dataAttributeName,
        type: at.dataAttributeDataType,
      }))
      // filter unique src tables
      const tarEntityAttributesList = tarEntityAttributes
        .filter(
          (sr, ix) =>
            tarEntityAttributes.findIndex((ed) => ed.attributeId === sr.attributeId && ed.tableID === sr.tableID) == ix,
        )
        .map((src) => ({
          attributeId: src.attributeId,
          attributeName: src.attributeName,
          type: src.type,
        }))

      //  filter unique target tables
      targetEntityList = targetTableData
        .filter((sr, ix) => targetTableData.findIndex((ed) => ed.entityId === sr.entityId) == ix)
        .map((d) => ({
          ...d,
          attributes: tarEntityAttributesList,
        }))
    }

    // filter nodeData for unique src or target based on user selection for srcEntity or TargetEntity

    const unNodeData = nodeData.filter((ds, ix) => nodeData.findIndex((nd) => nd.entityId === ds.entityId) === ix)

    if (unNodeData.length > 0) {
      const edgeData: any = []
      if (unNodeData.length) {
        // entity Attribute Map relations based on table ID
        // filter duplicate entries in dataSourceToTargetAttributeMap
        const edgd = dataSourceToTargetAttributeMap.filter(
          (ds, ix) =>
            dataSourceToTargetAttributeMap.findIndex(
              (dm) => dm.sourceTableId === ds.sourceTableId && dm.targetTableId === ds.targetTableId,
            ) === ix,
        )

        edgd.forEach((relation) => {
          edgeData.push({
            table: relation.sourceEntityName,
            tableId: relation.sourceEnityId,
            id: relation.id,
            leftEntityId: relation.sourceEnityId,
            leftEntity: relation.sourceEntityName,
            leftAttributeId: relation.sourceAttributeId,
            leftAttributeName: relation.sourceAttributeName,
            rightEntityId: relation.targetEntityId,
            rightEntity: relation.targetEntityName,
            rightAttributeId: relation.targetAttributeId,
            rightAttributeName: relation.targetAttributeName,
            edgeType: 'attribute',

            //cardinality: relation.cardinality,
          })
        })
      }
      if (edgeData.length) {
        // //  console.log({ edgeData })
        const fled = edgeData.filter((edge: any) =>
          tableData.some((se) => se.id === edge.leftEntityId || se.id === edge.rightEntityId),
        )
        nodeData = [...srcEntityList, ...targetEntityList, ...unNodeData]
        //nodeData = [...unNodeData]
        edgesData = [...sourceEdgeData, ...targetEdgeData, ...fled]
        //edgesData = [ ...fled]
        // setNodes([...srcTableList, ...unNodeData, ...targetTableList])

        // setEdges(fled)
      }
      //edgesData = [ ...targetEdgeData]
    }
    //nodeData = unNodeData
  }
  return { nodeData, edgesData }
}

export const processEntitiesNodeData = async (
  nodeData: NodeData[],
  edgesData: {}[],
  entityData: Entity[],
  dataSourceToTargetAttributeMap: DataSourceToTargetAtrributeMap[],
  srcTargetAttributes: DataSourceToTargetAtrributeMap[],
  fetchEntityMapping: (
    entityId?: number | undefined,
    tableId?: number | undefined,
  ) => Promise<EntityMapping[] | undefined>,
  getAttribute: (params: number) => Promise<AttributeProfile[] | undefined>,
  getProfileAttributes: (dataEntityID?: number, tableID?: number) => Promise<AppProfileAttribute[] | undefined>,
) => {
  console.log({ entityData })
  for (let i: number = 0; i < srcTargetAttributes?.length; i++) {
    const srcTargetObj = srcTargetAttributes[i]
    const srcEntity = {
      entityId: srcTargetObj.sourceEnityId,
      entityName: srcTargetObj.sourceEntityName + ' [Source Entity]',
      dataSystemName: '',
    }
    const srcEntityMapping = await fetchEntityMapping(srcEntity.entityId)
    if (srcEntityMapping?.length) {
      const dsName = srcEntityMapping[0].dataSystemName

      srcEntity.dataSystemName = dsName
    }
    //  console.log({ srcEntity })
    const srcAttributeData = await getAttribute(srcEntity.entityId)
    if (srcAttributeData?.length) {
      let srcNode = {
        ...srcEntity,
        attributes: srcAttributeData.map((attributeFrag) => ({
          attributeId: attributeFrag.id,
          attributeName: attributeFrag.dataAttributeName,
          type: attributeFrag.dataAttributeDataType,
        })),
      }
      nodeData.push(srcNode)
    }
    const targetEntity = {
      entityId: srcTargetObj.targetEntityId,
      entityName: srcTargetObj.targetEntityName + ' [Target Entity]',
      dataSystemName: '',
    }
    const tEntityMapping = await fetchEntityMapping(targetEntity.entityId)
    if (tEntityMapping?.length) {
      const tdsName = tEntityMapping[0].dataSystemName
      targetEntity.dataSystemName = tdsName
    }
    const targetAttributeData = await getAttribute(targetEntity.entityId)
    if (targetAttributeData?.length) {
      let srcNode = {
        ...targetEntity,
        attributes: targetAttributeData.map((attributeFrag) => ({
          attributeId: attributeFrag.id,
          attributeName: attributeFrag.dataAttributeName,
          type: attributeFrag.dataAttributeDataType,
        })),
      }
      nodeData.push(srcNode)
    }
    let srcTableList: {
      entityId: number
      entityName: string
      attributes: {
        attributeId: number
        attributeName: string
        type: string
      }[]
      dataSystemName: string
    }[] = []
    let targetTableList: {
      entityId: number
      entityName: string
      attributes: {
        attributeId: number
        attributeName: string
        type: string
      }[]
      dataSystemName: string
    }[] = []
    const srcEntityTableData = await getProfileAttributes(srcEntity.entityId)
    let sourceEdgeData: {}[] = []
    if (srcEntityTableData?.length) {
      const srcTableData = srcEntityTableData.map((at) => ({
        entityId: at.dataTableID,
        entityName: at.dataTableName + ' [Source Table]',
        dataSystemName: at.dataSystemName,
      }))
      sourceEdgeData = srcEntityTableData.map((te) => ({
        table: te.dataTableName,
        tableId: te.dataTableID,
        id: te.dataColumnId + te.dataTableID,
        leftEntityId: te.dataTableID,
        leftEntity: te.dataTableName,
        leftAttributeId: te.dataColumnId,
        leftAttributeName: te.dataColumnName,
        rightEntityId: srcEntity.entityId,
        rightEntity: srcEntity.entityName,
        rightAttributeId: te.dataAttributeID,
        rightAttributeName: te.dataAttributeName,
        edgeType: 'attribute',
      }))
      console.log({ sourceEdgeData })

      const srcTableColumns = srcEntityTableData.map((at) => ({
        attributeId: at.dataColumnId,
        tableID: at.dataTableID,
        attributeName: at.dataColumnName,
        type: at.dataColumnDatatype,
      }))
      // //  console.log({ srcTableColumns })
      // filter src unique table columns
      const srcTableColumnsList = srcTableColumns
        .filter(
          (sr, ix) =>
            srcTableColumns.findIndex((ed) => ed.attributeId === sr.attributeId && ed.tableID === sr.tableID) == ix,
        )
        .map((src) => ({
          attributeId: src.attributeId,
          attributeName: src.attributeName,
          type: src.type,
        }))

      //  filter src unique tables
      srcTableList = srcTableData
        .filter((sr, ix) => srcTableData.findIndex((ed) => ed.entityId === sr.entityId) == ix)
        .map((d) => ({
          ...d,
          attributes: srcTableColumnsList,
        }))
    }
    let targetEdgeData: {}[] = []
    const targetEntityTableData = await getProfileAttributes(targetEntity.entityId)
    if (targetEntityTableData?.length) {
      const targetTableData = targetEntityTableData.map((at) => ({
        entityId: at.dataTableID,
        entityName: at.dataTableName + ' [Target Table]',
        dataSystemName: at.dataSystemName,
      }))
      targetEdgeData = targetEntityTableData.map((te) => ({
        table: targetEntity.entityName,
        tableId: targetEntity.entityId,
        id: te.dataColumnId + te.dataTableID,
        leftEntityId: targetEntity.entityId,
        leftEntity: targetEntity.entityName,
        leftAttributeId: te.dataAttributeID,
        leftAttributeName: te.dataAttributeID,
        rightEntityId: te.dataTableID,
        rightEntity: te.dataTableName,
        rightAttributeId: te.dataColumnId,
        rightAttributeName: te.dataColumnName,
        edgeType: 'attribute',
      }))
      const tarTableColumns = targetEntityTableData.map((at) => ({
        attributeId: at.dataColumnId,
        tableID: at.dataTableID,
        attributeName: at.dataColumnName,
        type: at.dataColumnDatatype,
      }))
      // filter unique src tables
      const tarTableColumnsList = tarTableColumns
        .filter(
          (sr, ix) =>
            tarTableColumns.findIndex((ed) => ed.attributeId === sr.attributeId && ed.tableID === sr.tableID) == ix,
        )
        .map((src) => ({
          attributeId: src.attributeId,
          attributeName: src.attributeName,
          type: src.type,
        }))

      //  filter unique target tables
      targetTableList = targetTableData
        .filter((sr, ix) => targetTableData.findIndex((ed) => ed.entityId === sr.entityId) == ix)
        .map((d) => ({
          ...d,
          attributes: tarTableColumnsList,
        }))
    }

    // filter nodeData for unique src or target based on user selection for srcEntity or TargetEntity

    const unNodeData = nodeData.filter((ds, ix) => nodeData.findIndex((nd) => nd.entityId === ds.entityId) === ix)

    //setNodes([...nodeData])

    if (unNodeData.length > 0) {
      const edgeData: any = []
      if (unNodeData.length) {
        // filter duplicate entries in dataSourceToTargetAttributeMap
        const edgd = dataSourceToTargetAttributeMap.filter(
          (ds, ix) =>
            dataSourceToTargetAttributeMap.findIndex(
              (dm) => dm.sourceAttributeId === ds.sourceAttributeId && dm.targetAttributeId === ds.targetAttributeId,
            ) === ix,
        )

        edgd.forEach((relation) => {
          edgeData.push({
            table: relation.sourceEntityName,
            tableId: relation.sourceEnityId,
            id: relation.id,
            leftEntityId: relation.sourceEnityId,
            leftEntity: relation.sourceEntityName,
            leftAttributeId: relation.sourceAttributeId,
            leftAttributeName: relation.sourceAttributeName,
            rightEntityId: relation.targetEntityId,
            rightEntity: relation.targetEntityName,
            rightAttributeId: relation.targetAttributeId,
            rightAttributeName: relation.targetAttributeName,
            edgeType: 'attribute',

            //cardinality: relation.cardinality,
          })
        })
      }
      if (edgeData.length) {
        // console.log( "edges without filter"  ,edgeData)
        // const fled = edgeData.filter((edge: any) =>
        //   entityData.some((se) => se.id === edge.leftEntityId || se.id === edge.rightEntityId),
        // )
        nodeData = [...srcTableList, ...unNodeData, ...targetTableList]
        //nodeData = [...unNodeData]
        edgesData = [...sourceEdgeData, ...edgeData, ...targetEdgeData]
        // setNodes([...srcTableList, ...unNodeData, ...targetTableList])

        // setEdges(fled)
      }
    }
  }
  return { nodeData, edgesData }
}
