import { useLucidAuthContext } from "../LucidAuthContext/LucidAuthContext"
import {  configurationServices } from "../apiServices/lucidConfiguration"
import { Configuration, ConfigurationType } from "../businessObjects"


export const useGetConfigurationTypes = (): { getConfigurationTypes: () => Promise<ConfigurationType[]> } => {
  
  const { getBearerToken } = useLucidAuthContext()

  const getConfigurationTypes = async (): Promise<ConfigurationType[]> => {
    const token = await getBearerToken()

    if (token) {
      try {
        const { data, status } = await configurationServices.getConfigurationTypes(token);

        if (status === 200) 
          return data;
        
      } catch (error) {
        console.error('Error fetching Lucid configurations:', error);
      }
    } 

    return [];
  }
  return { getConfigurationTypes }
}

export const useGetConfigurations = (): { getConfigurations: (configTypeId?: number) => Promise<Configuration[]> } => {
  
  const { getBearerToken } = useLucidAuthContext()

  const getConfigurations = async (configTypeId?: number): Promise<Configuration[]> => {
    const token = await getBearerToken()

    if (token) {
      try {
        const { data, status } = await configurationServices.getConfigurations(token, configTypeId);

        if (status === 200) 
        {
          return data;
        }
        
      } catch (error) {
        console.error('Error fetching Lucid configurations:', error);
      }
    } 

    return [];
  }
  return { getConfigurations }
}

export const useAddConfiguration = (): { addConfiguration: (config: Configuration) => Promise<Configuration | null> } => {
  const { getBearerToken } = useLucidAuthContext();

  const addConfiguration = async (config: Configuration): Promise<Configuration | null> => {
    const token = await getBearerToken();

    if (token) {
      try {
          const { data, status } = await configurationServices.addConfiguration(token, config);

          if(status == 200) {
              return data;
          }
      } catch (error) {
        console.error('Error adding Lucid configuration:', error);
      }
    }

    return null;
  };

  return { addConfiguration };
};

export const useDeleteConfiguration = (): { deleteConfiguration: (id: number) => Promise<boolean> } => {
    const { getBearerToken } = useLucidAuthContext();
  
    const deleteConfiguration = async (id: number): Promise<boolean> => {
      const token = await getBearerToken();
  
      if (token) {
        try {
            const { status } = await configurationServices.deleteConfiguration(token, id);
            
            if(status == 204)
                return true;

        } catch (error) {
          console.error('Error deleting Lucid configuration:', error);
        }
      }

      return false;
    };
  
    return { deleteConfiguration };
};
  
export const useUpdateConfiguration = (): { updateConfiguration: (params: Configuration) => Promise<Configuration | null> } => {
    const { getBearerToken } = useLucidAuthContext();
  
    const updateConfiguration = async (params: Configuration): Promise<Configuration | null> => {
      const token = await getBearerToken();
  
      if (token) {
        try {
          const {data, status} = await configurationServices.updateConfiguration(token, params);
          
          if(status == 200)
            return data;

        } catch (error) {
          console.error('Error updating Lucid configuration:', error);
        }
      }

      return null;
    };
  
    return { updateConfiguration };
  };
    