import React, { useEffect, useMemo, useState } from 'react'

import {
  Button,
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  TextFieldProps,
} from '@mui/material'
import {
  DateTimePicker,
  DesktopDatePicker,
  DesktopDateTimePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import cronstrue from 'cronstrue'
import cronparser from 'cron-parser'
import {
  WeekDays,
  daysDefault,
  defCron,
  defaultCronWeekFieldArray,
  defaultFreq,
  monthsOfYear,
  weeks,
} from './SchedulerConstants'
import { Country } from 'country-state-city'
import { PipelineExtecutionStatus, PipelineSchedule } from '../../businessObjects'

interface MonthSelectorProps {
  value: string
  onChange: (dayFreq?: string, wkFreq?: string) => void
}

interface YearSelectorProps {
  value: string
  onChange: (dayFreq?: string, month?: string) => void
}

interface DaySelectorProps {
  value: string
  onChange: (value: string) => void
}

interface TimeSelectorProps {
  value: string
  onChange: (value: string) => void
}

interface FrequencySelectorProps {
  value: string
  onChange: (value: string) => void
}

export type TzOptions = { startDate?: Date; tz?: string; endDate?: Date }

export const getCronHumanReadable = (scheduleStr: string, tzOptions: TzOptions) => {
  let isCronValid = true
  try {
    cronparser.parseExpression(scheduleStr, tzOptions)
  } catch (e) {
    isCronValid = false
  }

  if (isCronValid) {
    const humanReadTxt = cronstrue.toString(scheduleStr)

    const txtparts = humanReadTxt.split(' ')
    // const hourSearch = ['hour', 'Hourly']
    // const days = ['day', 'days']
    let cronFr: CronFrequency = 'None'

    if (humanReadTxt.includes('hour')) {
      console.log('hourly')
      cronFr = 'Hourly'
    }
    if (humanReadTxt.includes('days') && !humanReadTxt.includes('month')) {
      console.log('days')
      cronFr = 'Daily'
    }
    if (humanReadTxt.includes('month') && txtparts.some((txt) => weeks.includes(txt))) {
      console.log('monthly')
      cronFr = 'Monthly'
    }
    if (txtparts.some((txt) => weeks.includes(txt))) {
      console.log('weekly')
      cronFr = 'Weekly'
    }

    return { cronFr, humanReadTxt }
  }
}
const MonthSelector: React.FC<MonthSelectorProps> = ({ value, onChange }) => {
  const [everyOccurence, setEveryOccurence] = useState<number>()

  const [selectedWeeks, setSelectedWeeks] = useState<number[]>([])

  const handleFormat = (newFormats: number[]) => {
    setSelectedWeeks(newFormats)
    //console.log({ newFormats })
    updateChanges(everyOccurence ? JSON.stringify(everyOccurence) : undefined, JSON.stringify(newFormats))
  }
  const handleMonthOc = (val: string) => {
    setEveryOccurence(Number(val))
    updateChanges(val, selectedWeeks.length ? JSON.stringify(selectedWeeks) : undefined)
  }

  const updateChanges = (dayFreq?: string, wkFreq?: string) => {
    onChange(dayFreq, wkFreq)
  }

  return (
    <>
      <Grid item container alignItems="center" spacing={2}>
        <Grid item container alignItems="center" alignContent="flex-start" xs={8} spacing={1}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">please enter day value from 1 to 31</Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography>Every</Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              required
              size="small"
              variant="outlined"
              value={everyOccurence}
              error={everyOccurence ? everyOccurence < 0 || everyOccurence > 31 : undefined}
              onChange={({ target }) => handleMonthOc(target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography> day of Month</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ToggleButtonGroup
            fullWidth
            value={selectedWeeks}
            onChange={(_, value) => handleFormat(value)}
            aria-label="week"
          >
            {weeks.map((wk, ix) => (
              <ToggleButton key={wk + ix} size="small" color="success" value={ix}>
                {wk.slice(0, 3)}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </>
  )
}

const YearSelector: React.FC<YearSelectorProps> = ({ value, onChange }) => {
  const [selectedMonth, setSelectedMonths] = useState<number>(0)
  const [everyOccurence, setEveryOccurence] = useState<number>(1)

  const handleMonthSelection = (mntind: string) => {
    const mnth = Number(mntind)
    setSelectedMonths(mnth)
    updateCallback(String(everyOccurence), String(mnth + 1))
  }

  const handleOccSelection = (occ: string) => {
    const mnth = Number(selectedMonth) + 1
    setEveryOccurence(Number(occ))
    updateCallback(occ, String(mnth))
  }

  const updateCallback = (dayFreq?: string, month?: string) => {
    onChange(dayFreq, month)
  }

  return (
    <Grid item container alignItems="center" spacing={2}>
      <Grid item container alignItems="center" alignContent="flex-start" xs={8} spacing={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">please enter day value from 1 to 31</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography>Every</Typography>
        </Grid>
        <Grid item xs={3}>
          <TextField
            required
            size="small"
            variant="outlined"
            value={everyOccurence}
            error={everyOccurence ? everyOccurence < 0 || everyOccurence > 31 : undefined}
            onChange={({ target }) => handleOccSelection(target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            required
            size="small"
            variant="outlined"
            value={selectedMonth}
            select
            onChange={({ target }) => handleMonthSelection(target.value)}
          >
            {monthsOfYear.map((mnth, ix) => (
              <MenuItem key={mnth} value={ix}>
                {mnth}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </Grid>
  )
}

const DaySelector: React.FC<DaySelectorProps> = ({ value, onChange }) => {
  const [dayFreq, setDayfreq] = useState<number>(0)
  const handleChange = (sel: string) => {
    setDayfreq(Number(sel))
    onChange(sel)
  }

  useEffect(() => {
    if (value && dayFreq == 0) {
      const interval = cronparser.parseExpression(value)
      const fields = JSON.parse(JSON.stringify(interval.fields)) // Fields is immutable
      const dayFreqArr = fields.dayOfMonth

      if (dayFreqArr.length && dayFreqArr.length > 1) {
        setDayfreq(dayFreqArr[1] - dayFreqArr[0])
      }
    }
  }, [value])
  return (
    <Grid item container alignItems="center" spacing={1}>
      <Grid item xs={4}>
        <Typography>Every</Typography>
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          required
          size="small"
          variant="outlined"
          value={dayFreq}
          error={dayFreq < 0}
          onChange={(e) => handleChange(e.target.value)}
        />
      </Grid>
      <Grid item xs={4}>
        <Typography>Days </Typography>
      </Grid>
    </Grid>
  )
}
const HourSelector: React.FC<TimeSelectorProps> = ({ value, onChange }) => {
  const [hourFreq, setHourFreq] = useState<number>(1)
  const handleChange = (sel: string) => {
    setHourFreq(Number(sel))
    onChange(sel)
  }
  return (
    <Grid item container alignItems="center" spacing={1}>
      <Grid item xs={4}>
        <Typography>Every</Typography>
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          required
          size="small"
          variant="outlined"
          value={hourFreq}
          error={hourFreq < 0}
          onChange={(e) => handleChange(e.target.value)}
        />
      </Grid>
      <Grid item xs={4}>
        <Typography> Hour </Typography>
      </Grid>
    </Grid>
  )
}

const WeeklyFreqency: React.FC<TimeSelectorProps> = ({ value, onChange }) => {
  const [everyOccurence, setEveryOccurence] = useState<number>()

  const [selectedWeeks, setSelectedWeeks] = useState<number[]>([])

  useEffect(() => {
    if (!selectedWeeks.length && value) {
      const interval = cronparser.parseExpression(value)
      const fields = JSON.parse(JSON.stringify(interval.fields)) // Fields is immutable
      fields.dayOfWeek.length && setSelectedWeeks(fields.dayOfWeek)
    }
  }, [value])

  const handleFormat = (event: React.MouseEvent<HTMLElement>, newFormats: string[]) => {
    setSelectedWeeks(newFormats.map((p) => Number(p)))
    //console.log({ newFormats })
    onChange(JSON.stringify(newFormats))
  }

  return (
    <Grid sx={{ ml: 0.5 }} container spacing={1}>
      <ToggleButtonGroup fullWidth value={selectedWeeks} onChange={handleFormat} aria-label="week">
        {weeks.map((wk, ix) => (
          <ToggleButton key={wk + ix} size="small" color="success" value={ix}>
            {wk.slice(0, 3)}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Grid>
  )
}

const FrequencySelector: React.FC<FrequencySelectorProps> = ({ value, onChange }) => {
  const freqArr = ['Hourly', 'Daily', 'Weekly', 'Monthly', 'None']
  return (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="Frequency"
      value={value}
      select
      onChange={(e) => onChange(e.target.value as string)}
    >
      {freqArr.map((frq) => (
        <MenuItem key={frq} value={frq}>
          {frq}
        </MenuItem>
      ))}
    </TextField>
  )
}
type CronFrequency = 'Monthly' | 'Yearly' | 'Daily' | 'Weekly' | 'None' | 'Hourly'
type CronProps = {
  cronFrequency: CronFrequency
  pipelineSchedule: PipelineSchedule
  updatePipelineSchedule: (freq: CronFrequency, pipelineSchedule: PipelineSchedule) => void
}
const CronScheduler: React.FC<CronProps> = ({ cronFrequency, pipelineSchedule, updatePipelineSchedule }) => {
  const [month, setMonth] = useState('*')

  const [time, setTime] = useState('00:00')
  const [showRecurrency, setShowRecurrency] = useState<{
    monthly: boolean
    daily: boolean
    yearly: boolean
    weekly: boolean
    hourly: boolean
    none: boolean
  }>(defaultFreq)
  const [frequency, setFrequency] = useState<CronFrequency>('None')

  const [startDate, setStartDate] = useState<Dayjs | null | undefined>()
  const [endDate, setEndDate] = useState<Dayjs | null | undefined>()
  const [scheduleParts, setScheduleParts] = useState<string>(defCron)
  const [tzOptions, setTzOptions] = useState<{ startDate?: Date; tz?: string; endDate?: Date }>({
    startDate: new Date(),
    tz: 'America/Toronto',
  })

  const updateCronDetails = (updFreq: CronFrequency, pipelineObj: {}) => {
    const upsc: PipelineSchedule = {
      ...pipelineSchedule,
      ...pipelineObj,
      // startdate: tzOptions.startDate?.toISOString() ?? '',
      // enddate: tzOptions.endDate?.toISOString() ?? '',
      // timeZone: tzOptions.tz ?? 'America/Toronto',
    }
    console.log({ pipelineObj }, { upsc })
    updatePipelineSchedule(updFreq, upsc)
  }

  useEffect(() => {
    if (pipelineSchedule) {
      setScheduleParts(pipelineSchedule.frequency)
      if (cronFrequency) {
        setFrequency(cronFrequency)
        switch (cronFrequency) {
          case 'Daily':
            setShowRecurrency({ ...defaultFreq, daily: true })
            break
          case 'Hourly':
            setShowRecurrency({ ...defaultFreq, hourly: true })
            break
          case 'Weekly':
            setShowRecurrency({ ...defaultFreq, weekly: true })
            break
          case 'Monthly':
            setShowRecurrency({ ...defaultFreq, monthly: true })
            break
          case 'Yearly':
            setShowRecurrency({ ...defaultFreq, yearly: true })
            break
          default:
            setShowRecurrency(defaultFreq)
        }
      }
      setTzOptions({
        startDate:
          pipelineSchedule?.startdate && pipelineSchedule?.startdate !== null
            ? new Date(pipelineSchedule?.startdate)
            : undefined,
        tz: !pipelineSchedule.timeZone ? 'America/Toronto' : pipelineSchedule.timeZone,
        endDate:
          pipelineSchedule?.enddate && pipelineSchedule?.enddate !== null
            ? new Date(pipelineSchedule?.enddate)
            : undefined,
      })
      pipelineSchedule?.startdate &&
        pipelineSchedule?.startdate !== null &&
        setStartDate(dayjs(pipelineSchedule.startdate))
      pipelineSchedule?.enddate && pipelineSchedule?.enddate !== null && setEndDate(dayjs(pipelineSchedule.enddate))
      // const interval = cronparser.parseExpression(pipelineSchedule.pipelineCronString)
      // const fields = JSON.parse(JSON.stringify(interval.fields)) // Fields is immutable
      // if (fields.hour.length > 1) {
      //   setFrequency('Hourly')
      // }
      // if (fields.month.length < 12 && fields.dayOfWeek.length > 0) {
      //   setFrequency('Monthly')
      // }
      // if (fields.dayOfWeek.length > 0) {
      //   setFrequency('Weekly')
      // }
    }
  }, [])
  // const interval = cronparser.parseExpression(scheduleParts)
  // const fields = JSON.parse(JSON.stringify(interval.fields))
  // console.log({ fields })
  const handleDateSelection = (selDay: Dayjs | null | undefined) => {
    console.log({ selDay })
    setStartDate(selDay)
    if (selDay && selDay) {
      const cDate = new Date(selDay.toDate())
      setTzOptions((prev) => ({
        ...prev,
        startDate: cDate,
      }))
      pipelineSchedule && updateCronDetails(cronFrequency, { startdate: cDate.toISOString() })
    }
  }
  console.log({ tzOptions })
  const handleEndDateSelection = (selDay: Dayjs | null | undefined) => {
    console.log({ selDay })
    setEndDate(selDay)
    if (selDay && selDay) {
      const cDate = new Date(selDay.toDate())
      setTzOptions((prev) => ({
        ...prev,
        endDate: cDate,
      }))
      pipelineSchedule && updateCronDetails(cronFrequency, { enddate: cDate.toISOString() })
    }
  }

  const handleFrequency = (freq: string) => {
    const selectedFreq = freq as typeof frequency
    setFrequency(selectedFreq)
    setScheduleParts(defCron)
    switch (selectedFreq) {
      case 'Daily':
        setShowRecurrency({ ...defaultFreq, daily: true })
        break
      case 'Hourly':
        setShowRecurrency({ ...defaultFreq, hourly: true })
        break
      case 'Weekly':
        setShowRecurrency({ ...defaultFreq, weekly: true })
        break
      case 'Monthly':
        setShowRecurrency({ ...defaultFreq, monthly: true })
        break
      case 'Yearly':
        setShowRecurrency({ ...defaultFreq, yearly: true })
        break
      default:
        setShowRecurrency(defaultFreq)
    }
  }

  const handleMonthyFreq = (dayFreq?: string, wkFreq?: string) => {
    const df = Number(dayFreq)
    if (df > 0 && wkFreq) {
      const dailyFreSc = `0 0 ${df} * *`
      const wkDays = JSON.parse(wkFreq)
      const interval = cronparser.parseExpression(dailyFreSc)
      const fields = JSON.parse(JSON.stringify(interval.fields)) // Fields is immutable

      //console.log({ fields })
      fields.dayOfWeek = !wkDays.length ? defaultCronWeekFieldArray : wkDays
      const modifiedInterval = cronparser.fieldsToExpression(fields)
      //console.log({ modifiedInterval })
      setScheduleParts(modifiedInterval.stringify())
      const cronStr = modifiedInterval.stringify()
      updateCronDetails(frequency, { frequency: modifiedInterval.stringify() })
    } else {
      const dailyFreSc = df > 0 ? `0 0 ${df} * *` : undefined
      dailyFreSc && updateCronDetails(frequency, { frequency: dailyFreSc })
      dailyFreSc && setScheduleParts(dailyFreSc)
    }
    //setScheduleParts(defCron)
  }

  // const handleYearlyFreq = (dayFreq?: string, month?: string) => {
  //   const df = Number(dayFreq)
  //   if (df > 0 && month) {
  //     const dailyFreSc = `0 0 ${df} ${month} *`

  //     setScheduleParts(dailyFreSc)
  //     updateCronDetails(frequency, dailyFreSc)
  //   } else {
  //     const dailyFreSc = df > 0 ? `0 0 ${df} * *` : undefined
  //     dailyFreSc && setScheduleParts(dailyFreSc)
  //     dailyFreSc && updateCronDetails(frequency, dailyFreSc)
  //   }
  //   //setScheduleParts(defCron)
  // }

  const handleDailyFreq = (freq: string) => {
    const df = Number(freq)
    console.log({ df })
    if (df > 0) {
      const dailyFreSc = `0 0 */${df} * *`
      setScheduleParts(dailyFreSc)
      updateCronDetails(frequency, { frequency: dailyFreSc })
    } else {
      setScheduleParts(defCron)
      updateCronDetails(frequency, { frequency: defCron })
    }
  }
  const handleHourlyFreq = (freq: string) => {
    // const interval = cronparser.parseExpression(scheduleParts)
    // const fields = JSON.parse(JSON.stringify(interval.fields)) // Fields is immutable
    // //console.log({ fields })
    // fields.hour = Array.from({ length: Number(freq) + 1 }, (_, i) => i)
    // const modifiedInterval = cronparser.fieldsToExpression(fields)
    const hrFr = Number(freq)
    const hrschedule = hrFr > 0 && hrFr < 24 ? `0 */${hrFr} * * *` : defCron
    setScheduleParts(hrschedule)
    updateCronDetails(frequency, { frequency: hrschedule })
  }

  const handleWeeklyFreq = (freq: string) => {
    const wkDays = JSON.parse(freq)
    const interval = cronparser.parseExpression(scheduleParts)
    const fields = JSON.parse(JSON.stringify(interval.fields)) // Fields is immutable

    fields.dayOfWeek = !wkDays.length ? defaultCronWeekFieldArray : wkDays
    //console.log({ fields })
    const modifiedInterval = cronparser.fieldsToExpression(fields)

    setScheduleParts(modifiedInterval.stringify())
    updateCronDetails(frequency, { frequency: modifiedInterval.stringify() })
  }

  const humanReadableText = useMemo(() => {
    return getCronHumanReadable(scheduleParts, tzOptions)
  }, [scheduleParts, tzOptions])

  const handleTimeZone = (tzString: string) => {
    setTzOptions((prv) => ({ ...prv, tz: tzString }))
    console.log({ tzString })
    updateCronDetails(cronFrequency, {
      timeZone: tzString ?? 'America/Toronto',
    })
  }

  return (
    <Grid item container spacing={2} alignItems="stretch">
      <Grid item xs={4.5}>
        <FrequencySelector value={frequency} onChange={handleFrequency} />
      </Grid>
      <Grid item xs={12} />
      {showRecurrency.hourly && (
        <>
          <Grid item xs={5}>
            <HourSelector value={scheduleParts} onChange={handleHourlyFreq} />
          </Grid>
          <Grid item xs={12} />
        </>
      )}
      {showRecurrency.daily && (
        <Grid item xs={12}>
          <DaySelector value={scheduleParts} onChange={handleDailyFreq} />
        </Grid>
      )}
      {showRecurrency.weekly && (
        <Grid item xs={12}>
          <WeeklyFreqency value={scheduleParts} onChange={handleWeeklyFreq} />
        </Grid>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid item xs={6}>
          <DesktopDateTimePicker
            label="Start time"
            value={startDate}
            disablePast
            inputFormat="DD-MMM-YYYY hh:mm a"
            onChange={(newValue) => handleDateSelection(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth size="small" />}
          />
        </Grid>

        <Grid item xs={6}>
          <DesktopDateTimePicker
            label="End time"
            value={endDate}
            disablePast
            inputFormat="DD-MMM-YYYY hh:mm a"
            onChange={(newValue) => handleEndDateSelection(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth size="small" />}
          />
        </Grid>

        <Grid item xs={4}>
          {showRecurrency.weekly && (
            <TimePicker
              label="Start time"
              onChange={(ntime) => ntime && setTime(ntime)}
              value={time}
              inputFormat="hh:mm a"
              ampm
              renderInput={(params) => <TextField {...params} fullWidth size="small" />}
            />
          )}
        </Grid>
      </LocalizationProvider>

      {showRecurrency.monthly && (
        <Grid item xs={12}>
          <MonthSelector value={month} onChange={handleMonthyFreq} />
        </Grid>
      )}
      {/* {showRecurrency.yearly && (
        <Grid item xs={12}>
          <YearSelector value={scheduleParts} onChange={handleYearlyFreq} />
        </Grid>
      )} */}
      <Grid item xs={12}>
        <TextField
          value={tzOptions.tz}
          size="small"
          fullWidth
          select
          onChange={({ target }) => handleTimeZone(target.value)}
        >
          {Country.getAllCountries()
            .map((country) =>
              country.timezones?.map((timeoptions) => {
                const { isoCode } = country
                const { tzName, zoneName, gmtOffsetName, abbreviation } = timeoptions
                return { tzName, zoneName, gmtOffsetName, abbreviation, isoCode }
              }),
            )
            .flat()
            .map((tp, ix) => (
              <MenuItem key={ix + `${tp?.tzName}`} value={tp?.zoneName}>
                {tp?.isoCode} {tp?.gmtOffsetName} {tp?.zoneName}
              </MenuItem>
            ))}
        </TextField>
      </Grid>

      <Grid item xs={12}>
        {humanReadableText && <Typography variant="caption"> {humanReadableText.humanReadTxt} </Typography>}
      </Grid>
    </Grid>
  )
}

export default CronScheduler
