import React, { ReactNode } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid, IconButton } from "@mui/material";
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined'
type Props = {
    onConfirm?: () => void
    openPopUp: boolean
    headingText: React.ReactNode
    closePopup: (close: boolean) => void
    children?: ReactNode
    confirmText?: string
    cancelText?: string
    showCloseIcon?: boolean
    diabledConfirm?: boolean
    jobsRunning?: boolean
    midButtonAction?: () => void
    midButtonText?: string
    solidCancelButton?: boolean
    solidMidButton?: boolean
}
const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function LucidWidePopUp(
    {
        headingText,
        onConfirm,
        openPopUp,
        closePopup,
        confirmText,
        cancelText,
        children,
        midButtonAction,
        midButtonText,
        showCloseIcon,
        diabledConfirm,
        solidCancelButton,
        solidMidButton,
    }: Props
) {
    // const [open, setOpen] = React.useState(false);
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);

    return (
        <div>
            {/* <Button onClick={handleOpen}>Open modal</Button> */}
            <Modal
                open={openPopUp}
                onClose={closePopup}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid item container justifyContent={'center'} alignItems={'stretch'} xs={11} spacing={3}>
                        <Grid item xs={11} />
                        <Grid item container justifyContent={'left'} alignItems={'stretch'} xs={15}>
                            {headingText}
                        </Grid>

                        {openPopUp && showCloseIcon ? (
                            <Grid item xs={1}>
                                <IconButton
                                    aria-label="close"
                                    color="primary"
                                    onClick={() => closePopup(!openPopUp)}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        //color: (theme) => theme.palette.primary,
                                    }}
                                >
                                    <CancelPresentationOutlinedIcon />
                                </IconButton>
                            </Grid>
                        ) : null}
                    </Grid>

                    {children}
                    <Grid container justifyContent="space-between" alignContent="baseline" spacing={2}>
                        <Grid item xs={6} md={4}>
                            {cancelText ? (
                                <Button
                                    fullWidth
                                    variant={solidCancelButton ? 'contained' : 'text'}
                                    onClick={() => closePopup(!openPopUp)}
                                >
                                    {cancelText}
                                </Button>
                            ) : (
                                <></>
                            )}
                        </Grid>
                        <Grid item xs={6} md={4}>
                            {midButtonText && midButtonAction ? (
                                <Button fullWidth variant={solidMidButton ? 'contained' : 'text'} onClick={midButtonAction}>
                                    {midButtonText}
                                </Button>
                            ) : (
                                <></>
                            )}
                        </Grid>

                        {confirmText && (
                            <Grid item xs={3} md={4}>
                                <Button variant="contained" fullWidth color="primary" disabled={diabledConfirm} onClick={onConfirm}>
                                    {confirmText}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Box>

            </Modal>
        </div>
    );
}
