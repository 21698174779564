import { sendGenApiRequest } from '../requestManager/sendGenApiRequest'

const dataPodPaths = {
  genCustomAttribute: 'CustomAttribute',
  genCustomEntity: 'CustomEntity',
  genSubjectAreas: 'DataPod/GenerateSubjectArea',
}

const generateCustomAttributeCode = async (
  customAttributeID: number,
  dataPodId: string,
  promptText: string,
  token?: string,
) => {
  const cmBody = {
    dataPodId: dataPodId,
    customAttributeId: customAttributeID,
    promptText: promptText,
    language: 'pyspark',
  }
  return await sendGenApiRequest(dataPodPaths.genCustomAttribute, 'POST', token ?? 'Lucid123', cmBody)
}

const generateCustomEntityCode = async (
  customEntityId: number,
  dataPodId: string,
  promptText: string,
  token?: string,
) => {
  const cmBody = {
    dataPodId: dataPodId,
    customEntityId: customEntityId,
    promptText: promptText,
    language: 'pyspark',
  }
  return await sendGenApiRequest(dataPodPaths.genCustomEntity, 'POST', token ?? 'Lucid123', cmBody)
}

const recommendSubjectAreas = async (dataPodId: string, token: string) => {
  return await sendGenApiRequest(dataPodPaths.genSubjectAreas, 'POST', token ?? 'Lucid123', {
    dataPodId,
  })
}

export const genServices = {
  generateCustomEntityCode,
  generateCustomAttributeCode,
  recommendSubjectAreas,
}
