import * as React from 'react'
import debounce from 'lodash.debounce'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useState } from 'react'
import { useGetDataPods } from '../hooks/datapodHooks'

type Props = { defaultVal?: string; selectPod: (id: any) => void; size?: any; disabled?: boolean }
function LucidDataPodDebounceSearch({ defaultVal = '', selectPod, size, disabled = false }: Props) {
  const [inputValue, setInputValue] = useState('')
  const { allDatapods, getPods, loading } = useGetDataPods({}, inputValue || defaultVal, 100, 1)

  const debouncedFetch = React.useCallback(
    debounce((value: string) => {
      getPods(value)
    }, 500),
    [],
  )

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newInputValue = event.target.value
    setInputValue(newInputValue)
    //At least 3 letters to allow search via api
    if (newInputValue && newInputValue.length > 2) {
      debouncedFetch(newInputValue)
    }
  }

  const options = allDatapods?.map((a: any) => a.name + ' (' + a.id + ')') || []
  let option: any = ''
  if (!inputValue && defaultVal && allDatapods?.length) {
    option = allDatapods?.find((a) => a.id === defaultVal)
    option = option ? option?.name + ' (' + option?.id + ')' : ''
  }

  return (
    <Autocomplete
      clearOnBlur={false}
      freeSolo={true}
      key={'autocomplete-lucid'}
      id={'autocomplete-id-lucid'}
      autoHighlight
      loading={loading}
      noOptionsText={`No Data Pods found with Keyword : ${inputValue}`}
      options={options}
      getOptionLabel={(option: any) => option}
      onChange={(event, option, reason) => {
        if (option && reason !== 'clear') {
          selectPod(option?.split(' (').pop()?.replace(')', ''))
        }
      }}
      isOptionEqualToValue={(option, newValue) => {
        return option === newValue
      }}
      disabled={disabled}
      value={option}
      renderInput={(params) => (
        <TextField
          {...params}
          color="primary"
          size={size ?? 'medium'}
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Search Data Pod"
        />
      )}
    />
  )
}

export default LucidDataPodDebounceSearch
