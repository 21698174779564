import { AddCoreEntityAttribute, getCoreEntityAttributeResponse } from "../businessObjects"
import { sendRequest } from "../requestManager/sendRequest"

const coreEntityAttributesPath = {
    getCoreEntityAttributes:"coreEntityAttribute/CoreEntityAttributes/GetCoreEntityAttributes",
    getCoreEntityAttributeById:"coreEntityAttribute/CoreEntityAttributes/GetCoreEntityAttributeById",
    addCoreEntityAttribute:"coreEntityAttribute/CoreEntityAttributes/AddCoreEntityAttribute",
    addMultipleCoreEntityAttributes:"coreEntityAttribute/CoreEntityAttributes/addMultiple",
    updateCoreEntityAttribute:"coreEntityAttribute/CoreEntityAttributes/UpdateCoreEntityAttribute",
    deletCoreEntityAttribute:"coreEntityAttribute/CoreEntityAttributes/DeleteCoreEntityAttribute"
  }


export const createMultipleCoreEntityAttributes = async (token: string, dataCoreEntityAttribute:AddCoreEntityAttribute[]) => {
    const endPoint = coreEntityAttributesPath.addMultipleCoreEntityAttributes
    return await sendRequest(endPoint, 'POST', token, dataCoreEntityAttribute)
}

export const getCoreEntityAttribute = async(token:string, datapodID:string, coreEntityId:number) => {
    let endPoint = coreEntityAttributesPath.getCoreEntityAttributes
    if(datapodID && coreEntityId){
        endPoint+=`?coreEntityId=${coreEntityId}&dataPodId=${datapodID}`
    }
    return await sendRequest(endPoint, 'GET', token)
}

export const updateCoreEntityAttribute = async(token:string, params:getCoreEntityAttributeResponse) =>{
    let endPoint = coreEntityAttributesPath.updateCoreEntityAttribute
    return await sendRequest(endPoint, 'PUT', token, params)
}