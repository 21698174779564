// cron expression
// *    *    *    *    *    *
// ┬    ┬    ┬    ┬    ┬    ┬
// │    │    │    │    │    |
// │    │    │    │    │    └ day of week (0 - 7, 1L - 7L) (0 or 7 is Sun)
// │    │    │    │    └───── month (1 - 12)
// │    │    │    └────────── day of month (1 - 31, L)
// │    │    └─────────────── hour (0 - 23)
// │    └──────────────────── minute (0 - 59)
// └───────────────────────── second (0 - 59, optional)

export enum WeekDays {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursay,
  Friday,
  Saturday,
}

export const weeks = ['Sunday', 'Monday', 'Tueday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const daysDefault = Array.from({ length: 31 }, (_, i) => i + 1)

export type CronFrequency = 'Monthly' | 'Yearly' | 'Daily' | 'Weekly' | 'None' | 'Hourly'

export enum MonthsOfYear {
  None,
  January,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}

export const defaultFreq = {
  monthly: false,
  daily: false,
  weekly: false,
  yearly: false,
  none: true,
  hourly: false,
}
export const defCron = '0 0 * * *'

export const defaultCronWeekFieldArray = [0, 1, 2, 3, 4, 5, 6, 7]

export const monthsOfYear = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

export const defaultTimeOptions = {
  startDate: new Date(),
  tz: 'America/Toronto',
}
