import { GenAICoreEntityAttribute, GenAIEnrichedCoreEntityAttribute, GenAIReconcilationBody, GenAIRelationsBody,generateGenAISubjectAreasBody } from "../businessObjects"
import { sendGenApiRequest } from '../requestManager/sendGenApiRequest'
const genAIPath = {
    getGenAiReconcilation:'EntityReconciliation',
    getGenAIRelations:'GenerateRelations',
    getCoreEntityAttributes:'coreEntityAttributes',
    getEnrichedCoreEntityAttributes:'enrichedCoreEntityAttributes',
    generateGenAISubjectAreas: 'GenerateSubjectArea',
  }

export const getGenAiReconcilation = async (
    token: string,
    dataPodId: string,
    prompt?:string
) => {
    const body: GenAIReconcilationBody = {
        dataPodId: dataPodId,
        prompt:prompt
    }
    const endPoint = genAIPath.getGenAiReconcilation
    return await sendGenApiRequest(endPoint, 'POST', token, body)
}

export const generateGenAISubjectAreas = async (
    token: string,
    dataPodId: string,
) => {
    const body: generateGenAISubjectAreasBody = {
        dataPodId: dataPodId
    }
    const endPoint = genAIPath.generateGenAISubjectAreas
    return await sendGenApiRequest(endPoint, 'POST', token, body)
}

export const getGenAiRelations = async (
    token: string,
    dataPodId: string,
    coreEntityId:number
) => {
    const body: GenAIRelationsBody = {
        dataPodId: dataPodId,
        dataCoreEntityId:coreEntityId
    }
    const endPoint = genAIPath.getGenAIRelations
    return await sendGenApiRequest(endPoint, 'POST', token, body)
}

export const getCoreEntityAttributes = async (
    token:string,
    dataPodId:string,
    coreEntityId:number
)=>{
    const body: GenAICoreEntityAttribute = {
        dataPodId: dataPodId,
        coreEntityId:coreEntityId
    }
    const endPoint = genAIPath.getCoreEntityAttributes
    return await sendGenApiRequest(endPoint, 'POST', token, body)
}

export const getEnrichedCoreEntityAttributes = async (
    token:string,
    dataPodId:string,
    enrichedEntityName: string,
    entityType: string,
    description: string,
    canonicalEntities: number[]
)=>{
    const body: GenAIEnrichedCoreEntityAttribute = {
        dataPodId: dataPodId,
        enrichedEntityName:enrichedEntityName,
        entityType:entityType,
        description:description,
        canonicalEntities:canonicalEntities

    }
    const endPoint = genAIPath.getEnrichedCoreEntityAttributes
    return await sendGenApiRequest(endPoint, 'POST', token, body)
}


export const entityServices = { getGenAiReconcilation,generateGenAISubjectAreas }