import { useState, useCallback, useEffect, useMemo } from 'react'
import ReactFlow, {
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  Node,
  Edge,
  OnNodesChange,
  OnEdgesChange,
  MarkerType,
  Controls,
  Background,
  MiniMap,
  ControlButton,
  Panel,
  ReactFlowProvider,
} from 'reactflow'
import 'reactflow/dist/style.css'
import './reactFlow.css'

import BiDirectionalNode from './BidirectionalNode'
import CustomNode from './CustomNode'
import CustomEdge from './CustomEdge'
import DownloadButton from './DownloadButton'
import CustomNodeIcon from './CustomNodeIcon'

import nodeHtmlToImage from 'node-html-to-image'

import React from 'react'
import Spinner from 'react-spinner-material'
import { colors } from '@mui/material'
import { BorderColor, LineWeight } from '@mui/icons-material'
import { object } from 'zod'
import { ProgressBar } from '../ui-components/ProgressBar'
import AnimatedLoader from '../ui-components/AnimatedLoader'
type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
}
const nodeTypes = {
  customNode: CustomNode,
  customNodeIcon: CustomNodeIcon,
  bidirectional: BiDirectionalNode,
}
const edgeTypes = {
  custom: CustomEdge,
}
type UpdatedNode = Node & {
  ref: string
}

type leftEdgeId = {
  leftId: string
  data: { label: string, tableFlag?: boolean }

}
type rightEdgeId = {
  rightId: string
  data: { label: string, tableFlag?: boolean }
  relation: string
}
const panOnDrag = [1, 2]
function createNodesFromJsonData<T>(data: T[], tableFlagDecider: boolean | undefined) {
  const nodes: Node[] = []
  const numColumns = 4
  const numRows = Math.ceil(data.length / numColumns)
  let sourceEntityCount = 0
  let sourceTableCount = 0;
  let targetCount = 0;
  let targetTableCount = 0;
  data.forEach((table: any, index: any) => {
    const groupId = `group-${index}`
    const rowIndex = Math.floor(index / numColumns)
    const colIndex = index % numColumns
    const groupWidth = 330
    const horizontalSpacing = 170
    const columnLength = table.attributes.length + 1
    let subNodeHeight = 50
    let groupHeight = subNodeHeight + 10
    const verticalSpacing = groupHeight + 1500
    let groupPosition: any
    let hiddenValue: boolean
    let nodeHiddenValue: boolean = true
    if ((table.entityName.includes("Source Table") || table.entityName.includes("Target Table")) && (tableFlagDecider)) {
      hiddenValue = true
    } else {
      hiddenValue = false
    }
    if (table.entityName.includes('Source')) {
      groupPosition = {
        x: 500,
        // y: 10 + ((groupWidth + horizontalSpacing) * sourceEntityCount) - (groupHeight) - 150,
        y: 10 + groupHeight
      }
    }
    if (table.entityName.includes('Source Table')) {
      groupPosition = {
        x: 0,
        y: 10 + (groupWidth + horizontalSpacing) * sourceTableCount + 40,
      }
      sourceTableCount += 1
    }
    if (table.entityName.includes("Target")) {
      groupPosition = {
        x: 1000,
        y: 10 + (groupWidth + horizontalSpacing) * targetCount + 40,
      }
      targetCount += 1
    }
    if (table.entityName.includes("Target Table")) {
      groupPosition = {
        x: 1500,
        y: 10 + (groupWidth + horizontalSpacing) * targetTableCount + 40,
      }
      targetTableCount += 1
    }
    const groupNode: Node = {
      id: groupId,
      type: 'group',
      data: { label: table.entityName, tableId: table.entityId, rows: columnLength },
      position: groupPosition,
      hidden: hiddenValue,
      draggable: true,
      style: {
        width: '300px',
        height: groupHeight,
        WebkitBoxShadow:
          '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        backgroundColor: '#F2F2F2',
        borderWidth: '0px',
        boxShadow: '3',
        borderRadius: '3',
        padding: '16px',
        overflow: 'hidden',
      },
    }
    nodes.push(groupNode)
    const customNodeId = `custom-node-${index}`
    const customNodeIconId = `custom-node-icon-${index}`
    const customNodePosition = { x: 0, y: 50 }
    const customNodePositionIcon = { x: 0, y: 0 }
    let nodeBackgroundColor: string
    if (table.entityName.includes('Source')) {
      nodeBackgroundColor = '#4ec571'
    } else {
      nodeBackgroundColor = '#bc633e'
    }
    let customNodeType: string = "customNodeIcon"

    const customNodeIcon: Node = {
      id: customNodeIconId,
      type: customNodeType,
      data: { label: table.entityName, entityName: table.entityName },
      draggable: false,
      extent: 'parent',
      hidden: hiddenValue,
      parentNode: groupId,
      position: customNodePositionIcon,
      style: {
        backgroundColor: nodeBackgroundColor,
        width: 300,
        color: 'white',
        padding: '16px',
        alignItems: 'center',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        lineHeight: '19.07px',
        fontWeight: 500,
        margin: 0,
      },
    }
    nodes.push(customNodeIcon)

    const customNode: Node = {
      id: customNodeId,
      type: 'customNode',
      data: { entityName: table.dataSystemName },
      draggable: false,
      extent: 'parent',
      hidden: hiddenValue,
      parentNode: groupId,
      position: customNodePosition,
      style: {
        backgroundColor: nodeBackgroundColor,
        width: 300,
        color: 'white',
        padding: '16px',
        alignItems: 'center',
        fontFamily: 'Open Sans',
        fontSize: '12px',
        lineHeight: '19.07px',
        fontWeight: 500,
        margin: 0,
      },
    }
    nodes.push(customNode)

    table.attributes.forEach((column: any, subIndex: any) => {
      const subNodeId = `${groupId}-${subIndex}`
      const subNodeLabel = column.attributeName
      const nodeType = column.type
      const subNodePosition = { x: 2, y: 60 + 50 + 50 * subIndex }
      const subNode: UpdatedNode = {
        id: subNodeId,
        data: { label: subNodeLabel, type: nodeType },
        ref: column.attributeId,
        position: subNodePosition,
        draggable: false,
        extent: 'parent',
        hidden: nodeHiddenValue,
        type: 'bidirectional',
        parentNode: groupId,
        style: {
          width: 295,
          height: subNodeHeight + 'px',
          backgroundColor: '#F7FAFC',
          color: '#202020',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          lineHeight: '19.07px',
          fontWeight: 500,
          cursor: 'pointer',
        },
      }
      nodes.push(subNode)
    })
  })

  return nodes
}

function getColumnIds(nodes: Node[], parentId: any, columnName: any) {
  const columnNodes = nodes.filter(
    (node: any) => node.type === 'bidirectional' && node.parentNode === parentId && node.ref === columnName,
  )
  return columnNodes.map((node: Node) => node.id)
}

function createEdges(relationData: any, nodes: Node[], tableFlagDecider: boolean | undefined) {
  const groupIds = nodes.filter((node: Node) => node.type === 'group').map((group) => group)
  const tableGroups = groupIds.filter((node: Node) => node.data.label.includes("Table")).map((tableGroup) => tableGroup.id)
  const leftColumnIds: leftEdgeId[] = []
  const rightColumnIds: rightEdgeId[] = []
  relationData.forEach((relation: any) => {
    const leftTable = relation.leftEntityId
    const leftTableId = groupIds.find((node: Node) => node.data.tableId === leftTable)?.id
    // console.log({ leftTable })
    // console.log({ leftTableId })

    if (!leftTableId) {
      console.error(`Group ID not found for table: ${leftTable}`)
      return
    }

    const rightTable = relation.rightEntityId
    const rightTableId = groupIds.find((node: Node) => node.data.tableId === rightTable)?.id
    //console.log({ rightTable })

    if (!rightTableId) {
      console.error(`Group ID not found for table: ${rightTable}`)
      return
    }

    const leftColumnId = getColumnIds(nodes, leftTableId, relation.leftAttributeId)
    //console.log({ leftColumnId })
    const leftNode = nodes.find((node: Node) => node.id === leftColumnId[0])
    //console.log({ leftNode })
    // const leftEdge: leftEdgeId = {
    //   leftId: leftColumnId[0],
    //   data: { label: leftNode.data.label },
    // }
    if (leftNode) {
      let tableFlag: boolean = false
      if ((leftNode.parentNode && tableGroups.includes(leftNode.parentNode)) && tableFlagDecider) {
        tableFlag = true
      }
      const leftEdge: leftEdgeId = {
        leftId: leftColumnId[0],
        data: { label: leftNode.data.label, tableFlag: tableFlag },
      }
      leftNode.data.source = true
      leftColumnIds.push(leftEdge)
      //console.log({ leftColumnIds })
    }

    // leftColumnIds.push(leftEdge)
    const rightColumnId = getColumnIds(nodes, rightTableId, relation.rightAttributeId)
    const rightNode = nodes.find((node: Node) => node.id === rightColumnId[0])
    // const rightEdge: rightEdgeId = {
    //   rightId: rightColumnId[0],
    //   data: { label: rightNode.data.label },
    //   relation: relation.cardinality,
    // }
    if (rightNode) {
      let tableFlag: boolean = false
      if ((rightNode.parentNode && tableGroups.includes(rightNode.parentNode)) && tableFlagDecider) {
        tableFlag = true
      }
      const rightEdge: rightEdgeId = {
        rightId: rightColumnId[0],
        data: { label: rightNode.data.label, tableFlag: tableFlag },
        relation: relation.cardinality,
      }
      rightNode.data.target = true
      rightColumnIds.push(rightEdge)
    }

    // rightColumnIds.push(rightEdge)
  })

  return { leftColumnIds, rightColumnIds }
}

function generateEdges(leftColumnIds: leftEdgeId[], rightColumnIds: rightEdgeId[]) {
  // console.log({ leftColumnIds })
  // console.log({ rightColumnIds })
  const edges: Edge[] = []
  leftColumnIds.forEach((leftColumnId, index) => {
    const rightColumnId = rightColumnIds[index]
    let hiddenFlag: boolean = false
    if (rightColumnId.data.tableFlag || leftColumnId.data.tableFlag) {
      hiddenFlag = true
    }
    const edge = {
      id: `edge-${leftColumnId.leftId}-${rightColumnId.rightId}-${index}`,
      data: {
        label: rightColumnId.relation,
        tableFlag: hiddenFlag
      },
      hidden: hiddenFlag,
      label: rightColumnId.relation,
      source: leftColumnId.leftId,
      target: rightColumnId.rightId,
      type: 'custom',
      style: {
        strokeWidth: 6,
        stroke: '#1a192b',
      },
    }
    edges.push(edge)
  })

  return edges
}

const ReactFlowGen = (data: any) => {
  const nodeData = data.nodeData
  //console.log({ nodeData })
  const edgeData = data.edgeData
  const selectedOption = data.selectedOption
  //let initialNodeValues: any = []
  // let initialEdges: any = []
  // if (nodeData.length) {
  //   initialNodeValues = createNodesFromJsonData(nodeData)
  // }
  // console.log({ initialNodeValues })
  const [nodes, setNodes] = useState<Node[]>([])
  const [edges, setEdges] = useState<Edge[]>([])
  const [hidden, setHidden] = useState(false)
  const [snowFlake, setSnowFlake] = useState(false)
  const [showTableData, setShowTableData] = useState<boolean | undefined>()


  // if (initialNodeValues.length) {
  //   const { leftColumnIds, rightColumnIds } = createEdges(edgeData, initialNodeValues)
  //   initialEdges = generateEdges(leftColumnIds, rightColumnIds)
  //   useEffect(() => {
  //     setNodes(initialNodeValues)
  //     setEdges(initialEdges)
  //   }, [])
  // }
  useEffect(() => {
    if (selectedOption && selectedOption.includes("Table")) {
      setShowTableData(false)
    } else if (selectedOption) {
      setShowTableData(true)
    }
  }, [selectedOption])
  useEffect(() => {
    if (!data.nodeData.length) {
      setNodes([])
      setEdges([])
    }
  }, [data.nodeData, data.edgeData])

  const initNodes = useMemo(() => {
    if (!nodeData.length) return []
    return createNodesFromJsonData(nodeData, showTableData)
  }, [nodeData])

  const initEdges = useMemo(() => {
    if (!edgeData.length) return []
    const { leftColumnIds, rightColumnIds } = createEdges(edgeData, initNodes, showTableData)
    return generateEdges(leftColumnIds, rightColumnIds)
  }, [initNodes, edgeData])

  useEffect(() => {
    if (initNodes.length) {
      // const initialNodeValues = createNodesFromJsonData(nodeData)
      // const { leftColumnIds, rightColumnIds } = createEdges(edgeData, initialNodeValues)
      // const initialEdges = generateEdges(leftColumnIds, rightColumnIds)
      setNodes(initNodes)
      setEdges(initEdges)
    }
  }, [initNodes, initEdges.length])
  const onNodesChange: OnNodesChange = useCallback(
    (changes: any) => setNodes((nodes) => applyNodeChanges(changes, nodes)),
    [setNodes],
  )
  const onEdgesChange: OnEdgesChange = useCallback(
    (changes: any) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges],
  )
  const onConnect = useCallback(
    (params: any) =>
      setEdges((eds) => addEdge({ ...params, type: 'smart', markerEnd: { type: MarkerType.Arrow } }, eds)),
    [setEdges],
  )
  const hide = (hidden: boolean) => (node: Node) => {
    node.hidden = hidden
    return node
  }

  useEffect(() => {
    const groupNodeCounts: any = {}
    const nodData = nodes.filter((node) => {
      if (!node.data.source && !node.data.target && node.type === 'bidirectional') {
        return false
      }
      return { ...node }
    })
    nodes.forEach((node) => {
      if ((node.data.source || node.data.target) && node.type === 'bidirectional') {
        const groupId = node.parentNode
        if (groupId) {
          if (groupNodeCounts[groupId]) {
            groupNodeCounts[groupId]++
          } else {
            groupNodeCounts[groupId] = 1
          }
        }
      }
    })
    const updatedNodes = nodData.map((node, index: number) => {
      if (node.type === 'group') {
        const groupId = node.id
        const subNodeHeight = 50
        const groupCount = groupNodeCounts[groupId] || 0
        if (groupCount === 0) {
          return {
            ...node,
            style: {
              ...node.style,
              height: 0,
            },
          }
        }
        const heightReduction = (groupCount + 1) * subNodeHeight + 10
        const newHeight = heightReduction
        return {
          ...node,
          style: {
            ...node.style,
            height: newHeight,
          },
        }
      } else if (node.type === 'bidirectional') {
        const nodePosition = node.position
        const parent = node.parentNode
        if (parent) {
          const parentGroupCount = groupNodeCounts[parent] || 0
          const subNodeHeight = 50
          let attributeIndex = 0
          for (const otherNode of nodData) {
            if (otherNode.parentNode === parent && otherNode.type === 'bidirectional') {
              attributeIndex++
              if (otherNode === node) {
                break
              }
            }
          }
          const updatedPosition = {
            x: nodePosition.x,
            y: parentGroupCount === 0 ? 0 : 10 + subNodeHeight * attributeIndex,
          }
          return {
            ...node,
            position: updatedPosition,
          }
        }
      }
      return node
    })

    if (hidden === true) {
      setNodes(updatedNodes)
    } else {
      setNodes(initNodes)
      setEdges(initEdges)
    }
  }, [hidden])

  const updatePos = useCallback(() => {
    setSnowFlake(!snowFlake)
    if (snowFlake) {
      const centerX = window.innerWidth / 2
      const centerY = window.innerHeight / 2
      const radius = 1000
      setNodes((prvNodes) => {
        const groupNodes = prvNodes.filter((node) => node.type === 'group')
        const angleStep = (2 * Math.PI) / groupNodes.length
        const updatedGroupNodes: Node[] = []
        const occupiedPositions: any = {}
        groupNodes.forEach((node, index) => {
          let angle = angleStep * index
          let distance = 0
          while (occupiedPositions[`${Math.round(angle * 100)},${distance}`]) {
            if (Math.abs(radius - distance) < 100) {
              angle += 0.2
              distance = 0
            } else {
              angle += 0.01
              distance += 10
            }
          }
          const x = centerX + (radius - distance) * Math.cos(angle)
          const y = centerY + (radius - distance) * Math.sin(angle)
          occupiedPositions[`${Math.round(angle * 100)},${distance}`] = true
          updatedGroupNodes.push({
            ...node,
            position: { x, y },
          })
        })

        const updatedNodes = prvNodes.map((node) => (node.type === 'group' ? updatedGroupNodes.shift() || node : node))
        return updatedNodes
      })
    }
  }, [snowFlake])
  const updateChildNodesVisibility = (object: any, tableDataFlag:boolean) => {
    const groupId = nodes.filter((node: any) => node.type === "group" && node.data.label.includes("Table"));
    let updatedNodes = [...nodes];
    let tempEdges = [...edges];
    groupId.forEach((group) => {
      const updatedGroup = updatedNodes.find((node) => node.id === group.id);
      let flag: boolean
      if (updatedGroup) {
        flag = !updatedGroup.hidden
        updatedGroup.hidden = !updatedGroup.hidden;
      }
      // Except the Bidirectional other nodes are activated on the click
      if(!tableDataFlag){
        updatedNodes = updatedNodes.map((node) => {
          if (node.parentNode === group.id && node.type !== "bidirectional" && !object.data.label.includes("Table")) {
            return {
              ...node,
              hidden: !node.hidden,
            };
          }
          return node;
        });
      }else{
        updatedNodes = updatedNodes.map((node) => {
          if (node.type === "bidirectional" && node.parentNode === object.parentNode) {
            return {
              ...node,
              hidden: flag
            }
          }
          return node;
        });
        updatedNodes = updatedNodes.map((node) => {
          if (node.type === "group" && node.id === object.parentNode) {
            let updatedStyle: any
            let tempHeight: any
            if (flag) {
              tempHeight = 60
              updatedStyle = {
                ...node.style,
                height: `${tempHeight}px`,
              };
            } else {
              tempHeight = (node.data.rows + 1) * 50 + 10
              updatedStyle = {
                ...node.style,
                height: `${tempHeight}px`,
              };
            }
            return {
              ...node,
              hidden:flag,
              style: updatedStyle,
            }
          }
          return node
        });
      }
    });
    tempEdges = edges.map((edge) => {
      if (edge.data.tableFlag) {
        return {
          ...edge,
          hidden: !edge.hidden
        }
      } else {
        return {
          ...edge
        }
      }
    })
    const updatedGroupNodes = updatedNodes.filter((node: any) => node.type === "group")
    console.log("latest Updated Nodes", updatedGroupNodes)
    setEdges(tempEdges)
    setNodes(updatedNodes);
  };
  // const updateBiDirectionalNodesVisibility = (object: any) => {
  //   const groupId = nodes.filter((node: any) => node.type === "group" && node.data.label.includes("Table"));
  //   let updatedNodes = [...nodes];
  //   groupId.forEach((group) => {
  //     const updatedGroup = updatedNodes.find((node) => node.id === group.id);
  //     let flag: boolean
  //     if (updatedGroup) {
  //       flag = !updatedGroup.hidden
  //       updatedGroup.hidden = !updatedGroup.hidden;
  //     }
  //     //Sub nodes of the clicked group node are expanded
      
  //   })
  //   setNodes(updatedNodes);
  // };

  const handleNodeClick = useCallback(
    (event: any, object: any) => {
      if (object.type === "customNodeIcon") {
        const clickedElement = event.target;
        let currentElement = clickedElement;
        let dropDownFlag: boolean
        while (currentElement) {
          if (currentElement.tagName === 'svg' && currentElement.id === 'TableData') {
            dropDownFlag = true
            console.log("table data")
            updateChildNodesVisibility(object,dropDownFlag)
            break;
          } else if (currentElement.tagName === 'svg' && currentElement.id === 'SourceTable') {
            console.log("source table")
            dropDownFlag = false
            updateChildNodesVisibility(object, dropDownFlag)
          }
          currentElement = currentElement.parentElement;
        }

      }
      if (object.type === 'bidirectional') {
        const selectedNodeId = object.id
        setEdges((edges: Edge[]) => {
          return edges.map((el) => {
            if (el.source === selectedNodeId || el.target === selectedNodeId) {
              return {
                ...el,
                ...(el.style = { stroke: 'red' }),
                animated: true,
              }
            } else if (el.source !== selectedNodeId || el.target !== selectedNodeId) {
              return {
                ...el,
                animated: false,
              }
            }
            return el
          })
        })
      }
    },
    [edges],
  )
  return (
    <div style={{ width: '95vw', height: '3600%' }} className="reactComponentMount">
      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          fitView
          minZoom={0.099}
          panOnScroll
          selectionOnDrag
          panOnDrag={panOnDrag}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          snapToGrid={true}
          onNodeClick={handleNodeClick}
        >
          <center>
            {/* <Spinner radius={30} color={'2E92DA'} stroke={2} visible={!edges.length || !nodes.length} /> */}
            {/* <ProgressBar loading={!edges.length || !nodes.length} /> */}
            <AnimatedLoader height='50%' width='40%' loading={!edges.length || !nodes.length}/>
          </center>
          <MiniMap
            zoomable
            pannable
            position="top-right"
            style={{ height: 100, top: 0, backgroundColor: '#2E92DA' }}
            title={'Mapping Mini Map'}
          />
          <Controls position="top-left" style={{ top: 25, backgroundColor: '#2E92DA' }}>
            <ControlButton>
              <DownloadButton />
            </ControlButton>
          </Controls>

          <div style={{ position: 'absolute', left: 10, top: 10, zIndex: 4 }}>
            <div>
              <label htmlFor="Hide columns with no relation">
                <input
                  id="ishidden"
                  type="checkbox"
                  checked={hidden}
                  onChange={(event) => setHidden(event.target.checked)}
                  className="react-flow__ishidden"
                />
                <> </>
                {'Show tables and key columns'}
              </label>
            </div>
          </div>
          <Background color="#2E92DA" gap={25} />
          {/* <div>
            <button onClick={updatePos} style={{ position: 'absolute', left: 10, top: 30, zIndex: 4 }}>
              Rearrange tables
            </button>
          </div> */}
        </ReactFlow>
      </ReactFlowProvider>
    </div>
  )
}
export default ReactFlowGen
