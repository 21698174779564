import { sendRequest } from '../requestManager/sendRequest'

const rolesPath = {
    getRoles: 'Permissions/roles',
    getUsers: 'Permissions/users',
    getUsersByRole: 'Permissions/role/users',
    assignRole: 'Permissions/assignrole'
}

const getRoles = async (token: string) => {
    const endPoint = rolesPath.getRoles
    return sendRequest(endPoint, 'GET', token)
}

const getUsers = async (token: string) => {
    const endPoint = rolesPath.getUsers
    return sendRequest(endPoint, 'GET', token)
}

const getUsersByRole = async (token: string, roleId: number) => {
    const endPoint = rolesPath.getUsersByRole + `?roleId=${roleId}`
    return sendRequest(endPoint, 'GET', token)
}

const assignRoles = async (token: string, userId: number, roleId: number) => {
    const endPoint = rolesPath.assignRole + `?userId=${userId}&roleId=${roleId}`
    return sendRequest(endPoint, 'POST', token)
}


export const rolesServices = {
    getRoles,
    getUsers,
    getUsersByRole,
    assignRoles,
}