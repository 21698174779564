import { useEffect, useState } from "react";
import { AxiosError } from "axios";

import { useLucidAuthContext } from "../LucidAuthContext/LucidAuthContext";
import { rolesServices } from "../apiServices/roles";
import { Role, User } from "../businessObjects";

export const useGetRoles = () => {
    const [allRoles, setRoles] = useState<Role[]>([]);
    const {getBearerToken, accountId} = useLucidAuthContext(); 

    const getRoles  = async (): Promise<void> => {
        try {
            const bearerToken = await getBearerToken();
            if (bearerToken && accountId) {
                const {data, status, headers} = await rolesServices.getRoles(bearerToken);
                
                if(status == 200) {
                    setRoles(data);
                }
            }
        } catch (error) {
            const err = error as AxiosError;
            console.log(err.message);
        }
    }

    useEffect(() => {
        if(!allRoles.length) {
            getRoles();
        }
    }, [])

    return {allRoles}
}

export const useGetUsers = () => {
    const [allUsers, setUsers] = useState<User[]>([]);
    const {getBearerToken, accountId} = useLucidAuthContext(); 

    const getUsers  = async (): Promise<void> => {
        try {
            const bearerToken = await getBearerToken();
            if (bearerToken && accountId) {
                const {data, status, headers} = await rolesServices.getUsers(bearerToken);
                
                if(status == 200) {
                    setUsers(data);
                }
            }
        } catch (error) {
            const err = error as AxiosError;
            console.log(err.message);
        }
    }

    useEffect(() => {
        if(!allUsers.length) {
            getUsers();
        }
    }, [])


    return {allUsers}
}

export const useGetUsersByRole = () => {
    const {getBearerToken, accountId} = useLucidAuthContext(); 

    const getUsersByRole  = async (roleId: number): Promise<number[]> => {
        try {
            const bearerToken = await getBearerToken();
            if (bearerToken && accountId) {
                const {data, status, headers} = await rolesServices.getUsersByRole(bearerToken, roleId);
                
                if(status == 200) {
                    return data;
                }
            }
        } catch (error) {
            const err = error as AxiosError;
            console.log(err.message);
            return []
        }

        return [];
    }

    return { getUsersByRole };
}

export const useAssignRole = () => {
    const [userRoleId, setuserRoleId] = useState<number>()
    const { getBearerToken } = useLucidAuthContext();

    const assignRole = async (userId: number, roleId: number): Promise<number> => {
        try {
            const bearerToken = await getBearerToken();
            if (bearerToken) {
                const {data, status, headers} = await rolesServices.assignRoles(bearerToken, userId, roleId);
                
                if (status == 200) {
                    setuserRoleId(data.id);
                    return status;
                }
            }
        } catch (error) {
            const err = error as AxiosError;
            console.log(err.message);
            throw err; 
        }
        return -1;
    }

    return { assignRole };
}