import { useEffect, useState } from 'react'
import { useNavigate, useParams, generatePath } from 'react-router-dom'
import { ROUTES } from '../../Routes/constants'
import DataPodDetailRibbon from '../../ui-components/DataPodDetailRibbon/DataPodDetailRibbon'
import ShadowTable, { ShadowTableAction } from '../../ui-components/ShadowTable/ShadowTable'
import ActionPage from '../../ui-components/ActionPage'
import {
  CardHeader,
  Button,
  Card,
  CardContent,
  Card as MUICard,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  IconButton,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { AnalyticsMeasure, AnalyticsGroup } from '../../businessObjects'
import { AddOutlined, SendOutlined } from '@mui/icons-material'
import { useGetDataPodInfo } from '../../hooks/datapodHooks'
import { useGetAnalyticGroups } from '../../hooks/analyticsGroupHooks'
import { useGetAnalyticMeasure, useMeasureNotebook } from '../../hooks/analyticsMeasureHooks'
import { DataTableRow } from '../../ui-components/DataTable/DataTableTypes'
import { ProgressBar } from '../../ui-components/ProgressBar'
import GroupEntities from './GroupEntities'
import { useGetQueryString } from '../../hooks/queryStringHook'
import { group } from 'console'
import ResultStatus from '../../ui-components/ResultStatus'
import AnimatedLoader from '../../ui-components/AnimatedLoader'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'

type ViewResultsParams = {
  dataPodId: string
}

const analyticsMeasureColumns = [
  {
    label: 'Measure ID',
  },
  {
    label: 'Measure Name',
  },
  {
    label: 'Measure Description',
  },
  { label: 'View Details' },
]

const AnalyticsGroupResults = () => {
  const groupId = useGetQueryString('groupId')
  const { dataPodId } = useParams<ViewResultsParams>()
  const { dataPodInfo } = useGetDataPodInfo(dataPodId)
  const { analyticsGroupLoading, analyticsGroupList } = useGetAnalyticGroups(dataPodId)
  const [selectedAnalyticGroup, setSelectAnalyticGroup] = useState<AnalyticsGroup>()
  const { getAnalyticsMeasure, analyticsMeasureList, analyticsMeasureLoading } = useGetAnalyticMeasure(dataPodId)
  const { generateMeasureKPI, addMeasureNotebookLoading, measureNotebookResponse, noteBookError } =
    useMeasureNotebook(dataPodId)

  const [selectedMeasureRows, setSelectMeasureRows] = useState<DataTableRow[]>([])
  const [errorState, setErrorState] = useState<boolean>(false)
  const navigate = useNavigate()

  const selectAnalyticGroup = (aGroup: AnalyticsGroup) => {
    if (aGroup?.id) {
      setSelectMeasureRows([])
      setSelectAnalyticGroup(aGroup)
      getAnalyticsMeasure(aGroup.id)
    }
  }
  useEffect(() => {
    //Once generate measure KP success, refreshing measures list table
    if (groupId && analyticsGroupList.length) {
      // const preSelectedGroup = analyticsGroupList.find((g) => g.id === parseInt(groupId);
      selectAnalyticGroup(analyticsGroupList.find((g) => g.id === parseInt(groupId)) as any)
    }
  }, [groupId, analyticsGroupList])

  const onAddMeasure = () => {
    let measureDetailPage = generatePath(ROUTES.AddDataAnalyticsMeasuresResults, {
      dataPodId: dataPodId,
    })
    if (selectedAnalyticGroup) {
      measureDetailPage = measureDetailPage + '?groupId=' + selectedAnalyticGroup.id
    }
    navigate(measureDetailPage)
  }

  useEffect(() => {
    if (noteBookError) {
      setErrorState(true)
    }
  }, [noteBookError])

  useEffect(() => {
    //Once generate measure KP success, refreshing measures list table
    if (measureNotebookResponse && selectedAnalyticGroup?.id) {
      getAnalyticsMeasure(selectedAnalyticGroup.id)
    }
  }, [measureNotebookResponse])

  useEffect(() => {
    if (analyticsMeasureList?.length) {
      const tMsrRows = analyticsMeasureList.map((msr: AnalyticsMeasure) => ({
        id: `${msr.analyticsMeasureId}`,
        values: [msr.analyticsMeasureId, msr.analyticsMeasureName, msr.analyticsMeasureDescription, 'VIEW'],
      })) as DataTableRow[]
      setSelectMeasureRows(tMsrRows)
    }
  }, [analyticsMeasureList])

  const onButtonClick = (measureId: string, actionType: ShadowTableAction) => {
    if (actionType === ShadowTableAction.Viewable) {
      let measureDetailPage = generatePath(ROUTES.DataAnalyticsMeasuresResults, {
        dataPodId: dataPodId,
      })
      if (selectedAnalyticGroup?.id && measureId) {
        measureDetailPage =
          measureDetailPage + '?groupId=' + selectedAnalyticGroup.id + '&measureId=' + Number(measureId)
      }
      navigate(measureDetailPage)
    }
  }

  const generateGroupMeasure = () => {
    if (dataPodId && selectedAnalyticGroup?.id) {
      generateMeasureKPI(selectedAnalyticGroup?.id)
    }
  }

  const onClickHome = () => {
    const dataProfilingRoute = generatePath(ROUTES.Overview, { dataPodId: dataPodId })
    navigate(dataProfilingRoute)
  }

  return (
    <ActionPage>
      {noteBookError?.message && (
        <ResultStatus
          showStatus={errorState}
          alertMessage={noteBookError?.message}
          severtiy="error"
          closeStatus={() => setErrorState(false)}
        />
      )}
      {/* <ProgressBar loading={addMeasureNotebookLoading} /> */}
      <AnimatedLoader height='50%' width='40%' loading={addMeasureNotebookLoading}/>
      <CardHeader
        disableTypography
        title={
          <Typography variant="h4" gutterBottom color="primary">
            {'Analytics Groups'}
          </Typography>
        }
      />
      <Grid item xs={0.5}>
        <IconButton onClick={onClickHome}>
          <HomeOutlinedIcon />
        </IconButton>
      </Grid>

      {/* <CardContent>
        <DataPodDetailRibbon
          dataPodId={dataPodId}
          dataPodName={dataPodInfo?.name}
          dataPodIndustryName={dataPodInfo?.industryName}
        />
      </CardContent> */}

      <Grid item container justifyContent={'center'} alignItems={'stretch'} xs={14} spacing={2}>
        <Grid item xs={3}>
          <Card sx={{ backgroundColor: 'white' }}>
            <CardHeader
              disableTypography
              sx={{ backgroundColor: 'grey' }}
              title={<Typography sx={{ fontSize: 15, align: 'right' }}>{'List Of Analytics Groups'}</Typography>}
            />

            {analyticsGroupLoading ? (
              <>
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
              </>
            ) : (
              <List dense sx={{ p: 0 }}>
                {analyticsGroupList.length > 0 ? (
                  analyticsGroupList?.map((p: AnalyticsGroup, index: number) => (
                    <ListItem key={p.id + index} sx={{ p: 0 }}>
                      <ListItemButton
                        onClick={() => selectAnalyticGroup(p)}
                        selected={selectedAnalyticGroup?.id === p.id}
                      >
                        <ListItemText primary={p.analyticsGroupName} />
                      </ListItemButton>
                    </ListItem>
                  ))
                ) : (
                  <Typography sx={{ p: 1, textAlign: 'center' }}>No Data</Typography>
                )}
              </List>
            )}
          </Card>
        </Grid>

        <Grid item xs={9} sx={{ p: 2 }}>
          {selectedAnalyticGroup && (
            <Card sx={{ p: 1, mb: 1 }}>
              <Grid item xs={12} container sx={{ marginBottom: 2 }}>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} variant="h3" textAlign={'left'}>
                    Analytics Group ID
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} textAlign={'left'}>
                    {selectedAnalyticGroup?.id}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} variant="h3" textAlign={'left'}>
                    Group Name
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} textAlign={'left'}>
                    {selectedAnalyticGroup?.analyticsGroupName}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} variant="h3" textAlign={'left'}>
                    Group Description
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} textAlign={'left'}>
                    {selectedAnalyticGroup?.analyticsGroupDescription}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          )}

          <Grid item xs={12} sx={{ mb: 2 }}>
            <GroupEntities />
          </Grid>

          {selectedMeasureRows && (
            <>
              <Grid item xs={12} justifyContent={'space-between'} display={'flex'} sx={{ mb: 2 }} alignItems={'center'}>
                <Grid item xs={6}>
                  <Typography sx={{ fontSize: 16 }} color="primary">
                    List of Analytics Measures
                  </Typography>
                </Grid>
                <Grid item xs={6} justifyContent={'end'} display={'flex'}>
                  {!analyticsMeasureLoading && selectedAnalyticGroup?.id && !analyticsMeasureList.length && (
                    <LoadingButton
                      variant="outlined"
                      color="primary"
                      startIcon={<SendOutlined />}
                      onClick={generateGroupMeasure}
                      loading={addMeasureNotebookLoading}
                      sx={{ mr: 2 }}
                    >
                      Generate Measures (KPI)
                    </LoadingButton>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onAddMeasure}
                    startIcon={<AddOutlined />}
                    disabled={!selectedAnalyticGroup?.id}
                  >
                    Add New Measure
                  </Button>
                </Grid>
              </Grid>
              {analyticsMeasureLoading ? (
                <>
                  <Skeleton variant="rectangular" height={40} />
                  <Skeleton variant="rectangular" height={40} />
                </>
              ) : (
                <ShadowTable
                  rows={selectedMeasureRows}
                  columns={analyticsMeasureColumns}
                  tableActionParams={{
                    onButtonClick: onButtonClick,
                    actions: [ShadowTableAction.Viewable],
                  }}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </ActionPage>
  )
}

export default AnalyticsGroupResults
