import { DataPodInfo } from '../../businessObjects'
import { isValidDescription, isValidEntry } from '../../utils/constants'

export const dataPodInit: DataPodInfo = {
  id: '',
  name: '',
  description: '',
  accountId: '',
  industryId: 0,
}

/*** returns true if object contains valid datapod info */
export const validateDataPodValues = (dataPod: DataPodInfo) => {
  const { name, description, accountId, industryId } = dataPod
  return !!(isValidEntry(name) && accountId && isValidDescription(description) && industryId)
}
