import React, { FC } from 'react'
import { EdgeProps, getSmoothStepPath, EdgeLabelRenderer, BaseEdge } from 'reactflow'

function EdgeLabel({ transform, label }: { transform: string; label: string }) {
  return (
    <div
      style={{
        position: 'absolute',
        background: 'transparent',
        padding: 1,
        color: '#89CFF0',
        fontSize: 12,
        fontWeight: 600,
        transform,
      }}
      className="nodrag nopan"
    >
      {label}
    </div>
  )
}

const CustomEdge: FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  style = {},
}) => {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  return (
    <>
      <BaseEdge id={id} path={edgePath} style={{ stroke: '#ff0072' }} />
      <EdgeLabelRenderer>
        {data.label && (
          <EdgeLabel transform={`translate(-110%, -100%) translate(${targetX}px,${targetY}px)`} label={data.label} />
        )}
      </EdgeLabelRenderer>
    </>
  )
}

export default CustomEdge
