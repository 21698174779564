import React from 'react'

import { ProcessVerticalFlow } from './ProcessVerticalFlow'
import { useGetSessionPodInfo } from '../../hooks/getSessionPodInfo'

type Props = {}

const Overview = (props: Props) => {
  const dataPodInfo = useGetSessionPodInfo()

  return (
    <>
      <ProcessVerticalFlow dataPod={dataPodInfo} />
    </>
  )
}

export default Overview
