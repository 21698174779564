import { useEffect, useState } from 'react'
import { useLucidAuthContext } from '../LucidAuthContext/LucidAuthContext'
import { addNewDataTransformer, updateDataTransformer, getDetailDataTransformer } from '../apiServices/dataTransformer'
import { DataTransformer, DataTransformerDetail, NewDataTransformer } from '../businessObjects'
import { HttpStatusCode } from 'axios'

export const useAddNewDataTransformer = (): {
  addTransformer: (newDataTransformer: NewDataTransformer, dataPodId: string) => Promise<void>
  transformerAddStatus: boolean
} => {
  const [transformerAddStatus, setTransformerAddStatus] = useState<boolean>(false)
  const { getBearerToken } = useLucidAuthContext()
  const addTransformer = async (newDataTransformer: NewDataTransformer, dataPodId: string) => {
    setTransformerAddStatus(false)
    const token = await getBearerToken()
    if (token) {
      const { data, status } = await addNewDataTransformer(token, newDataTransformer, dataPodId)
      if (status === 200) {
        setTransformerAddStatus(true)
      } else {
        setTransformerAddStatus(false)
      }
    }
  }
  return { addTransformer, transformerAddStatus }
}

export const useUpdateDataTransformer = (): {
  updateTransformer: (DataTransformer: DataTransformer, dataPodId: string) => Promise<void>
  transformerUpdateStatus: boolean
} => {
  const [transformerUpdateStatus, setTransformerUpdateStatus] = useState<boolean>(false)
  const { getBearerToken } = useLucidAuthContext()
  const updateTransformer = async (DataTransformer: DataTransformer, dataPodId: string) => {
    setTransformerUpdateStatus(false)
    const token = await getBearerToken()
    if (token) {
      const { data, status } = await updateDataTransformer(token, DataTransformer, dataPodId)
      if (status === 200) {
        setTransformerUpdateStatus(true)
      } else {
        setTransformerUpdateStatus(false)
      }
    }
  }
  return { updateTransformer, transformerUpdateStatus }
}

export const useGetDetailDataTransformer = (): {
  getDataTransformers: (datapodID: string | undefined) => Promise<void>
  dataTransformers: DataTransformerDetail[] | undefined
} => {
  const [dataTransformers, setDataTransformers] = useState<DataTransformerDetail[] | undefined>()
  const { getBearerToken } = useLucidAuthContext()
  const getDataTransformers = async (datapodID: string | undefined) => {
    const token = await getBearerToken()
    if (token && datapodID) {
      const { data, status } = await getDetailDataTransformer(token, datapodID)
      if (status === 200) {
        // return data as DataTransformerDetail[]
        setDataTransformers(data)
      }
    }
  }
  return { getDataTransformers, dataTransformers }
}

export const useAddNewCustomAttributeTransformer = (
  dataPodId?: string,
): {
  addTransformer: (newDataTransformer: NewDataTransformer) => Promise<boolean | void>
} => {
  const { getBearerToken } = useLucidAuthContext()
  const addTransformer = async (newDataTransformer: NewDataTransformer) => {
    const token = await getBearerToken()
    if (token && dataPodId) {
      const { status } = await addNewDataTransformer(token, newDataTransformer, dataPodId)
      return status == HttpStatusCode.Ok
    }
  }
  return { addTransformer }
}

export const useGetDataAttributeTransformer = (datapodId?: string, dataAttributeId?: number) => {
  const [dataTransformers, setDataTransformers] = useState<DataTransformerDetail[]>([])
  const { getBearerToken } = useLucidAuthContext()

  const getDataTransformers = async () => {
    try {
      const token = await getBearerToken()
      if (token && datapodId && dataAttributeId && !dataTransformers.length) {
        const { data, status } = await getDetailDataTransformer(token, datapodId, dataAttributeId)
        if (status === 200) {
          // return data as DataTransformerDetail[]
          setDataTransformers(data)
        }
      }
    } catch (error) {}
  }
  useEffect(() => {
    if (datapodId && dataAttributeId) {
      getDataTransformers()
    }
  }, [datapodId, dataAttributeId])
  return { dataTransformers }
}

export const useGetDataEntityTransformer = (datapodId?: string, dataEntityId?: number) => {
  const [dataTransformers, setDataTransformers] = useState<DataTransformerDetail[]>([])
  const { getBearerToken } = useLucidAuthContext()

  const getDataTransformers = async () => {
    try {
      const token = await getBearerToken()
      if (token && datapodId && dataEntityId && !dataTransformers.length) {
        const { data, status } = await getDetailDataTransformer(token, datapodId, undefined, dataEntityId)
        if (status === 200) {
          // return data as DataTransformerDetail[]
          setDataTransformers(data)
        }
      }
    } catch (error) {}
  }
  useEffect(() => {
    if (datapodId && dataEntityId) {
      getDataTransformers()
    }
  }, [datapodId, dataEntityId])
  return { dataTransformers }
}
