import { oneDark } from '@uiw/react-codemirror'
import React, { useEffect, useRef, useState } from 'react'
import CodeMirror from '@uiw/react-codemirror'
import { Button, Container, Grid, IconButton, Typography } from '@mui/material'
import { AddOutlined, MinimizeOutlined, PlayCircle } from '@mui/icons-material'
import { StreamLanguage } from '@codemirror/language'
import { python, pythonLanguage } from '@codemirror/lang-python'



// type Props = {}
const code = "console.log('hello world!');\n\n\n"
// Define the extensions outside the component for the best performance.
// If you need dynamic extensions, use React.useMemo to minimize reference changes
// which cause costly re-renders.
type CellEditor = {
  code: string
  id: number
  output?: {
    outputFormat?: 'table' | 'Media'
    outputValue?: string
  }
}

const initCells: CellEditor[] = [
  {
    id: 1,
    code: `Code generation pending. Please click 'Generate Code' to proceed.`,
  },
]

type Props = {
  headingText: string
  noteBookString?: string
  updateNoteBookString?: (txt: string) => void
  commitMessage?: string
  updateCommitMessage?: React.Dispatch<React.SetStateAction<string>>
  executeNotebook?: () => Promise<void>
}

const LucidEditor = ({ headingText, noteBookString, updateNoteBookString, commitMessage, updateCommitMessage, executeNotebook }: Props) => {
  const [cellEditor, setCellEditor] = useState<CellEditor[]>(initCells)
  const [value, setValue] = useState('print("hello world!")')
  const [output, setOutput] = useState<string>('')
  const onChange = (cellId: number, val: string) => {
    //console.log('val:', val)
    const updcell = cellEditor.map((cl) => (cl.id === cellId ? { ...cl, code: val } : cl))
    updateNoteBookString && updateNoteBookString(val)
    setCellEditor(updcell)
  }

  const onAddCell = (ix: number) => {
    const nID = (ix % cellEditor.length) + 1
    const newCell: CellEditor = {
      id: ix,
      code: '',
    }
    const updatedCell = [...cellEditor, newCell]

    console.log({ updatedCell })
    setCellEditor(updatedCell)
  }

  const removeCell = (ix: number) => {
    const rmCell = cellEditor.filter((cl) => cl.id !== ix)
    setCellEditor(rmCell)
  }
  // useEffect(() => {
  //   const socket = new WebSocket('ws://localhost:8887/ws')
  //   socket.onopen = () => {
  //     console.log('WebSocket connected')
  //   }
  //   socket.onmessage = (event) => {
  //     const message: { type: string; data: string } = JSON.parse(event.data)

  //     if (message.type === 'text') {
  //       setOutput((prevOutput) => prevOutput + message.data + '\n')
  //     } else if (message.type === 'html') {
  //       setOutput(message.data)
  //     } else if (message.type === 'image') {
  //       setOutput(`<img src="data:image/png;base64,${message.data}" />`)
  //     }
  //   }
  //   socket.onclose = () => {
  //     console.log('WebSocket closed')
  //   }

  //   return () => {
  //     if (socket.readyState === WebSocket.OPEN) {
  //       socket.close()
  //     }
  //   }
  // }, [])

  useEffect(() => {
    if (noteBookString?.length) {
      // console.log({noteBookString})
      setCellEditor([
        {
          id: 0,
          code: noteBookString
        }
      ])
    }
  }, [noteBookString])

  const sendCode = async () => {
    // try {
    //   await axios.post('http://localhost:8888/execute', cellEditor[0].code, {
    //     headers: {
    //       'Content-Type': 'text/plain',
    //     },
    //   })
    // } catch (error) { }
  }

  return (
    <Container style={{height:'100%'}}>
      <Grid item xs={16} alignItems={'baseline'} justifyContent={'center'}>
        <Typography sx={{ mt: 2, pt: 2, pd: 2 }} style={{ textAlign: 'justify' }} align="left" gutterBottom>
          {headingText}
        </Typography>
        {cellEditor.map((cl, ix) => (
          <Grid  item key={cl.id + ix} sx={{ border: 'thin', borderInline: 'true'}} xs={14}>
            {/* <Grid item xs={10}> */}
            <Button startIcon={<PlayCircle />} onClick={() => { executeNotebook && executeNotebook() }}>Execute</Button>
            <CodeMirror
              value={cl.code}
              maxHeight="100%"
              maxWidth='100%'
              width='100%'
              editable
              basicSetup={{
                foldGutter: false,
                dropCursor: false,
                indentOnInput: true,
                lineNumbers: true,
              }}
              extensions={[pythonLanguage]}
              onChange={(value) => onChange(cl.id, value)}
            />
            {/* </Grid> */}
            {/* <Grid item xs={1}>
            <Grid item xs={0.5}>
              <Button
                variant="text"
                onClick={() => onAddCell(cl.id + 1)}
                endIcon={<AddOutlined fontSize="small" />}
                title="Add Cell"
              />
            </Grid>
            <Grid item xs={0.5}>
              <Button
                variant="text"
                disabled={cellEditor.length === 1}
                onClick={() => removeCell(cl.id)}
                endIcon={<MinimizeOutlined fontSize="small" />}
                title="remove Cell"
              />
            </Grid>
          </Grid> */}
          </Grid>
        ))}
        {/* <div className="output">
        <div className="output" dangerouslySetInnerHTML={{ __html: output }} />
      </div> */}
      </Grid>
    </Container>
  )
}

export default LucidEditor
