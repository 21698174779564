import React from 'react'
import { Node, NodeProps } from 'reactflow'
type NodeData = {
  value: number
}
type CustomNode = Node<NodeData>
const handleStyle = {
  left: 10,
}

function CustomNode({ data }: NodeProps) {
  return (
    <>
      <div style={handleStyle}>
        <div className="custom-node__header">{data.entityName}</div>
      </div>
    </>
  )
}
CustomNode.displayName = 'CustomNode'
export default CustomNode
