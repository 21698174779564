import React from 'react'
import { DisplayRowProps } from './DataTableTypes'
import { Tooltip } from '@mui/material'

import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined'
import TimelapseOutlinedIcon from '@mui/icons-material/TimelapseOutlined'
import { StyledTableCell } from './DataTable'

export const DataTableRowCells: React.FC<DisplayRowProps> = ({ row, columns }: DisplayRowProps) => {
  return (
    <>
      {row.values
        .filter((r, i) => i < columns.length)
        .map((v, i) => {
          return (
            <StyledTableCell key={v + columns[i].label} align={columns[i].alignment === 'right' ? 'right' : 'left'}>
              {(columns[i].isStatus || columns[i].isUpdateStatus) && v && v !== 'pending' && (
                <Tooltip title={columns[i].isStatus ? 'Completed' : 'OK'}>
                  <TaskAltOutlinedIcon style={{ color: 'green' }} />
                </Tooltip>
              )}
              {(columns[i].isStatus || columns[i].isUpdateStatus) && (!v || v === 'pending') && (
                <Tooltip title={columns[i].isStatus ? 'pending' : 'Failed'}>
                  <TimelapseOutlinedIcon style={{ color: '' }} />
                </Tooltip>
              )}

              {!columns[i].isStatus && !columns[i].isUpdateStatus && v}
            </StyledTableCell>
          )
        })}
    </>
  )
}

export default DataTableRowCells
