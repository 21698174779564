import { PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../utils/environment'

export const generatefabricToken = async (redirectUri: string | undefined) => {

    try {

        const msalConfig = {
            auth: {
                clientId: environment.fabricTokenClientId || '9e1a4304-8c3c-47cb-8ebb-0960b8203615',
                authority: 'https://login.microsoftonline.com/organizations',
                redirectUri: '/'
            }
        }

        const pca = new PublicClientApplication(msalConfig);

        await pca.initialize();

        const loginResponse = await pca.loginPopup({
            scopes: ['https://api.fabric.microsoft.com/Workspace.ReadWrite.All', 'https://api.fabric.microsoft.com/Item.ReadWrite.All']
        });

        return loginResponse.accessToken

    }
    catch (error) {
        console.log('error in the fabric token generator is ', error)
    }
}
