import { useEffect, useState } from 'react'
import {
  AddEntityRecon,
  EntityRelationship,
  NewEntityRecon,
  NewEntityRelationShip,
  RelationShipResponse,
  TableRelationInput,
  TableRelationShips,
} from '../businessObjects'
import { applicationServices } from '../apiServices/application'
import { useLucidAuthContext } from '../LucidAuthContext/LucidAuthContext'
import { AxiosError } from 'axios'

type dataPodId = string | undefined
type searchText = string | undefined

type dataTableName = string | undefined
type dataEntityName = string | undefined

type Pagination = {
  updatePageCount?: (pages: number) => void
}

export const useGetRelationData = (
  { updatePageCount }: Pagination,
  datapodId: dataPodId,
): [RelationShipResponse[] | undefined, boolean | undefined, boolean | undefined] => {
  const [relationShipData, setRelationShipData] = useState<RelationShipResponse[]>()
  const [loading, setLoading] = useState<boolean>()
  const [errorFetching, setErrorFetching] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const getRelationShipData = async (
    datapodId?: dataPodId,
    searchText?: string,
    recordCount?: number,
    pageNumber?: number,
  ) => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status, headers } = await applicationServices.getEntityRelationships(
          bearerToken,
          datapodId,
          searchText,
          recordCount,
          pageNumber,
        )
        if (status == 200) {
          setRelationShipData(data)
          setLoading(false)
          if (updatePageCount && recordCount) {
            const totalRecords = headers['x-total-count']
            const totalPages = Math.round(totalRecords / recordCount)
            updatePageCount(totalPages)
          }
        }
      }
    } catch (error) {
      setErrorFetching(true)
      setLoading(false)
    }
  }
  useEffect(() => {
    if (datapodId && !relationShipData?.length) {
      getRelationShipData(datapodId)
    }
  }, [datapodId])

  return [relationShipData, loading, errorFetching]
}

export const useGetTableRelationships = (
  dataPodID?: string,
  searchText?: string,
): { tableRelationShips: TableRelationShips[]; refreshTableRelations: () => void } => {
  const [tableRelationShips, setTableRelationShips] = useState<TableRelationShips[]>([])
  const { getBearerToken } = useLucidAuthContext()

  const fetchRelationShips = async () => {
    const token = await getBearerToken()
    if (token) {
      const { data, status } = await applicationServices.getTableRelationShips(token, dataPodID, searchText)
      if (status == 200) {
        setTableRelationShips(data)
      }
    }
  }

  useEffect(() => {
    fetchRelationShips()
  }, [dataPodID])

  return { tableRelationShips: tableRelationShips, refreshTableRelations: fetchRelationShips }
}

export const useGetEntityRelationships = (
  dataPodID?: string,
  searchText?: string,
): { entityRelationShips: RelationShipResponse[]; refreshEntityRelations: () => void } => {
  const [entityRelationShips, setEntityRelationShips] = useState<RelationShipResponse[]>([])
  const { getBearerToken } = useLucidAuthContext()

  const fetchRelationShips = async () => {
    const token = await getBearerToken()
    if (token) {
      const { data, status } = await applicationServices.getEntityRelationships(token, dataPodID, searchText)
      if (status == 200) {
        setEntityRelationShips(data)
      }
    }
  }

  useEffect(() => {
    fetchRelationShips()
  }, [dataPodID])

  return { entityRelationShips: entityRelationShips, refreshEntityRelations: fetchRelationShips }
}

export const useAddTableRelationship = (
  dataPodId?: string,
): { addNewRelationShip: (param: TableRelationInput) => Promise<TableRelationShips | undefined> } => {
  const { getBearerToken } = useLucidAuthContext()

  const submit = async (param: TableRelationInput) => {
    const token = await getBearerToken()
    if (token && dataPodId) {
      const { data, status } = await applicationServices.addNewTableRelationship(token, dataPodId, param)
      if (status == 200) {
        return data as TableRelationShips
      }
    }
  }

  return { addNewRelationShip: submit }
}
export const useUpdateTableRelationship = (
  dataPodId?: string,
): { updateTableRelationShip: (param: TableRelationInput, id?: number) => Promise<TableRelationShips | undefined> } => {
  const { getBearerToken } = useLucidAuthContext()

  const submit = async (param: TableRelationInput, id?: number) => {
    const token = await getBearerToken()
    if (token && dataPodId && id) {
      const updateParams = {
        ...param,
        id,
      }
      const { data, status } = await applicationServices.updateTableRelationship(token, dataPodId, updateParams)
      if (status == 200) {
        return data as TableRelationShips
      }
    }
  }

  return { updateTableRelationShip: submit }
}

export const useAddEntityRelationship = (
  dataPodId?: string,
): { addEntityRelation: (param: NewEntityRelationShip) => Promise<EntityRelationship | undefined> } => {
  const { getBearerToken } = useLucidAuthContext()

  const submit = async (params: NewEntityRelationShip) => {
    const token = await getBearerToken()
    if (token && dataPodId) {
      const { data, status } = await applicationServices.addEntityRelationShip(token, dataPodId, params)
      if (status == 200) {
        return data as EntityRelationship
      }
    }
  }

  return { addEntityRelation: submit }
}

export const useUpdateEntityRelationship = (
  dataPodId?: string,
): { updateEntityRelation: (param: EntityRelationship) => Promise<EntityRelationship | undefined> } => {
  const { getBearerToken } = useLucidAuthContext()

  const submit = async (params: EntityRelationship) => {
    const token = await getBearerToken()
    if (token && dataPodId && params.id) {
      const { data, status } = await applicationServices.updateEntityRelationShip(token, dataPodId, params)
      if (status == 200) {
        return data as EntityRelationship
      }
    }
  }

  return { updateEntityRelation: submit }
}

export const useDeleteEntityRelation = (): {
  confirmDeleteFunc: (DataCoreEntityRelationShipId: number[]) => Promise<boolean | undefined>
} => {
  const { getBearerToken } = useLucidAuthContext()
  const confirmDeleteDataCoreEntityRelationShipId = async (DataCoreEntityRelationShipId: number[]) => {
    try {
      const token = await getBearerToken()
      if (token && DataCoreEntityRelationShipId) {
        const { status } = await applicationServices.deleteEntityRecon(token, DataCoreEntityRelationShipId)
        if (status == 204) {
          return true as boolean
        }
      }
    } catch (error) {
      const { message } = error as AxiosError
      console.log({ message })
    }
  }
  return { confirmDeleteFunc: confirmDeleteDataCoreEntityRelationShipId }
}

export const useCustomEnrichedGetEntityRelationships = () => {
  const { getBearerToken } = useLucidAuthContext()
  const fetchCustomEntityRelationships = async (
    dataPodID?: string | undefined,
    entityId?: number[],
    searchText?: string,
  ): Promise<RelationShipResponse[] | undefined> => {
    const bearerToken = await getBearerToken()
    if (bearerToken && dataPodID && entityId) {
      const { data, status } = await applicationServices.getCustomEnrichedEntityRelationships(
        bearerToken,
        dataPodID,
        entityId,
        searchText,
      )
      if (status == 200) {
        return data as RelationShipResponse[]
      }
    }
  }
  return fetchCustomEntityRelationships
}

export const useAddEntityRecon = (
  dataPodId?: string,
): { addEntityRecon: (param: AddEntityRecon) => Promise<NewEntityRecon | undefined> } => {
  const { getBearerToken } = useLucidAuthContext()

  const submit = async (params: AddEntityRecon) => {
    const token = await getBearerToken()
    if (token && dataPodId) {
      const { data, status } = await applicationServices.addEntityReconciliation(token, dataPodId, params)
      if (status == 200) {
        return data as NewEntityRecon
      }
    }
  }

  return { addEntityRecon: submit }
}

export const useDeleteEntityRecon = (): {
  confirmDeleteEntityRecon: (entityReconIds: number[]) => Promise<boolean | undefined>
} => {
  const { getBearerToken } = useLucidAuthContext()
  const confirmDeleteEntityRecon = async (entityReconIds: number[]) => {
    try {
      const token = await getBearerToken()
      if (token && entityReconIds.length) {
        const { status } = await applicationServices.deleteEntityRecon(token, entityReconIds)
        if (status == 204) {
          return true as boolean
        }
      }
    } catch (error) {
      const { message } = error as AxiosError
      console.log({ message })
    }
  }
  return { confirmDeleteEntityRecon }
}
