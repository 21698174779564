import { Grid, Typography, Box } from '@mui/material'

import { cardContainer } from '../../../styles/globalStyles'
import ActionPage from '../../../ui-components/ActionPage'
import DataProcessCard from '../DataProcessCard'
import { useGetSessionPodInfo } from '../../../hooks/getSessionPodInfo'

export default function DataLakeHome() {
  const dataPod = useGetSessionPodInfo()
  return (
    <ActionPage>
      <Box style={cardContainer}>
        <Grid container spacing={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} />
            <Grid item xs={15} container>
              <Grid item xs={2}>
                <Typography variant="h4" gutterBottom color="primary">
                  Data Lake
                </Typography>
              </Grid>
              <Grid item sx={{ paddingLeft: 2 }} xs={10} container spacing={1}>
                <Grid item xs={3} md={5.7}>
                  <DataProcessCard
                    headingText="Data Lake"
                    type="Data Lake Results"
                    dataPodId={dataPod?.id}
                    labelType={'DataPod'}
                    subLabel={dataPod?.name}
                  />
                </Grid>
                <Grid item xs={3} md={5.7}></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ActionPage>
  )
}
