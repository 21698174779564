import React from 'react'
import ActionPage from '../../../ui-components/ActionPage'
import { Grid, IconButton, Box, Typography, TextField, MenuItem, InputAdornment, Button } from '@mui/material'
import { cardContainer } from '../../../styles/globalStyles'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../../Routes/constants'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import { useGetDataPodInfo } from '../../../hooks/datapodHooks'

type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
}

export const DataEngineeringPipelineMonitoring = (props: Props) => {
  const { dataPodId, dataSystemID } = useParams<ViewResultsParams>()
  const { dataPodInfo } = useGetDataPodInfo(dataPodId)
  const navigate = useNavigate()
  const onClickHome = () => {
    const dataProfilingRoute = generatePath(ROUTES.Overview, { dataPodId: dataPodId })
    navigate(dataProfilingRoute)
  }

  return (
    <ActionPage>
      <Grid item container spacing={2}>
        <Grid item xs={2}>
          <IconButton onClick={onClickHome}>
            <HomeOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box style={cardContainer}>
        {/* <Grid container spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={4} alignItems={'center'}>
                  <Grid item container xs={15} spacing={1}>
                    <Grid item xs={6}>
                      <Typography component="span" variant="subtitle1" sx={{ fontSize: 14 }}>
                        Data Pod Name
                      </Typography>
                    </Grid>
                    <Grid item />

                    <Grid item xs={5}>
                      <Typography component="span" variant="subtitle1" sx={{ fontSize: 14 }}>
                        Data Pod ID
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={15} />

                    <Grid item container xs={15} spacing={1}>
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          id="outlined-select-dataSource"
                          variant="outlined"
                          value={dataPodInfo?.name}
                          required
                          size="small"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={1} />
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          id="outlined-select-dataSource"
                          variant="outlined"
                          value={dataPodInfo?.id}
                          required
                          size="small"
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} />
          </Grid>
        </Grid> */}
        <Grid item container spacing={2}>
          <Grid item xs={12} container alignItems={'center'}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                sx={{ marginLeft: 4 }}
                onClick={() =>
                  navigate(
                    generatePath(ROUTES.DataModelingAllTransformers, {
                      dataPodId: dataPodId,
                    }),
                  )
                }
              >
                Deploy Pipelines
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12} />
        </Grid>
      </Box>
    </ActionPage>
  )
}
