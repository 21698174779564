import { CardContent, Typography } from "@mui/material";
import DataPodDetailRibbon from "./DataPodDetailRibbon/DataPodDetailRibbon";
import { useGetSessionPodInfo } from "../hooks/getSessionPodInfo";

export const DatapodDetails = () => {
    const dataPod = useGetSessionPodInfo()
    return <>
        <CardContent>
            <DataPodDetailRibbon
                dataPodId={dataPod?.id}
                dataPodName={dataPod?.name}
                dataPodIndustryName={dataPod?.industryName}
            />
        </CardContent>
    </>
}