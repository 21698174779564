
import { NewSubjectArea, SubjectArea } from '../businessObjects'
import { sendRequest } from '../requestManager/sendRequest'

const subjectAreaPath = {
  getSubjectAreaBySubjectAreaID: 'SubjectAreas',
  getSubjectAreaByName: 'SubjectAreas/GetByName',
  getSubjectAreasByDataPodId: 'SubjectAreas/getAll/',
  addNewSubjectArea: 'SubjectAreas/addnew',
  deleteSubjectArea: 'SubjectAreas/delete',
  updateSubjectArea: '/SubjectAreas/update',
}

const getSubjectAreaById = async (token: string, subjectAreaID: number) => {
  const endPoint = subjectAreaPath.getSubjectAreaBySubjectAreaID + `?subjectAreaID=${subjectAreaID}`
  return sendRequest(endPoint, 'GET', token)
}

const getSubjectAreaByName = async (token: string, subjectAreaName: string, dataPodId: string) => {
  const endPoint =subjectAreaPath.getSubjectAreaByName +
  `?dataPodId=${dataPodId}&subjectAreaName=${subjectAreaName}`
  return sendRequest(endPoint, 'GET', token)
}

const getSubjectAreasByDataPodId = async (token: string, dataPodID: string, records?:number,pageNumber?:number) => {
 
  const url =  subjectAreaPath.getSubjectAreasByDataPodId + dataPodID
  const paginationParams = records && pageNumber ? `&RecordsPerPage=${records}&PageNumber=${pageNumber}` : null;
  const endPoint = paginationParams? url+ paginationParams: url
  return sendRequest(endPoint, 'GET', token)
}

const addNewSubjectArea = async (token: string, dataPodID: string, subjectArea: NewSubjectArea) => {
  
  const endPoint =subjectAreaPath.addNewSubjectArea + `?dataPodID=${dataPodID}`
  return sendRequest(endPoint, 'POST', token, subjectArea)
}

const deleteSubjectArea = async (token: string, subjectAreaID: number) => {
  const endPoint =subjectAreaPath.deleteSubjectArea + `?subjectAreaID=${subjectAreaID}`
  return sendRequest(endPoint, 'DELETE', token)
}

const updateSubjectArea = async (token: string, dataPodID: string, subjectArea: SubjectArea) => {
  const updateSubjArea = {
    id: subjectArea.id,
    subjectAreaName: subjectArea.subjectAreaName,
    subjectAreaDesc: subjectArea.subjectAreaDesc,
    industryId: subjectArea.industryId,
  }
  const endPoint = subjectAreaPath.updateSubjectArea + `?dataPodID=${dataPodID}`
  return sendRequest(endPoint, 'PUT', token, updateSubjArea)
}

export const subjectAreaServices = {
  getSubjectAreaById,
  getSubjectAreasByDataPodId,
  getSubjectAreaByName,
  addNewSubjectArea,
  updateSubjectArea,
  deleteSubjectArea,
}
