import { Grid } from '@mui/material'
import React, { ReactNode } from 'react'
import { paddingTopBottom } from '../styles/globalStyles'

type Props = {
  children: ReactNode
}

const ActionPage = ({ children }: Props) => {
  return (
    <div style={paddingTopBottom}>
      <div /* style={standardContentPadding} */>
        <div>
          <Grid container justifyContent="flex-start" spacing={4}>
            {/* <Grid item xs={1} /> */}
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default ActionPage
