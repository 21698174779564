import React, { useEffect, useMemo, useState } from 'react'
import ActionPage from '../../../../ui-components/ActionPage'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useAddNewAttributeMap, useAddttributeMap } from '../../../../hooks/attributeProfileHooks'
import { AppProfileAttribute, CreateJobParams, CreateJobResponse, JobClusterConfig, NewAttribute, NewAttributeMapBody, RunStatusResponse } from '../../../../businessObjects'
import { useGetProfileAttibuteOutput, useGetProfileEntitites } from '../../../../hooks/dataProfilingHooks'
import {
  Grid,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  MenuItem,
  Menu,
  Button,
  IconButton,
  Box,
  Card,
  Link,
} from '@mui/material'
import { HomeOutlined } from '@mui/icons-material'
import { ROUTES } from '../../../../Routes/constants'

import { GenSparkCode, SparkCode, useGenerateCustomAttribute } from '../../../../hooks/sparkHooks'
import {
  useAddNewDataAttribute,
  useGetDataAttributesByEntityId,
  useGetCustomAttribeNotebook,
  useSaveCustomAttribeNotebook,
  useUpdateCustomAttribeNotebook,
  useGetDataAttributeByID,
  useGetCustomDataAttributeByID,
  useDeleteAttributeMap,
  useUpdateAttributeDetails,
} from '../../../../hooks/dataAttrbutesHooks'
import { isValidDescription, isValidEntry } from '../../../../utils/constants'
import { LucidJupyter } from '../../../../ui-components/JupyterComponents/LucidJupyter'
import { ProgressBar } from '../../../../ui-components/ProgressBar'
import {
  useAddNewCustomAttributeTransformer,
  useGetDataAttributeTransformer,
} from '../../../../hooks/dataTransformerHooks'
import { useGetEntityByID } from '../../../../hooks/entityHooks'
import AnimatedLoader from '../../../../ui-components/AnimatedLoader'
import { LucidPopUp } from '../../../../ui-components/LucidPopUp/LucidPopUp'
import { usePostCreateDatabricksJob } from '../../../../hooks/databricksHooks'
import { RootState } from '../../../../app/store'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { filterActiveRunIds, initiateActiveRunId } from '../../../../features/notification/notificationSlice'
import ResultStatus from '../../../../ui-components/ResultStatus'
import { useGetServiceConnection } from '../../../../hooks/dataEngineeringHooks'
import constants from '../../../constants'

type Props = {}
type CustomAttributePageParams = {
  dataPodId: string
  dataSystemID: string
  entityID: string
  tableID: string
  dataAttributeID?: string
}

export const CusomAttribute = (props: Props) => {
  const { dataPodId, entityID, dataAttributeID } = useParams<CustomAttributePageParams>()
  const defDataAttrib = {
    dataEntityId: Number(entityID),
    dataAttributeDataType: '',
    dataAttributeName: '',
    dataClassification: '',
    isBusinessKey: false,
    description: '',
    dataAttributeType: 'simple',
  }

  const currentAttribeId = dataAttributeID ? Number(dataAttributeID) : undefined

  const [newAttibute, setNewAttribute] = useState<NewAttribute>(defDataAttrib)
  const [userPrompt, setUserPrompt] = useState<string>('')
  const [query, setQuery] = useState<GenSparkCode>()
  const [customAttribId, setCustomAttributeId] = useState<number>()
  const [processingReq, setProcessingReq] = useState<boolean>(false)
  //const [isNoteBookUpdated, setIsNoteBookUpdated] = useState(false)
  const [curNotebookString, setCurNotebookString] = useState<string>()
  const [commitMessage, setCommitMessage] = useState<string>('initial commit')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [userInput, setUserInput] = useState<string>('')
  const [isEditMode, setIsEditMode] = useState<boolean>(false)

  const navigate = useNavigate()

  const { entity } = useGetEntityByID(dataPodId, Number(entityID))
  const { attributeProfiles } = useGetDataAttributesByEntityId(Number(entityID))
  const { postCreateDatabricksJob } = usePostCreateDatabricksJob()

  const [errorMessage, setErrorMessage] = useState<string>()
  const [errorState, setErrorState] = useState<boolean>(false)

  const activeRunIds = useAppSelector((state: RootState) => state.notification.activeRunIds)
  const dispatch = useAppDispatch()

  const [newAttributeFunc] = useAddNewDataAttribute()
  const { addAttributeMapFunc } = useAddttributeMap(dataPodId)
  const { genAttribute } = useGenerateCustomAttribute(dataPodId)
  const { saveNoteBook } = useSaveCustomAttribeNotebook(dataPodId)
  const { addTransformer } = useAddNewCustomAttributeTransformer(dataPodId)
  const { updateAttibuteFunc } = useUpdateAttributeDetails(dataPodId)
  const { dataTransformers } = useGetDataAttributeTransformer(dataPodId, currentAttribeId) 
  const { getConnections } = useGetServiceConnection()

  //const { dataAttributes } = useGetDataAttributeByID(dataAttributeID ? Number(dataAttributeID) : undefined)
  const { dataAttributes, childAttributes, childAttributesMaps } = useGetCustomDataAttributeByID(
    dataPodId,
    currentAttribeId,
  )

  const { noteBookString } = useGetCustomAttribeNotebook(dataPodId, currentAttribeId)
  const { updateAttributeNoteBook } = useUpdateCustomAttribeNotebook(dataPodId)
  const { deleteAttributeMapId } = useDeleteAttributeMap()
  const [showPopUp, setShowPopUp] = useState<boolean>(false)
  const [jobClusterParams, setJobClusterParams] = useState<JobClusterConfig | null>(null);

  const columnMenuItems = useMemo(() => {
    if (!attributeProfiles.length) return []
    return attributeProfiles
  }, [attributeProfiles.length])

  const exAttributeMaps = useMemo(() => {
    if (childAttributesMaps.length) {
      return childAttributesMaps
    }
    return childAttributesMaps
  }, [childAttributesMaps])

  useEffect(() => {
    const fetchJobClusterParams = async () => {
      if (dataPodId) {
        const serviceConnection = await getConnections(dataPodId, undefined, 'Spark');

        if (serviceConnection && serviceConnection.length > 0) {
          const jsonTemplate = serviceConnection[0].serviceConnectionJsonTemplate;
          const parsedJson = JSON.parse(jsonTemplate);
          const params: JobClusterConfig = {
            url: parsedJson.workspace_Url,
            token: parsedJson.PAT_Token,
            clusterKey: parsedJson.cluster_Id,
            sparkVersion: parsedJson.sparkVersion,
            nodeTypeId: parsedJson.nodeTypeId,
          };
          setJobClusterParams(params);
        }
      }
    };

    fetchJobClusterParams();
  }, [])

  useEffect(() => {
    if (dataAttributes?.length && !newAttibute.dataAttributeName) {
      setNewAttribute({
        dataEntityId: Number(entityID),
        dataAttributeDataType: dataAttributes[0].dataAttributeDataType,
        dataAttributeName: dataAttributes[0].dataAttributeName,
        dataClassification: dataAttributes[0].dataClassification,
        isBusinessKey: dataAttributes[0].isBusinessKey,
        description: dataAttributes[0].description,
        dataAttributeType: dataAttributes[0].dataAttributeDataType,
      })
      setCustomAttributeId(dataAttributes[0].id)
    }
  }, [dataAttributes])

  useEffect(() => {
    if (childAttributes.length && !userInput) {
      const prvInput = childAttributes.map((cr) => `@${cr.dataAttributeName}`).join(' ')
      console.log({ prvInput }, { childAttributes })
      setUserInput(prvInput)
    }
  }, [childAttributes])

  useEffect(() => {
    if (dataTransformers.length && !userPrompt) {
      setUserPrompt(dataTransformers[0].transformerText)
    }
  }, [dataTransformers])

  useEffect(() => {
    if (noteBookString) {
      setCurNotebookString(noteBookString)
    }
  }, [noteBookString])

  const onChangeNewAttribute = (name: string, value: unknown) => {
    setNewAttribute(
      (prv) =>
      ({
        ...prv,
        [name]: value,
      } as NewAttribute),
    )
    if (currentAttribeId && !isEditMode) {
      setIsEditMode(true)
    }
  }

  const transformationTextChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setIsEditMode(true)
    setUserInput(value)
    // setShowColumnList(value.endsWith('@'))
    if (value.endsWith('@')) {
      setAnchorEl(e.currentTarget)
    } else {
      setAnchorEl(null)
    }
    if (currentAttribeId && !isEditMode) {
      setIsEditMode(true)
    }
  }

  const handleSelectMention = (columnName: string) => {
    const columnStr = userInput.replace(/@$/, `@${columnName}`)
    setUserInput(columnStr)
    if (currentAttribeId && !isEditMode) {
      setIsEditMode(true)
    }
  }

  const onClickHome = () => {
    const dataAttributePage = generatePath(ROUTES.DataModelingCanonicalModel, {
      dataPodId: dataPodId,
    })
    navigate(dataAttributePage)
  }

  const entityInfo = useMemo(() => {
    if (entity?.length) return entity[0]
  }, [entity, entityID])

  const saveCustomAttribute = async () => {
    if (newAttibute && dataPodId && !customAttribId) {
      const tosave = {
        ...newAttibute,
        dataAttributeType: 'CustomAttribute',
        isBusinessKey: Boolean(newAttibute.isBusinessKey),
      }
      const columnVals: string[] = userInput
        .split('@')
        .filter((uv) => uv && uv)
        .map((cs) => cs.trim())

      // find all attribute Object matching the selected columnName
      const selAttributes = columnMenuItems.filter((ci) => columnVals.some((cv) => ci.dataAttributeName === cv))

      // filter only unique attributes
      const uniq = selAttributes.filter((sa, ix) => selAttributes.findIndex((sela) => sela.id === sa.id) === ix)
      //console.log({ columnVals }, { selAttributes }, { uniq })
      if (columnVals.length != uniq.length) {
        return
      }
      setProcessingReq(true)
      const dAtt = await newAttributeFunc(tosave, dataPodId)
      let allMapsSuccess: number[] = []
      if (dAtt?.id && entityID && uniq.length) {
        for (let i = 0; i < uniq.length; i++) {
          const attribMap: NewAttributeMapBody = {
            //dataTableId: Number(tableID),
            dataEntityId: Number(entityID),
            //dataColumnId: uniq[i].dataColumnId,
            //dataEntityMapId: uniq[i].dataEntityMapId,
            dataAttributeId: dAtt?.id,
            dataSourceAttributeId: uniq[i].id,
            runid: 0,
          }
          const resp = await addAttributeMapFunc(attribMap)

          if (resp && resp.id) {
            // const transformSuccess = await addTransformer({
            //   dataAttributeMapId: resp.id,
            //   transformerText: userPrompt,
            //   transformerQuery: '',
            //   dataEntityId: attribMap.dataEntityId,
            //   transformerTypeName: 'compostite Attribute',
            // })
            // console.log('transformSuccess', transformSuccess)
            allMapsSuccess = [...allMapsSuccess, resp.id]
          }
        }
        if (allMapsSuccess.length == columnVals.length) {
          const transformSuccess = await addTransformer({
            dataAttributeMapId: allMapsSuccess[0],
            transformerText: userPrompt,
            transformerQuery: '',
            dataEntityId: Number(entityID),
            transformerTypeName: 'Composite',
          })
          if (transformSuccess && transformSuccess) {
            const res = await genAttribute(userPrompt, dAtt?.id)
            if (res?.data) {
              setQuery(res)
              setCurNotebookString(res.data)
              setCustomAttributeId(dAtt.id)
              setProcessingReq(false)
            }
          }
        }
      } else {
        console.log('some thing gone wrong')
      }
    }
  }

  const clickNextLabel = useMemo(() => {
    if (!customAttribId) {
      return 'Generate and Preview Code'
    }
    return 'Save'
  }, [customAttribId])

  const clickNextAction = async () => {
    if (!customAttribId) {
      saveCustomAttribute()
    }
    if (customAttribId && curNotebookString) {
      const success = await saveNoteBook(curNotebookString, customAttribId, commitMessage)
      if (success) {
        resetStates()
        onClickHome()
      }
    }
  }

  const resetStates = () => {
    setCustomAttributeId(undefined)
    setUserInput('')
    setUserPrompt('')
    setNewAttribute(defDataAttrib)
    setProcessingReq(false)
    setCurNotebookString(constants.defaultNotebookString)
    setQuery(undefined)
    setProcessingReq(false)
  }

  // const handleAttributeSelection = (sel: typeof attributeTypeOption) => {
  //   setAttributeTypeOption(sel)
  //   if (sel === 'simpleAttribute') {
  //     resetStates()
  //   }
  // }
  const disableContinue = useMemo(() => {
    return !dataAttributeID
      ? processingReq ||
      !newAttibute.dataAttributeName ||
      !newAttibute.description ||
      !newAttibute.dataAttributeDataType ||
      !newAttibute.dataClassification ||
      !userPrompt
      : false

    // return (
    //   processingReq ||
    //   !newAttibute.dataAttributeName ||
    //   !newAttibute.description ||
    //   !newAttibute.dataAttributeDataType ||
    //   !newAttibute.dataClassification ||
    //   !selectedColumnId
    // )
  }, [
    processingReq,
    //attributeTypeOption,
    userInput,
    newAttibute.dataAttributeName,
    newAttibute.description,
    newAttibute.dataAttributeDataType,
    newAttibute.dataClassification,
    userPrompt,
  ])
  const updateNewNoteBook = async () => {
    if (noteBookString && currentAttribeId && curNotebookString) {
      setShowPopUp(!showPopUp)
      // const updateSuccess = await updateAttributeNoteBook(curNotebookString, currentAttribeId, commitMessage)
      // if (updateSuccess) {

      // }
    }
  }

  const onUpdateSave = async () => {
    if (dataAttributeID && dataAttributes?.length) {
      const exAtt = dataAttributes[0]
      setProcessingReq(true)
      const updatedAttb = await updateAttibuteFunc({
        id: exAtt.id,
        name: newAttibute.dataAttributeName,
        description: newAttibute.description,
        dataAttributeDataType: newAttibute.dataAttributeDataType,
        dataClassification: newAttibute.dataClassification,
        isBusinessKey: newAttibute.isBusinessKey,
        coreEntity: false,
        entityType: '',
      })
      const columnVals: string[] = userInput
        .split('@')
        .filter((uv) => uv && uv)
        .map((cs) => cs.trim())

      // find all attribute Object matching the selected columnName
      const selAttributes = columnMenuItems.filter((ci) => columnVals.some((cv) => ci.dataAttributeName === cv))

      // filter only unique attributes
      const uniq = selAttributes.filter((sa, ix) => selAttributes.findIndex((sela) => sela.id === sa.id) === ix)
      console.log({ selAttributes }, { uniq })
      if (columnVals.length != uniq.length) {
        return
      }

      const exAtMapIds = exAttributeMaps.map((exm) => exm.dataAttributeId)

      const nAttribMapIds = uniq.map((uam) => uam.id)

      const newMaps = uniq.filter((um) => !exAtMapIds.includes(um.id))

      const toDeleteMaps = exAttributeMaps.filter((chlMaps) => !nAttribMapIds.includes(chlMaps.id))

      console.log({ toDeleteMaps })

      for (let delIdx = 0; delIdx < toDeleteMaps.length; delIdx++) {
        const delSuccess = await deleteAttributeMapId(toDeleteMaps[delIdx].id)
        if (!delSuccess) {
          return
        }
      }

      let allMapsSuccess: number[] = []
      if (updatedAttb?.id && entityID && newMaps.length) {
        for (let i = 0; i < newMaps.length; i++) {
          const attribMap: NewAttributeMapBody = {
            //dataTableId: Number(tableID),
            dataEntityId: Number(entityID),
            //dataColumnId: uniq[i].dataColumnId,
            //dataEntityMapId: uniq[i].dataEntityMapId,
            dataAttributeId: updatedAttb?.id,
            dataSourceAttributeId: uniq[i].id,
            runid: 0,
          }
          const resp = await addAttributeMapFunc(attribMap)

          if (resp && resp.id) {
            // const transformSuccess = await addTransformer({
            //   dataAttributeMapId: resp.id,
            //   transformerText: userPrompt,
            //   transformerQuery: '',
            //   dataEntityId: attribMap.dataEntityId,
            //   transformerTypeName: 'compostite Attribute',
            // })
            // console.log('transformSuccess', transformSuccess)
            allMapsSuccess = [...allMapsSuccess, resp.id]
          }
        }

        if (allMapsSuccess.length == newMaps.length && newMaps.length) {
          const transformSuccess = await addTransformer({
            dataAttributeMapId: allMapsSuccess[0],
            transformerText: userPrompt,
            transformerQuery: '',
            dataEntityId: Number(entityID),
            transformerTypeName: 'Composite',
          })
          if (transformSuccess && transformSuccess) {
            const res = await genAttribute(userPrompt, updatedAttb?.id)
            if (res?.data) {
              setQuery(res)
              setCurNotebookString(res.data)
              //setCustomAttributeId(updatedAttb.id)
              //setIsNoteBookUpdated(true)
              setProcessingReq(false)
              setIsEditMode(false)
            }
          }
        }
      }
      if (!newMaps.length) {
        const res = await genAttribute(userPrompt, updatedAttb?.id)
        if (res?.data) {
          setQuery(res)
          setCurNotebookString(res.data)
          //setCustomAttributeId(updatedAttb.id)
          //setIsNoteBookUpdated(true)
          setProcessingReq(false)
          setIsEditMode(false)
        }
      }
    }
  }

  const clickUpdateLabel = useMemo(() => {
    if (!isEditMode && currentAttribeId) {
      return 'Update & Generate'
    }
    return 'Save Notebook'
  }, [isEditMode])

  const executeNotebook = async () => {
    if (dataPodId && currentAttribeId && jobClusterParams) {
      const params: CreateJobParams = {
        dataPodId,
        customAttributeID: currentAttribeId,
        jobClusterConfig: jobClusterParams,
      }
      const result = await postCreateDatabricksJob(params)

      if(result) {
        updateRunIdState(result)
      }
      else {
        setErrorMessage("Failed to create Databricks Job");
        setErrorState(true)
      }
    }
  }

  const updateRunIdState = (result: CreateJobResponse) => {
    const activeRunId = activeRunIds.find((element) => element.run_id === result.runId);
  
    if(!activeRunId){
      const newRunId: RunStatusResponse = {
        run_id: result.runId,
        state: {
          life_cycle_state: "INITIATED",
          result_state: 'LOADING',
        },
        params: {
          run_id: result.runId,
          url: result.url,
          token: result.token
        }
      }

      dispatch(filterActiveRunIds());
      dispatch(initiateActiveRunId(newRunId));
    }
  }

  const clickUpdateAction = async () => {
    if (currentAttribeId && isEditMode) {
      await onUpdateSave()
    }
    if (currentAttribeId && !isEditMode) {
      await updateNewNoteBook()
    }
  }

  const handleUserPrompt = (newText: string) => {
    if (currentAttribeId && !isEditMode) {
      setIsEditMode(true)
    }
    setUserInput(newText)
  }
  const handleNext = async () => {
    if(currentAttribeId && commitMessage && curNotebookString){
      // console.log(noteBookString)
      // console.log(currentAttribeId)
      // console.log(commitMessage)
      const updatedCode = await updateAttributeNoteBook(curNotebookString,currentAttribeId,commitMessage)
      if(updatedCode){
        console.log('updated notebook')
        resetStates()
        onClickHome()
        setShowPopUp(!showPopUp)
      }
    }
  }
  const onTextChange = (name: string, value: string) => {
    setCommitMessage(value)
  }
  // const updateNoteBookInputString = (codeString: string) => {
  //   setIsNoteBookUpdated(true)
  //   setCurNotebookString(codeString)
  // }

  const closeErrorMessage = () => {
    setErrorState(false);
    setErrorMessage(undefined);
  }

  return (
    <>
    {errorMessage && (
      <ResultStatus
        showStatus={errorState}
        alertMessage={errorMessage}
        severtiy="error"
        closeStatus={() => closeErrorMessage()}
      />
    )}
    <ActionPage>
      <Grid item xs={12} container alignItems="center" spacing={2}>
        <Grid>
          <IconButton onClick={onClickHome} size="small" title="Data Mapping Attributes ">
            <HomeOutlined />
          </IconButton>
        </Grid>
        <Grid>
          <Typography variant="h3" color="primary" gutterBottom>
            Custom Attribute
          </Typography>
        </Grid>
      </Grid>

      <Grid display={'flex'}>
        <Grid container sx={{ pl: 5, flex: 2 }}>
          <Grid item xs={12} alignItems={'stretch'}>
            <Grid item xs={10}>
              <Typography variant="h4" color="primary" gutterBottom>
                Entity Name <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="outlined-select-industry"
                color="secondary"
                size="small"
                value={entityInfo?.dataEntityName}
                required
              />
            </Grid>
            <br></br>
            <Grid item xs={10}>
              <Typography variant="h4" color="primary" gutterBottom>
                Composite Attribute Name <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="outlined-select-industry"
                color="secondary"
                size="small"
                value={newAttibute?.dataAttributeName ?? ''}
                error={!isValidEntry(newAttibute.dataAttributeName)}
                onChange={({ target }) => onChangeNewAttribute('dataAttributeName', target.value)}
                required
              />
            </Grid>
            <br></br>
            <Grid item xs={10}>
              <Typography variant="h4" color="primary" gutterBottom>
                Attribute Description <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="outlined-select-industry"
                color="secondary"
                size="small"
                rows={3}
                multiline
                value={newAttibute?.description ?? ''}
                error={!isValidDescription(newAttibute.description)}
                onChange={({ target }) => onChangeNewAttribute('description', target.value)}
                required
              />
            </Grid>
            <br></br>
            <Grid item xs={10}>
              <Typography variant="h4" color="primary" gutterBottom>
                isBusinessKey <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="outlined-select-industry"
                color="secondary"
                size="small"
                select
                multiline
                value={newAttibute?.isBusinessKey ?? false}
                onChange={({ target }) => onChangeNewAttribute('isBusinessKey', target.value)}
                required
              >
                <MenuItem value={'true'}>true</MenuItem>
                <MenuItem value={'false'}>false</MenuItem>
              </TextField>
            </Grid>
            <br></br>
            <Grid item xs={10}>
              <Typography variant="h4" color="primary" gutterBottom>
                Classification <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                color="secondary"
                size="small"
                select
                onChange={({ target }) => onChangeNewAttribute('dataClassification', target.value)}
                value={newAttibute?.dataClassification ?? ''}
                required
              >
                {['internal', 'external', 'Public Data'].map((dtype) => (
                  <MenuItem key={dtype} value={dtype}>
                    {dtype}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <br></br>
            <Grid item xs={10}>
              <Typography variant="h4" color="primary" gutterBottom>
                Datatype <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                color="secondary"
                size="small"
                select
                onChange={({ target }) => onChangeNewAttribute('dataAttributeDataType', target.value)}
                value={newAttibute?.dataAttributeDataType ?? ''}
                required
              >
                {['string', 'Integer', 'boolean'].map((dtype) => (
                  <MenuItem key={dtype} value={dtype}>
                    {dtype}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <br></br>
            <Grid item xs={10}>
              <Typography variant="h4" color="primary" gutterBottom>
                Source Attribute Name <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                size="small"
                helperText={!userInput ? 'Type @ to mention' : ''}
                value={userInput}
                fullWidth
                onChange={transformationTextChangeHandler}
              />
              <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
                {columnMenuItems
                  .filter((cm) => !userInput.split('@').includes(cm.dataAttributeName))
                  ?.map((ea, ix) => (
                    <MenuItem
                      key={ea.id + ix + ea.dataAttributeName}
                      onClick={() => {
                        handleSelectMention(ea.dataAttributeName)
                        setAnchorEl(null)
                      }
                      }
                    >
                      {ea.dataAttributeName}
                    </MenuItem>
                  ))}
              </Menu>
            </Grid>
          </Grid>
        </Grid>

        <Grid container sx={{ pr: 5, flex: 2 }}>
          <Grid item xs={12} alignItems={'stretch'}>
            <Card sx={{ width: '100%' }}>
              <ProgressBar loading={processingReq} />
              <AnimatedLoader height="100%" width="100%" loading={processingReq} />
              <LucidJupyter
                executeNotebook={executeNotebook}
                noteBookString={curNotebookString}
                headingText={constants.codeHeaderText}
                updateNoteBookString={setCurNotebookString}
                commitMessage={commitMessage}
                updateCommitMessage={setCommitMessage}
              />
            </Card>
          </Grid>
          <br></br>
          <Grid item xs={12}>
            <Typography variant="h4" color="primary" gutterBottom>
              <span style={{ color: 'red' }}>*</span> Enter Prompt Text
            </Typography>
            <TextField
              multiline
              rows={10}
              fullWidth
              value={userPrompt}
              onChange={({ target }) => setUserPrompt(target.value)}
            />
          </Grid>

          <Grid item xs={12} container spacing={4}>
            <Grid item xs={4}>
              <Button variant="contained" fullWidth onClick={onClickHome}>
                Cancel
              </Button>
            </Grid>

            <Grid item xs={4}>
              {!dataAttributeID && (
                <Button fullWidth variant="contained" disabled={disableContinue} onClick={clickNextAction}>
                  {clickNextLabel}
                </Button>
              )}
            </Grid>
            <Grid item xs={4}>
              {currentAttribeId && (
                <Button
                  fullWidth
                  variant="contained"
                  disabled={isEditMode ? !userPrompt : processingReq || !curNotebookString}
                  onClick={clickUpdateAction}
                >
                  {clickUpdateLabel}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <LucidPopUp
        showCloseIcon
        closePopup={setShowPopUp}
        openPopUp={showPopUp}
        onConfirm={handleNext}
        headingText=""
        confirmText="Commit"
      >
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <Link href={'https://github.com/Lucid-Data-Hub/gen-datalake-dev'} target="blank">
              Git Hub Repository
            </Link>
          </Grid>
          <Grid item xs={12}>
            <TextField
              sx={{ fontSize: 12 }}
              fullWidth
              id="outlined-basic"
              label="Enter Commit Message"
              variant="outlined"
              color="secondary"
              required
              rows={2}
              multiline
              value={commitMessage}
              onChange={({ target }) => onTextChange('commitMessage', target.value)}
            />
          </Grid>
        </Grid>
      </LucidPopUp>
    </ActionPage>
    </>
  )
}
