import { Box } from '@mui/material'
import React, { ReactNode } from 'react'
import { mainPageContainer } from '../styles/globalStyles'

type Props = {
  children?: ReactNode
}

export const MainLayout = ({ children }: Props) => {
  return (
    <Box component="main" sx={{ flexGrow: 1, bgcolor: 'default' }}>
      <div style={mainPageContainer}>{children}</div>
    </Box>
  )
}

export default MainLayout
