import { useEffect, useMemo, useState } from 'react'
import { LucidAccount, UserAccount } from '../businessObjects'
import { accountServices } from '../apiServices/lucidAccount'
import { useLucidAuthContext } from '../LucidAuthContext/LucidAuthContext'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from '../Routes/constants'
import { AxiosError } from 'axios'

export type ServerLucidAccountParams = {
  accountId: string
  accountName: string
  accountDescription: string
  accountTenantId: string
  primaryContactFirstName: string
  primaryContactMiddleName: string
  primaryContactLastName: string
  primaryContactEmail: string
  primaryContactPhone: string
  agreementStatus: string
  securityGroups: string
}
type SubmitLucidAccount = (params: UserAccount) => Promise<void>
type UpdateLucidAccount = (params: ServerLucidAccountParams) => Promise<void>
type accountId = string
type tenantId = string
type LucidAccountParam = accountId | tenantId
type GetLucidAccount = (tenantId: string) => Promise<void>

const toClientAccounts = (accres: ServerLucidAccountParams): LucidAccount => {
  return {
    accountId: accres.accountId,
    accountName: accres.accountName,
    accountDescription: accres.accountDescription,
    accountTenantId: accres.accountTenantId,
    primaryContactFirstName: accres.primaryContactEmail,
    primaryContactMiddleName: accres.primaryContactMiddleName,
    primaryContactLastName: accres.primaryContactLastName,
    primaryContactEmail: accres.primaryContactEmail,
    primaryContactPhone: accres.primaryContactPhone,
    agreementStatus: accres.agreementStatus,
    securityGroups: accres.securityGroups,
  }
}

export const useCreateLucidAccount = (): [
  SubmitLucidAccount,
  LucidAccount | undefined,
  boolean | undefined,
  boolean | undefined,
] => {
  const [lucidAccount, setLucidAccount] = useState<LucidAccount>()
  const [loading, setLoading] = useState<boolean>(false)
  const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const submitLucidAccount = async (params: UserAccount): Promise<void> => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await accountServices.createAccount(bearerToken, params)
        // console.log(data)
        if (status == 200) {
          //console.log(status)
          setLucidAccount(toClientAccounts(data))
          setLoading(false)
        }
      }
    } catch (error) {
      console.log(error)
      setCreateError(true)
      setLoading(false)
    }
  }
  return [submitLucidAccount, lucidAccount, loading, createError]
}
export const useUpdateLucidAccount = (): [
  UpdateLucidAccount,
  LucidAccount | undefined,
  boolean | undefined,
  boolean | undefined,
] => {
  const [lucidAccount, setLucidAccount] = useState<LucidAccount>()
  const [loading, setLoading] = useState<boolean>(false)
  const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const updatedLucidAccount = async (params: ServerLucidAccountParams): Promise<void> => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await accountServices.updateAccount(bearerToken, params)
        //console.log(data)
        if (status == 200) {
          //console.log(status)
          setLucidAccount(toClientAccounts(data))
          setLoading(false)
        }
      }
    } catch (error) {
      console.log(error)
      setCreateError(true)
      setLoading(false)
    }
  }
  return [updatedLucidAccount, lucidAccount, loading, createError]
}

export const useGetAllLuciAccounts = (
  byTenant?: boolean,
): [GetLucidAccount, LucidAccount[] | undefined, boolean | undefined, boolean | undefined] => {
  // Here we are using the byTenant flag to fetch the data by Tenant Id or AccountId.
  const [lucidAccount, setLucidAccount] = useState<LucidAccount[]>()
  const [loading, setLoading] = useState<boolean>()
  const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken, accountId, tenantId } = useLucidAuthContext()
  const getAccounts = async () => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && accountId && !byTenant) {
        const { data, status } = await accountServices.getAllAccountsByAccountId(bearerToken, accountId)
        if (status == 200) {
          const serverLucidAccounts: ServerLucidAccountParams[] = data
          //console.log(serverLucidAccounts)
          setLucidAccount(serverLucidAccounts)
          setLoading(false)
        }
      } else if (bearerToken && tenantId && byTenant) {
        const { data, status } = await accountServices.getAllAccountsByTenantID(bearerToken, tenantId)
        if (status == 200) {
          const serverLucidAccounts = data
          //console.log(serverLucidAccounts)
          setLucidAccount(serverLucidAccounts)
          setLoading(false)
        }
      }
    } catch (error) {
      setCreateError(true)
      setLoading(false)
    }
  }
  return [getAccounts, lucidAccount, loading, createError]
}

// called immeadiately after signin
export const useGetAllLucidAccountByTenant = (): [
  GetLucidAccount,
  LucidAccount[] | undefined,
  boolean | undefined,
  boolean | undefined,
] => {
  const [lucidAccount, setLucidAccount] = useState<LucidAccount[]>()
  const [loading, setLoading] = useState<boolean>()
  const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken, updatePreferredAccountId } = useLucidAuthContext()
  const navigate = useNavigate()
  const location = useLocation()
  // console.log('URLPath', location.pathname.split('/'), 'ls', location.state)

  const isWithRoute = useMemo(() => {
    return !!location.pathname.split('/').filter((path) => path && path).length
  }, [location])
  console.log({ isWithRoute })

  const getAccounts = async (tenantId: string) => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      //console.log(tenantId)
      if (bearerToken && tenantId) {
        const { data, status } = await accountServices.getAllAccountsByTenantID(bearerToken, tenantId)
        if (status == 200) {
          const serverLucidAccounts = data as LucidAccount[]
          //console.log(serverLucidAccounts)
          setLoading(false)
          if (serverLucidAccounts.length) {
            setLucidAccount(serverLucidAccounts)
            serverLucidAccounts[0].accountId && updatePreferredAccountId(serverLucidAccounts[0].accountId)
            const refreshedRoute = isWithRoute ? location.pathname : ROUTES.DataPods
            navigate(refreshedRoute)
          } else {
            navigate(ROUTES.Account)
          }
        }
      }
    } catch (error) {
      navigate(ROUTES.Account)
      setCreateError(true)
    } finally {
      setLoading(false)
    }
  }
  return [getAccounts, lucidAccount, loading, createError]
}

// used when user is authenticated but to fetch account details when user opens in a new a tab or refreshes the browser.
// once the user refreshes the browser the context values need to be renewed.
export const useGetUserAccountByTentantId = (tenantId?: string, token?: string) => {
  const [lucidAccountId, setLucidAccountId] = useState<string>()
  const getUserAccount = async () => {
    try {
      if (token && tenantId) {
        const { data, status } = await accountServices.getAllAccountsByTenantID(token, tenantId)
        if (status == 200) {
          const usrAccount = data as LucidAccount[]
          setLucidAccountId(usrAccount[0].accountId)
        }
      }
    } catch (error) {
      const err = error as AxiosError
      console.log(err.message)
    }
  }
  useEffect(() => {
    getUserAccount()
  }, [tenantId])

  return { lucidAccountId }
}
