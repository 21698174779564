
import { UpdateDataPodParams } from '../hooks/datapodHooks'

import { sendRequest } from '../requestManager/sendRequest'

const dataPodPath = {
  createDataPod: 'DataPods/addnew',
  getAllDataPods: 'DataPods/Get',
  getDataPodById: 'DataPods/',
  getDataPodByName: 'DataPods/GetByName',
  updateDataPod: 'DataPods/update',
  deleteDataPod: 'DataPods/delete',
  getDataPodType: 'DataPods/GetDataPodTypes',
  addDataSourceDetails: 'DataPods/AddDataSourceDetails',
}

const createDataPod = async <T>(token: string, newDatapod: T) => {
  return await sendRequest(dataPodPath.createDataPod, 'POST', token, newDatapod)
}

const getDataPod = async (token: string, datapodId: string) => {
  const endPoint = dataPodPath.getDataPodById + datapodId
  return sendRequest(endPoint, 'GET', token)
}

const getDataPodType = async (token: string) => {
  const endPoint = dataPodPath.getDataPodType
  return sendRequest(endPoint, 'GET', token)
}

const getallDataPods = async (
  token: string,
  accountId: string,
  records?: number,
  pageNumber?: number,
  searchText?: string,
) => {
  console.log(records)
  const paginationParams = records && pageNumber ? `&RecordsPerPage=${records}&PageNumber=${pageNumber}` : null
  let mainPath = dataPodPath.getAllDataPods + `?accountId=${accountId}`
  mainPath = searchText ? mainPath + `&searchText=${searchText}` : mainPath
  const endPoint = paginationParams ? mainPath + paginationParams : mainPath
  return sendRequest(endPoint, 'GET', token)
}
// const getDataPodByName = async (token: string, datapodName: string) => {
//   const config = getAxiosRequestConfig(token)
//   return await axios.get(environment.baseURI + dataPodPath.getDataPodByName + `?dataPodName=${datapodName}`, config)
// }

// const getDataByName = async (token: string, datapodName: string) => {
//   const config = getAxiosRequestConfig(token)
//   return await axios.get(environment.baseURI + dataPodPath.getDataPodByName + datapodName, config)
// }

const updateDatapod = async (token: string, datapod: UpdateDataPodParams) => {
  return await sendRequest(dataPodPath.updateDataPod, 'PUT', token, datapod)
}

const deleteDataPod = async (token: string, dataPodId: string) => {
  return sendRequest(dataPodPath.deleteDataPod + `?dataPodId=${dataPodId}`, 'DELETE', token)
}

const createDataSourceDetails = async <T>(token: string, dataPodId: string, DSPayload: T) => {
  return await sendRequest(dataPodPath.addDataSourceDetails + `?dataPodId=${dataPodId}`, 'POST', token, DSPayload)
}

export const datapodServices = {
  updateDatapod,
  createDataPod,
  deleteDataPod,
  getallDataPods,
  getDataPod,
  getDataPodType,
  createDataSourceDetails,
}
