import React from 'react'

import { SearchIcon } from '../ui-icons/SearchIcon'
import { Container, Box, Typography, Button } from '@mui/material'

type Props = {
  onClick: () => void
  headingText: string
  bodyText?: string
  buttonText: string
  buttonDisabled?: boolean
}

function NoEntries({ onClick, headingText, bodyText, buttonText, buttonDisabled }: Props) {
  const contentText = !bodyText
    ? 'Lorem ipsum dolor sit amet consectetur. Phasellus libero lorem gravida vitae id congue ultrices habitasse.Morbi quis ullamcorper lectus'
    : bodyText
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 2,
          gap: 3,
          // position: 'absolute',
          // width: 'calc(1336px/2 - 208.5px)',
          // height: 180,
          // top: 'calc(45% - 206px/2 + 30px)',
        }}
      >
        <SearchIcon fontSize="large" viewBox=" 0 0 56 45" />
        <Typography variant="h4" textAlign={'center'}>
          {headingText}
        </Typography>
        <Typography textAlign={'center'} height={'63'}>
          {contentText}
        </Typography>
        <Button variant="contained" size="small" disabled={buttonDisabled} onClick={onClick}>
          {buttonText}
        </Button>
      </Box>
    </Container>
  )
}

export default NoEntries
