import React from 'react'
import { useLocation, Link as RouterLink } from 'react-router-dom'
import { entries } from '../Drawer/NavigationDrawerStyles'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'
import {
  Box,
  Breadcrumbs,
  Link,
  LinkProps,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'

type Props = {}

interface LinkRouterProps extends LinkProps {
  to: string
  replace?: boolean
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />
}

export const LucidBreadCrumbs = (props: Props) => {
  const location = useLocation()
  const pathnames: string[] = location.pathname.split('/').filter((p) => p && p)

  //console.log({ path })
  return (
    <Breadcrumbs maxItems={3} separator={''} aria-label="breadcrumb">
      {/* <LinkRouter underline="hover" color="inherit" to="/">
    Home
  </LinkRouter> */}
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1
        const to = `/${pathnames.slice(0, index + 1).join('/')}`
        const entry = entries.find((e) => e.link === value)
        return last ? (
          <List key={index} dense disablePadding>
            {entry && (
              <ListItemButton disableGutters>
                <ListItemIcon>
                  <entry.Icon fontSize="small" />
                  <KeyboardArrowRightOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={<Typography sx={{ fontSize: '18px' }}>{entry.text} </Typography>}
                />
              </ListItemButton>
            )}
          </List>
        ) : (
          <LinkRouter fontSize={'small'} underline="hover" color="inherit" to={to} key={index}>
            {entry && (
              <List dense disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <entry.Icon fontSize="small" />
                    <KeyboardArrowRightOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={<Typography sx={{ fontSize: '18px' }}>{entry.text} </Typography>}
                  />
                </ListItemButton>
              </List>
            )}
          </LinkRouter>
        )
      })}
    </Breadcrumbs>
  )
}
