import { AxiosError, HttpStatusCode } from 'axios'
import { useState, useEffect } from 'react'
import { useLucidAuthContext } from '../LucidAuthContext/LucidAuthContext'

import {
  ServiceConnections,
  Pipeline,
  PipelineExtecutionStatus,
  NewPipelineExecutionStatus,
  UpdatePipelineExecutionstatus,
  DataPipeline,
  NewDataPipeline,
  PipelineSchedule,
  NewPipelineSchedule,
  UpdateDataPipelinesParams,
} from '../businessObjects'
import { dataEngineeringServices } from '../apiServices/dataEngineering'
import { generatefabricToken } from '../utils/generateFabricToken'

type FlatObject = { [key: string]: any }

export const useGetServiceConnection = (): {
  getConnections: (
    datapodId: string,
    id?: number,
    serviceConnectionTypeID?: string,
    serviceTypeID?: number,
  ) => Promise<ServiceConnections[] | undefined>
} => {
  //const [serviceConnection, setServiceConnection] = useState<ServiceConnections[]>([])
  const { getBearerToken } = useLucidAuthContext()
  const getConnections = async (
    datapodId: string,
    id?: number,
    serviceConnectionTypeID?: string,
    serviceTypeID?: number,
  ) => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status, headers } = await dataEngineeringServices.getServiceConnection(
          bearerToken,
          datapodId,
          id,
          serviceConnectionTypeID,
          serviceTypeID,
        )

        if (status == 200) {
          return data as ServiceConnections[]
        }
      }
    } catch (error) {
      const err = error as AxiosError
      console.log(err.message)
    }
  }
  return { getConnections }
}

export const useGetPipeline = (
  datapodId?: string,
  id?: number,
  entityID?: number,
  dataLakeZoneId?: number,
  searchText?: string,
): {
  //getPiplines: (records?: number, pageNumber?: number) => Promise<void>
  pipelineData: Pipeline[]
  refreshDeployments: () => Promise<void>
} => {
  const [pipelineData, setPipelineData] = useState<Pipeline[]>([])
  const { getBearerToken } = useLucidAuthContext()
  // const { addPipeline } = useAddDataPipeline(datapodId)
  const getPiplines = async () => {
    const bearerToken = await getBearerToken()
    if (bearerToken && datapodId) {
      const { data, status } = await dataEngineeringServices.getPipeline(
        bearerToken,
        datapodId,
        id,
        entityID,
        dataLakeZoneId,
        searchText,
        100,
        1,
      )

      if (status == 200) {
        setPipelineData(data as Pipeline[])
      }
    }
  }
  useEffect(() => {
    getPiplines()
  }, [datapodId, id, entityID, dataLakeZoneId, searchText])

  return { pipelineData, refreshDeployments: getPiplines }
}

export const useUpdateDataPipelines = (
  dataPodId?: string,
): { updatePipeline: (dataPipline: DataPipeline) => Promise<boolean | undefined> } => {
  const { getBearerToken } = useLucidAuthContext()
  const updatePipeline = async (dataPipline: DataPipeline) => {
    try {
      const token = await getBearerToken()
      if (token && dataPodId && dataPipline.id) {
        const dataPipelinesUpdate: UpdateDataPipelinesParams = {
          id: dataPipline.id,
          entityid: dataPipline.entityid,
          pipelinename: dataPipline.pipelinename,
          scheduledId: dataPipline.scheduledId,
          serviceConnectionId: dataPipline.serviceConnectionId,
          scheduleOrder: dataPipline.scheduleOrder,
          dataPipelineScheduleId: dataPipline.scheduledId,
        }
        const { status } = await dataEngineeringServices.updateDataPipelines(token, dataPipelinesUpdate, dataPodId)
        return status == HttpStatusCode.Ok
      }
    } catch (error) {
      const err = error as AxiosError
      if (!err.isAxiosError && err.response?.status != 404) {
        console.log(err.response?.data)
      }
    }
  }
  return { updatePipeline }
}

export const useGetDataPipelineExecutions = (
  dataPodId?: string,
): {
  pipelineExecutions: PipelineExtecutionStatus[]
  refreshStatus: (scheduledId?: number, pipelineId?: number) => Promise<PipelineExtecutionStatus[] | undefined>
} => {
  const [pipelineExecutions, setPipelineExecutions] = useState<PipelineExtecutionStatus[]>([])
  const { getBearerToken } = useLucidAuthContext()

  const getPipelinesExecutions = async (scheduledId?: number, pipelineId?: number) => {
    try {
      const token = await getBearerToken()
      if (dataPodId && token) {
        const { data, status } = await dataEngineeringServices.getDataPipelineExecutionStatus(
          token,
          dataPodId,
          scheduledId,
          pipelineId,
        )
        if (status == HttpStatusCode.Ok) {
          setPipelineExecutions(data)
          return data as PipelineExtecutionStatus[]
        }
      }
    } catch (err) {
      console.log({ err })
    }
  }
  useEffect(() => {
    if (dataPodId) {
      getPipelinesExecutions()
    }
  }, [])
  return { pipelineExecutions, refreshStatus: getPipelinesExecutions }
}

export const useAddPipelineExecution = (
  dataPodId?: string,
): {
  addPipelineExecution: (pipelineExection: NewPipelineExecutionStatus) => Promise<PipelineExtecutionStatus | undefined>
} => {
  const { getBearerToken } = useLucidAuthContext()
  const addPipelineExecution = async (pipelineExection: NewPipelineExecutionStatus) => {
    try {
      const token = await getBearerToken()
      if (token && dataPodId) {
        const { data, status } = await dataEngineeringServices.addDataPipelineExecution(
          token,
          dataPodId,
          pipelineExection,
        )
        if (status == HttpStatusCode.Ok) {
          return data as PipelineExtecutionStatus
        }
      }
    } catch (err) {
      console.log({ err })
    }
  }
  return { addPipelineExecution }
}

export const useUpdatePipelineExecution = (
  dataPodId: string,
): {
  updateExecutionStatus: (
    upPipelineExection: UpdatePipelineExecutionstatus,
  ) => Promise<PipelineExtecutionStatus | undefined>
} => {
  const { getBearerToken } = useLucidAuthContext()

  const updateExecutionStatus = async (upPipelineExection: UpdatePipelineExecutionstatus) => {
    try {
      const token = await getBearerToken()
      if (token && dataPodId) {
        const { data, status } = await dataEngineeringServices.updateDataPipelineExecution(
          token,
          dataPodId,
          upPipelineExection,
        )

        if (status == HttpStatusCode.Ok) {
          return data as PipelineExtecutionStatus
        }
      }
    } catch (err) {
      console.log({ err })
    }
  }
  return { updateExecutionStatus }
}

export const useAddDataPipeline = (
  dataPodId?: string,
): {
  addPipeline: (newPipeline: NewDataPipeline, serviceTypeName: string | undefined, redirectUri: string | undefined) => Promise<DataPipeline | undefined>
  postError: FlatObject | undefined
  addPipelineSuccess: any
} => {
  const { getBearerToken } = useLucidAuthContext()
  
  const [postError, setPostError] = useState<FlatObject | undefined>()
  const [addPipelineSuccess, setAddPipelineSuccess] = useState<any>()

  const addPipeline = async (newPipeline: NewDataPipeline, serviceTypeName: string | undefined, redirectUri: string | undefined) => {
    try {
      const token = await getBearerToken()
      let fabricToken: any = ''
      //if service type is fabric. then generating a microsoft fabric token and pass in query params as bearerToken
      if(serviceTypeName && serviceTypeName.includes("Microsoft Fabric")) {
        fabricToken = await generatefabricToken(redirectUri)
      }

      if (token && dataPodId) {
        const { data, status } = await dataEngineeringServices.addDataPipeline(token, dataPodId, newPipeline, fabricToken)
        if (status == HttpStatusCode.Ok) {
          setAddPipelineSuccess(data)
          return data as DataPipeline
        } else {
          setPostError({ message: data?.toString(), date: Date.now() })
        }
      }
    } catch (error: any) {
      setPostError({
        message: error?.response?.data?.title || error?.response?.data || error?.message,
        date: Date.now(),
      })
    }
  }
  return { addPipeline, addPipelineSuccess, postError }
}

export const useGetPipelineSchedules = (
  scheduleId?: number,
  searchText?: string,
): { pipelineSchedules: PipelineSchedule[]; refreshData: () => Promise<void> } => {
  const [pipelineSchedules, setPipelineSchedules] = useState<PipelineSchedule[]>([])
  const { getBearerToken } = useLucidAuthContext()
  const getScheduleData = async () => {
    try {
      const token = await getBearerToken()
      if (token) {
        const { data, status } = await dataEngineeringServices.getDataPipelineSchedule(token, scheduleId, searchText)
        if (status == HttpStatusCode.Ok) {
          setPipelineSchedules(data as PipelineSchedule[])
        }
      }
    } catch (err) {
      console.log({ err })
    }
  }
  useEffect(() => {
    getScheduleData()
  }, [scheduleId, searchText])

  return { pipelineSchedules, refreshData: getScheduleData }
}

export const useAddPipelineSchedule = (): {
  addPipelineSchedule: (newPipelineSchedule: NewPipelineSchedule) => Promise<PipelineSchedule | undefined>
} => {
  const { getBearerToken } = useLucidAuthContext()
  const addPipelineSchedule = async (newPipelineSchedule: NewPipelineSchedule) => {
    try {
      const token = await getBearerToken()
      if (token && newPipelineSchedule.name && newPipelineSchedule.description && newPipelineSchedule.frequency) {
        const { data, status } = await dataEngineeringServices.addDataPipelineSchedule(token, newPipelineSchedule)
        if (status == HttpStatusCode.Ok) {
          return data as PipelineSchedule
        }
      }
    } catch (err) {
      console.log({ err })
    }
  }
  return { addPipelineSchedule }
}

export const useUpdatePipelineSchedule = (): {
  updatePipelineSchedule: (pipelineSchedule: PipelineSchedule) => Promise<PipelineSchedule | undefined>
} => {
  const { getBearerToken } = useLucidAuthContext()
  const updatePipelineSchedule = async (newPipelineSchedule: PipelineSchedule) => {
    try {
      const token = await getBearerToken()
      if (token && newPipelineSchedule.name && newPipelineSchedule.description && newPipelineSchedule.frequency) {
        const { data, status } = await dataEngineeringServices.updateDataPipelineSchedule(token, newPipelineSchedule)
        if (status == HttpStatusCode.Ok) {
          return data as PipelineSchedule
        }
      }
    } catch (err) {
      console.log({ err })
    }
  }
  return { updatePipelineSchedule }
}

export const usePostDataLake = (
  dataPodId: string,
): {
  postDataLakeStatus: any
  postError: FlatObject | undefined
  addDataLakes: (dataLakeBody: any) => Promise<void | undefined>
} => {
  const [postDataLakeStatus, setPostDataLakeStatus] = useState<any>()
  const [postError, setPostError] = useState<FlatObject | undefined>()
  const { getBearerToken } = useLucidAuthContext()
  const addDataLakes = async (dataLakeBody: any) => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId) {
        const { data, status } = await dataEngineeringServices.addDataLake(bearerToken, dataLakeBody, dataPodId)

        if (status == 200) {
          setPostDataLakeStatus(data)
        } else {
          setPostError({ message: data?.toString(), date: Date.now() })
        }
      }
    } catch (error: any) {
      setPostError({
        message: error?.response?.data?.title || error?.response?.data || error?.message,
        date: Date.now(),
      })
    }
  }
  return { addDataLakes, postError, postDataLakeStatus }
}
