import {
  Box,
  Grid,
  IconButton,
  Typography,
  MenuItem,
  TextField,
  Autocomplete,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  styled,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionProps,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { cardContainer } from '../../../styles/globalStyles'
import ActionPage from '../../../ui-components/ActionPage'

import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../../Routes/constants'

import {
  useGetProfileAttributes,
  useGetProfileAttributesTablesByEntityId,
  useGetProfileEntity,
} from '../../../hooks/dataProfilingHooks'

import { useGetAllDataSystemsByPodId, useGetDataSystemByDataSystemId } from '../../../hooks/dataSystemHooks'
import { useGetSubjectArea } from '../../../hooks/subjectAreaHooks'

import {
  AppProfileEntity,
  Entity,
  NewEntity,
  CoreEntity,
  EntityReconData,
  DataTable,
  DataSystem,
  SourceToTargetMapRanking,
  DataColumnMapLucidMeansV2,
  MapDataColumnMapLucidMeansV2,
  getDataSourceToTargetColumnMapV2,
  DataSourceColumnSelectedTargetsMapV2,
} from '../../../businessObjects'

import ShadowTable, { ShadowTableAction } from '../../../ui-components/ShadowTable/ShadowTable'
import { DataTableRow } from '../../../ui-components/DataTable/DataTableTypes'
import { green } from '@mui/material/colors'

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import {
  useAddEntityMap,
  useAddNewEntity,
  useGetEntity,
  useGetCoreEntity,
  useUpdateEntityMap,
  useUpdateEntityName,
  useGetEntityMappingByEntityIdAndPodId,
} from '../../../hooks/entityHooks'
import CancelIcon from '@mui/icons-material/Cancel'
import { Add, AddOutlined, SaveOutlined, TrySharp } from '@mui/icons-material'
import { useGetRelationData } from '../../../hooks/applicationHooks'
import { useGetDataAttributesByEntity, useGetDataReconcilationByEntityId } from '../../../hooks/dataAttrbutesHooks'
import { useGetDataPodInfo } from '../../../hooks/datapodHooks'
import Spinner from 'react-spinner-material'
import CustomEnrichedModel from '../DataModeling/CustomEnrichedModel'
import { useGetQueryString } from '../../../hooks/queryStringHook'
import { useGetAllDataTables } from '../../../hooks/dataTableHooks'
import {
  useAddTargetToSourceColumnMapV2,
  useDownloadSourceToTargetMapRanking,
  useGetDataColumnMapLucidMeansV2,
  useGetDataSourceColumnSelectedTargetsMapV2,
  useGetDataSourceToTargetAttributeMap,
  useGetDataSourceToTargetColumnMapV2,
  useUpdateTargetToSourceColumnMapV2,
} from '../../../hooks/generativeIntegartionHooks'
import ReactFlowGen from '../../../ReactFlow/ReactFlowGen'
import { processEntitiesNodeData, processTableNodeData } from './SourceToTargetMappingHelper'
import { useGetDataColumnsByTableId } from '../../../hooks/dataColumnHooks'
import { ResultsTable } from '../DataProfilingViewResults'
import { downloadFile } from '../../../utils/downloadFile'
import { boolean } from 'zod'
import LucidWidePopUp from '../../../ui-components/LucidWidePopUp'

type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
}
type SourceAndTargetColumns = {
  sourceTableId: number
  sourceColumnId: number
  sourceDataSystemName: string
  sourceDataTableName: string
  sourceDataTableDescription: string
  sourceDataColumnName: string
  sourceDataColumnDescription: string
  sourceDataType: string
  targetTableId: number
  targetColumnId: number
  targetDataSystemName: string
  targetDataTableName: string
  targetDataTableDescription: string
  targetDataColumnName: string
  targetDataColumnDescription: string
  targetDataType: string
}
type SourceDataTableNamesObjects = {
  [tableName: string]: SourceAndTargetColumns[]
}
export const SourceToTargetMapping = (props: Props) => {
  const { dataPodId, dataSystemID } = useParams<ViewResultsParams>()
  const { dataPodInfo } = useGetDataPodInfo(dataPodId)
  const typeId = useGetQueryString('typeId')
  const [tableView, setTableView] = useState<boolean>(true)
  const [reactFlowView, setReactFlowView] = useState<boolean>(false)
  const [selectedSourceID, setSelectedSourceID] = useState<number>()
  const [entityTypeId, setEntityTypeId] = useState<number>(1)

  const [entityData, setEntityData] = useState<Entity[]>([])
  const [entityDataOptions, setEntityDataOptions] = useState<any[]>([])
  const [tableData, setTableData] = useState<DataTable[]>([])
  const [selectedTableData, setSelectedTableData] = useState<DataTable[]>([])
  const [selectedOption, setSelectedOption] = useState<
    'Source Entity' | 'Target Entity' | 'Source Table' | 'Target Table' | 'Data System'
  >('Source Entity')

  const [selectedEntity, setSelectedEntity] = useState<Entity[]>([])
  const [dataSystems] = useGetAllDataSystemsByPodId({}, dataPodId)
  const [dataSystemsData, setDataSystemsData] = useState<any[]>([])
  // const { dataSystemInfo } = useGetDataSystemByDataSystemId(selectedSourceID)

  //const { profileEntities } = useGetProfileEntity(dataPodId, selectedSourceID, undefined, undefined, undefined)

  const [entityKeyValue, setEntityKeyValue] = useState<any[]>([])
  const [nodes, setNodes] = useState<any[]>([])
  const [edges, setEdges] = useState<any[]>([])

  const [entities] = useGetEntity(
    {},
    ['Source Entity', 'Target Entity'].includes(selectedOption) ? dataPodId : undefined,
    undefined,
    entityTypeId,
  )

  const { dataTables } = useGetAllDataTables(
    !['Source Entity', 'Target Entity'].includes(selectedOption) ? dataPodId : undefined,
    undefined,
    undefined,
    entityTypeId,
  )

  const srcEntityID = selectedOption === 'Source Entity' && selectedEntity.length ? selectedEntity[0].id : undefined
  const targetEntityID = selectedOption === 'Target Entity' && selectedEntity.length ? selectedEntity[0].id : undefined
  const srcTableId = selectedOption === 'Source Table' && selectedTableData.length ? selectedTableData[0].id : undefined
  const targetTableId =
    selectedOption === 'Target Table' && selectedTableData.length ? selectedTableData[0].id : undefined
  //const srcDatasystemId = selectedOption === 'Data System' && dataSystemID.length ?

  const { dataSourceToTargetAttributeMap } = useGetDataSourceToTargetAttributeMap(
    dataPodId,
    undefined,
    srcEntityID,
    targetEntityID,
    undefined,
    undefined,
    srcTableId,
    targetTableId,
    //srcDatasystemId,
    //targetDatasystemId,
  )
  const { fetchEntityMapping } = useGetEntityMappingByEntityIdAndPodId(dataPodId)
  const { getDataColumns } = useGetDataColumnsByTableId()
  const getAttribute = useGetDataAttributesByEntity()
  const { getProfileAttributes } = useGetProfileAttributesTablesByEntityId(dataPodId)
  const [jsonDownloadedSourceToTargetRanking, setjsonDownloadedSourceToTargetRanking] =
    useState<SourceToTargetMapRanking[]>()
  const [getDownloadData, sourceToTargetMapRanking] = useDownloadSourceToTargetMapRanking()
  const [getSelectedSourceTargetData] = useGetDataSourceColumnSelectedTargetsMapV2()
  const [sourceData, setSourceData] = useState<SourceAndTargetColumns[]>([])
  const [selectedSourceData, setSelectedSourceData] = useState<SourceAndTargetColumns[]>([])
  const [selectedSourceName, setSelectedSourceName] = useState<SourceAndTargetColumns[]>([])
  const [sourceDataTableNames, setSourceDataTableNames] = useState<string[]>([])
  const [showPopUp, setShowPopUp] = useState<boolean>(false)
  const [getDataColumnMapLucidMeansV2, DataColumnMapLucidMeansV2] = useGetDataColumnMapLucidMeansV2()
  const [getSourceToTargetColumnMapV2, sourceToTargetColumnMapV2] = useGetDataSourceToTargetColumnMapV2()
  const [userSelectedExistingMapping, setuserSelectedExistingMapping] = useState<getDataSourceToTargetColumnMapV2[]>([])
  const [targetList, setTargetList] = useState<DataColumnMapLucidMeansV2[]>([])
  const [userSelectedTarget, setUserSelectedTarget] = useState<DataColumnMapLucidMeansV2>()
  const [updateNewTargetToSourceColumn] = useUpdateTargetToSourceColumnMapV2()
  const [expanded, setExpanded] = useState<string | false>('')
  type DataTableColumn = {
    label: string
    alignment?: string
    numeric?: boolean
    isStatus?: boolean
    isUpdateStatus?: boolean
  }
  type DataTableRow = {
    id: string
    values: string[]
    subRows?: DataTableRow[]
    subColumnsHeadings?: DataTableColumn[]
  }
  const sourceTargetTransformer = (
    sourceTargetData: DataSourceColumnSelectedTargetsMapV2[],
  ): SourceAndTargetColumns[] => {
    const sourceColumns = sourceTargetData.map((data) => ({
      mapId: data.id,
      sourceTableId: data.dataTableId,
      sourceColumnId: data.sourceColumnId,
      sourceDataSystemName: data.sourceDataSystemName,
      sourceDataTableName: data.sourceDataTableName,
      sourceDataTableDescription: data.sourceDataTableDescription,
      sourceDataColumnName: data.sourceDataColumnName,
      sourceDataColumnDescription: data.sourceDataColumnDescription,
      sourceDataType: data.sourceDataType,
      targetTableId: data.dataTableId,
      targetColumnId: data.targetColumnId,
      targetDataSystemName: data.targetDataSystemName,
      targetDataTableName: data.targetDataTableName,
      targetDataTableDescription: data.targetDataTableDescription,
      targetDataColumnName: data.targetDataColumnName,
      targetDataColumnDescription: data.targetDataColumnDescription,
      targetDataType: data.targetDataType,
    }))
    return sourceColumns
  }
  const initSelectedData = async () => {
    const SelectedData = await getSelectedSourceTargetData(dataPodId)
    if (SelectedData) {
      const transformedData = sourceTargetTransformer(SelectedData)
      setSourceData(transformedData)
      const uniqueSourceDataTableNamesSet: Set<string> = new Set()
      transformedData.forEach((item) => uniqueSourceDataTableNamesSet.add(item.sourceDataTableName))
      const uniqueSourceDataTableNames: string[] = Array.from(uniqueSourceDataTableNamesSet)
      setSourceDataTableNames(uniqueSourceDataTableNames)
    }
  }
  useEffect(() => {
    if (typeId && !entityTypeId) {
      setEntityTypeId(Number(typeId))
    }
    if (dataPodId) {
      getDownloadData(dataPodId)
      getSelectedSourceTargetData(dataPodId)
      initSelectedData()
    }
  }, [])

  useEffect(() => {
    if (dataSystems.length) {
      const optionSet = dataSystems.map((ds) => ({
        id: ds.id,
        label: ds.dataSystemName,
      }))
      setDataSystemsData(optionSet)
    }
  }, [dataSystems])

  // useEffect(() => {
  //   if (dataSystemID && !selectedSourceID) {
  //     setSelectedSourceID(Number(dataSystemID))
  //   }
  // }, [dataSystemID])
  useEffect(() => {
    if (sourceToTargetColumnMapV2?.length) {
      setuserSelectedExistingMapping(sourceToTargetColumnMapV2)
    }
  }, [sourceToTargetColumnMapV2])
  useEffect(() => {
    if (entities.length) {
      //console.log({entities})
      setEntityData(entities)
      const optionSet = entities.map((e) => ({
        id: e.id,
        label: e.dataEntityName,
      }))
      setEntityDataOptions(optionSet)
      if (['Source Entity', 'Target Entity'].includes(selectedOption)) {
        setSelectedEntity([entities[0]])
      }
    }
  }, [entities, selectedOption, entityTypeId])

  useEffect(() => {
    if (dataTables?.length) {
      setTableData(dataTables)
      setSelectedTableData([dataTables[0]])
    }
  }, [dataTables, selectedOption])

  useEffect(() => {
    if (sourceToTargetMapRanking?.length) {
      setjsonDownloadedSourceToTargetRanking(sourceToTargetMapRanking)
    }
  }, [sourceToTargetMapRanking])

  // useEffect(() => {
  //   if (SelectedData?.length) {
  //     console.log("inside the use effect if")
  //     const sourceColumns = SelectedData.map((data) => ({
  //       mapId: data.id,
  //       sourceTableId: data.dataTableId,
  //       sourceColumnId: data.sourceColumnId,
  //       sourceDataSystemName: data.sourceDataSystemName,
  //       sourceDataTableName: data.sourceDataTableName,
  //       sourceDataTableDescription: data.sourceDataTableDescription,
  //       sourceDataColumnName: data.sourceDataColumnName,
  //       sourceDataColumnDescription: data.sourceDataColumnDescription,
  //       sourceDataType: data.sourceDataType,
  //       targetTableId: data.dataTableId,
  //       targetColumnId: data.targetColumnId,
  //       targetDataSystemName: data.targetDataSystemName,
  //       targetDataTableName: data.targetDataTableName,
  //       targetDataTableDescription: data.targetDataTableDescription,
  //       targetDataColumnName: data.targetDataColumnName,
  //       targetDataColumnDescription: data.targetDataColumnDescription,
  //       targetDataType: data.targetDataType,
  //     }))
  //     setSourceData(sourceColumns)
  //     const uniqueSourceDataTableNamesSet: Set<string> = new Set()
  //     sourceColumns.forEach((item) => uniqueSourceDataTableNamesSet.add(item.sourceDataTableName))
  //     const uniqueSourceDataTableNames: string[] = Array.from(uniqueSourceDataTableNamesSet)
  //     setSourceDataTableNames(uniqueSourceDataTableNames)
  //   }
  // }, [SelectedData])

  useEffect(() => {
    if (DataColumnMapLucidMeansV2?.length) {
      DataColumnMapLucidMeansV2.sort((a, b) => a.lucidMeanRank - b.lucidMeanRank)
      const tempArray = DataColumnMapLucidMeansV2.filter((dc, ix) => {
        if (ix < 5) {
          return dc
        }
      })
      setTargetList(tempArray)
    }
  }, [DataColumnMapLucidMeansV2])
  const fetchingData = useMemo(() => {
    const loading = ['Source Entity', 'Target Entity'].includes(selectedOption) ? entities.length : tableData?.length
    return !!dataSourceToTargetAttributeMap.length || !!loading
  }, [
    dataSourceToTargetAttributeMap.length,
    srcEntityID,
    targetEntityID,
    srcTableId,
    targetTableId,
    dataTables?.length,
    entities.length,
    selectedOption,
  ])

  useEffect(() => {
    const idData: any = []
    if (selectedEntity.length) {
      selectedEntity.forEach((entity: any) => {
        idData.push({
          id: entity.id,
          entityName: entity.dataEntityName,
        })
      })
    }
    if (selectedTableData.length) {
      selectedTableData.forEach((td) => {
        idData.push({
          id: td.id,
          tableName: td.name,
        })
      })
    }
    setEntityKeyValue(idData)
  }, [selectedEntity, selectedTableData])

  const srcTargetAttributes = useMemo(() => {
    if (!dataSourceToTargetAttributeMap.length) {
      return []
    }

    if (['Source Table', 'Target Table'].includes(selectedOption)) {
      return selectedTableData.length
        ? dataSourceToTargetAttributeMap.filter(
            (ds, ix) =>
              dataSourceToTargetAttributeMap.findIndex((dm) =>
                entityTypeId == 1
                  ? dm.targetTableId === ds.targetTableId && ds.sourceTableId === selectedTableData[0].id
                  : dm.sourceTableId === ds.sourceTableId && ds.targetTableId === selectedTableData[0].id,
              ) === ix,
          )
        : dataSourceToTargetAttributeMap
    }
    // filter for unique entities base entityType & selectedEntity

    return dataSourceToTargetAttributeMap.filter(
      (ds, ix) =>
        dataSourceToTargetAttributeMap.findIndex((dm) =>
          entityTypeId == 1
            ? dm.targetEntityId === ds.targetEntityId && ds.sourceEnityId === selectedEntity[0].id
            : dm.sourceEnityId === ds.sourceEnityId && ds.targetEntityId === selectedEntity[0].id,
        ) === ix,
    )
  }, [
    dataSourceToTargetAttributeMap,
    srcEntityID,
    targetEntityID,
    srcTableId,
    targetTableId,
    entityTypeId,
    selectedTableData,
  ])

  //console.log({ selectedTableData }, { dataSourceToTargetAttributeMap }, { srcTargetAttributes })
  //  console.log({ srcTargetAttributes })
  const processEntities = async () => {
    let nodeData: {
      attributes: {
        attributeId: number
        attributeName: string
        type: string
      }[]
      entityId: number
      entityName: string
      dataSystemName: string
    }[] = []
    let edgesData: {}[] = []

    if (['Source Table', 'Target Table'].includes(selectedOption)) {
      const processedTableNodeData = await processTableNodeData(
        nodeData,
        edgesData,
        entityData,
        tableData,
        dataSourceToTargetAttributeMap,
        srcTargetAttributes,
        fetchEntityMapping,
        getAttribute,
        getDataColumns,
        getProfileAttributes,
      )
      nodeData = processedTableNodeData.nodeData
      edgesData = processedTableNodeData.edgesData
    } else {
      const processedNodeEdgeData = await processEntitiesNodeData(
        nodeData,
        edgesData,
        entityData,
        dataSourceToTargetAttributeMap,
        srcTargetAttributes,
        fetchEntityMapping,
        getAttribute,
        getProfileAttributes,
      )
      nodeData = processedNodeEdgeData.nodeData
      edgesData = processedNodeEdgeData.edgesData
    }

    const nodeWithoutDuplicateEntites = nodeData.filter(
      (nd, ix) => nodeData.findIndex((id) => id.entityId === nd.entityId) === ix,
    )
    setNodes(nodeWithoutDuplicateEntites)

    setEdges(edgesData)
  }

  useEffect(() => {
    if (entityKeyValue.length && srcTargetAttributes.length) {
      processEntities()
    }
    if (!srcTargetAttributes.length) {
      setNodes([])
      setEdges([])
    }
  }, [entityKeyValue, srcTargetAttributes, srcEntityID, targetEntityID, srcTableId, targetTableId])

  const navigate = useNavigate()
  const onClickHome = () => {
    const dataProfilingRoute = generatePath(ROUTES.Overview, { dataPodId: dataPodId })
    navigate(dataProfilingRoute)
  }

  const handleEntityType = (typId: unknown) => {
    setEntityTypeId(Number(typId))
  }

  const handleSelectOption = (selOption: unknown) => {
    if ((selOption as string).includes('Source')) {
      setEntityTypeId(1)
    } else {
      setEntityTypeId(2)
    }
    setSelectedOption(selOption as typeof selectedOption)
  }

  const tableSelected = useMemo(() => {
    return selectedTableData.length ? selectedTableData[0].id : 0
  }, [selectedTableData])

  const handleChange = (event: any, newValue: any) => {
    if (newValue && newValue.id) {
      setSelectedEntity(entityData.filter((ed) => newValue.id === ed.id))
    } else {
      setSelectedEntity([])
    }
  }
  const downloadData = async (event: any) => {
    if (sourceToTargetMapRanking?.length) {
      downloadFile({
        data: JSON.stringify(sourceToTargetMapRanking),
        fileName: 'sourceToTargetMapRanking.xlsx',
        fileType: 'excel',
      })
    }
  }
  const toggleView = (toggle: boolean) => {
    setReactFlowView(!toggle)
    setTableView(toggle)
  }
  const handleSubColumnClick = (colid: number) => {
    getDataColumnMapLucidMeansV2(dataPodId, colid)
    setShowPopUp(true)
  }
  const handleNext = async () => {
    if (dataPodId && userSelectedTarget && userSelectedExistingMapping?.length) {
      const body = {
        id: userSelectedExistingMapping[0].id,
        sourceColumnId: userSelectedTarget.sourceColumnId,
        targetColumnId: userSelectedTarget.targetColumnId,
        runId: 0,
        profileName: 'Manual',
        sourceDataType: userSelectedTarget.sourceDataType,
        targetDataType: userSelectedTarget.targetDataType,
        lucidMean: userSelectedTarget.mean,
      }
      const response = await updateNewTargetToSourceColumn(dataPodId, body)
      if (response) {
        const SelectedData = await getSelectedSourceTargetData(dataPodId)
        if (SelectedData) {
          const transformedData = sourceTargetTransformer(SelectedData)
          const selectedAccordinData = transformedData.filter(
            (item) => item.sourceDataTableName === userSelectedTarget.sourceDataTableName,
          )
          setSelectedSourceData(selectedAccordinData)
          setShowPopUp(!showPopUp)
        }
      }
    }
  }
  // const handleTargetClick = (target: number) => {
  //   console.log("Inside the handle target click")
  //   const selectedTarget = targetList.find(tl => tl.targetColumnId === target);
  //   setUserSelectedTarget(selectedTarget);
  // }
  const leftRows = useMemo(() => {
    if (targetList.length) {
      return targetList.map((tl) => ({
        id: `${tl.targetColumnId}`,
        values: [
          tl.targetDataTableName,
          tl.targetDataTableDescription,
          tl.targetDataColumnName,
          tl.targetDataColumnDescription,
          tl.targetDataType,
          tl.lucidMean,
          tl.lucidMeanRank,
          'SELECT',
        ],
      })) as DataTableRow[]
    }
    return []
  }, [targetList])
  const rightRows = useMemo(() => {
    if (selectedSourceData.length) {
      return selectedSourceData.map((sd) => ({
        id: `${sd.sourceColumnId}`,
        values: [
          sd.sourceDataColumnDescription,
          sd.sourceDataType,
          sd.sourceDataColumnName,
          sd.targetDataColumnName,
          sd.targetDataColumnDescription,
          sd.targetDataType,
          sd.targetDataTableName,
          sd.targetDataTableDescription,
          'SELECTTARGET',
        ],
      })) as DataTableRow[]
    }
    return []
  }, [selectedSourceData])
  const sourceDataRow = useMemo(() => {
    if (selectedSourceName) {
      return selectedSourceName.map((sn) => ({
        id: `${sn.sourceColumnId}`,
        values: [
          sn.sourceDataTableName,
          sn.sourceDataTableDescription,
          sn.sourceDataColumnName,
          sn.sourceDataColumnDescription,
          sn.sourceDataType,
        ],
      })) as DataTableRow[]
    }
    return []
  }, [selectedSourceName])
  const leftTableColumns = useMemo(() => {
    return [
      { label: 'Table Name', sortableColumn: true },
      { label: 'Table Description' },
      { label: 'Column Name', sortableColumn: true },
      { label: 'Column Description' },
      { label: 'Data Type', sortableColumn: true },
      { label: 'Confidence' },
      { label: 'Lucid Mean Rank' },
      { label: 'Select Target' },
    ]
  }, [])
  const rightTableColumns = useMemo(() => {
    return [
      { label: 'Source Column Description' },
      { label: 'Source Data Type', sortableColumn: true },
      { label: 'Source Column Name', sortableColumn: true },
      { label: 'Target Column Name', sortableColumn: true },
      { label: 'Target Column Description' },
      { label: 'Target Data Type', sortableColumn: true },
      { label: 'Target Table Name', sortableColumn: true },
      { label: 'Target Table Description' },
      { label: 'Change Target' },
    ]
  }, [])

  const sourcePopUpHeaders = useMemo(() => {
    return [
      { label: 'Source Table Name', sortableColumn: true },
      { label: 'Source Table Description' },
      { label: 'Source Column Name', sortableColumn: true },
      { label: 'Source Column Description' },
      { label: 'Source Data Type', sortableColumn: true },
    ]
  }, [])

  const onButtonClick = (rowId: string, actionType: ShadowTableAction) => {
    if (actionType == ShadowTableAction.Select) {
      const selectedTarget = targetList.find((tl) => tl.targetColumnId === Number(rowId))
      setUserSelectedTarget(selectedTarget)
      handleNext()
    }
    if (actionType === ShadowTableAction.SelectTarget) {
      const source: SourceAndTargetColumns[] = sourceData.filter((item) => item.sourceColumnId == Number(rowId))
      setSelectedSourceName(source)
      getSourceToTargetColumnMapV2(dataPodId, Number(rowId))
      getDataColumnMapLucidMeansV2(dataPodId, Number(rowId))
      setShowPopUp(true)
    }
  }
  const handleExpand = (panel: string, tableName: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
    const selectedAccordinData = sourceData.filter((item) => item.sourceDataTableName === tableName)
    setSelectedSourceData(selectedAccordinData)
  }
  return (
    <ActionPage>
      <Grid item container spacing={2}>
        <Grid item xs={2}>
          <IconButton onClick={onClickHome}>
            <HomeOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box style={cardContainer}>
        <Grid container spacing={2}>
          <Grid item container spacing={2}>
            {/* <Grid item xs={12}>
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={4} alignItems={'center'}>
                  <Grid item container xs={15} spacing={1}>
                    <Grid item xs={6}>
                      <Typography component="span" variant="subtitle1" sx={{ fontSize: 14 }}>
                        Data Pod Name
                      </Typography>
                    </Grid>
                    <Grid item />

                    <Grid item xs={5}>
                      <Typography component="span" variant="subtitle1" sx={{ fontSize: 14 }}>
                        Data pod ID
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={15} />

                    <Grid item container xs={15} spacing={1}>
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          id="outlined-select-dataSource"
                          variant="outlined"
                          value={dataPodInfo?.name}
                          required
                          size="small"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={1} />
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          id="outlined-select-dataSource"
                          variant="outlined"
                          value={dataPodInfo?.id}
                          required
                          size="small"
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid> */}
            <Grid item xs={12} />
            <Grid item xs={5}>
              <TextField
                label="Select an option from"
                fullWidth
                select
                size="small"
                SelectProps={{
                  onChange: ({ target }) => {
                    handleSelectOption(target.value)
                  },
                }}
                value={selectedOption}
              >
                {['Source Entity', 'Target Entity', 'Source Table', 'Target Table', 'Data Systems'].map((val) => (
                  <MenuItem key={val} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </TextField>
              {/* <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={entityTypeId}
                  onChange={({ target }) => handleEntityType(target.value)}
                >
                  <FormControlLabel value={1} control={<Radio />} label="Source" />
                  <FormControlLabel value={2} control={<Radio />} label="Target" />
                </RadioGroup>
                <RadioGroup
                  row
                  aria-labelledby="radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={tableOrEntity}
                  onChange={({ target }) => setTableOrEntity(target.value as typeof tableOrEntity)}
                >
                  <FormControlLabel value="Entity" control={<Radio />} label="Entity" />
                  <FormControlLabel value="Table" control={<Radio />} label="Table" />
                </RadioGroup>
              </FormControl> */}
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <Autocomplete
                disableClearable
                disablePortal
                id="combo-box-demo"
                size="small"
                options={dataSystemsData}
                sx={{ width: '100%' }}
                renderInput={(params) => <TextField {...params} label="Data Systems" />}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              {['Source Entity', 'Target Entity'].includes(selectedOption) ? (
                // <TextField
                //   fullWidth
                //   id="outlined-select-dataSource"
                //   select
                //   label="Entity"
                //   variant="outlined"
                //   value={selectedEntity.map((se) => se.id)}
                //   size="small"
                //   SelectProps={{
                //     //multiple:true,
                //     onChange: ({ target }) => {
                //       // setEdges([])
                //       // setNodes([])
                //       //setSelectedCoreEntity( entityData.filter((ed) => (target.value as string[]).includes(ed.id)))
                //       setSelectedEntity(entityData.filter((ed) => target.value == ed.id))
                //     },
                //   }}
                // >
                //   {entityData.map((d) => (
                //     <MenuItem key={d.id} value={d.id}>
                //       <Typography fontSize={'small'}> {d.dataEntityName}</Typography>
                //     </MenuItem>
                //   ))}
                // </TextField>
                <Autocomplete
                  disableClearable
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  getOptionLabel={(option) => option.label}
                  options={entityDataOptions}
                  // value={}
                  defaultValue={entityDataOptions[0]}
                  onChange={handleChange}
                  sx={{ width: '100%' }}
                  renderInput={(params) => <TextField {...params} label="Entity" />}
                />
              ) : (
                <TextField
                  fullWidth
                  id="outlined-select-dataSource"
                  select
                  label="Table"
                  variant="outlined"
                  value={tableSelected}
                  size="small"
                  SelectProps={{
                    //multiple:true,
                    onChange: ({ target }) => {
                      //setSelectedTableData( tableData.filter((ed) => (target.value as string[]).includes(ed.id)))
                      setSelectedTableData(tableData.filter((ed) => target.value == ed.id))
                    },
                  }}
                >
                  {tableData.map((d) => (
                    <MenuItem key={d.id} value={d.id}>
                      <Typography fontSize={'small'}> {d.name}</Typography>
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <Button onClick={downloadData} style={{ width: '50%', outline: '1px' }} color="primary">
                {' '}
                Download All Data{' '}
              </Button>
              <IconButton color={tableView ? 'primary' : 'inherit'} onClick={() => toggleView(true)}>
                <TableRowsOutlinedIcon />
              </IconButton>
              <IconButton color={reactFlowView ? 'primary' : 'inherit'} onClick={() => toggleView(false)}>
                <AccountTreeIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} container>
          <Grid container spacing={2}>
            <Grid item xs={12} />
            {fetchingData && !tableView && (
              <ReactFlowGen
                nodeData={nodes.length ? nodes : []}
                edgeData={edges.length ? edges : []}
                selectedOption={selectedOption}
              />
            )}
            {/* {!fetchingData && (
              <center>
                <Spinner radius={50} color={'2E92DA'} stroke={2} visible={true} />{' '}
              </center>
            )} */}
          </Grid>
        </Grid>

        {tableView && (
          <>
            <Typography variant="h4" style={{ paddingBottom: '2.5%' }}>
              Please review and adjust the source to target column mappings
            </Typography>

            <Grid
              container
              direction={'row'}
              // justifyContent={'space-evenly'}
              // alignItems={'stretch'}
              // spacing={0.5}
              item
            >
              <Grid item xs={15}>
                {sourceDataTableNames.map((data, index) => (
                  <Accordion
                    key={index}
                    expanded={expanded === `panel${index}`}
                    onChange={handleExpand(`panel${index}`, data)}
                    style={{ width: '100%' }}
                  >
                    <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`}>
                      <Typography variant="h4" textAlign={'left'} width={'100%'}>
                        {data}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item container sx={{ maxWidth: '100%', pr: '20', pl: '20', overflow: 'scroll' }}>
                        <ShadowTable
                          rows={rightRows}
                          columns={rightTableColumns}
                          selectedRow={`${userSelectedTarget?.targetColumnId ?? 0}`}
                          // selectRow={(id) => { console.log(id) }}
                          tableActionParams={{
                            onButtonClick: onButtonClick,
                            actions: [ShadowTableAction.SelectTarget],
                          }}
                          sourceIndex={[0, 1, 2]}
                          targetIndex={[3, 4, 5, 6, 7]}
                          sourceToTarget
                        />
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </Grid>
          </>
        )}
        <LucidWidePopUp
          showCloseIcon
          closePopup={setShowPopUp}
          openPopUp={showPopUp}
          onConfirm={handleNext}
          headingText={
            <Typography variant="h4" gutterBottom align="justify">
              Source Column
            </Typography>
          }
          confirmText=""
        >
          <Grid container spacing={1}>
            <Grid item xs={1} />
            <Grid item container xs={16} md={12}>
              <ShadowTable
                rows={sourceDataRow}
                columns={sourcePopUpHeaders}
                // selectedRow={`${userSelectedTarget?.targetColumnId ?? 0}`}
                sourceToTarget
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={1} />
            <Grid item container xs={16} md={12}>
              <Typography variant="h4" gutterBottom align="justify" pt={'2.5%'} pb={'2.5%'}>
                Select Target Column
              </Typography>
              <ShadowTable
                rows={leftRows}
                columns={leftTableColumns}
                selectedRow={`${userSelectedTarget?.targetColumnId ?? 0}`}
                selectRow={(id) => {}}
                tableActionParams={{
                  onButtonClick: onButtonClick,
                  actions: [ShadowTableAction.Select],
                }}
                sourceToTarget
              />
            </Grid>
          </Grid>
        </LucidWidePopUp>
      </Box>
    </ActionPage>
  )
}
