import { useState, useEffect } from 'react'
import { AnalyticsGroup } from '../businessObjects'
import { genServices } from '../apiServices/lucidGenAIServices'
import { useLucidAuthContext } from '../LucidAuthContext/LucidAuthContext'

type FlatObject = { [key: string]: any }

export const useNotebookToQueryString = (
  dataPodId?: string,
  dataSystemId?: string,
  selectedTableId?: number,
): {
  getNotebookStringBySystemId: () => void
  getNotebookStringBySystemIdAndTableId: (dataTableId: number) => void
  loadCanonicalNotebookStringByEntityId: (dataEntityId: number) => void
  notebookResponse: FlatObject | undefined
  notebookLoading: boolean
  notebookError: FlatObject | undefined
} => {
  const [notebookError, setNotebookError] = useState<FlatObject | undefined>()
  const [notebookLoading, setNotebookLoading] = useState<boolean>(false)
  const [notebookResponse, setNotebookResponse] = useState<FlatObject | undefined>([])

  const { getBearerToken } = useLucidAuthContext()

  const getNotebookStringBySystemId = async (): Promise<void> => {
    try {
      setNotebookLoading(true)
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId && dataSystemId) {
        const { data, status } = await genServices.getNotebookStringBySystemId({
          dataPodId,
          dataSystemId: parseInt(dataSystemId),
        })
        if (status == 200) {
          setNotebookResponse({ time: Date.now(), message: data?.data })
        } else {
          setNotebookError({ time: Date.now(), message: data?.toSring() })
        }
        setNotebookLoading(false)
      }
    } catch (error: any) {
      setNotebookLoading(false)
      setNotebookError({ time: Date.now(), message: error.message })
    }
  }

  const getNotebookStringBySystemIdAndTableId = async (dataTableId: number): Promise<void> => {
    try {
      setNotebookLoading(true)
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId && dataSystemId && dataTableId) {
        const { data, status } = await genServices.getNotebookStringBySystemIdAndTableId({
          dataPodId,
          dataSystemId: parseInt(dataSystemId),
          dataTableId,
        })
        if (status == 200) {
          setNotebookResponse({ time: Date.now(), message: data?.data })
        } else {
          setNotebookError({ time: Date.now(), message: data?.toSring() })
        }
        setNotebookLoading(false)
      }
    } catch (error: any) {
      setNotebookLoading(false)
      setNotebookError({ time: Date.now(), message: error.message })
    }
  }

  const loadCanonicalNotebookStringByEntityId = async (dataEntityId: number): Promise<void> => {
    try {
      setNotebookLoading(true)
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId && dataEntityId) {
        const { data, status } = await genServices.loadCanonicalNotebookStringByEntityId({
          dataPodId,
          dataEntityId,
        })
        if (status == 200) {
          setNotebookResponse({ time: Date.now(), message: data?.data })
        } else {
          setNotebookError({ time: Date.now(), message: data?.toSring() })
        }
        setNotebookLoading(false)
      }
    } catch (error: any) {
      setNotebookLoading(false)
      setNotebookError({ time: Date.now(), message: error.message })
    }
  }

  useEffect(() => {
    if (selectedTableId && dataPodId && dataSystemId) {
      getNotebookStringBySystemIdAndTableId(selectedTableId)
    } else if (dataPodId && dataSystemId) {
      getNotebookStringBySystemId()
    }
  }, [dataSystemId, selectedTableId])

  return {
    getNotebookStringBySystemId,
    getNotebookStringBySystemIdAndTableId,
    loadCanonicalNotebookStringByEntityId,
    notebookError,
    notebookLoading,
    notebookResponse,
  }
}
