export const validName = /[^a-zA-Z0-9_ ]/
export const isValidEntry = (nameText?: string) => {
  if (!nameText || nameText.startsWith(' ')) return false
  return !validName.test(nameText)
}
export const isValidDescription = (desc?: string) => {
  return !!(desc && !desc?.startsWith(' '))
}

export const isValidJson = (json: string) => {
  try {
    if(json) {
      json && JSON.parse(json)
      return JSON.stringify(json)
    } else {
      return json
    }
  } catch (e) {
    return false
  }
}
