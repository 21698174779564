import { useState, useEffect } from 'react';
import { dataQualityServices } from '../apiServices/dataQuality';
import { useLucidAuthContext } from '../LucidAuthContext/LucidAuthContext';
import { DataQualityCodeRequest, DataQualityNBRequest, DataQualityRule, Rules } from '../businessObjects';

type FlatObject = { [key: string]: any }

export const useGetActiveRules = () => {
  const [activeRules, setActiveRules] = useState<Rules>();
  const [isActiveRulesloading, setIsActiveRulesLoading] = useState<boolean>(true);
  const [isActiveRulesError, setIsActiveRulesError] = useState<string | null>(null);
  const { getBearerToken } = useLucidAuthContext()

  const fetchActiveRules = async () => {
    setIsActiveRulesLoading(true)

    try {
        const token = await getBearerToken()

        if(token) {
            const {data, status} = await dataQualityServices.getActiveRules(token);
            if (status === 200) {
                setActiveRules(data);
            }
        }

    } catch (err) {
        setIsActiveRulesError('Failed to fetch active rules.');
    } 

    setIsActiveRulesLoading(false);
  };

  useEffect(() => {
    fetchActiveRules();
  }, []);

  return { activeRules, isActiveRulesloading, isActiveRulesError };
};

export const useGetDataQualityRules = (entityId: number | undefined) : {
  dataQualityRules: DataQualityRule[],
  isDataQualityRulesLoading: boolean,
  isDataQualityRulesError: FlatObject | undefined,
} => {
  const [dataQualityRules, setDataQualityRules] = useState<DataQualityRule[]>([]);
  const [isDataQualityRulesLoading, setIsDataQualityRulesLoading] = useState<boolean>(false)
  const [isDataQualityRulesError, setIsDataQualityRulesError] = useState<FlatObject | undefined>()
  const { getBearerToken } = useLucidAuthContext()

  const fetchDataQualityRules = async (entityId: number) : Promise<void> => {
    try {
      setIsDataQualityRulesLoading(true)
      const token = await getBearerToken()

      if(token && entityId) {
        const {data, status} = await dataQualityServices.getDataQualityRules(token, entityId);

        if (status === 200) {
          setDataQualityRules(data)
        } else {
          setIsDataQualityRulesError({ time: Date.now(), message: "Failed to add Data Quality Rule. Internal Server Error." });
        }
      }
    } catch (err) {
      setIsDataQualityRulesError({ time: Date.now(), message: "Failed to add Data Quality Rule. Internal Server Error." });
    }
    setIsDataQualityRulesLoading(false);
  }

  useEffect(() => {
    if(entityId) {
      fetchDataQualityRules(entityId);
    }
  }, [entityId])

  return {dataQualityRules, isDataQualityRulesLoading, isDataQualityRulesError}
}

export const useGetDataQualityRuleById = (ruleId: number | undefined) : {
  dataQualityRule: DataQualityRule | undefined,
  isDataQualityRuleLoading: boolean,
  isDataQualityRuleError: FlatObject | undefined,
} => {
  const [dataQualityRule, setDataQualityRule] = useState<DataQualityRule>();
  const [isDataQualityRuleLoading, setIsDataQualityRuleLoading] = useState<boolean>(false)
  const [isDataQualityRuleError, setIsDataQualityRuleError] = useState<FlatObject | undefined>()
  const { getBearerToken } = useLucidAuthContext()

  const fetchDataQualityRule = async (ruleId: number) : Promise<void> => {
    try {
      setIsDataQualityRuleLoading(true)
      const token = await getBearerToken()

      if(token && ruleId) {
        const {data, status} = await dataQualityServices.getDataQualityRuleById(token, ruleId);

        if (status === 200) {
          setDataQualityRule(data)
        } else {
          setIsDataQualityRuleError({ time: Date.now(), message: "Failed to add Data Quality Rule. Internal Server Error." });
        }
      }
    } catch (err) {
      setIsDataQualityRuleError({ time: Date.now(), message: "Failed to add Data Quality Rule. Internal Server Error." });
    }
    setIsDataQualityRuleLoading(false);
  }

  useEffect(() => {
    if(ruleId) {
      fetchDataQualityRule(ruleId);
    }
  }, [ruleId])

  return {dataQualityRule, isDataQualityRuleLoading, isDataQualityRuleError}
}

export const useAddDataQualityRule = () : {
    isAddRuleSuccess: boolean,
    isAddRuleError: FlatObject | undefined,
    addDataQualityRule : (payload: DataQualityRule) => Promise<DataQualityRule | undefined>
} => {
    const [isAddRuleSuccess, setIsAddRuleSuccess] = useState<boolean>(false);
    const [isAddRuleError, setIsAddRuleError] = useState<FlatObject | undefined>();
    const { getBearerToken } = useLucidAuthContext()
  
    const addDataQualityRule = async (payload: DataQualityRule): Promise<DataQualityRule | undefined> => {  
      try {

        setIsAddRuleSuccess(false);
        const token = await getBearerToken()
  
        if(token && payload) {
            const {data, status} = await dataQualityServices.addDataQualityRule(token, payload);

            if (status === 200) {
                setIsAddRuleSuccess(true);
                return data
            } else {
                setIsAddRuleSuccess(false);
                setIsAddRuleError({ time: Date.now(), message: "Failed to add Data Quality Rule. Internal Server Error." });
            }
        }
  
      } catch (err) {
            setIsAddRuleError({ time: Date.now(), message: "Failed to add Data Quality Rule. Internal Server Error." });
      } 
    };
  
    return { isAddRuleSuccess, isAddRuleError, addDataQualityRule };
  };

export const useUpdateDataQualityRule = () : {
    isUpdateRuleSuccess: boolean,
    isUpdateRuleError: FlatObject | undefined,
    updateDataQualityRule : (payload: DataQualityRule) => Promise<DataQualityRule | undefined>
} => {
    const [isUpdateRuleSuccess, setIsUpdateRuleSuccess] = useState<boolean>(false);
    const [isUpdateRuleError, setIsUpdateRuleError] = useState<FlatObject | undefined>();
    const { getBearerToken } = useLucidAuthContext()
  
    const updateDataQualityRule = async (payload: DataQualityRule): Promise<DataQualityRule | undefined> => {  
      try {

        setIsUpdateRuleSuccess(false);
        const token = await getBearerToken()
  
        if(token && payload) {
            const {data, status} = await dataQualityServices.updateDataQualityRule(token, payload);

            if (status === 200) {
              setIsUpdateRuleSuccess(true);
                return data
            } else {
              setIsUpdateRuleSuccess(false);
              setIsUpdateRuleError({ time: Date.now(), message: "Failed to add Data Quality Rule. Internal Server Error." });
            }
        }
  
      } catch (err) {
        setIsUpdateRuleError({ time: Date.now(), message: "Failed to add Data Quality Rule. Internal Server Error." });
      } 
    };
  
    return { isUpdateRuleSuccess, isUpdateRuleError, updateDataQualityRule };
  };

export const useDeleteDataQualityRule = () : {
    isDeleteRuleSuccess: boolean,
    isDeleteRuleError: FlatObject | undefined,
    deleteDataQualityRule : (ruleId: number) => Promise<boolean>
} => {
    const [isDeleteRuleSuccess, setIsDeleteRuleSuccess] = useState<boolean>(false);
    const [isDeleteRuleError, setIsDeleteRuleError] = useState<FlatObject | undefined>();
    const { getBearerToken } = useLucidAuthContext()
  
    const deleteDataQualityRule = async (ruleId: number): Promise<boolean> => {  
      try {

        setIsDeleteRuleSuccess(false);
        const token = await getBearerToken()
  
        if(token && ruleId) {
            const {data, status} = await dataQualityServices.deleteDataQualityRule(token, ruleId);

            if (status === 204) {
              setIsDeleteRuleSuccess(true);
              return true
            } else {
              setIsDeleteRuleSuccess(false);
              setIsDeleteRuleError({ time: Date.now(), message: "Failed to delete Data Quality Rule. Internal Server Error." });
            }
          }
          
        } catch (err) {
          setIsDeleteRuleError({ time: Date.now(), message: "Failed to delete Data Quality Rule. Internal Server Error." });
        } 
        
        return false
    };
  
    return { isDeleteRuleSuccess, isDeleteRuleError, deleteDataQualityRule };
  };

export const useAddDataQualityNB = () : {
    isAddDataQualityNBSuccess: boolean,
    isAddDataQualityNBError: FlatObject | undefined,
    addDataQualityNB : (payload: DataQualityNBRequest) => Promise<void>
} => {
    const [isAddDataQualityNBSuccess, setIsAddDataQualityNBSuccess] = useState<boolean>(false);
    const [isAddDataQualityNBError, setIsAddDataQualityNBError] = useState<FlatObject | undefined>();
    const { getBearerToken } = useLucidAuthContext()
  
    const addDataQualityNB = async (payload: DataQualityNBRequest) => {  
      try {

        setIsAddDataQualityNBSuccess(false);
        setIsAddDataQualityNBError(undefined);

        const token = await getBearerToken()
  
        if(token && payload) {
            const {data, status} = await dataQualityServices.addDataQualityNB(token, payload);

            if (status === 200) {
              setIsAddDataQualityNBSuccess(true);
            } else {
              setIsAddDataQualityNBSuccess(false);
              setIsAddDataQualityNBError({ time: Date.now(), message: "Failed to add Data Quality notebook. Internal Server Error." });
            }
        }
  
      } catch (err) {
        setIsAddDataQualityNBError({ time: Date.now(), message: "Failed to add Data Quality notebook. Internal Server Error." });
      } 
    };
  
    return { isAddDataQualityNBSuccess, isAddDataQualityNBError, addDataQualityNB };
  };

export const useUpdateDataQualityNB = () : {
  isUpdateDataQualityNBSuccess: boolean,
  isUpdateDataQualityNBError: FlatObject | undefined,
  updateDataQualityNB : (payload: DataQualityNBRequest) => Promise<void>
} => {
  const [isUpdateDataQualityNBSuccess, setIsUpdateDataQualityNBSuccess] = useState<boolean>(false);
  const [isUpdateDataQualityNBError, setIsUpdateDataQualityNBError] = useState<FlatObject | undefined>();
  const { getBearerToken } = useLucidAuthContext()

  const updateDataQualityNB = async (payload: DataQualityNBRequest) => {  
    try {

      setIsUpdateDataQualityNBSuccess(false);
      setIsUpdateDataQualityNBError(undefined);

      const token = await getBearerToken()

      if(token && payload) {
          const {data, status} = await dataQualityServices.updateDataQualityNB(token, payload);

          if (status === 200) {
            setIsUpdateDataQualityNBSuccess(true);
          } else {
            setIsUpdateDataQualityNBSuccess(false);
            setIsUpdateDataQualityNBError({ time: Date.now(), message: "Failed to update Data Quality notebook. Internal Server Error." });
          }
      }

    } catch (err) {
      setIsUpdateDataQualityNBError({ time: Date.now(), message: "Failed to update Data Quality notebook. Internal Server Error." });
    } 
  };

  return { isUpdateDataQualityNBSuccess, isUpdateDataQualityNBError, updateDataQualityNB };
};


export const useGetDataQualityNB = (dataPodId: string | undefined, ruleId: number | undefined) : {
    dataQualityNB : string | undefined,
    isGetDataQualityNBError: FlatObject | undefined,
    fetchDataQualityNB : (dataPodId: string, ruleId: number) => Promise<void>
} => {
    const [dataQualityNB, setDataQualityNB] = useState<string | undefined>();
    const [isGetDataQualityNBError, setIsGetDataQualityNBError] = useState<FlatObject | undefined>();
    const { getBearerToken } = useLucidAuthContext()
  
    const fetchDataQualityNB = async (dataPodId: string, ruleId: number) => {  
      try {

        const token = await getBearerToken()
  
        if(token && dataPodId && ruleId) {
            const {data, status} = await dataQualityServices.getDataQualityNB(token, dataPodId, ruleId);

            if (status === 200) {
              setDataQualityNB(data)
            } else {
              setIsGetDataQualityNBError({ time: Date.now(), message: "Failed to get Data Quality Rule NB. Internal Server Error." });
            }
        }
  
      } catch (err) {
        setIsGetDataQualityNBError({ time: Date.now(), message: "Failed to get Data Quality Rule NB. Internal Server Error." });
      } 
    };

    useEffect(() => {
      if(dataPodId && ruleId)
        fetchDataQualityNB(dataPodId, ruleId)
    }, [dataPodId, ruleId])
  
    return { dataQualityNB, isGetDataQualityNBError, fetchDataQualityNB };
  };

export const useGetDataQualityCode = (): {
    dataQualityCodeResponse: string
    dataQualityCodeLoading: boolean
    dataQualityCodeError: FlatObject | undefined
    fetchDataQualityCode: (payload: DataQualityCodeRequest) => Promise<void>
  } => {
    const [dataQualityCodeError, setDataQualityCodeError] = useState<FlatObject | undefined>()
    const [dataQualityCodeLoading, setDataQualityCodeLoading] = useState<boolean>(false)
    const [dataQualityCodeResponse, setDataQualityCodeResponse] = useState<string>("")
  
    const fetchDataQualityCode = async (payload: DataQualityCodeRequest): Promise<void> => {
      try {
        setDataQualityCodeLoading(true)
        setDataQualityCodeError(undefined)
        
        const { data, status } = await dataQualityServices.getDataQualtiyCode(payload)

        if (status == 200) {        
            setDataQualityCodeResponse(data.data)
        } else {
            setDataQualityCodeError({ time: Date.now(), message: data?.result || data?.toString() })       
        }

        setDataQualityCodeLoading(false)
      } catch (error: any) {
        setDataQualityCodeLoading(false)
        setDataQualityCodeError({ time: Date.now(), message: "Failed to GenerateKPI. Internal Server Error." })
      }
    }
  
    // useEffect(() => {
    //   if (dataPodId && measureId && promptText && promptText.length) {
    //     generateKPI()
    //   }
    // }, [measureId, promptText])
  
    return {
        dataQualityCodeResponse,
        dataQualityCodeLoading,
        dataQualityCodeError,
        fetchDataQualityCode,
    }
  }