import React, { useEffect, useState } from 'react'
import { Card, Typography, Grid, Skeleton } from '@mui/material'
import { AnalyticsMeasure } from '../../businessObjects'

type Props = {
  analyticsMeasureLoading: boolean
  selectedAnalyticMeasure: AnalyticsMeasure | undefined
}

const MeasureDetailCard = ({ analyticsMeasureLoading, selectedAnalyticMeasure }: Props) => {
  return (
    <>
      {analyticsMeasureLoading ? (
        <>
          <Skeleton variant="text" height={30} />
          <Skeleton variant="text" height={30} />{' '}
        </>
      ) : (
        selectedAnalyticMeasure && (
          <Card sx={{ p: 1, mb: 1 }}>
            <Grid item xs={12} container sx={{ marginBottom: 2 }}>
              <Grid item xs={2}>
                <Typography sx={{ fontSize: 14 }} variant="h3" textAlign={'left'}>
                  Group ID
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" textAlign={'left'}>
                  {selectedAnalyticMeasure?.analyticsGroupId}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: 14 }} variant="h3" textAlign={'left'}>
                  Group Name
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" textAlign={'left'}>
                  {selectedAnalyticMeasure?.analyticsGroupName}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography sx={{ fontSize: 14 }} variant="h3" textAlign={'left'}>
                  Group Description
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" textAlign={'left'}>
                  {selectedAnalyticMeasure?.analyticsGroupDescription}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container sx={{ marginBottom: 2 }}>
              <Grid item xs={2}>
                <Typography sx={{ fontSize: 14 }} variant="h3" textAlign={'left'}>
                  Measure ID
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" textAlign={'left'}>
                  {selectedAnalyticMeasure?.analyticsMeasureId}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: 14 }} variant="h3" textAlign={'left'}>
                  Measure Name
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" textAlign={'left'}>
                  {selectedAnalyticMeasure?.analyticsMeasureName}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography sx={{ fontSize: 14 }} variant="h3" textAlign={'left'}>
                  Measure Description
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" textAlign={'left'}>
                  {selectedAnalyticMeasure?.analyticsMeasureDescription}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        )
      )}
    </>
  )
}

export default MeasureDetailCard
