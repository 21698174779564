import CodeMirror from '@uiw/react-codemirror'
import { json } from '@codemirror/lang-json'
import { lintGutter } from '@codemirror/lint'
import { EditorView } from '@codemirror/view'
import { SetStateAction, useEffect, useState } from 'react'
import { Button, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import { AddOutlined, CancelOutlined } from '@mui/icons-material'
import {
  ServiceConnectionType,
  useGetServiceConnections,
  usePostServiceConnection,
} from '../../../hooks/serviceConnectionHooks'
import { isValidJson, isValidEntry } from '../../../utils/constants'
import ResultStatus from '../../../ui-components/ResultStatus'
import AnimatedLoader from '../../../ui-components/AnimatedLoader'
import ServiceNameWithIcon from '../../../ui-components/ServiceNameWithIcon'
import LucidDataPodDebounceSearch from '../../../ui-components/LucidDataPodDebounceSearch'

const options = {
  autoFocus: true,
  lineNumbers: true,
  lineWrapping: true,
  autocompletion: true,
  highlightActiveLine: true,
  highlightSelectionMatches: true,
  mode: {
    name: 'javascript',
    json: true,
    statementIndent: 2,
  },
}

type DataService = {
  id: number
  serviceName: string
  serviceDescription: string
  isActive: boolean
  serviceConnectionTypeId: string
  jsonConfiguration: string
}

type Props = {
  selectedConnection?: ServiceConnectionType
  closeAddForm: (podId?: string | undefined) => void
  dataPodId?: string
  handleService?: (args: any) => void
  isAddNewPodStep?: boolean
}

const AddServiceConnection = ({
  handleService,
  dataPodId,
  selectedConnection,
  closeAddForm,
  isAddNewPodStep,
}: Props) => {
  const isEdit = selectedConnection?.action === 'edit'
  const isView = selectedConnection?.action === 'view'
  const hideDataPodOption = isAddNewPodStep

  const [addedSuccess, setAddedSuccess] = useState<boolean>(false)
  const [errorState, setErrorState] = useState<boolean>(false)
  const [connectionName, setConnectionName] = useState<string>('')
  const [connectionType, setConnectionType] = useState<string>('')
  const [serviceType, setServiceType] = useState<string>('')
  const [serviceConfig, setServiceConfig] = useState<any>()
  const [dataPod, setDataPod] = useState<string>(dataPodId || selectedConnection?.dataPodId || '')

  const { dataServices, connectionTypes = [], getServiceType, isDataServiceLoading } = useGetServiceConnections()
  const { postError, postComplete, addServiceConnection, updateServiceConnection } = usePostServiceConnection(dataPod)

  const body = {
    id: selectedConnection?.id || 0,
    serviceConnectionJsonTemplate: serviceConfig,
    serviceConnectionTypeId: connectionType,
    serviceTypeId: serviceType,
    locationInfo: '/data/services/json',
    connectionName: connectionName,
    connectionType: connectionType,
    action: selectedConnection?.id ? 'edit' : 'new',
  }

  const handleEditorChange = (json: any) => {
    try {
      setServiceConfig(json)
      JSON.parse(json)
      handleService && handleService(body)
      setErrorState(false)
    } catch (e) {
      setErrorState(true)
    }
  }

  useEffect(() => {
    if (postError) {
      //Add/Edit - handling error message.
      setErrorState(true)
    }
  }, [postError])

  useEffect(() => {
    if (selectedConnection?.dataPodId && selectedConnection?.action) {
      setConnectionName(selectedConnection?.connectionName)
      setDataPod(selectedConnection?.dataPodId)
      handleConnectionType(selectedConnection?.serviceConnectionTypeId)
    }
  }, [selectedConnection?.dataPodId, selectedConnection?.action])

  //this will wait for load connection type and data pods at edit/view case
  useEffect(() => {
    if (selectedConnection?.dataPodId && selectedConnection?.action) {
      //on view/edit once all pre-selection done
      setServiceType(selectedConnection?.serviceTypeId?.toString())
      setServiceConfig(selectedConnection?.serviceConnectionJsonTemplate)
    }
  }, [dataServices?.length])

  useEffect(() => {
    if (postComplete?.dataPodId) {
      //Edit - allowing modify n times.
      if (isEdit) {
        setAddedSuccess(true)
      } else {
        //Add - Once added successfully navigated to List page with podId filter
        closeAddForm(dataPod)
      }
    }
  }, [postComplete])

  useEffect(() => {
    if (handleService && body) {
      handleService(body)
    }
  }, [connectionName, serviceConfig, connectionType, serviceType])

  const handleSave = () => {
    if (isEdit) {
      body.id = selectedConnection.id
      updateServiceConnection(body)
    } else {
      addServiceConnection(body)
    }
  }

  const handleConnectionType = (val: string) => {
    if (val) {
      setConnectionType(val)
      getServiceType(val)
      setServiceConfig('')
    }
  }

  const handleServiceType = (value: string) => {
    setServiceType(value)
    const jsonConfiguration = dataServices.find((s: DataService) => s.id === parseInt(value))?.jsonConfiguration
    handleEditorChange(jsonConfiguration)
  }

  const handleAddClose = () => {
    setConnectionName('')
    setConnectionType('')
    setServiceType('')
    setServiceConfig('')
    closeAddForm(dataPod)
  }

  return (
    <>
      <>
        <ResultStatus
          severtiy="success"
          showStatus={addedSuccess}
          closeStatus={() => setAddedSuccess(false)}
          alertMessage={`SUCCESS: ${isEdit ? `Updated` : `Created`}  Service: ${connectionName} !`}
        />
        <ResultStatus
          showStatus={errorState}
          alertMessage={postError?.message || 'Please fill required fields or invalid entries'}
          severtiy="error"
          closeStatus={() => setErrorState(false)}
        />
      </>
      <AnimatedLoader height="50%" width="40%" loading={isDataServiceLoading} />
      <Grid container item xs={12}>
        <Grid item xs={12} justifyContent={'space-between'} display={'flex'} alignItems={'center'}>
          <Grid item xs={4}>
            <Typography variant="h1">{isView ? `View` : isEdit ? `Update` : `Create`} Service Connection</Typography>
          </Grid>
          {!handleService && (
            <Grid item xs={8} justifyContent={'end'} display={'flex'}>
              <Button
                color="secondary"
                onClick={handleAddClose}
                variant="outlined"
                startIcon={<CancelOutlined />}
                sx={{ mr: 2 }}
              >
                Back
              </Button>
              {!isView && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  disabled={
                    !isValidEntry(connectionName) || !connectionType || !serviceType || !isValidJson(serviceConfig)
                  }
                  startIcon={<AddOutlined />}
                >
                  {isEdit ? `Update` : `Save`} Connection
                </Button>
              )}
            </Grid>
          )}
        </Grid>
        {!hideDataPodOption && (
          <Grid item xs={12} sx={{ lineHeight: 3 }}>
            <Typography component="span" variant="subtitle1">
              Data Pod Name
            </Typography>

            <LucidDataPodDebounceSearch
              defaultVal={dataPod}
              selectPod={setDataPod}
              size="small"
              disabled={isEdit || isView}
            />
          </Grid>
        )}

        {dataPod && (
          <>
            <Grid item xs={12} sx={{ lineHeight: 3 }}>
              <Typography component="span" variant="subtitle1">
                Connection Name
              </Typography>
              <TextField
                fullWidth
                id="service-name"
                variant="outlined"
                value={connectionName}
                required
                size="small"
                disabled={isView}
                onChange={(e: { target: { value: SetStateAction<string> } }) => setConnectionName(e.target.value)}
                error={!isValidEntry(connectionName)}
                helperText={!isValidEntry(connectionName) ? 'Invalid Connection Name' : ''}
              />
            </Grid>
            <Grid item xs={12} sx={{ lineHeight: 3 }} display={'flex'}>
              <Grid item xs={6}>
                <Typography component="span" variant="subtitle1">
                  Connection Type
                </Typography>
                <Select
                  fullWidth
                  labelId="select-service-type-label"
                  id="service-typet"
                  value={connectionType || ''}
                  size="small"
                  disabled={isEdit || isView}
                  onChange={({ target }) => handleConnectionType(target?.value)}
                  required
                >
                  {connectionTypes?.map((srvc: any) => (
                    <MenuItem key={srvc?.id} value={srvc?.id}>
                      {srvc?.serviceConnectionType1}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={6}>
                <Grid item xs={12} sx={{ lineHeight: 3, ml: 2 }}>
                  <Typography component="span" variant="subtitle1">
                    Service Type
                  </Typography>

                  <Select
                    fullWidth
                    labelId="select-service-type-label"
                    id="service-type"
                    value={serviceType || ''}
                    size="small"
                    disabled={isEdit || isView}
                    onChange={({ target }) => handleServiceType(target?.value)}
                    required
                  >
                    {dataServices?.map((srvc: DataService) => (
                      <MenuItem key={srvc?.id} value={srvc?.id?.toString()}>
                        <ServiceNameWithIcon serviceName={srvc?.serviceName} />
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        {serviceType && (
          <Grid item xs={12} sx={{ lineHeight: 3 }}>
            <Typography component="span" variant="subtitle1">
              Service Configuaration
            </Typography>

            <CodeMirror
              {...options}
              editable={!isView || isEdit}
              value={serviceConfig}
              basicSetup={options}
              onChange={(value: any) => handleEditorChange(value)}
              extensions={[EditorView.lineWrapping, json(), lintGutter()]}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default AddServiceConnection
