type Routes = {
  DataPods: string
  AccountProfile: string
  AnalyticalsGroup: string
  AnalyticsMeasure: string
  Home: string
  AddDatapod: string
  DatapodLayout: string
  DatapodDetails: string
  AddSubjectAreaPopup: string
  DatapodsList: string
  AccountHome: string
  AccountLayout: string
  AddEmail: string
  AddAdditionalDetails: string
  AddPersonalDetails: string
  DataProfiling: string
  DataProfilingViewResults: string
  Overview: string
  DataModeling: string
  DataEngineering: string
  SourceToTargetMapping: string
  DataEngineeringPipelineSchedules: string
  DataEngineeringPipelineMonitoring: string
  DataLakeResults: string
}

export const ROUTES = {
  DataModeling: '/dataModeling',
  DataProfilingViewResults: '/dataProfiling/:dataPodId/results/:dataSystemID',
  DataMappingResults: '/dataMapping/:dataPodId/mapping/:dataSystemID',
  DataMappingAttribues: '/dataMapping/:dataPodId/mapping/:dataSystemID/:entityID/:tableID',
  DataMappingCustomAttribue: '/dataMapping/:dataPodId/mapping/:dataSystemID/:entityID/:tableID/customAttribute',
  DataModelingResults: '/dataModeling/:dataPodId/modeling',
  DataModelingEnterpriseModels: '/dataModeling/:dataPodId/enterpriseModeling',
  DataModelingEnterpriseEnrichedTables: '/dataModeling/:dataPodId/enterpriseModeling/enrichedTables',
  CustomEnrichedModel: '/dataModeling/:dataPodId/enterpriseModeling/customEnrichedModel',
  EditCustomEnrichedModel: '/dataModeling/:dataPodId/enterpriseModeling/customEnrichedModel/:customEntityId',
  SourceToTargetMapping: '/generativeIntegrations/:dataPodId/sourceToTarget',
  DataModelingEntityRelationships: '/dataModeling/:dataPodId/entityRelationships',
  DataModelingEntityReconciliation: '/dataModeling/:dataPodId/entityReconciliation',
  DataModelingCanonicalModel: '/dataModeling/:dataPodId/canonicalModel',
  DataModelingCanonicalModelAttributeDetails: '/dataModeling/:dataPodId/canonicalModel/:dataAttributeID',
  DataModelingCanonicalModelAddAttribute: '/dataModeling/:dataPodId/canonicalModel/:entityID',
  DataModelingCanonicalModelEditCustomAttribute: '/dataModeling/:dataPodId/canonicalModel/:entityID/:dataAttributeID',
  DataModelingCanonicalModelAddDataQuality: '/dataModeling/:dataPodId/canonicalModel/dataquality/add/:entityID',
  DataModelingCanonicalModelEditDataQuality:
    '/dataModeling/:dataPodId/canonicalModel/dataquality/edit/:entityID/:dataQualtiyID',
  DataModelingAllTransformers: '/dataModeling/:dataPodId/transformers',
  DataProfiling: '/dataProfiling',
  DataMapping: '/dataMapping',
  AccountLayout: '/account/addAccount',
  Account: '/account',
  DatapodsList: 'datapodsList',
  Overview: '/overview/:dataPodId',
  overviewSession: '/overview',
  AddSubjectAreaPopup: 'addSubjectAreaPopup',
  DatapodDetails: '/datapodDetails',
  DatapodLayout: '/datapodLayout',
  AddDataPod: '/addDatapod',
  DataPods: '/',
  AccountProfile: '/accountProfile',
  SystemAnaylsis: '/systemanalysis',
  SytemProfiling: '/systemprofiling',
  DataSystemProfiling: '/systemProfiling/:dataPodId',
  DataEngineering: '/dataengineering',
  DataEngineeringResults: '/dataengineering/:dataPodId',
  DataEngineeringPipelineSchedules: '/dataengineering/pipelineschedules/:dataPodId',
  DataEngineeringManageSchedules: '/dataengineering/manageschedules/:dataPodId',
  DataEngineeringPipelineMonitoring: '/dataengineering/pipelinemonitoring/:dataPodId',
  DataLakeHome: '/datalakes',
  DataLakeResults: '/datalakes/:dataPodId',
  DataSourceModels: '/systemProfiling/:dataPodId/sourceDataModels/:datasystemIDs',
  Milestoneplan: '/milestoneplan',
  Documentation: '/documentation',
  Settings: '/settings',
  ListServiceConnection: '/settings/serviceconnection',
  ManageConfiguration: '/settings/configuration/:configTypeId',
  ManageRoles: '/settings/manageroles',
  DataAnalyticsHome: '/dataanalytics',
  DataAnalyticsGroupResults: '/dataanalytics/analyticsgroupresults/:dataPodId',
  DataAnalyticsMeasuresResults: '/dataanalytics/analyticsmeasuresresults/:dataPodId',
  AddDataAnalyticsMeasuresResults: '/dataanalytics/addanalyticsmeasure/:dataPodId',
  Home: '/',
  DataPodContextRoute: '/datapodContextRoute',
  SignUpHome: '/signup'
}
