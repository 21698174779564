import { useState, useCallback, useEffect } from 'react'
import ReactFlow, {
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  Node,
  Edge,
  OnNodesChange,
  OnEdgesChange,
  MarkerType,
  Controls,
  Background,
  MiniMap,
  ControlButton,
  useStoreApi,
} from 'reactflow'
import 'reactflow/dist/style.css'
import '../../../ReactFlow/reactFlow.css'

import BiDirectionalNode from '../../../ReactFlow/BidirectionalNode'
import CustomNode from '../../../ReactFlow/CustomNode'
import SelectionNode from '../../../ReactFlow/SelectionNode'
import DownloadButton from '../../../ReactFlow/DownloadButton'
import { useParams } from 'react-router-dom'
import { useUpdateEntityRecon } from '../../../hooks/dataAttrbutesHooks'
import { EntityReconData } from '../../../businessObjects'
import CustomNodeEditor from '../../../ReactFlow/CustomNodeEditor'
import { useUpdateEntity } from '../../../hooks/entityHooks'
type UpdatedNode = Node & {
  ref: string
}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
}
type ParentChildProps = {
  reconData: any;
  onNodeClicked: (nodeData: Node) => void | Promise<void>;
  refetchRecon: () => void | Promise<void>
};
const nodeTypes = {
  customNode: CustomNode,
  bidirectional: BiDirectionalNode,
  SelectionNode: SelectionNode,
  NodeEditor:CustomNodeEditor
}
const panOnDrag = [1, 2]
// const MIN_DISTANCE = 150
// function createParentChildHashMap(data: any) {
//   const entityMap: any = {}

//   for (const entity of data) {
//     const parent = entity.parent
//     if (entityMap[parent]) {
//       entityMap[parent].push(entity)
//     } else {
//       entityMap[parent] = [entity]
//     }
//   }
//   return entityMap
// }
// const nodeEditorSubmit=(updatedCoreEntityBody:any)=>{
//   console.log("Inside the node editor submit")
//   console.log(updatedCoreEntityBody)
// }

// function createNodesFromJsonData(data: any) {
//   const keysArray: string[] = Object.keys(data)
//   const nodes: Node[] = []
//   const numColumns = 4
//   const numRows = Math.ceil(data.length / numColumns)
//   // console.log({data})
//   keysArray.forEach((parentEntity: any, index: any) => {
//     const groupId = `group-${index}`
//     const rowIndex = Math.floor(index / numColumns)
//     const colIndex = index % numColumns
//     const groupWidth = 330
//     const horizontalSpacing = 170
//     const columnLength = data[parentEntity].length
//     let subNodeHeight = 50
//     let groupHeight = (columnLength + 2) * subNodeHeight + 10
//     const verticalSpacing = groupHeight + 500
//     const groupPosition = {
//       x: 800 + (groupWidth + horizontalSpacing) * colIndex + 40,
//       y: 900 + verticalSpacing * rowIndex,
//     }

//     const groupNode: Node = {
//       id: groupId,
//       type: 'group',
//       data: { label: parentEntity },
//       position: groupPosition,
//       style: {
//         width: '300px',
//         height: groupHeight,
//         WebkitBoxShadow:
//           '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
//         backgroundColor: '#F2F2F2',
//         borderWidth: '0px',
//         boxShadow: '3',
//         borderRadius: '3',
//         padding: '16px',
//         overflow: 'hidden',
//       },
//     }
//     nodes.push(groupNode)
//     const customNodeId = `custom-node-${index}`
//     const customNodePosition = { x: 0, y: 0 }
//     const customNode: Node = {
//       id: customNodeId,
//       type: 'customNode',
//       data: { entityName: parentEntity, coreEntityId:data[parentEntity][0].dataCoreEntityId,submitData:nodeEditorSubmit},
//       draggable: false,
//       extent: 'parent',
//       parentNode: groupId,
//       position: customNodePosition,
//       style: {
//         backgroundColor: '#316896',
//         width: 300,
//         color: 'white',
//         padding: '16px',
//         alignItems: 'center',
//         fontFamily: 'Open Sans',
//         fontSize: '16px',
//         lineHeight: '19.07px',
//         fontWeight: 500,
//         margin: 0,
//       },
//     }
//     nodes.push(customNode)

//     data[parentEntity].forEach((childEntity: any, subIndex: any) => {
//       const subNodeId = `${groupId}-${subIndex}`
//       const subNodeLabel = childEntity.child
//       const subNodePosition = { x: 2, y: 60 + 50 * subIndex }
//       const subNode: UpdatedNode = {
//         id: subNodeId,
//         data: { label: subNodeLabel },
//         ref: childEntity.child,
//         position: subNodePosition,
//         draggable: true,
//         type: 'bidirectional',
//         parentNode: groupId,
//         style: {
//           width: 295,
//           height: subNodeHeight + 'px',
//           backgroundColor: '#F7FAFC',
//           color: '#202020',
//           fontFamily: 'Open Sans',
//           fontSize: '12px',
//           lineHeight: '19.07px',
//           fontWeight: 500,
//           cursor: 'pointer',
//         },
//       }
//       nodes.push(subNode)
//     })
//     const customNodeButtonId = `custom-node-button${index}`
//     const buttonPosition = (columnLength + 1) * 50
//     const customNodeButtonPosition = { x: 3, y: buttonPosition }
//     const childEntities = data[parentEntity]
//     const customNodeButton: Node = {
//       id: customNodeButtonId,
//       type: 'SelectionNode',
//       data: { exclude: childEntities, parentEntity: parentEntity, parentId: data[parentEntity][0].dataCoreEntityId },
//       draggable: false,
//       extent: 'parent',
//       parentNode: groupId,
//       position: customNodeButtonPosition,
//       style: {
//         width: 295,
//         height: subNodeHeight + 'px',
//         backgroundColor: '#F7FAFC',
//         color: '#202020',
//         fontFamily: 'Open Sans',
//         fontSize: '12px',
//         lineHeight: '19.07px',
//         fontWeight: 500,
//         cursor: 'pointer',
//       },
//     }
//     nodes.push(customNodeButton)
//   })

//   return nodes
// }
// function changeParentNode(source: any, parentChildHashMap: any) {
//   let currentParent = null
//   let traversingElement = null
//   for (const parentNode in parentChildHashMap) {
//     const childNodes = parentChildHashMap[parentNode]
//     const index = childNodes.findIndex((element: any) => element.child === source.data.label)
//     if (index !== -1 && !traversingElement) {
//       currentParent = parentNode
//       traversingElement = childNodes[index]
//       childNodes.splice(index, 1)
//       parentChildHashMap[parentNode] = childNodes
//       return traversingElement
//     }
//   }
// }

const ParentChildComponent = ({ reconData, onNodeClicked, refetchRecon }: ParentChildProps) => {
  const { dataPodId } = useParams<ViewResultsParams>()
  const [parentChildHashMap, setParentChildHashMap] = useState<any[]>([])
  const [Load, setLoad] = useState<boolean>(false)
  const [nodes, setNodes] = useState<Node[]>([])
  const [dragStartEvent, setDragStartEvent] = useState<any>()
  let [customNodeClickCount, setCustomNodeClickCount] = useState<number>(0)
  const { updateEntityFunc } = useUpdateEntity()

  function createParentChildHashMap(data: any) {
    const entityMap: any = {}
  
    for (const entity of data) {
      const parent = entity.parent
      if (entityMap[parent]) {
        entityMap[parent].push(entity)
      } else {
        entityMap[parent] = [entity]
      }
    }
    return entityMap
  }
  // const nodeEditorSubmit=(updatedCoreEntityBody:any)=>{
  //   console.log("Inside the node editor submit")
  //   console.log(updatedCoreEntityBody)
  // }
  
  function createNodesFromJsonData(data: any) {
    const keysArray: string[] = Object.keys(data)
    const nodes: Node[] = []
    const numColumns = 4
    const numRows = Math.ceil(data.length / numColumns)
    // console.log({data})
    keysArray.forEach((parentEntity: any, index: any) => {
      const groupId = `group-${index}`
      const rowIndex = Math.floor(index / numColumns)
      const colIndex = index % numColumns
      const groupWidth = 330
      const horizontalSpacing = 170
      const columnLength = data[parentEntity].length
      let subNodeHeight = 50
      let groupHeight = (columnLength + 2) * subNodeHeight + 10
      const verticalSpacing = groupHeight + 500
      const groupPosition = {
        x: 800 + (groupWidth + horizontalSpacing) * colIndex + 40,
        y: 900 + verticalSpacing * rowIndex,
      }
  
      const groupNode: Node = {
        id: groupId,
        type: 'group',
        data: { label: parentEntity },
        position: groupPosition,
        style: {
          width: '300px',
          height: groupHeight,
          WebkitBoxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
          backgroundColor: '#F2F2F2',
          borderWidth: '0px',
          boxShadow: '3',
          borderRadius: '3',
          padding: '16px',
          overflow: 'hidden',
        },
      }
      nodes.push(groupNode)
      const customNodeId = `custom-node-${index}`
      const customNodePosition = { x: 0, y: 0 }
      const customNode: Node = {
        id: customNodeId,
        type: 'customNode',
        data: { entityName: parentEntity, coreEntityId:data[parentEntity][0].dataCoreEntityId,submitData:submitData},
        draggable: false,
        extent: 'parent',
        parentNode: groupId,
        position: customNodePosition,
        style: {
          backgroundColor: '#316896',
          width: 300,
          color: 'white',
          padding: '16px',
          alignItems: 'center',
          fontFamily: 'Open Sans',
          fontSize: '16px',
          lineHeight: '19.07px',
          fontWeight: 500,
          margin: 0,
        },
      }
      nodes.push(customNode)
  
      data[parentEntity].forEach((childEntity: any, subIndex: any) => {
        const subNodeId = `${groupId}-${subIndex}`
        const subNodeLabel = childEntity.child
        const subNodePosition = { x: 2, y: 60 + 50 * subIndex }
        const subNode: UpdatedNode = {
          id: subNodeId,
          data: { label: subNodeLabel },
          ref: childEntity.child,
          position: subNodePosition,
          draggable: true,
          type: 'bidirectional',
          parentNode: groupId,
          style: {
            width: 295,
            height: subNodeHeight + 'px',
            backgroundColor: '#F7FAFC',
            color: '#202020',
            fontFamily: 'Open Sans',
            fontSize: '12px',
            lineHeight: '19.07px',
            fontWeight: 500,
            cursor: 'pointer',
          },
        }
        nodes.push(subNode)
      })
      const customNodeButtonId = `custom-node-button${index}`
      const buttonPosition = (columnLength + 1) * 50
      const customNodeButtonPosition = { x: 3, y: buttonPosition }
      const childEntities = data[parentEntity]
      const customNodeButton: Node = {
        id: customNodeButtonId,
        type: 'SelectionNode',
        data: { exclude: childEntities, parentEntity: parentEntity, parentId: data[parentEntity][0].dataCoreEntityId },
        draggable: false,
        extent: 'parent',
        parentNode: groupId,
        position: customNodeButtonPosition,
        style: {
          width: 295,
          height: subNodeHeight + 'px',
          backgroundColor: '#F7FAFC',
          color: '#202020',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          lineHeight: '19.07px',
          fontWeight: 500,
          cursor: 'pointer',
        },
      }
      nodes.push(customNodeButton)
    })
  
    return nodes
  }
  function changeParentNode(source: any, parentChildHashMap: any) {
    let currentParent = null
    let traversingElement = null
    for (const parentNode in parentChildHashMap) {
      const childNodes = parentChildHashMap[parentNode]
      const index = childNodes.findIndex((element: any) => element.child === source.data.label)
      if (index !== -1 && !traversingElement) {
        currentParent = parentNode
        traversingElement = childNodes[index]
        childNodes.splice(index, 1)
        parentChildHashMap[parentNode] = childNodes
        return traversingElement
      }
    }
  }
  

  const submitData = async(updatedCoreEntityBody:any) =>{
    // console.log("Inside submit data")
    if(updatedCoreEntityBody){
        // console.log(updatedCoreEntityBody)
        const response = await updateEntityFunc(updatedCoreEntityBody)
        if(response && response.id){
          // console.log("Edited")
          await refetchRecon()
        }
    }
  }
  const [updateEntityRecon, updatedReconData] = useUpdateEntityRecon(dataPodId)
  const getClosestGroupNode = (draggedNode: any, nodes: any) => {
    let closestGroupNode = null
    let closestDistance = Number.MAX_VALUE
    for (const node of nodes) {
      if (node.type === 'group') {
        const dx = node.position.x - draggedNode.positionAbsolute.x
        const dy = node.position.y - draggedNode.positionAbsolute.y
        const d = Math.sqrt(dx * dx + dy * dy)
        if (d < closestDistance && node.id != draggedNode.parentNode) {
          closestDistance = d
          closestGroupNode = node
        }
      }
    }
    return closestGroupNode
  }
  useEffect(() => {
    if (reconData.length) {
      const hashMap = createParentChildHashMap(reconData)
      setParentChildHashMap(hashMap)
      const nodes = createNodesFromJsonData(parentChildHashMap)
      if (nodes.length) {
        // console.log(nodes)
        setNodes(nodes)
      }
    }
  }, [reconData.length, reconData])
  const onNodesChange: OnNodesChange = useCallback(
    (changes: any) => setNodes((nodes) => applyNodeChanges(changes, nodes)),
    [setNodes],
  )
  const onNodeDragStop = async (event: any, node: any) => {
    const flag = checkEventPositions(event)
    const closestGroupNode = await getClosestGroupNode(node, nodes)
    if (closestGroupNode && flag) {
      const traversingElement = changeParentNode(node, parentChildHashMap)
      if (traversingElement) {
        const destination = closestGroupNode.data.label
        const children = parentChildHashMap[destination]
        traversingElement.parent = destination
        const coreEntityId = children[0].dataCoreEntityId
        children.push(traversingElement)
        parentChildHashMap[destination] = children
        const params = {
          id: traversingElement.id,
          dataCoreEntityId: coreEntityId,
          dataSystemEntityId: traversingElement.dataSystemEntityId,
        }
        await updateEntityRecon(params)
        setParentChildHashMap(parentChildHashMap)
        const nodes = createNodesFromJsonData(parentChildHashMap)
        if (nodes.length) {
          setNodes(nodes)
        }
      }
    }
  }
  const onNodeDragStart = async (event: any, node: any) => {
    setDragStartEvent(event)
  }
  const checkEventPositions = (event: any) => {
    if (event && dragStartEvent && event.screenX != dragStartEvent.screenX) {
      return true
    } else {
      return false
    }
  }
  // const testProps = (test:any) =>{
  //   console.log("Inside test props");
  // }
  const handleNodeClick = (event: any, node: Node) => {
    if(node?.type ==='customNode'){
      customNodeClickCount+=1
      setCustomNodeClickCount(customNodeClickCount)
      if(customNodeClickCount>=3){
        let editingNode:Node = node
        // console.log({editingNode})
        editingNode.type='NodeEditor'
        editingNode.style = {...editingNode.style, height:'70px'}
        setNodes([...nodes,editingNode])
        setCustomNodeClickCount(0)
      }
    }
    onNodeClicked(node);
  }
  useEffect(() => {
    const nodes = createNodesFromJsonData(parentChildHashMap)
    if (nodes.length) {
      setNodes(nodes)
    }
  }, [parentChildHashMap])
  return (
    <div style={{ width: '100vw', height: '3000%' }} className="reactComponentMount">
      <ReactFlow
        nodes={nodes}
        onNodesChange={onNodesChange}
        onNodeDragStart={onNodeDragStart}
        onNodeDragStop={onNodeDragStop}
        fitView
        minZoom={0.099}
        panOnScroll
        selectionOnDrag
        panOnDrag={panOnDrag}
        nodeTypes={nodeTypes}
        snapToGrid={true}
        attributionPosition="top-right"
        onNodeClick={handleNodeClick}
      >
        <Controls position="top-right">
          <ControlButton>
            <DownloadButton />
          </ControlButton>
        </Controls>
        <MiniMap
          zoomable
          pannable
          position="top-right"
          style={{ height: 100, top: 0, backgroundColor: '#2E92DA' }}
          title={'Mapping Mini Map'}
        />
        <Background />
      </ReactFlow>
    </div>
  )
}
export default ParentChildComponent
