

import { JobRequestParams } from '../businessObjects'
import { JobName } from '../hooks/constants'
import { sendRequest } from '../requestManager/sendRequest'

const PROFILING_PATH = {
  getProfile: 'Application/getProfileInput',
  getProfileEntity: 'Application/getProfileEntityOutput',
  getProfileAttribute: 'Application/getProfileAttributeOutput',
  runJob: 'Application/RunJob',
  getJobStatus: 'Application/JobStatus',
  getEntityRelationships: 'Application/getEntityRelationShips',
  getColumnByDataSystemID: 'Application/getColumnByDataSystemID',
  executeDynamicSQLQuery: 'Application/DynamicSQLExecutor',
}

const getProfileInp = async (
  token: string,
  dataPodId: string,
  subjectAreaID?: number,
  dataSystemID?: number,
  recordsPerPage?: number,
  pageNumber?: number,
) => {
 
  let profileUrl = PROFILING_PATH.getProfile + `?dataPodId=${dataPodId}`
  if (subjectAreaID) {
    profileUrl += `&subjectAreaID=${subjectAreaID}`
  }
  if (dataSystemID) {
    profileUrl += `&dataSystemID=${dataSystemID}`
  }
  if (recordsPerPage && pageNumber) {
    profileUrl += `&RecordsPerPage=${recordsPerPage}&PageNumber=${pageNumber}`
  }
  return sendRequest(profileUrl, 'GET', token)
}

const getProfileEntityOp = async (
  token: string,
  dataPodId: string,
  subjectAreaID?: number,
  dataSystemID?: number,

  recordsPerPage?: number,
  pageNumber?: number,
  searchText?: string,
) => {
  
  let profileEntityUrl = PROFILING_PATH.getProfileEntity + `?dataPodId=${dataPodId}`
  if (subjectAreaID) {
    profileEntityUrl += `&subjectAreaID=${subjectAreaID}`
  }
  if (searchText) {
    profileEntityUrl += `&searchText=${searchText.trim()}`
  }
  if (dataSystemID) {
    profileEntityUrl += `&dataSystemID=${dataSystemID}`
  }
  if (recordsPerPage && pageNumber) {
    profileEntityUrl += `&RecordsPerPage=${recordsPerPage}&PageNumber=${pageNumber}`
  }
  return sendRequest(profileEntityUrl, 'GET', token)
}

const getProfileAttributes = async (
  token: string,
  dataPodId: string,
  subjectAreaID?: number,
  dataSystemID?: number,
  dataTableID?: number,
  dataEntityID?: number,
  searchText?: string,
  recordsPerPage?: number,
  pageNumber?: number,
) => {
  
  let profileAttributeUrl = PROFILING_PATH.getProfileAttribute + `?dataPodId=${dataPodId}`
  if (dataSystemID) {
    profileAttributeUrl += `&dataSystemID=${dataSystemID}`
  }
  if (dataEntityID) {
    profileAttributeUrl += `&dataEntityID=${dataEntityID}`
  }
  if (searchText) {
    profileAttributeUrl += `&searchText=${searchText}`
  }
  if (subjectAreaID) {
    profileAttributeUrl += `&subjectAreaID=${subjectAreaID}`
  }

  if (dataTableID) {
    profileAttributeUrl += `&dataTableID=${dataTableID}`
  }
  if (recordsPerPage && pageNumber) {
    profileAttributeUrl += `&RecordsPerPage=${recordsPerPage}&PageNumber=${pageNumber}`
  }
  //return await axios.get(profileAttributeUrl, config)
  return sendRequest(profileAttributeUrl, 'GET', token)
}

const runBackendJob = async (token: string, jobName: JobName, params: JobRequestParams) => {
  //const config = getAxiosRequestConfig(token)
  const runJobUrl = PROFILING_PATH.runJob + `?jobName=${jobName}&JobToRun=${jobName}`

  return sendRequest(runJobUrl, 'POST', token, params)
}
const getJobStatus = async (token: string, jobName: JobName, params: JobRequestParams) => {
  const { dataPodId, dataSystemId, industryId, subjectAreaId, dataTableId, entityId } = params
 
  let status_URL =  PROFILING_PATH.getJobStatus + `?jobName=${jobName}`
  if (dataPodId) {
    status_URL += `&dataPodID=${dataPodId}`
  }
  if (subjectAreaId) {
    status_URL += `&subjectAreaID=${subjectAreaId}`
  }
  if (dataSystemId) {
    status_URL += `&dataSystemID=${dataSystemId}`
  }
  if (entityId) {
    status_URL += `&dataEntityID=${entityId}`
  }
  if (dataTableId) {
    status_URL += `&dataTableID=${dataTableId}`
  }
  if (industryId) {
    status_URL += `&industryId=${industryId}`
  }

  //return await axios.get(status_URL, config)
  return sendRequest(status_URL, 'GET', token)
}

export const applicationProfileServices = {
  getProfileInp,
  getProfileEntityOp,
  getProfileAttributes,
  runBackendJob,
  getJobStatus,
}
