import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { LucidAccount } from '../../businessObjects'

type Props = {
  userAccount?: LucidAccount
  goToStep?: (step: number) => void
}

export const Confirmation = ({ userAccount, goToStep }: Props) => {
  return (
    <Box sx={{ ml: '2rem', mt: '3rem', mr: '2rem' }}>
      <Grid container spacing={2}>
        <Grid item container alignItems={'center'} spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h3" color="primary">
              Email
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={9}>
          <Grid item container spacing={2}>
            <Grid item container xs={6}>
              <Grid item xs={8}>
                <Typography variant="h5">Email</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{userAccount?.primaryContactEmail}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={16} />
            <Grid item xs={4}>
              <Typography variant="h3" color="primary">
                Additional Details
              </Typography>
            </Grid>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={4}>
                <Typography variant="h5">Name</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h5">Tenant ID</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h5">Agreement Status</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>{userAccount?.accountName}</Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography>{userAccount?.accountTenantId}</Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography>{userAccount?.agreementStatus}</Typography>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xs={4}>
                <Typography variant="h5">Description</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>{userAccount?.accountDescription}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={9} container spacing={1}>
          <Grid item xs={4}>
            <Typography variant="h3" color="primary">
              Personal Details
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={4}>
              <Typography variant="h5">First Name</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5">Last Name</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5">Phone Number</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>{userAccount?.primaryContactFirstName}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography>{userAccount?.primaryContactLastName}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography>{userAccount?.primaryContactPhone}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
