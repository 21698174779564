import { useState } from "react"
import { useLucidAuthContext } from "../LucidAuthContext/LucidAuthContext"
import { databricksServices } from "../apiServices/databricks"
import { CreateJobParams, CreateJobResponse, NotebookParams, RunIdStatus, RunNewJobParams, RunStatusResponse } from "../businessObjects"


export const useGetDatabricksRunStatus = (): {fetchRunStatus: (runIdStatus: RunIdStatus[]) => Promise<RunStatusResponse[] | undefined>} => {
    const { getBearerToken } = useLucidAuthContext()

    const fetchRunStatus = async (runIdStatus: RunIdStatus[]): Promise<RunStatusResponse[] | undefined> => {
      try {
        const bearerToken = await getBearerToken()
        if (bearerToken) {
            const { data, status } = await databricksServices.getRunStatus(bearerToken, runIdStatus)
            if (status == 200) {
                return data;
            }
          }
        } catch (error) {
            console.log("Error in getting Job Status", error);
        }
    }
    return {fetchRunStatus}
  }

export const usePostDatabricksJobRunNew = (): {postRunNewJob: (params: RunNewJobParams) => Promise<number | undefined>} => {
    const { getBearerToken } = useLucidAuthContext()

    const postRunNewJob = async (params: RunNewJobParams): Promise<number | undefined> => {
      try {
        const bearerToken = await getBearerToken()
        if (bearerToken) {
            const { data, status } = await databricksServices.postRunNewJob(bearerToken, params)
            if (status == 200) {
                return data;
            }
          }
        } catch (error) {
            console.log("Error in getting Job Status", error);
        }
    }
    return {postRunNewJob}
  }

export const usePostCreateDatabricksJob = (): {postCreateDatabricksJob: (params: CreateJobParams) => Promise<CreateJobResponse | undefined>} => {
    const { getBearerToken } = useLucidAuthContext()

    const postCreateDatabricksJob = async (params: CreateJobParams): Promise<CreateJobResponse| undefined> => {
      try {
        const bearerToken = await getBearerToken()
        if (bearerToken) {
            const { data, status } = await databricksServices.postCreateJob(bearerToken, params)
            if (status == 200) {
                return data;
            }
          }
        } catch (error) {
            console.log("Error in getting Job Status", error);
        }
    }
    return {postCreateDatabricksJob}
  }