import { useEffect, useState } from 'react'
import {
  DataColumnMapLucidMeansV2,
  DataSourceColumnSelectedTargetsMapV2,
  DataSourceToTargetAtrributeMap,
  SourceToTargetMapRanking,
  addDataTargetToSourceColumnMapV2body,
  getDataSourceToTargetColumnMapV2,
  updateDataTargetToSourceColumnMapV2body,
} from '../businessObjects'
import { generativeIntegartionServices } from '../apiServices/generativeIntegrations'
import { useLucidAuthContext } from '../LucidAuthContext/LucidAuthContext'

// type dataPodId = string | undefined
// type Id = number | undefined
// type SourceEntityId = number | undefined
// type TargetEntityId = number | undefined
// type SourceAttributeId = number | undefined
// type TargetAttributeId = number | undefined
// type sourceTableID = number | undefined
// type targetTableID = number | undefined
// type sourceDatasystemID = number | undefined
// type targetDataSystemID = number | undefined

type GetDownloadData = (datapodId: string | undefined) => Promise<void>
type GetDataSourceColumnSelectedTargetsMapV2 = (
  datapodId: string | undefined,
  dataTableId?: number | undefined,
  sourceColumnId?: number | undefined,
  targetColumnId?: number | undefined,
) => Promise<DataSourceColumnSelectedTargetsMapV2[] | undefined>
type GetSoureToTargetColumnMapV2 = (
  datapodId: string | undefined,
  Id?: number | undefined,
  sourceColumnId?: number | undefined,
  targetColumnId?: number | undefined,
) => Promise<void>

type GetDataColumnMapLucidMeansV2 = (
  datapodId: string | undefined,
  sourceColumnId?: number | undefined,
  targetColumnId?: number | undefined,
) => Promise<void>
type AddNewTargetToSourceColumn = (
  datapodId: string,
  sourceColumnId: number,
  targetColumnId: number,
) => Promise<getDataSourceToTargetColumnMapV2 | undefined>
type UpdateNewTargetToSourceColumn = (
  datapodId: string,
  body: updateDataTargetToSourceColumnMapV2body,
) => Promise<boolean | undefined>

export const useGetDataSourceToTargetAttributeMap = (
  datapodId?: string,
  Id?: number,
  SourceEntityId?: number,
  TargetEntityId?: number,
  SourceAttributeId?: number,
  TargetAttributeId?: number,
  sourceTableID?: number,
  targetTableID?: number,
  sourceDatasystemID?: number,
  targetDataSystemID?: number,
): {
  dataSourceToTargetAttributeMap: DataSourceToTargetAtrributeMap[]
  refreshDataSourceToTargetAttributeMap: () => void
} => {
  const [dataSourceToTargetAttributeMap, setDataSourceToTargetAttributeMap] = useState<
    DataSourceToTargetAtrributeMap[]
  >([])
  const { getBearerToken } = useLucidAuthContext()

  const fetchDataSourceToTargetAttributeMap = async () => {
    try {
      const token = await getBearerToken()
      if (token && datapodId && (SourceEntityId || TargetEntityId || sourceTableID || targetTableID)) {
        const { data, status } = await generativeIntegartionServices.getDataSourceToTargetAttributeMap(
          token,
          datapodId,
          Id,
          SourceEntityId,
          TargetEntityId,
          SourceAttributeId,
          TargetAttributeId,
          sourceTableID,
          targetTableID,
          sourceDatasystemID,
          targetDataSystemID,
        )
        if (status == 200) {
          setDataSourceToTargetAttributeMap(data)
        }
      }
    } catch (error) {
      console.log({ error })
    }
  }

  useEffect(() => {
    fetchDataSourceToTargetAttributeMap()
  }, [
    datapodId,
    Id,
    SourceEntityId,
    TargetEntityId,
    SourceAttributeId,
    TargetAttributeId,
    sourceTableID,
    targetTableID,
    sourceDatasystemID,
    targetDataSystemID,
  ])

  return {
    dataSourceToTargetAttributeMap: dataSourceToTargetAttributeMap,
    refreshDataSourceToTargetAttributeMap: fetchDataSourceToTargetAttributeMap,
  }
}
export const useDownloadSourceToTargetMapRanking = (): [GetDownloadData, SourceToTargetMapRanking[] | undefined] => {
  const [sourceToTargetMapRankingJson, setSourceToTargetMapRankingJson] = useState<SourceToTargetMapRanking[]>()
  const { getBearerToken, accountId, tenantId } = useLucidAuthContext()
  const getDownloadData = async (datapodId: string | undefined) => {
    try {
      const token = await getBearerToken()
      if (token && datapodId) {
        const { data, status } = await generativeIntegartionServices.downloadSourceToTargetMapRanking(token, datapodId)
        if (status == 200) {
          setSourceToTargetMapRankingJson(data)
        }
      }
    } catch (error) {
      console.log({ error })
    }
  }
  return [getDownloadData, sourceToTargetMapRankingJson]
}
export const useGetDataSourceColumnSelectedTargetsMapV2 = (): [
  GetDataSourceColumnSelectedTargetsMapV2,
  // DataSourceColumnSelectedTargetsMapV2[] | undefined,
] => {
  const [DataSourceColumnSelectedTargetsMapV2, setDataSourceColumnSelectedTargetsMapV2] =
    useState<DataSourceColumnSelectedTargetsMapV2[]>()
  const { getBearerToken, accountId, tenantId } = useLucidAuthContext()
  const getDataSourceColumnSelectedTargetsMapV2 = async (
    datapodId: string | undefined,
    dataTableId?: number | undefined,
    sourceColumnId?: number | undefined,
    targetColumnId?: number | undefined,
  ) => {
    try {
      const token = await getBearerToken()
      if (token && datapodId) {
        if (dataTableId || sourceColumnId || targetColumnId) {
          const { data, status } = await generativeIntegartionServices.getDataSourceColumnSelectedTargetsMapV2(
            token,
            datapodId,
            dataTableId,
            sourceColumnId,
            targetColumnId,
          )
          if (status == 200) {
            // setDataSourceColumnSelectedTargetsMapV2(data)
            return data as DataSourceColumnSelectedTargetsMapV2[]
          }
        } else {
          const { data, status } = await generativeIntegartionServices.getDataSourceColumnSelectedTargetsMapV2(
            token,
            datapodId,
          )
          if (status == 200) {
            // setDataSourceColumnSelectedTargetsMapV2(data)
            return data as DataSourceColumnSelectedTargetsMapV2[]
          }
        }
      }
    } catch (error) {
      console.log({ error })
    }
  }
  return [getDataSourceColumnSelectedTargetsMapV2]
}
export const useGetDataSourceToTargetColumnMapV2 = (): [
  GetSoureToTargetColumnMapV2,
  getDataSourceToTargetColumnMapV2[] | undefined,
] => {
  const [sourceToTargetDataMapV2, setsourceToTargetDataMapV2] = useState<getDataSourceToTargetColumnMapV2[]>([])
  const { getBearerToken, accountId, tenantId } = useLucidAuthContext()
  const getDataSourceColumnSelectedTargetsMapV2 = async (
    datapodId: string | undefined,
    sourceColumnId?: number | undefined,
    targetColumnId?: number | undefined,
    Id?: number | undefined,
  ) => {
    try {
      const token = await getBearerToken()
      if (token && datapodId && (Id || sourceColumnId || targetColumnId)) {
        const { data, status } = await generativeIntegartionServices.geDataSourceToTargetColumnMapV2(
          token,
          datapodId,
          sourceColumnId,
          targetColumnId,
          Id,
        )
        if (status == 200) {
          setsourceToTargetDataMapV2(data)
        }
      }
    } catch (error) {
      console.log({ error })
    }
  }
  return [getDataSourceColumnSelectedTargetsMapV2, sourceToTargetDataMapV2]
}

export const useGetDataColumnMapLucidMeansV2 = (): [
  GetDataColumnMapLucidMeansV2,
  DataColumnMapLucidMeansV2[] | undefined,
] => {
  const [DataColumnMapLucidMeansV2, setDataColumnMapLucidMeansV2] = useState<DataColumnMapLucidMeansV2[]>()
  const { getBearerToken, accountId, tenantId } = useLucidAuthContext()
  const getDataSourceColumnSelectedTargetsMapV2 = async (
    datapodId: string | undefined,
    sourceColumnId?: number | undefined,
    targetColumnId?: number | undefined,
  ) => {
    try {
      const token = await getBearerToken()
      if (token && datapodId && (sourceColumnId || targetColumnId)) {
        const { data, status } = await generativeIntegartionServices.getDataColumnMapLucidMeansV2(
          token,
          datapodId,
          sourceColumnId,
          targetColumnId,
        )
        if (status == 200) {
          setDataColumnMapLucidMeansV2(data)
        }
      }
    } catch (error) {
      console.log({ error })
    }
  }
  return [getDataSourceColumnSelectedTargetsMapV2, DataColumnMapLucidMeansV2]
}

export const useAddTargetToSourceColumnMapV2 = (): [
  AddNewTargetToSourceColumn,
  // addDataTargetToSourceColumnMapV2response | undefined,
] => {
  // const [newTargetToSourceColumn, setNewTargetToSourceColumn] = useState<getDataSourceToTargetColumnMapV2>()
  const { getBearerToken } = useLucidAuthContext()
  const addTargetToSourceColumnMapV2 = async (datapodId: string, sourceColumnId: number, targetColumnId: number) => {
    try {
      const bearerToken = await getBearerToken()
      const body: addDataTargetToSourceColumnMapV2body = {
        sourceColumnId: sourceColumnId,
        targetColumnId: targetColumnId,
        runId: 0,
        profileName: 'string',
        sourceDataType: 'string',
        targetDataType: 'string',
        lucidMean: 0,
      }
      if (bearerToken) {
        const { data, status } = await generativeIntegartionServices.addDataTargetToSourceColumnMapV2(
          bearerToken,
          datapodId,
          body,
        )
        if (status == 200) {
          return data as getDataSourceToTargetColumnMapV2
        }
      }
    } catch (error) {
      console.log({ error })
    }
  }
  return [addTargetToSourceColumnMapV2]
}
export const useUpdateTargetToSourceColumnMapV2 = (): [
  UpdateNewTargetToSourceColumn,
  // addDataTargetToSourceColumnMapV2response | undefined,
] => {
  // const [newTargetToSourceColumn, setNewTargetToSourceColumn] = useState<addDataTargetToSourceColumnMapV2response>()
  const { getBearerToken } = useLucidAuthContext()
  const updateTargetToSourceColumnMapV2 = async (
    datapodId: string,
    updateBody: updateDataTargetToSourceColumnMapV2body,
  ) => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await generativeIntegartionServices.updateDataTargetToSourceColumnMapV2(
          bearerToken,
          datapodId,
          updateBody,
        )
        if (status == 200) {
          return true
        }
      }
    } catch (error) {
      console.log({ error })
    }
  }
  return [updateTargetToSourceColumnMapV2]
}
