import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
export const downloadFile = ({ data, fileName, fileType }: { data: BlobPart, fileName: string, fileType: string }) => {
  // Create a blob with the data we want to download as a file
  let blob: any
  if (fileType === 'excel') {
    exportToExcel(data, fileName)
  } else {
    blob = new Blob([data], { type: fileType });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  }
};
const exportToExcel = (data: any, fileName: string) => {
  const worksheet = XLSX.utils.json_to_sheet(JSON.parse(data));
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Buffer to store the generated Excel file
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
  saveAs(blob, fileName);
}