import { sendRequest } from '../requestManager/sendRequest'
import {MeasureNotebook, GenerateMeasurePayload, GenerateKPIType, AdvAnalyticsPayload, DimensionData} from '../businessObjects'
import { sendGenApiRequest } from '../requestManager/sendGenApiRequest'

const analyticsMeasurePath = {
    AddAnalyticsMeasure : 'AnalyticsMeasure/AddAnalyticsMeasure',
    UpdateAnalyticsMeasure : 'AnalyticsMeasure/UpdateAnalyticsMeasure',
    deleteAnalyticsMeasure : 'AnalyticsMeasure/deleteAnalyticsMeasure',

    AddAnalyticsMeasureMap : 'AnalyticsMeasure/AddAnalyticsMeasureMap',
    UpdateAnalyticsMeasureMap : 'AnalyticsMeasure/UpdateAnalyticsMeasureMap',

    getAnalyticsMeasure : 'AnalyticsMeasure/getAnalyticsMeasure',
    getMapping : 'AnalyticsMeasure/getMapping',

    AddMeasureNB : 'AnalyticsMeasure/AddMeasureNB',
    UpdateMeasureNB : 'AnalyticsMeasure/UpdateMeasureNB',
    GetMeasureNB : 'AnalyticsMeasure/GetMeasureNB',

    GetMeasureDimensions: 'AnalyticsMeasure/GetMeasureDimensions',
    SynchronizeMeasureDimensions: 'AnalyticsMeasure/SynchronizeMeasureDimensions',


    //Generate KPIs
    GenerateMeasure: 'GenerateMeasures',
    GenerateKPI: 'GenerateKPI',
    GetDataScienceModels: 'AdvanceAnalyticsRecommender',

    //old
    runAnalyticsMeasure: 'AnalyticsMeasure/RunAnalyticsMeasureProfile',
    getAnalyticsMeasureOutput: 'AnalyticsMeasure/GetAnalyticsMeasureOutput',
    getAnalyticsMeasureStatus: 'AnalyticsMeasure/GetAnalyticsMeasureStatus',
    getAllAnalyticsMeasure: 'AnalyticsMeasure/getAnalyticsMeasure',
    getAnalyticsMeasureMapping: 'AnalyticsMeasure/getMapping'
}

const addAnalyticsMeasure = async (token: string, dataPodId: string, body: any) => {
    return sendRequest(analyticsMeasurePath.AddAnalyticsMeasure + '?dataPodId=' + dataPodId, 'POST', token, body)
}

const updateAnalyticsMeasure = async (token: string, dataPodId: string, body: any) => {
    return sendRequest(analyticsMeasurePath.UpdateAnalyticsMeasure + '?dataPodId=' + dataPodId, 'PUT', token, body)
}

const deleteAnalyticsMeasure = async (token: string, analyticsMeasureID: string) => {
    return sendRequest(analyticsMeasurePath.deleteAnalyticsMeasure + '?analyticsMeasureID=' + analyticsMeasureID, 'DELETE', token)
}

const addAnalyticsMeasureMap = async (token: string, dataPodId: string, body: any) => {
    return sendRequest(analyticsMeasurePath.AddAnalyticsMeasureMap + '?dataPodId=' + dataPodId, 'POST', token, body)
}

const updateAnalyticsMeasureMap = async (token: string, dataPodId: string, body: any) => {
    return sendRequest(analyticsMeasurePath.UpdateAnalyticsMeasureMap + '?dataPodId=' + dataPodId, 'PUT', token, body)
}

const getAnalyticsMeasure = async (token: string, dataPodId: string, analyticsGroupID?: number, analyticsMeasureID?: number, MeasureName?: string, RecordsPerPage?: number, PageNumber?: number) => {
    const paginationParams = RecordsPerPage && PageNumber ? `&RecordsPerPage=${RecordsPerPage}&PageNumber=${PageNumber}` : null
    let url = analyticsMeasurePath.getAnalyticsMeasure;
    if (dataPodId) {
        url += `?datapodID=${dataPodId}`;
    } 
    
    if (analyticsMeasureID) {
        url += `&analyticsMeasureID=${analyticsMeasureID}`;
    } 
    
    if (analyticsGroupID) {
        url += `&analyticsGroupID=${analyticsGroupID}`;
    } 
    
    if (MeasureName) {
        url += `&MeasureName=${MeasureName}`;
    }

    if(paginationParams) {
        url + paginationParams
    }

    return sendRequest(url, 'GET', token)
}

const getMapping = async (token: string, dataPodId: string, analyticsMeasureName?: string, dataEntityName?: string, RecordsPerPage?: number, PageNumber?: number) => {
    const paginationParams = RecordsPerPage && PageNumber ? `&RecordsPerPage=${RecordsPerPage}&PageNumber=${PageNumber}` : null
    let url = analyticsMeasurePath.getMapping;
    if (dataPodId) {
        url += `?datapodID=${dataPodId}`;
    } 
    
    if (analyticsMeasureName) {
        url += `&analyticsMeasureName=${analyticsMeasureName}`;
    }
    
    if (dataEntityName) {
        url += `&dataEntityName=${dataEntityName}`;
    } 
    
    if (dataEntityName) {
        url += `&dataEntityName=${dataEntityName}`;
    }
    
    if(paginationParams) {
        url + paginationParams
    }

    return sendRequest(url, 'GET', token)
}

const getMeasureDimensions = async (token: string, dataPodId: string, measureId: number) => {
    return sendRequest(`${analyticsMeasurePath.GetMeasureDimensions}?dataPodId=${dataPodId}&measureId=${measureId}`, 'GET', token)
}

const synchronizeMeasureDimensions = async (token: string, dataPodId: string, measureId: number, body: DimensionData[]) => {
    return sendRequest(`${analyticsMeasurePath.SynchronizeMeasureDimensions}?dataPodId=${dataPodId}&measureId=${measureId}`, 'POST', token, body)
}


//Measure Notebook APIs

const addMeasureNB = async (token: string, dataPodId: string, body: MeasureNotebook) => {
    return sendRequest(analyticsMeasurePath.AddMeasureNB + '?dataPodId=' + dataPodId, 'POST', token, body)
}

const updateMeasureNB = async (token: string, dataPodId: string, body: MeasureNotebook) => {
    return sendRequest(analyticsMeasurePath.UpdateMeasureNB + '?dataPodId=' + dataPodId, 'PUT', token, body)
}

const getMeasureNB = async (token: string, dataPodId: string, analyticsMeasureID: number) => {
    return sendRequest(analyticsMeasurePath.GetMeasureNB + '?dataPodId=' + dataPodId + '&measureID=' + analyticsMeasureID, 'GET', token)
}

//Generate measuer APIS

const generateMeasure = async (body: GenerateMeasurePayload, token?: string) => {
    return sendGenApiRequest(analyticsMeasurePath.GenerateMeasure, 'POST', token ?? 'Lucid123', body)
}

const generateKPI = async (body: GenerateKPIType, token?: string) => {
    return sendGenApiRequest(analyticsMeasurePath.GenerateKPI, 'POST', token ?? 'Lucid123', body)
}

const getDataScienceModels = async (body: AdvAnalyticsPayload, token?: string) => {
    return sendGenApiRequest(analyticsMeasurePath.GetDataScienceModels, 'POST', token ?? 'Lucid123', body)
}

export const analyticsMeasureServices = {
    addAnalyticsMeasure,
    updateAnalyticsMeasure,
    deleteAnalyticsMeasure,
    addAnalyticsMeasureMap,
    updateAnalyticsMeasureMap,
    getAnalyticsMeasure,
    getMapping,
    addMeasureNB,
    updateMeasureNB,
    getMeasureNB,
    generateMeasure,
    generateKPI,
    getDataScienceModels,
    getMeasureDimensions,
    synchronizeMeasureDimensions,
}