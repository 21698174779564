import { useMemo, useEffect, useState, FormEvent } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from '@mui/material'

import { cardContainer } from '../../../styles/globalStyles'

import ActionPage from '../../../ui-components/ActionPage'
import ShadowTable, { ShadowTableAction } from '../../../ui-components/ShadowTable/ShadowTable'

import { Configuration } from '../../../businessObjects'
import { DataTableRow } from '../../../ui-components/DataTable/DataTableTypes'
import {
  useGetConfigurations,
  useAddConfiguration,
  useUpdateConfiguration,
  useDeleteConfiguration,
} from '../../../hooks/lucidConfigurationHooks'
import { DeletePopUp } from '../../DataProcess/DataMapping/DataMappingPopUps'
import ResultStatus from '../../../ui-components/ResultStatus'
import { useParams } from 'react-router-dom'

type ViewResultsParams = {
  configTypeId: string
}

type Props = {}

export const ManageConfiguration = (props: Props) => {
  const { configTypeId } = useParams<ViewResultsParams>()

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [isAdding, setIsAdding] = useState<boolean>(false)

  const [tableRows, setTableRows] = useState<DataTableRow[]>([])
  const [configurations, setConfigurations] = useState<Configuration[]>([])
  const { getConfigurations } = useGetConfigurations()
  const { addConfiguration } = useAddConfiguration()
  const { updateConfiguration } = useUpdateConfiguration()
  const { deleteConfiguration } = useDeleteConfiguration()

  const [selectedConfig, setSelectedConfig] = useState<Configuration>()
  const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false)
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false)

  const [successMessage, setSuccessMessage] = useState<string>()
  const [successState, setSuccessState] = useState<boolean>(false)

  const [errorMessage, setErrorMessage] = useState<string>()
  const [errorState, setErrorState] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = async () => {
      const result = await getConfigurations(Number(configTypeId))
      setConfigurations(result)
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (!errorState) {
      setErrorMessage(undefined)
    } else if (!successState) {
      setSuccessMessage(undefined)
    }
  }, [errorState, successState])

  useEffect(() => {
    const tablerowsData = configurations.map((config) => ({
      id: String(config.id),
      values: [config.key, config.value?.substring(0, 90) + (config.value?.length > 90 ? '...' : ''), 'EDIT', 'DELETE'],
    }))

    setTableRows(tablerowsData)
  }, [configurations])

  const tableColumns = useMemo(() => {
    return [
      {
        label: 'Configuration Name',
        sortableColumn: true,
      },
      {
        label: 'Configuration Value',
      },
      {
        label: 'EDIT',
      },
      {
        label: 'DELETE',
      },
    ]
  }, [])

  const onButtonClick = (rowId: string, actionType: ShadowTableAction) => {
    const configId = Number(rowId)
    const config = configurations.find((config) => config.id == configId)

    if (config) {
      setSelectedConfig({ ...config })
    }

    if (actionType === ShadowTableAction.Editable) {
      setIsAdding(false)
      setOpenDialog(true)
    } else if (actionType === ShadowTableAction.Deletable) {
      setShowDeleteWarning(true)
    }
  }

  const confirmDeleteConfig = async () => {
    if (!selectedConfig) {
      return
    }

    const isDeleted = await deleteConfiguration(selectedConfig.id)

    if (isDeleted) {
      setConfigurations((prevConfigurations) => prevConfigurations.filter((config) => config.id !== selectedConfig.id))

      setShowDeleteWarning(false)
      setDeleteSuccess(true)
      setSelectedConfig(undefined)
    } else {
      console.error('Error deleting Lucid configuration:', isDeleted)
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setSelectedConfig(undefined)
  }

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (isAdding && selectedConfig) {
      const newConfig = await addConfiguration({ ...selectedConfig, configurationType: Number(configTypeId) })

      if (newConfig) {
        setConfigurations((prevConfigurations) => [...prevConfigurations, newConfig])
        setSuccessMessage('Configuration added successfully.')
        setSuccessState(true)
      } else {
        setErrorMessage('Failed to add configuration.')
        setErrorState(true)
      }
    } else if (!isAdding && selectedConfig) {
      const result = await updateConfiguration(selectedConfig)

      if (result) {
        setConfigurations((prevConfigurations) =>
          prevConfigurations.map((config) => (config.id === selectedConfig.id ? result : config)),
        )

        setSuccessMessage('Configuration updated successfully.')
        setSuccessState(true)
      } else {
        setErrorMessage('Failed to add configuration.')
        setErrorState(true)
      }
    }

    setOpenDialog(false)
    setSelectedConfig(undefined)
  }

  const isFormValid = () => {
    if (!selectedConfig) return false

    return selectedConfig?.key?.trim() !== '' && selectedConfig?.value?.trim() !== ''
  }

  return (
    <ActionPage>
      <Button
        onClick={() => {
          setIsAdding(true)
          setOpenDialog(true)
        }}
      >
        {' '}
        + Add configuration{' '}
      </Button>
      {/* <ProgressBar loading={loadingAttrbibutes} /> */}
      <ResultStatus
        severtiy="success"
        showStatus={deleteSuccess}
        closeStatus={setDeleteSuccess}
        alertMessage={`SUCCESS: Deleted configuration`}
      />
      {successMessage && (
        <ResultStatus
          severtiy="success"
          showStatus={successState}
          closeStatus={setSuccessState}
          alertMessage={successMessage}
        />
      )}
      {errorMessage && (
        <ResultStatus
          severtiy="error"
          showStatus={errorState}
          closeStatus={setErrorState}
          alertMessage={errorMessage}
        />
      )}
      {/* <ResultStatus
      severtiy="warning"
      showStatus={editWarning}
      closeStatus={setEditWarning}
      alertMessage={`Warning: Editing Attribute "${selectedAttributeRow?.dataAttributeName}" will impact corresponding dependencies.`}
    /> */}
      <DeletePopUp
        showDeleteWarning={showDeleteWarning}
        confirmDeleteMap={confirmDeleteConfig}
        closeDeleteWarning={() => setShowDeleteWarning(false)}
        itemToDelete={selectedConfig?.key}
      />

      <Box style={cardContainer}>
        <Grid item container spacing={2}>
          <Grid item xs={14} justifyContent={'center'} alignItems={'stretch'} container>
            <ShadowTable
              rows={tableRows || []}
              columns={tableColumns}
              tableActionParams={{
                onButtonClick: onButtonClick,
                actions: [ShadowTableAction.Editable],
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>{isAdding ? 'Add Configuration' : 'Edit Configuration'}</DialogTitle>
        <DialogContent>
          <form id="addUserForm" onSubmit={handleFormSubmit}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                label="Configuration Name"
                variant="outlined"
                required
                disabled={!isAdding}
                value={selectedConfig?.key || ''}
                onChange={(event) => {
                  setSelectedConfig(
                    (prevConfig) =>
                      ({
                        ...prevConfig,
                        key: event.target.value,
                      } as Configuration),
                  )
                }}
              />
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                label="Configuration Value"
                variant="outlined"
                required
                value={selectedConfig?.value || ''}
                onChange={(event) => {
                  setSelectedConfig(
                    (prevConfig) =>
                      ({
                        ...prevConfig,
                        value: event.target.value,
                      } as Configuration),
                  )
                }}
              />
            </FormControl>
            <DialogActions>
              <Button type="submit" form="addUserForm" disabled={!isFormValid()}>
                {isAdding ? 'Add' : 'Update'}
              </Button>
              <Button onClick={handleCloseDialog}>Cancel</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </ActionPage>
  )
}

export default ManageConfiguration
