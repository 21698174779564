import {
  Box,
  Grid,
  Button,
  IconButton,
  Typography,
  MenuItem,
  TextField,
  InputAdornment,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Select,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Card,
  Divider,
  CardHeader,
  ListItemButton,
  Menu,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { cardContainer } from '../../styles/globalStyles'
import ActionPage from '../../ui-components/ActionPage'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../Routes/constants'

import { useGetAllDataPods, useGetDataPodInfo } from '../../hooks/datapodHooks'
import { useGetJobStatus, useGetProfileInputs } from '../../hooks/dataProfilingHooks'
import { jobNames } from '../../hooks/constants'
import { useSparkPost, useGetDataSystemByDataSystemId } from '../../hooks/dataSystemHooks'
import { useGetSubjectArea } from '../../hooks/subjectAreaHooks'

import { useGetAllDataTablesProfiling } from '../../hooks/dataTableHooks'
import { TableColumnCard } from './TableColumnCards'
import { useGetAllDataColumnsByTableId, useGetDataColumnsByTable } from '../../hooks/dataColumnHooks'
import { useNotebookToQueryString } from '../../hooks/notebookHooks'
import { DataTable, NotebookParams, RunNewJobParams, RunStatusResponse } from '../../businessObjects'
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined'
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined'
import ShadowTable from '../../ui-components/ShadowTable/ShadowTable'
import { DataTableColumn, DataTableRow } from '../../ui-components/DataTable/DataTableTypes'
import CancelIcon from '@mui/icons-material/Cancel'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import { LucidJupyter } from '../../ui-components/JupyterComponents/LucidJupyter'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { RootState } from '../../app/store'
import { filterActiveRunIds, initiateActiveRunId } from '../../features/notification/notificationSlice'
import { usePostDatabricksJobRunNew } from '../../hooks/databricksHooks'
import { useGetServiceConnection } from '../../hooks/dataEngineeringHooks'
import { LucidPopUp } from 'ui-components/LucidPopUp/LucidPopUp'
import { isValidEntry } from 'utils/constants'
import ToggleView, { ViewType } from 'ui-components/ToggleView/ToggleView'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { ArrowDownwardOutlined, ArrowUpwardOutlined } from '@mui/icons-material'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { useGetQueryString } from 'hooks/queryStringHook'

type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
}
type FileType = `CSV|Parquet|Delta|SQL Table` | undefined
type FileTypes = {
  id: number
  name: FileType
}
export const DataProfilingViewResults = (props: Props) => {
  const { dataPodId, dataSystemID } = useParams<ViewResultsParams>()
  const [selectedSourceID, setSelectedSourceID] = useState<number>()
  const selTableId = useGetQueryString('tableId')
  // const [tableView, setTableView] = useState<boolean>(true)
  const [cardView, setCardView] = useState<ViewType>('Table')
  const [tableSearch, setTableSearch] = useState<'Table' | 'Column'>('Table')
  const [searchText, setSearchText] = useState<string>()
  // const [tableData, setTableData] = useState<DataTable[]>([])

  // const { dataPodInfo } = useGetDataPodInfo(dataPodId)

  const { sparkApiLoading, injectDataParamsToSpark } = useSparkPost(dataSystemID, dataPodId)
  // const [tableData, setTableData] = useState<DataTable[]>([])
  // const { dataPodInfo } = useGetDataPodInfo(dataPodId)
  // const { getNotebookStringBySystemId } = useNotebookToQueryString(dataPodId, dataSystemID)
  const { profileInputs } = useGetProfileInputs(dataPodId ?? undefined)
  const { dataSystemInfo } = useGetDataSystemByDataSystemId(selectedSourceID)
  const { subjectAreaInfo } = useGetSubjectArea(dataSystemInfo?.subjectAreaId)
  const [selectedTableID, setSelectedTableID] = useState<number>()
  // const { dataTables } = useGetAllDataTables(dataPodId, selectedSourceID, searchText)
  const { postRunNewJob } = usePostDatabricksJobRunNew()
  const activeRunIds = useAppSelector((state: RootState) => state.notification.activeRunIds)
  const dispatch = useAppDispatch()
  const { getConnections } = useGetServiceConnection()
  const { fetchDataTables, dataTables } = useGetAllDataTablesProfiling()
  const [showNotebookPopUp, setShowNotebookPopup] = useState<boolean>(false)
  const [dataSize, setDataSize] = useState<number>()
  // const [fileType, setFileType] = useState<'csv'|'parquet'|'delta'|'other'>()
  const [fileType, setFileType] = useState<FileType>()
  const [relativePath, setRelativePath] = useState<string>()
  const [fileTypes, setFileTypes] = useState<FileTypes[]>([])
  const [selectedFolderOption, setSelectedFolderOption] = useState<string>('')
  const [showRadioSection, setShowRadioSection] = useState<boolean>(true)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFolderOption((event.target as HTMLInputElement).value)
  }
  const [runId, setRunId] = useState<number>()
  const [dataBricksJobStatus, setdataBricksJobStatus] = useState<boolean>(false)
  const selectedDSTable = useMemo(() => {
    if (!dataTables?.length) return []
    return dataTables
  }, [dataTables, searchText])
  // const { getJobStatus, jobStatus } = useGetJobStatus(jobNames.SCAN_AND_PROFILE, dataPodId)

  useEffect(() => {
    const fileTypes = [
      { id: 1, name: 'CSV' as FileType },
      { id: 2, name: 'Parquet' as FileType },
      { id: 3, name: 'Delta' as FileType },
      { id: 4, name: 'SQL Table' as FileType },
    ]
    setFileTypes(fileTypes)
  }, [])

  useEffect(() => {
    if (dataPodId && selectedSourceID) {
      fetchDataTables(dataPodId, selectedSourceID)
    }
  }, [selectedSourceID])

  useEffect(() => {
    if (fileType === ('SQL Table' as FileType)) {
      setShowRadioSection(false)
    } else {
      setShowRadioSection(true)
    }
  }, [fileType as FileType])

  useEffect(() => {
    if (dataTables?.length && tableSearch === 'Table') {
      setSelectedTableID(dataTables[0].id)
    }
  }, [dataTables, selectedSourceID])
  useEffect(()=>{
    if(selTableId){
      setSelectedTableID(Number(selTableId))
    }
  },[selTableId])
  useEffect(() => {
    if (searchText?.length && tableSearch === 'Table') {
      // console.log("Fetch the Table with the search Text")
      fetchDataTables(dataPodId, selectedSourceID, searchText)
    } else {
      console.log('Inside else')
      fetchDataTables(dataPodId, selectedSourceID)
    }
  }, [searchText, tableSearch, searchText?.length])
  useEffect(() => {
    if (dataSystemID && !selectedSourceID) {
      setSelectedSourceID(Number(dataSystemID))
    }
  }, [dataSystemID])
  useEffect(()=>{
    if(activeRunIds && runId){
      const temp = activeRunIds.find((element) => element.run_id === runId)
      if(temp && temp.state.result_state === "SUCCESS"){
        if(temp.run_name?.includes('LDH_DataSourceScan') && selectedSourceID){
          fetchDataTables(dataPodId, selectedSourceID)
        }else if(temp.run_name?.includes('LDH_TableScan') && selectedTableID){
          fetchDataTables(dataPodId, selectedSourceID)
          setdataBricksJobStatus(true)
        }
      }
    }
  },[activeRunIds])
  const navigate = useNavigate()
  const onClickHome = () => {
    const dataProfilingRoute = generatePath(ROUTES.Overview, { dataPodId: dataPodId })
    navigate(dataProfilingRoute)
  }

  const dataSources = useMemo(() => {
    if (!profileInputs) return []
    return profileInputs
  }, [profileInputs])

  // const subjectArea = useMemo(() => {
  //   if (!subjectAreaInfo) return
  //   return subjectAreaInfo
  // }, [subjectAreaInfo])

  const handleSourceChange = ({ target }: any) => {
    const dataProfilingViewResultsRoute = generatePath(ROUTES.DataProfilingViewResults, {
      dataPodId: dataPodId,
      dataSystemID: Number(target.value),
    })
    setSelectedSourceID(Number(target.value))
    navigate(dataProfilingViewResultsRoute)
  }

  const handleReScan = () => {
    injectDataParamsToSpark()
    setSelectedTableID(0)
  }

  const showRequiredDetailsPopUpForScan = () => {
    setShowNotebookPopup(true)
  }
  // const handleNewPopUpData = (field:string, value:string)=>{
  //   console.log(field, value)
  //   // if(field === '')
  // }
  const runDataSourceScan = async () => {
    if (dataPodId) {
      const serviceConnection = await getConnections(dataPodId, undefined, 'Spark')
      if (serviceConnection) {
        const jsonTemplate = serviceConnection[0].serviceConnectionJsonTemplate
        const parsedJsonTemplate = JSON.parse(jsonTemplate)
        const params: RunNewJobParams = {
          notebookParams: {
            i_data_pod_id: dataPodId || '',
            i_data_system_id: dataSystemID || '',
            i_data_sample_size: String(dataSize) || '',
            i_file_type: String(fileType) || '',
            i_relative_path: selectedFolderOption || '',
          },
          url: parsedJsonTemplate.workspace_Url,
          jobId: parsedJsonTemplate.dataSource_Job_Id,
          token: parsedJsonTemplate.PAT_Token,
        }

        runNewJob(params,"SourceScan")
        setShowNotebookPopup(false)
        setDataSize(undefined)
        setFileType(undefined)
      }
    }
  }

  const runTableScan = async () => {
    if (dataPodId) {
      const serviceConnection = await getConnections(dataPodId, undefined, 'Spark')
      if (serviceConnection) {
        const jsonTemplate = serviceConnection[0].serviceConnectionJsonTemplate
        const parsedJsonTemplate = JSON.parse(jsonTemplate)
        const params: RunNewJobParams = {
          notebookParams: {
            i_data_pod_id: dataPodId || '',
            i_data_system_id: dataSystemID || '',
            i_data_table_id: selectedTableID?.toString() || '',
          },
          url: parsedJsonTemplate.workspace_Url,
          jobId: parsedJsonTemplate.dataTable_Job_Id,
          token: parsedJsonTemplate.PAT_Token,
        }
        runNewJob(params,"TableScan")
      }
    }
  }

  const runNewJob = async (params: RunNewJobParams, jobType:"SourceScan" | "TableScan" | "Measures" | "Entity") => {
    const result = await postRunNewJob(params)
    setdataBricksJobStatus(false)
    if (result) {
      const activeRunId = activeRunIds.find((element) => element.run_id === result)
      let jobParameters = {}
      const routePage = generatePath(ROUTES.DataProfilingViewResults,{dataPodId:dataPodId,dataSystemID:dataSystemID})
      switch(jobType){
        case "SourceScan":
         jobParameters = {
            id:selectedSourceID,
            name:dataSources.find((ds)=> ds.dataSystemID == selectedSourceID)?.dataSystemName,
            route: routePage
          }
          break
        case "TableScan":
          jobParameters = {
            id:selectedTableID,
            name:selectedDSTable.find((dt)=> dt.id == selectedTableID)?.name ?? '',
            route: routePage +`?tableId=${selectedTableID}`
          }
      }

      if (!activeRunId) {
        setRunId(result)
        const newRunId: RunStatusResponse = {
          run_id: result,
          state: {
            life_cycle_state: 'INITIATED',
            result_state: 'LOADING',
          },
          params: {
            run_id: result,
            url: params.url,
            token: params.token,
          },
          jobDetails:{
            triggerType:jobType,
            parameters:{
              ...jobParameters,
            }
          }
        } as RunStatusResponse

        dispatch(filterActiveRunIds())
        dispatch(initiateActiveRunId(newRunId))
      }
    }
  }

  const handleView = (vt: ViewType) => {
    if (vt !== cardView) {
      setCardView(vt)
    }
  }

  return (
    <>
      <LucidPopUp
        openPopUp={showNotebookPopUp}
        closePopup={setShowNotebookPopup}
        showCloseIcon
        headingText={
          <Typography variant="h4" color={'error'}>
            Enter the following details
          </Typography>
        }
        confirmText="Confirm"
        cancelText="Cancel"
        disabledConfirm={!dataSize || !fileType || !selectedFolderOption}
        onConfirm={runDataSourceScan}
      >
        <Grid justifyContent={'center'} alignItems={'center'} container spacing={2}>
          <Grid item xs={9} justifyContent={'center'}>
            <Typography color={'text.primary'}>
              <span style={{ color: 'red' }}>*</span> Indicates a required field
            </Typography>
          </Grid>
          {/* <Grid item xs={14} display={'flex'} flexDirection={'row'}> */}
          <Grid item xs={9} justifyContent={'center'}>
            <Typography color={'text.primary'}>Sample Data Size *</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              style={{ width: '50%' }}
              id="outlined-basic"
              label="Enter data size"
              variant="outlined"
              color="secondary"
              size="small"
              type="number"
              value={dataSize ?? ''}
              onChange={({ target }) => {
                const value = parseFloat(target.value)
                if (value >= 0 && value <= 1) {
                  setDataSize(value)
                }
              }}
              required
              inputProps={{ step: 0.1, min: 0, max: 1 }}
              // error={!isValidEntry(dataSize)}
              // helperText={!isValidEntry(newSubjectArea?.name) ? 'Invalid Name' : ''}
            />
          </Grid>
          <Grid item xs={9} justifyContent={'center'}>
            <Typography color={'text.primary'}>File Type*</Typography>
          </Grid>
          <Grid item xs={9} justifyItems={'center'}>
            <Select
              label="File Type"
              value={fileType}
              onChange={(event) => setFileType(event.target.value as FileType)}
              required
              style={{ width: '50%' }}
            >
              {fileTypes.map((role) => (
                <MenuItem key={role.id} value={role.name}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {showRadioSection && (
            <>
              <Grid item xs={9} justifyContent={'center'}>
                <Typography color={'text.primary'}>Choose the folder structure type * </Typography>
              </Grid>
              <Grid item xs={9}>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="options" name="options" value={selectedFolderOption} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio />} label="Each Directory is a Data File/Table" />
                    <FormControlLabel value="2" control={<Radio />} label="Each File is a Data File/Table" />
                    <FormControlLabel value="3" control={<Radio />} label="Each Directory Contains Data Files/Tables" />
                    <FormControlLabel
                      value="4"
                      control={<Radio />}
                      label="Each Directory Contains Directories as Data Files/Tables"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
      </LucidPopUp>
      <ActionPage>
        <Grid item container spacing={2}>
          <Grid item xs={2}>
            <IconButton onClick={onClickHome}>
              <HomeOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Box style={cardContainer}>
          <Grid container spacing={2}>
            <Grid item container spacing={6}>
              <Grid item xs={12}>
                <Box sx={{ mt: 2 }}>
                  <Grid container spacing={4} alignItems={'center'}>
                    <Grid item container xs={15} spacing={1}>
                      <Grid item xs={6}>
                        <Typography component="span" variant="subtitle1" sx={{ fontSize: 14 }}>
                          Data Source Name
                        </Typography>
                      </Grid>
                      <Grid item />

                      <Grid item xs={5}>
                        <Typography component="span" variant="subtitle1" sx={{ fontSize: 14 }}>
                          Data Domain Name
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={15} />

                      <Grid item container xs={15} spacing={1}>
                        <Grid item xs={5}>
                          <TextField
                            fullWidth
                            id="outlined-select-dataSource"
                            select
                            variant="outlined"
                            value={selectedSourceID ?? 0}
                            required
                            size="small"
                            type="number"
                            onChange={handleSourceChange}
                          >
                            {dataSources.map((d) => (
                              <MenuItem key={d.dataSystemID} value={d.dataSystemID}>
                                <Typography fontSize={'small'}> {d.dataSystemName}</Typography>
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={5}>
                          <TextField
                            fullWidth
                            id="outlined-select-dataSource"
                            variant="outlined"
                            value={subjectAreaInfo?.subjectAreaName}
                            required
                            size="small"
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} />
            </Grid>
          </Grid>
          <Grid item container spacing={2} alignItems={'center'}>
            <Grid item container xs={2}>
              <Grid item xs={4} />
              <FormControl>
                <FormLabel id="Search By Label">Search By</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={tableSearch}
                  onChange={({ target }) => setTableSearch(target.value as typeof tableSearch)}
                  name="radio-buttons-group"
                >
                  <Grid item xs={8} alignContent={'space-evenly'} container>
                    <Grid item xs={4}>
                      <FormControlLabel value={'Table'} control={<Radio />} label={'Table'} />
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                      <FormControlLabel value={'Column'} control={<Radio />} label={'Column'} />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <TextField
                placeholder="Search"
                value={searchText ?? ''}
                onChange={({ target }) => setSearchText(target.value)}
                color="primary"
                margin="dense"
                size="small"
                type="search"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: searchText ? (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearchText('')}>
                        <CancelIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <></>
                  ),
                }}
              />
            </Grid>
            <Grid item container xs={2}>
              <Button
                variant="contained"
                onClick={showRequiredDetailsPopUpForScan}
                title={`Scan Data Source`}
              >{`Scan Data Source`}</Button>
            </Grid>
            <Grid item container xs={2}>
              <Button
                disabled={selectedTableID ? false : true}
                variant="contained"
                onClick={runTableScan}
                title={`Scan Data Table`}
              >{`Scan Data Table`}</Button>
            </Grid>
            <Grid item xs={0} alignItems={'end'}>
              <ToggleView viewType={cardView} handleToggle={handleView} />
              {/* <IconButton color={tableView ? 'primary' : 'inherit'} onClick={() => toggleView(true)}>
                <TableRowsOutlinedIcon />
              </IconButton>
              <IconButton color={cardView ? 'primary' : 'inherit'} onClick={() => toggleView(false)}>
                <GridViewOutlinedIcon />
              </IconButton> */}
            </Grid>
            <Grid item md={12}></Grid>
            <Grid item xs={12} container>
              {cardView === 'Table' && (
                <ResultsTable
                  dataSourceTables={selectedDSTable}
                  searchText={searchText}
                  databricksTableScanFlag={dataBricksJobStatus}
                  tableSearch={tableSearch}
                  selectedTableId={selectedTableID}
                  updateTableId={setSelectedTableID}
                />
              )}
              {cardView === 'Grid' && (
                <Grid container spacing={2}>
                  {selectedDSTable.map((tb) => (
                    <Grid item xs={4} key={tb.id}>
                      <TableColumnCard
                        tableId={tb.id}
                        tableName={tb.name}
                        searchText={!tableSearch ? searchText : undefined}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item container spacing={2} alignItems={'center'}>
            <Grid item md={12} />
            <Grid item container md={12}>
              {sparkApiLoading ? (
                <Grid item md={12}>
                  <Skeleton variant="rectangular" height={100} />
                  <Skeleton variant="rectangular" height={100} />
                </Grid>
              ) : (
                <Grid item md={12}></Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </ActionPage>
    </>
  )
}

type ResultTableProps = {
  dataSourceTables: DataTable[]
  searchText?: string
  databricksTableScanFlag?:boolean
  tableSearch: 'Table' | 'Column'
  selectedTableId?: number
  updateTableId: (tableId: number) => void
}

export const ResultsTable = ({
  dataSourceTables,
  searchText,
  databricksTableScanFlag,
  selectedTableId,
  updateTableId,
  tableSearch,
}: ResultTableProps) => {
  const { dataPodId, dataSystemID } = useParams<ViewResultsParams>()
  const [leftTable, setLeftTable] = useState<DataTable[]>([])
  const [rightTable, setRightTable] = useState<DataTableRow[]>([])
  const [notebookString, setNotebookString] = useState<string>()
  //This is getting the right side data and we are providing this first tableId from left side to the getDataColumns.
  const { getDataColumns, dataColumns } = useGetDataColumnsByTable()
  const { notebookResponse } = useNotebookToQueryString(dataPodId, dataSystemID, selectedTableId)
  const [isTableAsc, setIsTableAsc] = useState<boolean>(true)
  const [filterAnchor, setFilterAnchor] = useState<null | HTMLElement>(null)
  const [filterApplied, setFilterApplied] = useState<'profiled' | 'unprofiled' | 'all'>('all')
  const sortDataTable = (d1: DataTable, d2: DataTable) => {
    return d1.name > d2.name && isTableAsc ? -1 : 1
  }
  const rightTableColumns = useMemo(() => {
    return [
      {
        label: 'Column ID',
        sortableColumn: true,
      },
      {
        label: 'Column Name',
        sortableColumn: true,
      },
      {
        label: 'Data Type',
        sortableColumn: true,
      },
      {
        label: ' isPrimary',
        sortableColumn: true,
      },
      {
        label: ' isUnique',
        sortableColumn: true,
      },
    ]
  }, [])
  const leftTableColumns: DataTableColumn[] = useMemo(() => {
    return [{ label: 'Table Name', sortableColumn: true }]
  }, [])

  useEffect(() => {
    if (notebookResponse?.message) {
      setNotebookString(notebookResponse?.message)
    }
  }, [notebookResponse])

  useEffect(() => {
    if (!selectedTableId) {
      setNotebookString('')
    }

    if (selectedTableId && dataSourceTables.length && tableSearch === 'Column') {
      // console.log("Inside if")
      getDataColumns(selectedTableId, searchText)
    } else if (selectedTableId && dataSourceTables.length && tableSearch === 'Table') {
      // console.log("inside else if")
      getDataColumns(selectedTableId)
    }
  }, [selectedTableId, searchText, dataSourceTables, tableSearch, searchText?.length, databricksTableScanFlag])
  // console.log({ selectedTableId })
  // useEffect(() => {
  //   if (dataSourceTables.length && !searchText) {
  //     const rows = dataSourceTables.map((dt) => ({
  //       id: `${dt.id}`,
  //       values: [dt.name],
  //     })) as DataTableRow[]
  //     setLeftTable(rows)
  //   }
  //   if (!dataSourceTables.length) {
  //     setLeftTable([])
  //     setRightTable([])
  //   }
  // }, [dataSourceTables, searchText, selectedTableId])

  // const leftRows = useMemo(() => {
  //   if (dataSourceTables.length) {
  //     return dataSourceTables.map((dt) => ({
  //       id: `${dt.id}`,
  //       values: [dt.name],
  //     })) as DataTableRow[]
  //   }
  //   return []
  // }, [dataSourceTables])

  useEffect(() => {
    if (dataSourceTables.length) {
      setLeftTable(dataSourceTables.sort((d1, d2) => (d1.profileStatus > d2.profileStatus ? -1 : 1)))
    }
  }, [dataSourceTables, dataSystemID])

  useEffect(() => {
    if (selectedTableId) {
      const columnList = dataColumns.map((cl) => ({
        id: `${cl.id}`,
        values: [cl.id, cl.dataColumnName, cl.datatype, `${cl.isPrimary}`, cl.isUnique] as string[],
      }))
      setRightTable(columnList)
    } else {
      setRightTable([])
    }
  }, [selectedTableId, dataColumns, searchText, dataSourceTables])

  // useEffect(() => {
  //   if (!dataSourceTables.length) {
  //     setSelectedTableID(undefined)
  //     setLeftTable([])
  //     setRightTable([])
  //   }
  // }, [dataSourceTables])

  const refreshRightTable = (sid: unknown) => {
    // setNotebookString('')
    getDataColumns(Number(sid))
    updateTableId(Number(sid))
  }

  const handleFilterSelect = (id: typeof filterApplied) => {
    // const filtedToUpdate = filterApplied.includes(id as string)
    //   ? filterApplied.filter((ft) => ft !== id)
    //   : [...filterApplied, id]
    setFilterApplied(id)
    switch (id) {
      case 'profiled':
        setLeftTable(dataSourceTables.filter((dt) => dt.profileStatus && dt.profileStatus).sort(sortDataTable))
        setFilterAnchor(null)
        break
      case 'unprofiled':
        setLeftTable(dataSourceTables.filter((dt) => !dt.profileStatus).sort(sortDataTable))
        setFilterAnchor(null)
        break
      case 'all':
        setLeftTable(dataSourceTables)
        setFilterAnchor(null)
        break
    }
    // setFilterApplied(id as typeof filterApplied)
    // if (id === 'profiled') {
    //   setLeftTable(dataSourceTables.filter((dt) => dt.profileStatus && dt.profileStatus))
    // }
    // if (id === 'unprofiled') {
    //   setLeftTable(dataSourceTables.filter((dt) => !dt.profileStatus))
    // } else {
    //   setLeftTable(dataSourceTables)
    // }

    // // setFilterApplied(filtedToUpdate as string[])
    // setFilterAnchor(null)
  }

  const sortedLeftTables = useMemo(() => {
    return leftTable.sort(sortDataTable)
  }, [leftTable, isTableAsc])

  return (
    <Grid item container justifyContent={'center'} alignItems={'stretch'} xs={14} spacing={2}>
      <Grid item xs={3}>
        <Card sx={{ backgroundColor: 'white' }}>
          <CardHeader
            disableTypography
            sx={{ backgroundColor: 'grey', height: 40 }}
            title={
              <>
                <Grid item xs={12} container alignItems="baseline" spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="body2" gutterBottom>
                      {'List of Tables'}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      size="small"
                      title={`sort ${isTableAsc ? 'desc' : 'asc'}`}
                      onClick={() => setIsTableAsc(!isTableAsc)}
                    >
                      {isTableAsc ? (
                        <ArrowDownwardOutlined fontSize="small" />
                      ) : (
                        <ArrowUpwardOutlined fontSize="small" />
                      )}
                    </IconButton>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton title="Filter" onClick={({ currentTarget }) => setFilterAnchor(currentTarget)}>
                      <FilterAltOutlinedIcon fontSize="small" />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={filterAnchor}
                      open={Boolean(filterAnchor)}
                      onClose={handleFilterSelect}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        'aria-multiselectable': 'true',
                      }}
                    >
                      {['profiled', 'unprofiled', 'all'].map((itm) => (
                        <MenuItem
                          selected={filterApplied === itm}
                          key={itm}
                          onClick={() => handleFilterSelect(itm as typeof filterApplied)}
                        >
                          {itm}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Grid>
                </Grid>
              </>
            }
          />
          <Divider />
          <List sx={{ width: '100%', maxWidth: 350, marginTop: -1 }} dense>
            {sortedLeftTables.map((dt, ix) => (
              <ListItem key={ix}>
                <ListItemButton
                  sx={{ color: selectedTableId === dt.id ? 'primary.main' : 'inherit' }}
                  onClick={() => refreshRightTable(dt.id)}
                >
                  {dt.name.length > 25 ? (
                    <Tooltip title={dt.name} placement="top-start">
                      <Typography variant="body2">{dt.name.slice(0, 20) + '...'}</Typography>
                    </Tooltip>
                  ) : (
                    <Typography variant="body2">{dt.name}</Typography>
                  )}
                  {/* <Typography variant="body2">{dt.name}</Typography> */}
                </ListItemButton>

                {/* <ListItemText
                  disableTypography
                  primary={
                    dt.name.length > 20 ? (
                      <Tooltip title={dt.name} placement="top-start">
                        <Typography variant="body2">{dt.name.slice(0, 20) + '...'}</Typography>
                      </Tooltip>
                    ) : (
                      <Typography sx={{backgroundColor: selectedTableId==}} variant="body2">{dt.name}</Typography>
                    )
                  }
                /> */}
                {dt.profileStatus && <CheckCircleIcon fontSize="small" color="primary" titleAccess="Data Profiled" />}
              </ListItem>
            ))}
          </List>
        </Card>
        {/* <ShadowTable
          rows={leftRows}
          columns={leftTableColumns}
          selectedRow={`${selectedTableId ?? 0}`}
          selectRow={(id) => refreshRightTable(id)}
        /> */}
      </Grid>
      <Grid item container xs={9}>
        <>
          {/* <Grid item xs={12} sx={{ mb: 2 }}>
            <LucidJupyter
              noteBookString={notebookString}
              headingText={'Verify and adjust generated spark code'}
              updateNoteBookString={setNotebookString}
            />
          </Grid> */}
          <ShadowTable rows={rightTable} columns={rightTableColumns} />
        </>
      </Grid>
    </Grid>
  )
}
