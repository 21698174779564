import { Box, CssBaseline } from '@mui/material'
import Typography from '@mui/material/Typography'

type Props = {
  children?: React.ReactNode
}

export const PageLayout: React.FC<Props> = ({ children }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      {children}
    </Box>
  )
}
