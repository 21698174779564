import { useState, useEffect } from 'react'
import { AnalyticsMeasureType, AnalyticsMeasure, MeasureNotebook, GenerateKPIType, GenereateKPIResponse, GenerateKPI, DimensionData, AdvAnalyticsResponse } from '../businessObjects'
import { analyticsMeasureServices } from '../apiServices/analyticsMeasure'
import { useLucidAuthContext } from '../LucidAuthContext/LucidAuthContext'

type FlatObject = { [key: string]: any }

const dimensionDataToDictionary = (data: DimensionData[] = []) => {
  return data.reduce((acc, item) => {
    acc[item.entityName] = item.attributes;
    return acc;
  }, {} as FlatObject);
}


export const useGetAnalyticMeasure = (
  dataPodId?: string,
): {
  getAnalyticsMeasure: (groupId?: number) => void
  analyticsMeasureList: AnalyticsMeasure[]
  addAnalyticsMeasure: (body: AnalyticsMeasureType) => void
  updateAnalyticsMeasure: (body: AnalyticsMeasureType) => void
  postMeasure: any
  analyticsMeasureLoading: boolean
  measureError: FlatObject | undefined
} => {
  const [measureError, setMeasureError] = useState<FlatObject | undefined>()
  const [analyticsMeasureLoading, setAnalyticsMeasureLoading] = useState<boolean>(false)
  const [analyticsMeasureList, setAnalyticsMeasureList] = useState<AnalyticsMeasure[]>([])
  const [postMeasure, setPostMeasure] = useState<any>()

  const { getBearerToken } = useLucidAuthContext()

  const getAnalyticsMeasure = async (analyticsGroupID?: number): Promise<void> => {
    try {
      setAnalyticsMeasureLoading(true)
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId) {
        const { data, status } = await analyticsMeasureServices.getAnalyticsMeasure(
          bearerToken,
          dataPodId,
          analyticsGroupID,
        )
        // data?.map(
        //   async (msr: any) =>
        //     await analyticsMeasureServices.deleteAnalyticsMeasure(bearerToken, msr?.analyticsMeasureId),
        // )
        if (status == 200) {
          setAnalyticsMeasureList(data)
        } else {
          setMeasureError({ time: Date.now(), message: data?.toString() })
        }
        setAnalyticsMeasureLoading(false)
      }
    } catch (error: any) {
      setAnalyticsMeasureLoading(false)
      setMeasureError({ time: Date.now(), message: error?.response?.data?.title || error.message })
    }
  }

  const addAnalyticsMeasure = async (body: AnalyticsMeasureType): Promise<void> => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId) {
        const { data, status } = await analyticsMeasureServices.addAnalyticsMeasure(bearerToken, dataPodId, body)
        if (status == 200) {
          setPostMeasure(data)
        } else {
          setMeasureError({ time: Date.now(), message: data?.toString() })
        }
      }
    } catch (error: any) {
      setMeasureError({ time: Date.now(), message: error?.response?.data?.title || error.message })
    }
  }

  const updateAnalyticsMeasure = async (body: AnalyticsMeasureType): Promise<void> => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId) {
        const { data, status } = await analyticsMeasureServices.updateAnalyticsMeasure(bearerToken, dataPodId, body)
        if (status == 200) {
          setPostMeasure(data)
        } else {
          setMeasureError({ time: Date.now(), message: data?.toString() })
        }
      }
    } catch (error: any) {
      setMeasureError({ time: Date.now(), message: error?.response?.data?.title || error.message })
    }
  }

  return {
    getAnalyticsMeasure,
    analyticsMeasureList,
    updateAnalyticsMeasure,
    addAnalyticsMeasure,
    postMeasure,
    analyticsMeasureLoading,
    measureError,
  }
}

export const useGetAnalyticMeasureByGroupMeasureId = (
  dataPodId?: string,
  groupId?: any,
  measureId?: any,
): {
  getAnalyticsMeasureByGroupAndMeasureId: () => void
  analyticsMeasureList: AnalyticsMeasure[]
  analyticsGroupMeasureLoading: boolean
  measureGroupError: FlatObject | undefined
} => {
  const [measureGroupError, setMeasureGroupError] = useState<FlatObject | undefined>()
  const [analyticsGroupMeasureLoading, setAnalyticsGroupMeasureLoading] = useState<boolean>(false)
  const [analyticsMeasureList, setAnalyticsMeasureList] = useState<AnalyticsMeasure[]>([])

  const { getBearerToken } = useLucidAuthContext()

  const getAnalyticsMeasureByGroupAndMeasureId = async (): Promise<void> => {
    try {
      setAnalyticsGroupMeasureLoading(true)
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId) {
        const { data, status } = await analyticsMeasureServices.getAnalyticsMeasure(
          bearerToken,
          dataPodId,
          groupId,
          measureId,
        )
        if (status == 200) {
          setAnalyticsMeasureList(data)
        } else {
          setMeasureGroupError({ time: Date.now(), message: data?.toString() })
        }
        setAnalyticsGroupMeasureLoading(false)
      }
    } catch (error: any) {
      setAnalyticsGroupMeasureLoading(false)
      setMeasureGroupError({ time: Date.now(), message: error?.response?.data?.title || error.message })
    }
  }

  useEffect(() => {
    if (dataPodId) {
      getAnalyticsMeasureByGroupAndMeasureId()
    }
  }, [dataPodId])

  return {
    getAnalyticsMeasureByGroupAndMeasureId,
    analyticsMeasureList,
    analyticsGroupMeasureLoading,
    measureGroupError,
  }
}

export const useMeasureNotebook = (
  dataPodId?: string,
  measureId?: any,
): {
  addMeasureNotebook: (body: MeasureNotebook) => void
  getMeasureNotebook: (measureId?: number | undefined) => void
  measureNotebookResponse: FlatObject | undefined
  addMeasureNotebookLoading: boolean
  noteBookError: FlatObject | undefined
  generateMeasureKPI: (groupId: number) => void
  // generateKPI: (measureID: number, promptText: string) => void
} => {
  const [noteBookError, setNotebookError] = useState<FlatObject | undefined>()
  const [addMeasureNotebookLoading, setAddMeasureNotebookLoading] = useState<boolean>(false)
  const [measureNotebookResponse, setPostMeasureNotebookResponse] = useState<FlatObject | undefined>()

  const { getBearerToken } = useLucidAuthContext()

  const addMeasureNotebook = async (body: MeasureNotebook): Promise<void> => {
    try {
      setAddMeasureNotebookLoading(true)
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId) {
        const { data, status } = await analyticsMeasureServices.addMeasureNB(bearerToken, dataPodId, body)
        if (status == 200) {
          setPostMeasureNotebookResponse({ time: Date.now(), message: data })
        } else {
          setNotebookError({ time: Date.now(), message: data?.toString() })
        }
        setAddMeasureNotebookLoading(false)
      }
    } catch (error: any) {
      setAddMeasureNotebookLoading(false)
      setNotebookError({ time: Date.now(), message: error.message })
    }
  }

  const getMeasureNotebook = async (analyticsMeasureId: number | undefined): Promise<void> => {
    try {
      setAddMeasureNotebookLoading(true)
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId && analyticsMeasureId) {
        const { data, status } = await analyticsMeasureServices.getMeasureNB(bearerToken, dataPodId, analyticsMeasureId)
        if (status == 200) {
          setPostMeasureNotebookResponse({ time: Date.now(), notebook: data })
        } else {
          setNotebookError({ time: Date.now(), message: data?.result || data?.toString() })
        }
        setAddMeasureNotebookLoading(false)
      }
    } catch (error: any) {
      setAddMeasureNotebookLoading(false)
      setNotebookError({ time: Date.now(), message: error.message })
    }
  }

  const processMeasuresAndNotebooks = async (measureList: any, measureGroupId: number) => {
    const bearerToken = await getBearerToken()
    if (dataPodId && measureList?.length && measureGroupId && bearerToken) {
      for (const key of Object.keys(measureList[0])) {
        const body = {
          analyticsMeasureName: key,
          measureGroupId,
          analyticsMeasureDescription: measureList[0][key],
          runId: 0,
          measureQuery: '',
        }
        const { data }: any = await analyticsMeasureServices.addAnalyticsMeasure(bearerToken, dataPodId, body)
        if (data && data?.id && data?.analyticsMeasureDescription) {
          const dimensions = await analyticsMeasureServices.getMeasureDimensions(bearerToken, dataPodId, data.id);
          // await analyticsMeasureServices.synchronizeMeasureDimensions(bearerToken, dataPodId, data.id, dimensions.data);

          // const body1: GenerateKPIType = {
          //   dataPodId,
          //   measureID: data.id,
          //   promptText: data.analyticsMeasureDescription,
          // }
          const body1: GenerateKPIType = {
            dataPodId,
            measureID: data.id,
            promptText: data.analyticsMeasureDescription,
            dimension_data: [dimensionDataToDictionary(dimensions.data)]
          };
          await analyticsMeasureServices.generateKPI(body1)
        }
      }
    }
  }

  //Generate KPI API's
  const generateMeasureKPI = async (analyticsGroupId: number): Promise<void> => {
    try {
      setAddMeasureNotebookLoading(true);
      if (dataPodId && analyticsGroupId) {
        const payload = {
          dataPodId,
          analyticsGroupId,
        };
        const { data, status } = await analyticsMeasureServices.generateMeasure(payload);
        if (status == 200) {
          await processMeasuresAndNotebooks(data?.data, analyticsGroupId);
          setPostMeasureNotebookResponse({ time: Date.now(), message: data?.data });
        } else {
          setNotebookError({ time: Date.now(), message: data?.result || data?.toString() });
        }
        setAddMeasureNotebookLoading(false);
      }
    } catch (error: any) {
      setAddMeasureNotebookLoading(false);
      setNotebookError({ time: Date.now(), message: error.message });
    }
  };
  

  // const generateKPI = async (measureID: number, promptText: string): Promise<void> => {
  //   try {
  //     setAddMeasureNotebookLoading(true)
  //     if (dataPodId && measureID && promptText) {
  //       const payload = {
  //         dataPodId,
  //         measureID,
  //         promptText,
  //       }
  //       const { data, status } = await analyticsMeasureServices.generateKPI(payload)
  //       if (status == 200) {
  //         setPostMeasureNotebookResponse({ time: Date.now(), notebook: data?.data })
  //       } else {
  //         setNotebookError({ time: Date.now(), message: data?.result || data?.toString() })
  //       }
  //       setAddMeasureNotebookLoading(false)
  //     }
  //   } catch (error: any) {
  //     setAddMeasureNotebookLoading(false)
  //     setNotebookError({ time: Date.now(), message: error.message })
  //   }
  // }

  useEffect(() => {
    if (measureId) {
      getMeasureNotebook(measureId)
    }
  }, [measureId])

  return {
    addMeasureNotebook,
    getMeasureNotebook,
    measureNotebookResponse,
    addMeasureNotebookLoading,
    noteBookError,
    generateMeasureKPI,
    // generateKPI,
  }
}

export const useUpdateMeasureNB = (
  dataPodId?: string,
): {
  updateMeasureNotebook: (body: MeasureNotebook) => void;
  updateMeasureNotebookResponse: FlatObject | undefined;
  updateMeasureNotebookLoading: boolean;
  measureNotebookError: FlatObject | undefined;
} => {
  const [measureNotebookError, setMeasureNotebookError] = useState<FlatObject | undefined>();
  const [updateMeasureNotebookLoading, setUpdateMeasureNotebookLoading] = useState<boolean>(false);
  const [updateMeasureNotebookResponse, setUpdateMeasureNotebookResponse] = useState<FlatObject | undefined>();

  const { getBearerToken } = useLucidAuthContext();

  const updateMeasureNotebook = async (body: MeasureNotebook): Promise<void> => {
    try {
      setUpdateMeasureNotebookLoading(true);
      const bearerToken = await getBearerToken();
      if (bearerToken && dataPodId) {
        const { data, status } = await analyticsMeasureServices.updateMeasureNB(bearerToken, dataPodId, body);
        if (status === 200) {
          setUpdateMeasureNotebookResponse(data);
        } else {
          setMeasureNotebookError({ time: Date.now(), message: data?.toString() });
        }
      }
    } catch (error: any) {
      setMeasureNotebookError({ time: Date.now(), message: error?.response?.data?.title || error.message });
    } finally {
      setUpdateMeasureNotebookLoading(false);
    }
  };

  return {
    updateMeasureNotebook,
    updateMeasureNotebookResponse,
    updateMeasureNotebookLoading,
    measureNotebookError,
  };
};

export const useGetGenerateKPI = (
  dataPodId?: string,
  measureId?: number,
  promptText?: string,
): {
  generateKPIResponse: GenerateKPI | undefined
  generateKPILoading: boolean
  generateKPIError: FlatObject | undefined
  generateKPI: (dimensionData: DimensionData[]) => Promise<void>
} => {
  const [generateKPIError, setgenerateKPIError] = useState<FlatObject | undefined>()
  const [generateKPILoading, setGenerateKPILoading] = useState<boolean>(false)
  const [generateKPIResponse, setGenerateKPIResponse] = useState<GenerateKPI | undefined>()

  const generateKPI = async (dimensionData: DimensionData[]): Promise<void> => {
    try {
      setGenerateKPILoading(true)

      if (dataPodId && measureId && promptText) {
        const payload = {
          dataPodId,
          measureID: measureId,
          promptText,
          dimension_data: [dimensionDataToDictionary(dimensionData)]
        }

        const { data, status }: { data: GenereateKPIResponse; status: number } =
          await analyticsMeasureServices.generateKPI(payload)

        if (status == 200) {
          const response: GenerateKPI = {
            time: Date.now(), 
            notebook: data?.data, 
            dimensionData:  data?.dimension_data.map((item) => {
              const entityName = Object.keys(item)[0]

              return {
                entityName,
                attributes: item[entityName]
              }

            }) || []
          }

          setGenerateKPIResponse({ ...response })

        } else {
          setgenerateKPIError({ time: Date.now(), message: data?.result || data?.toString() })
        }
        setGenerateKPILoading(false)
      }
    } catch (error: any) {
      setgenerateKPIError({ time: Date.now(), message: "Failed to GenerateKPI. Internal Server Error." })
    } finally {
      setGenerateKPILoading(false)
    }
  }

  // useEffect(() => {
  //   if (dataPodId && measureId && promptText && promptText.length) {
  //     generateKPI()
  //   }
  // }, [measureId, promptText])

  return {
    generateKPIResponse,
    generateKPILoading,
    generateKPIError,
    generateKPI,
  }
}

export const useGetMeasureDimensions = (
  dataPodId?: string,
  measureId?: number,
): {
  getDimensionsResponse: DimensionData[] | undefined
  getDimensionsError: FlatObject | undefined
  getDimensionsLoading: boolean
} => {
  const [getDimensionsError, setGetDimensionsError] = useState<FlatObject | undefined>()
  const [getDimensionsLoading, setGetDimensionsLoading] = useState<boolean>(false)
  const [getDimensionsResponse, setGetDimensionsResponse] = useState<DimensionData[] | undefined>()

  const { getBearerToken } = useLucidAuthContext();

  const getMeasureDimensions = async (): Promise<void> => {
    try {
      setGetDimensionsLoading(true)

      const bearerToken = await getBearerToken();

      if (bearerToken && dataPodId && measureId) {

        const { data, status } = await analyticsMeasureServices.getMeasureDimensions(bearerToken, dataPodId, measureId)

        if (status == 200) {
          setGetDimensionsResponse(data)
        }
        else {
          setGetDimensionsError({ time: Date.now(), message:'Error, while fetching Measure dimensions.'})
        }

        setGetDimensionsLoading(false)
      }
    }
    catch (error: any) {
      setGetDimensionsError({ time: Date.now(), message:'Error, while fetching Measure dimensions.'})
    }
  }

  useEffect(() => {
    if (dataPodId && measureId) {
      getMeasureDimensions()
    }
  }, [dataPodId, measureId])

  return {
    getDimensionsResponse,
    getDimensionsError,
    getDimensionsLoading
  }
}

export const useSynchronizeMeasureDimensions = (
  dataPodId?: string,
  measureId?: number,
): {
  syncDimensionsError: FlatObject | undefined
  synchronizeDimensions: (dimensionData: DimensionData[]) => Promise<void>
} => {
  const [syncDimensionsError, setSyncDimensionsError] = useState<FlatObject | undefined>()

  const { getBearerToken } = useLucidAuthContext();

  const synchronizeDimensions = async (dimensionData: DimensionData[]): Promise<void> => {
    try {
      const bearerToken = await getBearerToken();

      if (bearerToken && dataPodId && measureId && dimensionData) {

        const { data, status } = await analyticsMeasureServices.synchronizeMeasureDimensions(bearerToken, dataPodId, measureId, dimensionData)

        if (status == 200) {
          setSyncDimensionsError(undefined)
        }


        } else {
          setSyncDimensionsError({ time: Date.now(), message:'Error, while updating Measure details.'})
        }
      }
    catch (error: any) {
      setSyncDimensionsError({ time: Date.now(), message:'Error, while updating Measure details.'})
    }
  }

  return {
    syncDimensionsError,
    synchronizeDimensions
  }
}

export const useGetDataScienceModels = (
  datapodId?: string,
  measureName?: string,
): {
  isGetDataScienceModelsLoading: boolean
  getDataScienceModelsError: any
  getDataScienceModelsData?: AdvAnalyticsResponse
  getDataScienceModels: () => Promise<void>
} => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)
  const [data, setData] = useState<AdvAnalyticsResponse>()

  const getDataScienceModels = async () => {
    setIsLoading(true)

    try {
      if (datapodId && measureName) {
        const { status, data } = await analyticsMeasureServices.getDataScienceModels({
          dataPodId: datapodId,
          measure: measureName,
        })

        if (status === 200) {
          setData(data)
        }
      }
    } catch (error: any) {
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  // useEffect(() => {
  //   if (datapodId && measureName) {
  //     fetchData(datapodId, measureName);
  //   }
  // }, [measureName]);

  return {
    isGetDataScienceModelsLoading: isLoading,
    getDataScienceModelsError: error,
    getDataScienceModelsData: data,
    getDataScienceModels,
  }
}