import { useEffect, useState, useCallback } from 'react'
import debounce from 'lodash.debounce'
import { DataPod, DataPodInfo, DataPodType } from '../businessObjects'
import { datapodServices } from '../apiServices/dataPods'
import { useLucidAuthContext } from '../LucidAuthContext/LucidAuthContext'
import { useRegisterDataPod } from './dataProfilingHooks'
import { AxiosError } from 'axios'

export type ServerDataPodParams = {
  id: string
  dataPodName: string
  dataPodDesc: string
  accountId: string
  industryId: number
  industryName: string
  isActive: boolean
  datapodType?: number
}

export type UpdateDataPodParams = {
  id: string
  dataPodName: string
  dataPodDesc: string
  accountId: string
  industryId: number
  datapodType?: number
}
type SubmitDataPod = (params: DataPodInfo) => Promise<void>
type UpdateDataPod = (dataPodId: string, params: DataPodInfo) => Promise<void>

/*** fetchAllDataPods if podId is not passed. pass podId to get corresponding DataPod */
type GetPods = (podId?: string, recordCount?: number, pageNumber?: number, searchText?: string) => Promise<void>

const toClientPods = (podres: ServerDataPodParams): DataPod => {
  return {
    id: podres.id,
    name: podres.dataPodName,
    description: podres.dataPodDesc,
    industryId: podres.industryId,
    accountId: podres.accountId,
    industryName: podres.industryName,
    datapodType: podres.datapodType,
  }
}

export const useCreateDataPod = (): [SubmitDataPod, DataPod | undefined, boolean | undefined] => {
  const [dataPod, setDataPod] = useState<DataPod>()
  const [loading, setLoading] = useState<boolean>(false)
  const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken, accountId } = useLucidAuthContext()
  const submitDataPod = async (params: DataPodInfo): Promise<void> => {
    console.log('****into the submit data pod*****')
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await datapodServices.createDataPod(bearerToken, params)
        console.log('****`{data}`*****')
        if (status == 200) {
          //console.log(status)
          setDataPod(toClientPods(data))
          setLoading(false)
        }
      }
    } catch (error) {
      console.log(error)
      setCreateError(true)
      setLoading(false)
    }
  }
  return [submitDataPod, dataPod, createError]
}

export const useCreateDataSource = (dataPodId: string) => {
  const [dataSourceDetails, setDataSourceDetails] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken, accountId } = useLucidAuthContext()
  const createDataSource = async (DSPayload: any): Promise<void> => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        // Service connection Payload
        // const DSPayload: any = {}
        const { data, status } = await datapodServices.createDataSourceDetails(bearerToken, dataPodId, DSPayload)
        setDataSourceDetails(data)
        if (status == 200) {
          console.log(status)
          setLoading(false)
          return data
        }
      }
    } catch (error) {
      console.log(error)
      setCreateError(true)
      setLoading(false)
    }
  }

  return [createDataSource, dataSourceDetails, createError]
}

export const useUpdateDataPod = (): [UpdateDataPod, DataPod | undefined, boolean | undefined, boolean | undefined] => {
  const [dataPod, setDataPod] = useState<DataPod>()
  const [loading, setLoading] = useState<boolean>(false)
  const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const updateDataPod = async (dataPodId: string, params: DataPodInfo): Promise<void> => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const datapod: UpdateDataPodParams = {
          id: dataPodId,
          dataPodName: params.name,
          industryId: params.industryId,
          dataPodDesc: params.description,
          accountId: params.accountId,
          datapodType: params.datapodType,
        }
        const { data, status } = await datapodServices.updateDatapod(bearerToken, datapod)
        //console.log(data)
        if (status == 200) {
          //console.log(status)
          setDataPod(toClientPods(data))
          setLoading(false)
        }
      }
    } catch (error) {
      const err = error as AxiosError
      console.log(err.message)
      setCreateError(true)
      setLoading(false)
    }
  }
  return [updateDataPod, dataPod, loading, createError]
}
type Pagination = {
  updatePageCount?: (pages: number) => void
}
export const useGetAllDataPods = ({
  updatePageCount,
}: Pagination): [GetPods, DataPod[] | undefined, boolean | undefined, boolean | undefined] => {
  const [dataPod, setDataPod] = useState<DataPod[]>()
  const [loading, setLoading] = useState<boolean>()
  const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken, accountId } = useLucidAuthContext()
  const getPods = async (
    podId?: string,
    recordCount?: number,
    pageNumber?: number,
    searchText?: string,
  ): Promise<void> => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && accountId) {
        if (!podId) {
          const { data, status, headers } = await datapodServices.getallDataPods(
            bearerToken,
            accountId,
            recordCount ? recordCount : 100,
            pageNumber ? pageNumber : 1,
            searchText,
          )
          //console.log({ headers })

          if (status == 200) {
            const serverDataPods: ServerDataPodParams[] = data
            const pods = serverDataPods.map(toClientPods)
            setDataPod(pods)
            setLoading(false)
            if (updatePageCount && recordCount) {
              const totalRecords = headers['x-total-count']
              const totalPages = Math.round(totalRecords / recordCount)
              updatePageCount(totalPages)
            }
          }
        } else {
          const { data, status } = await datapodServices.getDataPod(bearerToken, podId)
          if (status == 200) {
            //console.log(status)
            const serverDataPods: ServerDataPodParams[] = data
            const pods = serverDataPods.map(toClientPods)
            setDataPod(pods)
            setLoading(false)
          } else {
            console.log({ status })
            console.log(data.message)
          }
        }
      }
    } catch (error) {
      const err = error as AxiosError
      console.log(err.message)
      setCreateError(true)
      setLoading(false)
    }
  }
  return [getPods, dataPod, loading, createError]
}

export const useGetDataPods = (
  { updatePageCount }: Pagination,
  searchText?: string,
  recordCount?: number,
  pageNumber?: number,
): {
  allDatapods?: DataPod[]
  getPods: (searchString?: string) => Promise<void>
  loading: boolean | undefined
  error: boolean | undefined
} => {
  const [dataPod, setDataPod] = useState<DataPod[]>()
  const [loading, setLoading] = useState<boolean>()
  const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken, accountId } = useLucidAuthContext()
  const getPods = async (searchString?: string): Promise<void> => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && accountId) {
        const { data, status, headers } = await datapodServices.getallDataPods(
          bearerToken,
          accountId,
          recordCount ? recordCount : 100,
          pageNumber ? pageNumber : 1,
          searchString,
        )
        //console.log({ headers })

        if (status == 200) {
          const serverDataPods: ServerDataPodParams[] = data
          const pods = serverDataPods.map(toClientPods)
          setDataPod(pods)
          setLoading(false)
          if (updatePageCount && recordCount) {
            const totalRecords = headers['x-total-count']
            const totalPages = Math.round(totalRecords / recordCount)
            updatePageCount(totalPages)
          }
        }
      }
    } catch (error) {
      const err = error as AxiosError
      console.log(err.message)
      setCreateError(true)
      setLoading(false)
    }
  }

  const debouncedFetch = useCallback(
    debounce((value: string) => {
      getPods(value)
    }, 500),
    [],
  )

  useEffect(() => {
    if (updatePageCount) {
      // debouncedFetch(searchText || '')
      getPods(searchText)
    }
  }, [searchText, pageNumber, recordCount, accountId])
  return { allDatapods: dataPod, getPods, loading, error: createError }
}

export const useGetDataPodInfo = (dataPodID?: string): { dataPodInfo: DataPod | undefined } => {
  const [dataPod, setDataPod] = useState<DataPod>()

  const { getBearerToken } = useLucidAuthContext()
  const getPodInfo = async (podId?: string): Promise<void> => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && podId) {
        const { data, status } = await datapodServices.getDataPod(bearerToken, podId)
        if (status == 200) {
          //console.log(status)
          const serverDataPods: ServerDataPodParams[] = data
          const pods = serverDataPods.map(toClientPods)
          setDataPod(pods.length ? pods[0] : undefined)
        }
      }
    } catch (error) {
      const err = error as AxiosError
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (!dataPod && dataPodID) {
      getPodInfo(dataPodID)
    }
  }, [dataPodID])
  return { dataPodInfo: dataPod }
}

export const useGetDataPodTypes = (): {
  allDatapodTypes: DataPodType[]
} => {
  const [datapodType, setDataPodType] = useState<DataPodType[]>([])
  const { getBearerToken, accountId } = useLucidAuthContext()
  const getPodTypes = async (): Promise<void> => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && accountId) {
        const { data, status, headers } = await datapodServices.getDataPodType(bearerToken)

        if (status == 200) {
          setDataPodType(data)
        }
      }
    } catch (error) {
      const err = error as AxiosError
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (!datapodType.length) {
      getPodTypes()
    }
  }, [])

  return { allDatapodTypes: datapodType }
}

export const useDeleteDataPod = (): [deleteDataPod: (dataPodId: string) => Promise<boolean | undefined>] => {
  const { getBearerToken } = useLucidAuthContext()
  const deleteDataPod = async (dataPodId: string) => {
    try {
      const token = await getBearerToken()
      if (token) {
        const { status } = await datapodServices.deleteDataPod(token, dataPodId)
        return status == 204
      }
    } catch (error) {
      const { message } = error as AxiosError
      console.log({ message })
    }
  }

  return [deleteDataPod]
}
