import React from 'react'
import { DataTableHeadProps } from './DataTableTypes'
import { TableHead, TableRow, Checkbox, TableSortLabel } from '@mui/material'
import { green } from '@mui/material/colors'
import { StyledTableCell } from './DataTable'

export const DataTableHead: React.FC<DataTableHeadProps> = ({
  columns,
  onSelectAllClick,
  doSort,
  sortable,
  checkable,
}: DataTableHeadProps) => {
  const [allSelected, setAllSelected] = React.useState<boolean>(false)
  const sortDescendingInitial = columns.map(() => false)
  const [sortDescending, setSortDescending] = React.useState<boolean[]>(sortDescendingInitial)

  const clicked = (): void => {
    onSelectAllClick(!allSelected)
    setAllSelected(!allSelected)
  }

  const sortBy = (id: number): void => {
    doSort(id, sortDescending[id] ? 'desc' : 'asc')
    sortDescending[id] = !sortDescending[id]
    setSortDescending(sortDescending)
  }

  return (
    <TableHead>
      <TableRow>
        {checkable && (
          <StyledTableCell padding="checkbox">
            <Checkbox
              disableRipple
              onClick={clicked}
              checked={allSelected}
              sx={{
                color: 'green',
                '&.Mui-checked': {
                  color: green[600],
                },
              }}
              inputProps={{ 'aria-label': 'select all' }}
            />
          </StyledTableCell>
        )}

        {columns.map((col, i) => {
          const cellContent = sortable ? (
            <TableSortLabel direction={sortDescending[i] ? 'desc' : 'asc'} onClick={() => sortBy(i)}>
              <b>{col.label}</b>
            </TableSortLabel>
          ) : (
            <b>{col.label}</b>
          )
          return (
            <StyledTableCell key={col.label} align={col.alignment === 'right' ? 'right' : 'left'}>
              {cellContent}
            </StyledTableCell>
          )
        })}
        {/* <StyledTableCell align="right" /> */}
      </TableRow>
    </TableHead>
  )
}

export default DataTableHead
