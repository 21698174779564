import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@mui/material/styles'
import { ThemeOptions } from '@mui/material/styles'
import { Typography } from '@mui/material/styles/createTypography'
import './fonts.css'

const typography: Typography = {
  fontFamily: 'Open Sans',
  h1: {
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '27.24px',
    //letterSpacing: '0.25px',
  },
  h2: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '27.24px' /* letterSpacing: '0.25px' */,
  },
  h3: {
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24.51px' /* letterSpacing: '0.25px' */,
  },
  h4: {
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '21.79px' /*  letterSpacing: '0.25px' */,
  },
  h5: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '19.07px' /* letterSpacing: '0.25px' */,
  },
  h6: {
    fontFamily: 'Open Sans',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '19.07px' /* letterSpacing: '0.25px' */,
  },
  subtitle1: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16.34px',
    //letterSpacing: '0.15px',
  },
  subtitle2: {
    fontFamily: 'Open Sans',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16.34px',
    //letterSpacing: '0.1px',
  },
  body1: { fontFamily: 'Open Sans', fontWeight: 500, fontSize: '14px', lineHeight: '19.07px' },
  body2: { fontFamily: 'Open Sans', fontWeight: 400, fontSize: '12px', lineHeight: '20px', letterSpacing: '0.25px' },
  button: { fontFamily: 'Open Sans', fontWeight: 500, fontSize: '12px', lineHeight: '20px', letterSpacing: '0.25px' },
  caption: {
    fontFamily: 'Open Sans',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '14.4px',
    letterSpacing: '0.25px',
  },
  overline: { fontFamily: 'Open Sans', fontWeight: 500, fontSize: '12px', lineHeight: '20px', letterSpacing: '0.25px' },
  fontSize: 14,
  fontWeightLight: 400,
  fontWeightRegular: 500,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  htmlFontSize: 16,
  pxToRem: function (px: number): string {
    return `${px * 0.062}rem`
  },
}

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',

    primary: {
      main: '#5048E9',
      light: '#1FADFF',
      dark: '#1D3783',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#473D70',
      light: '#EDF0F6',
      dark: '#1C143C',
      contrastText: '#000000',
    },
    text: {
      primary: '#202020',
      secondary: '#7E7F97',
      disabled: '#3C4657',
    },
    grey: {
      '500': '#C2C2C2',
      '400': '#DEDEDE',
      '300': '#F0F0F0',
      '200': '#FAFAFA',
      '100': '#FDFDFF',
    },
    background: {
      paper: '#FFFFFF',
      default: '#F7FAFC',
    },
    divider: '#D8E7EC',
    error: {
      main: '#CF7676',
      light: '#FBF3F3',
      dark: '#AF3E3E',
    },
    success: {
      main: '#76CF7A',
      light: '#F4FBF3',
      dark: '#4AB24E',
    },
    info: {
      main: '#A276CF',
      light: '#F8F1FF',
      dark: '#6A4C89',
    },
    warning: {
      main: '#FFA726',
      light: '#FDF0DE',
      dark: '#F57C00',
    },
    getContrastText: (background: string) => {
      if (background === 'white') return '#FFFFFF'
      return '#000000'
    },
  },
  typography: typography,

  components: {
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            mr: '-15px',
            ml: '10px',
          }),
      },
    },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: ({ theme }) =>
    //       theme.unstable_sx({
    //         backgroundColor: theme.palette.divider,
    //       }),
    //   },
    // },
  },
}

// Create a theme instance.
export const theme = createMuiTheme(themeOptions)

export const { breakpoints, spacing } = createMuiTheme()
