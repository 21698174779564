import {
  Typography,
  Grid,
  TextField,
  MenuItem,
  Button,
  Menu,
  IconButton,
  Card,
  Box,
  CardContent,
  Link,
  Paper,
} from '@mui/material'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import React, { useEffect, useMemo, useState } from 'react'
import { LucidPopUp } from '../../../ui-components/LucidPopUp/LucidPopUp'
import ActionPage from '../../../ui-components/ActionPage'
import {
  AddCoreEntityAttribute,
  CoreEntity,
  CreateJobParams,
  CreateJobResponse,
  CustomEntityData,
  DataAttribute,
  Entity,
  EntityReconData,
  JobClusterConfig,
  NewEntityData,
  NewParent,
  RelationShipResponse,
  RunStatusResponse,
  UpdateEntityNameBody,
  coreEntityAttributeData,
  getCoreEntityAttributeResponse,
} from '../../../businessObjects'
import { isValidDescription, isValidEntry } from '../../../utils/constants'
import {
  useAddCustomEntity,
  useAddNewEntity,
  useDeleteEntity,
  useGetCoreEntity,
  useGetCoreEntityByID,
  useGetCustomEntityRecon,
  useGetEntities,
  useGetEntityNB,
  useSaveEntityNB,
  useUpdateCustomEntity,
  useUpdateEntityNB,
  useUpdateEntityName,
} from '../../../hooks/entityHooks'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ProgressBar } from '../../../ui-components/ProgressBar'
import {
  useAddEntityRecon,
  useCustomEnrichedGetEntityRelationships,
  useDeleteEntityRecon,
} from '../../../hooks/applicationHooks'
import { LucidJupyter } from '../../../ui-components/JupyterComponents/LucidJupyter'
import { ROUTES } from '../../../Routes/constants'
import { AddOutlined, HomeOutlined } from '@mui/icons-material'
import { SparkCode, useGenerateEnrichedModel } from '../../../hooks/sparkHooks'
import { useAddNewCustomAttributeTransformer, useGetDataEntityTransformer } from '../../../hooks/dataTransformerHooks'
import DataPodDetailRibbon from '../../../ui-components/DataPodDetailRibbon/DataPodDetailRibbon'
import { useGetDataPodInfo } from '../../../hooks/datapodHooks'
import AnimatedLoader from '../../../ui-components/AnimatedLoader'
import { DataTableRow } from '../../../ui-components/DataTable/DataTableTypes'
import ShadowTable from '../../../ui-components/ShadowTable/ShadowTable'
import { usePostCreateDatabricksJob } from '../../../hooks/databricksHooks'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import { filterActiveRunIds, initiateActiveRunId } from '../../../features/notification/notificationSlice'
import ResultStatus from '../../../ui-components/ResultStatus'
import { useGetServiceConnection } from '../../../hooks/dataEngineeringHooks'
import {
  useAddMultipleCoreEntityAttributes,
  useGetCoreEntityAttributes,
  useUpdateCoreAttributeDetails,
} from '../../../hooks/coreEntityAttributeHooks'
import { useGetDataAttributesByMultipleEntityId } from '../../../hooks/dataAttrbutesHooks'
import { paddingLeft } from '../../../styles/globalStyles'
import { useGetGenAiEnrichedCoreEntityAttributes } from '../../../hooks/genAIApiHooks'
// import { useGetGenAiCoreEntityAttributes } from '../../../hooks/genAIApiHooks'
import constants from '../../constants'
import LoadingButton from '@mui/lab/LoadingButton'

type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
  customEntityId: string
}

type EntityType = {
  id: number
  type: string
}

type FunctionsType = {
  id: number
  function: string
}

type SelectedAttribute = {
  attributeName: string
  attributeDescription: string
  attributeType: string
}
type GeneratedEnrichedAttribute = {
  attributeName: string
  attributeDescription: string
}

type UpdatedDataAttribute = DataAttribute & {
  dataEntityName: string | undefined | null
}

const initCoreEntity = {
  coreEntity: true,
  profileName: 'clientApp',
  description: '',
  dataEntityName: '',
  entityType: 'reference',
  dataLakeZone: 2,
}
const entityTypes = [
  { id: 1, value: 'Reference' },
  { id: 2, value: 'Transaction' },
]

const promptTextFunctions = [
  { id: 1, function: 'aggregate' },
  { id: 2, function: 'groupBy' },
  { id: 3, function: 'filter' },
]
export const CustomEnrichedModel = () => {
  const { dataPodId, customEntityId } = useParams<ViewResultsParams>()
  const currentEntityId = customEntityId ? Number(customEntityId) : undefined
  const [enrichedTableData, setEnrichedTableData] = useState<NewEntityData>(initCoreEntity)
  const { dataPodInfo } = useGetDataPodInfo(dataPodId)
  const [userInput, setUserInput] = useState<string>('')
  const [userInput2, setUserInput2] = useState<string>('')
  const [userInput3, setUserInput3] = useState<string>('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null)
  const [anchorEl3, setAnchorEl3] = useState<null | HTMLElement>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const [coreEntityEdit, setCoreEntityEdit] = useState<boolean>(false)
  //const [coreEntityDisplay, setCoreEntityDisplay] = useState<boolean>(false)
  const { saveNewEntity } = useAddNewEntity(dataPodId ?? '')
  const [entityData, setEntityData] = useState<Entity[]>([])
  const [entityDataAll, setEntityDataAll] = useState<Entity[]>([])
  const [coreEntityAttribute, setCoreEntityAttributeData] = useState<UpdatedDataAttribute[]>([])
  const [currentChildEntityIds, setCurrentChildEntityIds] = useState<EntityReconData[]>([])
  const { entities: fetchedEntities } = useGetEntities(dataPodId)
  const { addEntityRecon } = useAddEntityRecon(dataPodId)

  const [updateEntityName, newEntityName] = useUpdateEntityName()
  const [notebookString, setNotebookString] = useState<string>()
  const [commitMessage, setcommitMessage] = useState<string>('initial commit')
  const [processingReq, setProcessingReq] = useState<boolean>(false)

  const [query, setQuery] = useState<SparkCode>()
  const [newCoreEntityId, setNewCoreEntityId] = useState<number>()
  const { updateCustomEntity, errorUpdatingEntity } = useUpdateCustomEntity()
  const { confirmDeleteEntityRecon } = useDeleteEntityRecon()
  const { entity: customEntity } = useGetCoreEntityByID(dataPodId, currentEntityId)
  const { fetchCustomEntityChildren } = useGetCustomEntityRecon(dataPodId)

  const { saveNewCustomEntity } = useAddCustomEntity(dataPodId)
  const { genEnrichedSparkCode } = useGenerateEnrichedModel(dataPodId)
  const { entityNBstring, error } = useGetEntityNB(dataPodId, currentEntityId)
  const { postCreateDatabricksJob } = usePostCreateDatabricksJob()
  const { saveNoteBoook } = useSaveEntityNB(dataPodId)
  const { updateEntityNB } = useUpdateEntityNB(dataPodId)
  const { addTransformer } = useAddNewCustomAttributeTransformer(dataPodId)
  const { dataTransformers } = useGetDataEntityTransformer(dataPodId, currentEntityId)
  const { getConnections } = useGetServiceConnection()
  const [entityId, setEntityId] = useState<number[]>([])
  const [attributeSelected, setAttributeSelected] = useState<String[]>([])
  const [attributesToDelete, setAttributesToDelete] = useState<string[]>([])
  const fetchCustomEntityRelationships = useCustomEnrichedGetEntityRelationships()
  const [fetchedRelations, setFetchedRelations] = useState<RelationShipResponse[]>([])
  const [relationTableFlag, setRelationTableFlag] = useState<boolean>(false)
  const navigate = useNavigate()
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [showPopUp, setShowPopUp] = useState<boolean>(false)

  const [errorMessage, setErrorMessage] = useState<string>()
  const [errorState, setErrorState] = useState<boolean>(false)

  const [jobClusterParams, setJobClusterParams] = useState<JobClusterConfig | null>(null)

  const activeRunIds = useAppSelector((state: RootState) => state.notification.activeRunIds)
  // const { fetchCoreEntityAttributes } = useGetGenAiCoreEntityAttributes()
  const { fetchEnrichedCoreEntityAttributes } = useGetGenAiEnrichedCoreEntityAttributes()
  const [fetchCoreAttributes, coreAttributes] = useGetCoreEntityAttributes(dataPodId)
  const { refetchAttributes } = useGetDataAttributesByMultipleEntityId()
  const [attributeChanged, setAttributeChanged] = useState<boolean>(false)
  const [entityChanged, setEntityChanged] = useState<boolean>(false)
  const [submitNewAttribute] = useAddMultipleCoreEntityAttributes()
  const [selectedAttributes, setSelectedAttributes] = useState<SelectedAttribute[]>([])
  const [generatedEnrichedAttributes, setGeneratedEnrichedAttributes] = useState<any[]>([])
  const [nbStringPresent, setnbStringPresent] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const [DataSystemEntityIds, setDataSystemEntityIds] = useState<any>([])
  const [fetchLoading, setFetchLoading] = useState<boolean>(false)
  useEffect(() => {
    const fetchJobClusterParams = async () => {
      if (dataPodId) {
        const serviceConnection = await getConnections(dataPodId, undefined, 'Spark')

        if (serviceConnection && serviceConnection.length > 0) {
          const jsonTemplate = serviceConnection[0].serviceConnectionJsonTemplate
          const parsedJson = JSON.parse(jsonTemplate)
          const params: JobClusterConfig = {
            url: parsedJson.workspace_Url,
            token: parsedJson.PAT_Token,
            clusterKey: parsedJson.cluster_Id,
            sparkVersion: parsedJson.sparkVersion,
            nodeTypeId: parsedJson.nodeTypeId,
          }
          setJobClusterParams(params)
        }
      }
    }

    fetchJobClusterParams()
  }, [])

  useEffect(() => {
    if (customEntity && !newCoreEntityId) {
      setEnrichedTableData({
        coreEntity: true,
        profileName: customEntity.profileName,
        description: customEntity.description,
        dataEntityName: customEntity.dataEntityName,
        entityType: customEntity.entityType,
        dataLakeZone: 2,
      })
    }
  }, [customEntity, newCoreEntityId])

  const getEntityChilds = async () => {
    const childEntites = await fetchCustomEntityChildren(Number(customEntityId))
    // console.log({ childEntites })
    if (childEntites) {
      setCurrentChildEntityIds(childEntites)
      const usrInpt = childEntites.map((ce) => `@${ce.child}`).join(' ')
      setUserInput(usrInpt)
      const ids = childEntites.map((item) => item.dataSystemEntityId)
      // getEntityRelations(ids)
      setEntityId(ids)
    }
  }
  const getEntityRelations = async (entityIds: any) => {
    // console.log("Inside get entity relations")
    const entityArray: number[] = Array.from(entityIds)
    const response = await fetchCustomEntityRelationships(dataPodId, entityArray)
    if (response && response.length) {
      setRelationTableFlag(true)
      setFetchedRelations(response)
    }
  }
  const getAttributes = async (coreEntityId: number) => {
    if (dataPodId) {
      await fetchCoreAttributes(coreEntityId)
    }
  }
  const concatenateAttributeNames = (attributes: getCoreEntityAttributeResponse[]): string => {
    console.log({ attributes })
    return attributes.map((attribute) => `@${attribute.dataCoreAttributeName}`).join(' ')
  }

  // const getAttributeEntityNameByAttributeName = (attributeName: string, attributeProfiles: DataAttribute[]) => {
  //   // if(attributeProfiles){
  //   const attribute = attributeProfiles.find((attr) => attr.dataAttributeName === attributeName);
  //   if (!attribute) {
  //     return null;
  //   }

  //   const entity = fetchedEntities.find((ent) => ent.id === attribute.dataEntityId);
  //   if (!entity) {
  //     return null;
  //   }

  //   return `${entity.dataEntityName}.${attribute.dataAttributeName}`;
  //   // }

  // }

  // const fetchAllAttributesOfEntities = async (dataSystemEntityIds: number[]) => {
  //   const attributeProfiles = await refetchAttributes(dataSystemEntityIds)
  //   // console.log({attributeProfiles})
  //   // console.log({fetchedEntities})
  //   if (attributeProfiles?.length) {
  //     console.log({attributeProfiles})
  //     setCoreEntityAttributeData(attributeProfiles)
  //   }
  // }

  const fetchAllAttributesOfEntities = async (dataSystemEntityIds: number[]) => {
    const attributeProfiles = await refetchAttributes(dataSystemEntityIds)
    if (attributeProfiles?.length) {
      // Create a map of dataEntityId to dataEntityName
      const entityMap = new Map<number, string>()
      fetchedEntities.forEach((entity) => {
        entityMap.set(entity.id, entity.dataEntityName)
      })

      // Add dataEntityName to each attribute in attributeProfiles
      const enrichedAttributes = attributeProfiles.map((attr) => ({
        ...attr,
        dataEntityName: entityMap.get(attr.dataEntityId) || 'Unknown',
      }))

      console.log({ enrichedAttributes })
      setCoreEntityAttributeData(enrichedAttributes)
    }
  }

  useEffect(() => {
    if (coreAttributes?.length) {
      const attributesString = concatenateAttributeNames(coreAttributes)
      // console.log(attributesString)
      if (attributesString) {
        setUserInput3(attributesString)
      }
    }
  }, [coreAttributes, coreAttributes?.length])
  useEffect(() => {
    if (customEntityId && !userInput) {
      getEntityChilds()
    }
  }, [customEntityId, userInput])
  // useEffect(() => {
  //   if (coreAttributes) {
  //     const selectedAttributeNames = new Set(attributeSelected);
  //     const currentAttributeNames = new Set(coreAttributes.map(attr => attr.dataCoreAttributeName));

  //     // Attributes to be deleted are in coreAttributes but not in attributeSelected
  //     const toDelete = Array.from(currentAttributeNames).filter(attr => !selectedAttributeNames.has(attr));
  //     console.log('Attributes to delete:', toDelete);
  //     setAttributesToDelete(toDelete);
  //   }
  // }, [coreAttributes, attributeSelected, userInput3.length]);

  useEffect(() => {
    if (fetchedEntities && !entityData.length) {
      const dataSystemEntityIds = fetchedEntities.map((entity) => entity.id)
      // console.log(dataSystemEntityIds)
      setDataSystemEntityIds(dataSystemEntityIds)
      fetchAllAttributesOfEntities(dataSystemEntityIds)
      setEntityData(fetchedEntities)
      setEntityDataAll(fetchedEntities)
    }
  }, [fetchedEntities, fetchedEntities.length])

  useEffect(() => {
    if (entityNBstring && !notebookString) {
      if (entityNBstring !== 'Notebook not available!') {
        setNotebookString(entityNBstring)
        setnbStringPresent(true)
      }
    }
    if (error) {
      console.log(error)
    }
  }, [customEntityId, entityNBstring, error])

  useEffect(() => {
    if (notebookString?.length) {
      // console.log("the code already is present")
    } else {
      // console.log("code is not present")
    }
  }, [notebookString])

  useEffect(() => {
    if (dataTransformers.length && !userInput2) {
      //console.log({ dataTransformers })
      setUserInput2(dataTransformers[0].transformerText)
    }
  }, [dataTransformers])

  useEffect(() => {
    // console.log(entityId)
    if (entityId.length >= 2) {
      // console.log("Inside the use effect")
      const uniqueIds = new Set(entityId)
      getEntityRelations(uniqueIds)
    } else {
      setRelationTableFlag(false)
    }
  }, [entityId, entityId.length])
  useEffect(() => {
    if (userInput) {
      syncEntityIds()
    } else {
      setRelationTableFlag(false)
    }
  }, [userInput, userInput.length])
  useEffect(() => {
    if (currentEntityId) {
      // console.log("current entity id", currentEntityId)
      getAttributes(currentEntityId)
    }
  }, [newCoreEntityId, currentEntityId])
  // useEffect(() => {
  //   let typingTimer: any = 0
  //   function handleKeyUp(event: any) {
  //     const { key } = event
  //     if (key === 'Enter' || key === 'Tab') {
  //       setIsTyping(false)
  //       setSaveData(true)
  //       console.log('Enter or Tab is pressed')
  //     } else {
  //       setIsTyping(true)
  //       clearTimeout(typingTimer)
  //       typingTimer = setTimeout(() => {
  //         setIsTyping(false)
  //       }, 30000)
  //     }
  //   }
  //   const inputElement = document.getElementById('enrichedTableDescription')
  //   if (inputElement) {
  //     inputElement.addEventListener('keyup', handleKeyUp)

  //     return () => {
  //       inputElement.removeEventListener('keyup', handleKeyUp)
  //       clearTimeout(typingTimer)
  //     }
  //   }
  // }, [])
  // useEffect(() => {
  //   if (
  //     enrichedTableData?.dataEntityName &&
  //     enrichedTableData?.entityType &&
  //     enrichedTableData?.description &&
  //     saveData == true &&
  //     isTyping == false &&
  //     !newCoreEntity
  //   ) {
  //     onConfirmSave()
  //   }
  // }, [enrichedTableData, saveData])
  // useEffect(() => {
  //   if (newCoreEntityEdit && newCoreEntity) {
  //     const updatedCoreEntity = newCoreEntity
  //     if (
  //       enrichedTableData &&
  //       enrichedTableData.dataEntityName &&
  //       enrichedTableData.entityType &&
  //       enrichedTableData.description &&
  //       saveData == true &&
  //       isTyping == false
  //     ) {
  //       updatedCoreEntity.dataEntityName = enrichedTableData?.dataEntityName
  //       updatedCoreEntity.entityType = enrichedTableData.entityType
  //       updatedCoreEntity.description = enrichedTableData.description
  //       console.log(updatedCoreEntity)
  //       setNewCoreEntity(updatedCoreEntity)
  //     }
  //   }
  // }, [enrichedTableData, isTyping])

  const saveEnrichedModel = async () => {
    setLoading(true)
    setIsEditMode(false)
    if (enrichedTableData?.dataEntityName && enrichedTableData?.entityType && enrichedTableData?.description) {
      const res = await saveNewCustomEntity(enrichedTableData)
      if (res?.id) {
        // console.log(res)
        // console.log(userInput)
        const selectedEntitiesStrings = userInput
          .split('@')
          .filter((uv) => uv.trim().length > 0)
          .map((cs) => cs.trim())
        const selen = entityDataAll.filter((ed) =>
          selectedEntitiesStrings.some((ss) => ss.toLowerCase() === ed.dataEntityName.toLowerCase()),
        )
        const uniqEn = selen.filter((ed, ix) => selen.findIndex((ss) => ss.id === ed.id) === ix)
        let allEntityReconIds: number[] = []
        for (let i = 0; i < uniqEn.length; i++) {
          const reqBody = {
            dataCoreEntityId: res.id,
            dataSystemEntityId: uniqEn[i].id,
            profileName: 'string',
          }
          const enRecon = await addEntityRecon(reqBody)
          if (enRecon?.id) {
            allEntityReconIds = [...allEntityReconIds, enRecon?.id]
          }
        }
        if (userInput3.length && dataPodId && res && coreEntityAttribute.length) {
          const attributeNames = userInput3
            .split('@')
            .filter((name) => name.trim().length > 0)
            .map((name) => name.trim())
          console.log({ attributeNames })
          console.log({ coreEntityAttribute })
          const combinedBody: AddCoreEntityAttribute[] | boolean = generateEnrichedCoreAttributesBodySave(
            selectedAttributes,
            generatedEnrichedAttributes,
            dataPodId,
            res.id,
          )
          if (combinedBody && combinedBody.length) {
            const response = await submitNewAttribute(combinedBody)
            console.log(response)
          } else if (!combinedBody) {
            console.log('No need to create attributes')
          }
          // }
        }
        console.log(allEntityReconIds)
        console.log(selectedEntitiesStrings)
        if (allEntityReconIds.length === selectedEntitiesStrings.length) {
          console.log('Inside the if of the transformers')
          const transformBody = {
            dataAttributeMapId: allEntityReconIds[0],
            transformerText: userInput2,
            transformerQuery: '',
            dataEntityId: res.id,
            transformerTypeName: 'Enriched',
          }
          const success = await addTransformer(transformBody)
          if (success && success) {
            const pycode = await genEnrichedSparkCode(userInput2, res?.id)
            if (pycode?.data) {
              setNewCoreEntityId(res.id)
              setNotebookString(pycode.data)
              // console.log(notebookString)
              // console.log(newCoreEntityId)
              // console.log(commitMessage)
              // console.log("about to call save Notebook")
              const success = await saveNoteBoook(res.id, pycode.data, commitMessage)
              success && success && onClickHome()

              setLoading(false)
            } else {
              console.log('error: generate code')
            }
          }
        } else {
          console.log('Error: EntityRecon')
        }
      } else {
        console.log('Error: addNew entity')
      }
    }
  }

  const updateEnrichedModel = async () => {
    setLoading(true)
    setIsEditMode(true)
    if (
      enrichedTableData?.dataEntityName &&
      enrichedTableData?.entityType &&
      enrichedTableData?.description &&
      currentEntityId
    ) {
      const updateSuccess = await updateCustomEntity({
        id: currentEntityId,
        ...enrichedTableData,
        profileState: 'update',
      })
      if (updateSuccess) {
        const selectedEntitiesStrings = userInput
          .split('@')
          .filter((uv) => uv.trim().length > 0)
          .map((cs) => cs.trim())

        // const selen = entityData.filter((ed) => selectedEntitiesStrings.some((ss) => ss === ed.dataEntityName))
        const selen = entityData.filter((ed) =>
          selectedEntitiesStrings.some((ss) => ss.toLowerCase() === ed.dataEntityName.toLowerCase()),
        )
        const uniqEn = selen.filter((ed, ix) => selen.findIndex((ss) => ss.id === ed.id) === ix)
        // const uniqEn = selen.filter((ed, ix) => selen.findIndex((ss) => ss.id === ed.id) === ix)
        let allEntityReconIds: number[] = []

        // 1. eval newChild entites with ref current child entites
        // 2. eval child entites toDelete that are not included in the update
        const currentChildEIDS = currentChildEntityIds.map((ce) => ce.dataSystemEntityId)

        const selectedEIds = uniqEn.map((ue) => ue.id)

        const newEntitiesToAdd = uniqEn.filter((uq) => !currentChildEIDS.includes(uq.id))

        const childEntitiesToDelete = currentChildEntityIds.filter(
          (cID) => !selectedEIds.includes(cID.dataSystemEntityId),
        )

        for (let i = 0; i < newEntitiesToAdd.length; i++) {
          const reqBody = {
            dataCoreEntityId: currentEntityId,
            dataSystemEntityId: newEntitiesToAdd[i].id,
            profileName: 'string',
          }
          const enRecon = await addEntityRecon(reqBody)
          if (enRecon?.id) {
            allEntityReconIds = [...allEntityReconIds, enRecon?.id]
          }
        }
        if (userInput3.length && dataPodId && currentEntityId && coreEntityAttribute.length) {
          const attributeNames = userInput3
            .split('@')
            .filter((name) => name.trim().length > 0)
            .map((name) => name.trim())
          console.log({ attributeNames })
          console.log({ coreEntityAttribute })
          const combinedBody: AddCoreEntityAttribute[] | boolean = generateEnrichedCoreAttributesBodySave(
            selectedAttributes,
            generatedEnrichedAttributes,
            dataPodId,
            currentEntityId,
          )
          if (combinedBody && combinedBody.length) {
            const response = await submitNewAttribute(combinedBody)
            console.log(response)
          } else if (!combinedBody) {
            console.log('No need to create attributes')
          }
        }
        const toDeleteReconIds = childEntitiesToDelete.map((ct) => ct.id)
        // console.log({ toDeleteReconIds })
        if (toDeleteReconIds.length) {
          const deleteReconSuccess = await confirmDeleteEntityRecon(toDeleteReconIds)
          // console.log({ deleteReconSuccess })
        }
        if (allEntityReconIds.length === newEntitiesToAdd.length && newEntitiesToAdd.length) {
          const transformBody = {
            dataAttributeMapId: allEntityReconIds[0],
            transformerText: userInput2,
            transformerQuery: '',
            dataEntityId: currentEntityId,
            transformerTypeName: 'Enriched',
          }
          const success = await addTransformer(transformBody)
          if (success && success) {
            const pycode = await genEnrichedSparkCode(userInput2, currentEntityId)
            if (pycode?.data) {
              //setNewCoreEntityId(res.id)
              setNotebookString(pycode.data)
              setLoading(false)
              setCoreEntityEdit(false)
            } else {
              console.log('error: generate code')
            }
          }
        }
        if (!newEntitiesToAdd.length && !allEntityReconIds.length) {
          const pycode = await genEnrichedSparkCode(userInput2, currentEntityId)
          if (pycode?.data) {
            //setNewCoreEntityId(res.id)
            setNotebookString(pycode.data)
            if (notebookString && newCoreEntityId && commitMessage) {
              // console.log("about to call save Notebook")
              const success = await saveNoteBoook(newCoreEntityId, notebookString, commitMessage)
              success && success && onClickHome()
            }
            setLoading(false)
            setCoreEntityEdit(false)
          } else {
            console.log('error: generate code')
          }
        } else {
          console.log('Error: EntityRecon')
        }
      } else {
        console.log('Error: addNew entity')
      }
    }
  }

  const addEnrichedModelData = (name: string, value: string) => {
    setEnrichedTableData(
      (prev) =>
        ({
          ...prev,
          [name]: value,
        } as NewParent),
    )
    if (currentEntityId) {
      setCoreEntityEdit(true)
    }
  }
  const handleSelectMention = (id: number, entityName: string) => {
    const tempEntities = userInput.replace(/@$/, `@${entityName}`)
    if (!entityId.includes(id)) {
      setEntityId([...entityId, id])
    }
    setEntityChanged(true)
    setUserInput(tempEntities)
    // if (newCoreEntity && id) {
    //   const reqBody = {
    //     dataCoreEntityId: newCoreEntity.id,
    //     dataSystemEntityId: id,
    //     profileName: 'string',
    //   }
    //   saveNewEntityRecon(reqBody)
    // }
    setAnchorEl(null)
    if (currentEntityId && !coreEntityEdit) {
      setCoreEntityEdit(true)
    }
  }
  // const syncEntityIds = () => {
  //   const mentionPattern = /@(\w+)/g;
  //   let matches = userInput.match(mentionPattern);

  //   let currentMentions = matches ? matches.map(mention => mention.substring(1)) : [];

  //   const filteredEntityIds = entityId.filter((id, index) => {

  //     const entity = entityData.find(ent => ent.id === id);
  //     return entity && currentMentions.includes(entity.dataEntityName);
  //   });
  //   setEntityId(filteredEntityIds);
  // }
  const syncEntityIds = () => {
    const mentionPattern = /@(\w+)/g
    let matches = userInput.match(mentionPattern)
    let currentMentions = matches ? matches.map((mention) => mention.substring(1)) : []
    const filteredEntityIds = entityId.filter((id, index) => {
      const entity = entityDataAll.find((ent) => ent.id === id)
      return entity && currentMentions.includes(entity.dataEntityName)
    })
    setEntityId(filteredEntityIds)
    const tempEntities = entityDataAll.filter((ent) => !entityId.includes(ent.id))
    setEntityData(tempEntities)
  }
  const sourceDataRow = useMemo(() => {
    if (entityId.length || fetchedRelations.length) {
      return fetchedRelations.map((relation) => ({
        id: `${relation.id}`,
        values: [
          relation.leftEntityName,
          relation.leftAttributeName,
          relation.rightEntityName,
          relation.rightAttributeName,
          relation.cardinality,
        ],
      })) as DataTableRow[]
    }
    return []
  }, [fetchedRelations, fetchedRelations.length, entityId, entityId.length])
  const sourcePopUpHeaders = useMemo(() => {
    return [
      {
        label: 'Left Entity',
        sortableColumn: true,
      },
      {
        label: 'Left Attribute',
        sortableColumn: true,
      },
      {
        label: 'Right Entity',
        sortableColumn: true,
      },
      {
        label: 'Right Attribute',
        sortableColumn: true,
      },
      {
        label: 'Cardinality',
      },
    ]
  }, [])

  // const saveNewEntityRecon = async (addReconData: AddEntityRecon) => {
  //   if (addReconData) {
  //     const enRecon = await addEntityRecon(addReconData)
  //     console.log(userEnteredEntityIds)
  //     if (enRecon && userEnteredEntityIds.length >= 2) {
  //     }
  //   }
  // }
  // console.log(userEnteredEntityIds)
  const transformationTextChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    // const entityNamesArray = value.split(',')
    // entityNamesArray.forEach((entityName) => {
    //   const matchingEntities = fetchedEntities.filter((ent) => `@${ent.dataEntityName}` === entityName)
    //   if (matchingEntities.length > 0) {
    //     tempIds.push(...matchingEntities.map((ent) => ent.id))
    //   }
    // })

    // console.log(tempIds)
    // setUserEnteredEntityIds([...tempIds])
    setUserInput(value)
    // setShowColumnList(value.endsWith('@'))
    if (value.endsWith('@')) {
      setAnchorEl(e.currentTarget)
    } else {
      setAnchorEl(null)
    }
    if (currentEntityId && !coreEntityEdit) {
      setCoreEntityEdit(true)
    }
  }
  const transformationTextChangeHandler2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setUserInput2(value)
    // setShowColumnList(value.endsWith('@'))
    if (value.endsWith('@')) {
      setAnchorEl2(e.currentTarget)
    } else {
      setAnchorEl2(null)
    }
  }
  const transformationTextChangeHandler3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setUserInput3(value)
    // setShowColumnList(value.endsWith('@'))
    if (value.endsWith('@')) {
      setAnchorEl3(e.currentTarget)
    } else {
      setAnchorEl3(null)
    }
  }
  const handleSelectMention2 = (columnName: string) => {
    const promptText = userInput2.replace(/@$/, `@${columnName}`)
    setUserInput2(promptText)
    // const tempId = userEnteredEntityIds.includes(id)
    //   ? userEnteredEntityIds.filter((ix) => {
    //       return ix !== id
    //     })
    //   : [...userEnteredEntityIds, id]
    // const tmpUserInput = entities
    //   .filter((e) => tempId.includes(e.id))
    //   .map((ec) => `@${ec.dataEntityName}`)
    //   .join(',')
    // setUserInput(tmpUserInput)
    // setUserEnteredEntityIds(tempId)
    setAnchorEl2(null)
    if (currentEntityId && !coreEntityEdit) {
      setCoreEntityEdit(true)
    }
  }
  const handleSelectMention3 = (attributeName: string, attributeId: number, description: string, type: string) => {
    const promptText = userInput3.replace(/@$/, `@${attributeName}`)
    const selectedCoreAttribute = coreEntityAttribute.find((attr) => attr.id === attributeId)
    if (!attributeSelected.includes(attributeName) && selectedCoreAttribute) {
      setAttributeSelected([...attributeSelected, attributeName])
      // console.log(selectedCoreAttribute)
      setSelectedAttributes((prevAttributes) => {
        const isDuplicate = prevAttributes.some((attr) => attr.attributeName === attributeName)
        if (!isDuplicate) {
          return [...prevAttributes, { attributeName, attributeDescription: description, attributeType: type }]
        }
        return prevAttributes
      })
      setAttributeChanged(true)
    }
    setUserInput3(promptText)
    // const tempId = userEnteredEntityIds.includes(id)
    //   ? userEnteredEntityIds.filter((ix) => {
    //       return ix !== id
    //     })
    //   : [...userEnteredEntityIds, id]
    // const tmpUserInput = entities
    //   .filter((e) => tempId.includes(e.id))
    //   .map((ec) => `@${ec.dataEntityName}`)
    //   .join(',')
    // setUserInput(tmpUserInput)
    // setUserEnteredEntityIds(tempId)
    setAnchorEl3(null)
    // if (currentEntityId && !coreEntityEdit) {
    //   setCoreEntityEdit(true)
    // }
  }
  // const handleSelectMention3 = (attributeName: string, attributeId: number) => {
  //   const promptText = userInput3.replace(/@$/, `@${attributeName}`);

  //   // Find the selected attribute in the coreEntityAttribute array
  //   const selectedAttribute = coreEntityAttribute.find(attr => attr.id === attributeId);

  //   if (selectedAttribute) {
  //     setAttributeSelected(prevSelected => {
  //       if (!prevSelected.includes(attributeName)) {
  //         return [...prevSelected, attributeName];
  //       }
  //       return prevSelected;
  //     });

  //     setAttributeDict(prevDict => {
  //       if (!prevDict[attributeId]) {
  //         return {
  //           ...prevDict,
  //           [attributeId]: {
  //             AttributeName: attributeName,
  //             AttributeDescription: selectedAttribute.dataAttributeDescription // Use the correct property for description
  //           }
  //         };
  //       }
  //       return prevDict;
  //     });

  //     setAttributeChanged(true);
  //   }

  //   setUserInput3(promptText);
  //   setAnchorEl3(null);
  // };

  // const handleEditClick = () => {
  //   console.log('inside the edit click')
  //   setCoreEntityDisplay(!coreEntityDisplay)
  //   setNewCoreEntityEdit(true)
  //   // updateEntityName(newCoreEntity as UpdateEntityNameBody)
  // }

  // const handleDeleteClick = async () => {
  //   console.log('inside the delete click')
  //   const entityId: number[] = []
  //   if (newCoreEntity) {
  //     entityId.push(newCoreEntity?.id)
  //   }
  //   const res = await deleteEntity(entityId)
  //   if (res) {
  //   }
  // }

  const onClickHome = () => {
    const enrichedModelPage = generatePath(ROUTES.DataModelingEnterpriseEnrichedTables, {
      dataPodId: dataPodId,
    })
    navigate(enrichedModelPage)
  }
  const onButtonClick = () => {
    const enrichedModelToRelations = generatePath(ROUTES.DataModelingEntityRelationships, { dataPodId: dataPodId })
    navigate(enrichedModelToRelations)
  }
  // const clickNextAction = () => {
  //   console.log("Inside click next action")
  //   console.log(currentEntityId)
  // }
  const disableContinue = useMemo(() => {
    const disableCon = !(
      isValidEntry(enrichedTableData?.dataEntityName) &&
      isValidDescription(enrichedTableData?.description) &&
      userInput &&
      userInput2
    )
    if (!newCoreEntityId) return disableCon || loading
    if (newCoreEntityId) return !notebookString
    if (currentEntityId) return loading
    return
  }, [
    enrichedTableData?.dataEntityName,
    enrichedTableData?.description,
    userInput,
    userInput2,
    loading,
    newCoreEntityId,
    currentEntityId,
  ])

  const clickHandler = async () => {
    if (!currentEntityId) {
      // console.log("there is no core entity id present")
      // if (notebookString && newCoreEntityId && commitMessage) {
      //   // console.log("about to call save Notebook")
      //   const success = await saveNoteBoook(newCoreEntityId, notebookString, commitMessage)
      //   success && success && onClickHome()
      // } else {
      // console.log("note book else")
      // console.log("inside else of notebook string new core entity id and commit message")
      await saveEnrichedModel()
      // }
    } else {
      // if (!coreEntityEdit && notebookString && commitMessage) {
      //   setShowPopUp(!showPopUp)
      // } else {
      // console.log("inside corentityedit else")
      await updateEnrichedModel()
      // }
    }
  }
  const clickUpdateLabel = useMemo(() => {
    if (nbStringPresent) {
      return 'Update Code'
    } else {
      return 'Generate Code'
    }
  }, [isEditMode, nbStringPresent])

  const SaveUpdateLabel = useMemo(() => {
    if (nbStringPresent) {
      return 'Update Notebook'
    }
    return 'Save Notebook'
  }, [isEditMode, nbStringPresent])

  // const clickUpdateSaveOrEdit = useMemo(() => {
  //   if (attributeChanged) {
  //     return 'Save'
  //   }
  // }, [attributeChanged,coreAttributes?.length, attributeSelected])

  const handleNext = async () => {
    console.log('comes inside the handle next')
    console.log(notebookString, commitMessage, currentEntityId, coreEntityEdit)
    if (!coreEntityEdit && notebookString && commitMessage && currentEntityId) {
      const success = await updateEntityNB(currentEntityId, notebookString, commitMessage)
      success && success && onClickHome()
    }
  }
  const onTextChange = (name: string, value: string) => {
    setcommitMessage(value)
  }

  const executeNotebook = async () => {
    if (dataPodId && currentEntityId && jobClusterParams) {
      const params: CreateJobParams = {
        dataPodId,
        customEntityID: currentEntityId,
        jobClusterConfig: jobClusterParams,
      }
      const result = await postCreateDatabricksJob(params)

      if (result) {
        updateRunIdState(result)
      } else {
        setErrorMessage('Failed to create Databricks Job')
        setErrorState(true)
      }
    }
  }

  const updateRunIdState = (result: CreateJobResponse) => {
    const activeRunId = activeRunIds.find((element) => element.run_id === result.runId)

    if (!activeRunId) {
      const newRunId: RunStatusResponse = {
        run_id: result.runId,
        state: {
          life_cycle_state: 'INITIATED',
          result_state: 'LOADING',
        },
        params: {
          run_id: result.runId,
          url: result.url,
          token: result.token,
        },
      }

      dispatch(filterActiveRunIds())
      dispatch(initiateActiveRunId(newRunId))
    }
  }

  const closeErrorMessage = () => {
    setErrorState(false)
    setErrorMessage(undefined)
  }

  const generateEnrichedCoreAttributesBodySave = (
    userEnteredAttribute: SelectedAttribute[],
    genAIGeneratedAttributes: any[],
    dataPodId: string,
    coreEntityId: number,
  ) => {
    const attributes = [...userEnteredAttribute, ...genAIGeneratedAttributes]
    console.log({ attributes })
    if (attributes.length) {
      return attributes.map((attribute) => ({
        dataCoreEntityId: coreEntityId,
        dataCoreAttributeName:
          attribute.attributeName !== undefined ? attribute.attributeName : attribute.AttributeName,
        dataPodId: dataPodId,
        dataCoreAttributeDataType: 'string',
        dataCoreClassification: 'string',
        description:
          attribute.attributeDescription !== undefined
            ? attribute.attributeDescription
            : attribute.AttributeDescription,
        isBusinessKey: true,
        dataAttributeType: 'string',
      })) as AddCoreEntityAttribute[]
    } else {
      return false
    }
  }

  // const saveNewlyAddedCoreAttributes = async () => {
  //   if (attributeChanged) {
  //     if (selectedAttributes && dataPodId && currentEntityId) {
  //       // console.log({selectedAttributes})
  //       const cummulativeBody = generateCoreAttributesBody(selectedAttributes, dataPodId, currentEntityId)
  //       // console.log({cummulativeBody})
  //       const response = await submitNewAttribute(cummulativeBody)
  //       if (response) {
  //         getAttributes(currentEntityId)
  //         setAttributeChanged(false)
  //       }
  //     }
  //     // const resp = await updateCoreEntityAttibuteFunc()
  //   }
  // }
  const getGenAiEnrichedCoreEntityAttributes = async () => {
    // console.log('clicked')
    if (entityChanged && enrichedTableData && dataPodId && entityId.length) {
      setFetchLoading(true)
      // console.log("Inside if")
      // const entityIds = currentChildEntityIds.map((entity:any) => entity.dataSystemEntityId);
      // console.log(entityIds)
      if (DataSystemEntityIds) {
        const attributeProfiles = await refetchAttributes(DataSystemEntityIds)
        if (attributeProfiles) {
          const attributes = await fetchEnrichedCoreEntityAttributes(
            dataPodId,
            enrichedTableData.dataEntityName,
            enrichedTableData.description,
            enrichedTableData.entityType,
            entityId,
          )
          if (attributes) {
            // console.log(attributes)
            const coreEntityGeneratedAttributes = attributes.data
            // console.log(coreEntityGeneratedAttributes)
            setGeneratedEnrichedAttributes(coreEntityGeneratedAttributes)
            // const attributesString = coreEntityGeneratedAttributes.map(attribute => `@${attribute.AttributeName}`).join(' ');
            const attributesString = coreEntityGeneratedAttributes
              .map((attribute) => {
                return `@${attribute.AttributeName}`
              })
              .join(' ')

            setUserInput3(attributesString)
            setFetchLoading(false)
          }
        }
      }
    }
  }

  const onClickBack = (event: any): void => {
    onClickHome()
  }

  const handleNotebookString = async () => {
    if (!nbStringPresent) {
      if (notebookString && commitMessage && currentEntityId) {
        // console.log("about to call save Notebook")
        const success = await saveNoteBoook(currentEntityId, notebookString, commitMessage)
        success && success && onClickHome()
      }
    } else {
      if (!coreEntityEdit && notebookString && commitMessage) {
        setShowPopUp(!showPopUp)
      }
    }
  }

  return (
    <>
      {errorMessage && (
        <ResultStatus
          showStatus={errorState}
          alertMessage={errorMessage}
          severtiy="error"
          closeStatus={() => closeErrorMessage()}
        />
      )}
      <ActionPage>
        {/* <CardContent>
          <DataPodDetailRibbon
            dataPodId={dataPodId}
            dataPodName={dataPodInfo?.name}
            dataPodIndustryName={dataPodInfo?.industryName}
          />
        </CardContent> */}
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          spacing={2}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Grid item xs={4} display={'flex'} flexDirection={'row'} justifyContent={'flex-start'}>
            <Grid item xs={2.5}>
              <IconButton onClick={onClickHome} size="small" title="Data Modelling ">
                <HomeOutlined />
              </IconButton>
            </Grid>
            {/* <Grid item xs={4}> */}
            <Typography variant="h3" color="primary" gutterBottom>
              Enriched Data Entity
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Button
              variant="outlined"
              onClick={onClickBack}
              startIcon={<KeyboardBackspaceOutlinedIcon fontSize="small" />}
            >
              Back
            </Button>
          </Grid>
        </Grid>
        {/* <ProgressBar loading={loading} /> */}
        {/* <AnimatedLoader height="50%" width="40%" loading={loading} /> */}
        <Grid container item xs={14} alignItems={'stretch'} alignContent={'center'} spacing={2} paddingTop={'10px'}>
          <Grid item xs={5} container alignItems={'stretch'} alignContent={'baseline'} spacing={1}>
            <Grid item xs={10}>
              <Typography variant="h6" gutterBottom>
                Enriched Entity Name
              </Typography>

              <TextField
                placeholder="E.g Department Totals"
                size="small"
                fullWidth
                variant="outlined"
                id="outlined-basic"
                // label="Enter Enriched Table Name"
                required
                onChange={({ target }) => addEnrichedModelData('dataEntityName', target.value)}
                value={enrichedTableData?.dataEntityName}
                error={!isValidEntry(enrichedTableData?.dataEntityName)}
                //disabled={coreEntityDisplay}
              />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={10}>
              <Typography variant="h6" gutterBottom>
                Entity Type
              </Typography>

              <TextField
                fullWidth
                id="outlined-select-entityType"
                select
                required
                size="small"
                value={enrichedTableData?.entityType ?? 0}
                onChange={({ target }) => addEnrichedModelData('entityType', target.value)}
                //disabled={coreEntityDisplay}
              >
                {entityTypes.map((name, ix) => (
                  <MenuItem key={name.id} value={name.id}>
                    {name.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={10}>
              <Typography gutterBottom>Description</Typography>

              <TextField
                placeholder="E.g Department Totals"
                id="enrichedTableDescription"
                required
                size="small"
                fullWidth
                multiline
                value={enrichedTableData?.description}
                onChange={({ target }) => addEnrichedModelData('description', target.value)}
                rows={3}
                variant="outlined"
                //disabled={coreEntityDisplay}
              />
            </Grid>
            <Grid item xs={12} />

            <Grid item xs={10}>
              <Grid xs={10} alignItems={'center'}>
                <Typography variant="h6" gutterBottom>
                  Canonical Entities
                </Typography>
              </Grid>
              <TextField
                placeholder="@Sales, @Department"
                size="small"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                value={userInput}
                onChange={transformationTextChangeHandler}
              />
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                {entityData.map((ent) => (
                  <MenuItem key={ent.id} onClick={() => handleSelectMention(ent.id, ent.dataEntityName)}>
                    {ent.dataEntityName}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12} />
            <br></br>
            <Grid item xs={12}>
              {!fetchedRelations.length && (
                <Grid justifyContent={'center'}>
                  <Typography>
                    Warning: No relationships have been discovered between the selected entities. Code generation is
                    disabled until valid relationships are defined.
                  </Typography>
                </Grid>
              )}
              <Grid style={{ display: 'flex', marginTop: '5%' }} justifyContent="space-between">
                <Typography variant="h6" gutterBottom>
                  Entity Relationships
                </Typography>
                <Button onClick={() => onButtonClick()}>Edit In Relationship Page</Button>
              </Grid>
              <ShadowTable
                rows={sourceDataRow}
                columns={sourcePopUpHeaders}
                // selectedRow={`${userSelectedTarget?.targetColumnId ?? 0}`}
                // tableActionParams={{
                //   onButtonClick: onButtonClick,
                //   actions: [ShadowTableAction.EditLinkRelation],
                // }}
              />
            </Grid>
            <Grid
              item
              xs={10}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              style={{ marginTop: '5%' }}
            >
              <Grid xs={5} alignItems={'center'}>
                <Typography variant="h6" gutterBottom>
                  Core Attributes
                </Typography>
              </Grid>
              <Grid item xs={5} justifyContent={'center'}>
                {entityChanged && enrichedTableData && currentChildEntityIds && (
                  <LoadingButton
                    color="primary"
                    onClick={() => getGenAiEnrichedCoreEntityAttributes()}
                    variant="outlined"
                    loading={fetchLoading}
                    disabled={
                      !enrichedTableData.coreEntity ||
                      !enrichedTableData.entityType ||
                      !enrichedTableData.dataEntityName ||
                      !enrichedTableData.description
                    }
                  >
                    Refresh Core Attributes
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <TextField
                placeholder="@Id, @Name"
                size="small"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                value={userInput3}
                onChange={transformationTextChangeHandler3}
              />
              <Menu anchorEl={anchorEl3} open={Boolean(anchorEl3)} onClose={() => setAnchorEl3(null)}>
                {coreEntityAttribute.map((attr, idx) => (
                  <MenuItem
                    key={attr.id}
                    onClick={() =>
                      handleSelectMention3(attr.dataAttributeName, attr.id, attr.description, attr.dataAttributeType)
                    }
                  >
                    {attr.dataEntityName}:{attr.dataAttributeName}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h6" gutterBottom>
                Enter the Prompt text
              </Typography>

              <TextField
                placeholder="possible to use @Aggregate, @groupby & @filter"
                size="small"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                value={userInput2}
                onChange={transformationTextChangeHandler2}
              />
              <Menu anchorEl={anchorEl2} open={Boolean(anchorEl2)} onClose={() => setAnchorEl2(null)}>
                {promptTextFunctions.map((ent) => (
                  <MenuItem key={ent.id} onClick={() => handleSelectMention2(ent.function)}>
                    {ent.function}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
            <Grid item xs={12} />
          </Grid>
          <Grid item container xs={7}>
            <Card>
              {/* <ProgressBar loading={processingReq} /> */}
              <AnimatedLoader height="50%" width="40%" loading={processingReq} />
              <Grid display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNotebookString}
                  startIcon={<AddOutlined />}
                  disabled={!notebookString}
                >
                  {SaveUpdateLabel}
                </Button>
              </Grid>
              <LucidJupyter
                executeNotebook={executeNotebook}
                noteBookString={notebookString}
                headingText={constants.codeHeaderText}
                updateNoteBookString={setNotebookString}
                commitMessage={commitMessage}
                updateCommitMessage={setcommitMessage}
              />
            </Card>
          </Grid>
          <Grid item xs={8} container spacing={1}>
            <Grid item xs={1.5}>
              <Button variant="contained" fullWidth onClick={onClickHome}>
                Cancel
              </Button>
            </Grid>
            <Grid item />
            <Grid item xs={4.5}>
              <LoadingButton
                fullWidth
                variant="contained"
                onClick={clickHandler}
                loading={loading || processingReq}
                disabled={
                  !enrichedTableData.coreEntity ||
                  !enrichedTableData.dataEntityName ||
                  !enrichedTableData.description ||
                  !userInput2 ||
                  !userInput3.length
                }
              >
                {clickUpdateLabel}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>

        <LucidPopUp
          showCloseIcon
          closePopup={setShowPopUp}
          openPopUp={showPopUp}
          onConfirm={handleNext}
          headingText=""
          confirmText="Commit"
        >
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Link target="blank">Git Hub Repository</Link>
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ fontSize: 12 }}
                fullWidth
                id="outlined-basic"
                label="Enter Commit Message"
                variant="outlined"
                color="secondary"
                required
                rows={2}
                multiline
                value={commitMessage}
                onChange={({ target }) => onTextChange('commitMessage', target.value)}
              />
            </Grid>
          </Grid>
        </LucidPopUp>
      </ActionPage>
    </>
  )
}

export default CustomEnrichedModel
