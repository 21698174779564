import { Configuration, PopupRequest } from '@azure/msal-browser'
import { environment } from './utils/environment'

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: environment.clientID,
    authority: environment.authority,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  },
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ['User.Read', 'User.Read.All'],
  authority: environment.authority,
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
}
