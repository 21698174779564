import { useMemo, useEffect, useState } from 'react'
import { Button, Grid, Link, Typography } from '@mui/material'
import AddServiceConnection from '../Settings/ServiceConnection/AddServiceConnection'
import {
  usePostServiceConnection,
  ServiceConnectionType,
  useQueryServiceConnections,
} from '../../hooks/serviceConnectionHooks'
import NoEntries from '../../ui-components/NoEntries'
import { DataItemAction, DataTableColumn, DataTableRow } from '../../ui-components/DataTable/DataTableTypes'
import DataTable from '../../ui-components/DataTable/DataTable'
import ServiceNameWithIcon from '../../ui-components/ServiceNameWithIcon'
import ResultStatus from '../../ui-components/ResultStatus'
import AnimatedLoader from '../../ui-components/AnimatedLoader'
import { DataSystem } from '../../businessObjects'
import { LucidPopUp } from '../../ui-components/LucidPopUp/LucidPopUp'
// import { LucidPopUp } from 'ui-components/LucidPopUp/LucidPopUp'
// import { DataSystem } from 'businessObjects'

type Props = {
  dataPodId: string
  handleService: (args: any) => void
  serviceInfo?: ServiceConnectionType
  dataSystemsList: DataSystem[]
}

export const DataContextService = ({ handleService, dataPodId, serviceInfo, dataSystemsList }: Props) => {
  const [dataPodConnections, setServiceConnections] = useState<DataTableRow[]>([])
  const { connectionsLoading, getServiceConnectionsByPodId, connections } = useQueryServiceConnections(dataPodId)
  const { postComplete, deleteServiceConnection, postError, deleteStatus } = usePostServiceConnection(dataPodId)
  const [resultState, setResultState] = useState<boolean>(false)
  const [selectedConnection, selectServiceConnection] = useState<ServiceConnectionType>()
  const [isAddService, setAddService] = useState<boolean>(false)
  const [showPopUp, setShowPopUp] = useState<boolean>(false)
  const [openWarning, setOpenWarning] = useState<boolean>(false)
  const [selectedId, setSelectedId] = useState<number>()
  const dataPodColumns: DataTableColumn[] = useMemo(() => {
    return [
      {
        label: 'Id',
      },
      {
        label: 'Connection Name',
      },
      {
        label: 'Connection Type',
      },
      {
        label: 'Service Name',
      },
      {
        label: 'Actions',
      },
    ]
  }, [])

  useEffect(() => {
    if ((dataPodId && postError) || deleteStatus?.status) {
      //Add/Edit - handling error message.
      setResultState(true)
      getServiceConnectionsByPodId(dataPodId)
    }
  }, [postError, deleteStatus])

  useEffect(() => {
    const tRows = connections?.map((conn: any) => ({
      id: `${conn.id}`,
      values: [
        conn.id,
        conn.connectionName,
        conn.connectionType,
        // eslint-disable-next-line react/jsx-key
        <ServiceNameWithIcon serviceName={conn.serviceTypeName} />,
      ],
    })) as DataTableRow[]
    //below will refresh list page. upon service creation
    if (dataPodId && serviceInfo?.action === 'error') {
      setResultState(true)
    }

    if ((dataPodId && serviceInfo?.action === 'list') || postComplete?.dataPodId) {
      setAddService(false)
      selectServiceConnection({} as any)
      handleService({} as any)
      getServiceConnectionsByPodId(dataPodId)
    }
    setServiceConnections(tRows)
  }, [connections, serviceInfo?.action, postComplete])

  useEffect(() => {
    if (isAddService) {
      handleService({ action: 'new' })
    } else if (selectedConnection?.id) {
      handleService({ action: 'edit' })
    }
  }, [isAddService, selectedConnection?.id])

  const clickTableButton = (id: string, action: DataItemAction): void => {
    setSelectedId(Number(id))

    if (id && action) {
      if (action === 'delete') {
        setOpenWarning(true)
      } else {
        const selected = connections.find((c) => c.id === parseInt(id))
        if (selected?.dataPodId) {
          selectServiceConnection({ ...selected, action })
        }
      }
    }
  }

  const confirmDelete = async () => {
    if (selectedId) {
      await deleteServiceConnection(selectedId)
      setSelectedId(undefined)
      setOpenWarning(false)
    }
  }

  return (
    <>
      <LucidPopUp
        openPopUp={openWarning}
        closePopup={setOpenWarning}
        headingText={
          <Typography variant="h4" color={'error'}>
            Area you sure to delete?
          </Typography>
        }
        confirmText="Confirm"
        cancelText="Cancel"
        disabledConfirm={dataSystemsList.some((ds) => ds.serviceConnectionId === selectedId)}
        onConfirm={confirmDelete}
      >
        <Typography>
          Delete will effect all dependencies. Please delete Data Systems before proceeding this action
        </Typography>
      </LucidPopUp>
      <Grid item alignContent="center" justifyContent={'center'} xs={6} md={8} lg={12}>
        {connectionsLoading ? (
          <AnimatedLoader height="50%" width="40%" loading={connectionsLoading} />
        ) : isAddService || selectedConnection?.id ? (
          <AddServiceConnection
            dataPodId={dataPodId}
            selectedConnection={selectedConnection}
            closeAddForm={() => {}}
            handleService={(service: ServiceConnectionType) => handleService(service)}
            isAddNewPodStep={true}
          />
        ) : connections.length > 0 ? (
          <>
            <Grid item xs={12} spacing={2} sx={{ mb: 2, mt: 2 }}>
              <Grid item xs={12} display={'flex'} justifyContent={'end'}>
                <Button variant="contained" onClick={() => setAddService(true)}>
                  {'Add Service Connection'}
                </Button>
              </Grid>
              <DataTable
                columns={dataPodColumns}
                rows={dataPodConnections}
                allowedActions={{ editable: true, deletable: true }}
                filterColumn={1}
                // fromPage={'service'}
                onButtonClicked={clickTableButton}
              />
            </Grid>
          </>
        ) : (
          <NoEntries
            onClick={() => setAddService(true)}
            bodyText="Service connection represents a grouping of associated cloud partner like Azure, AWS and Google. It contains predefined service configuration template."
            headingText={'No Service Connections are Created'}
            buttonText={'Add Service Connection'}
          />
        )}
        <ResultStatus
          showStatus={resultState}
          alertMessage={deleteStatus?.status ? 'Deleted Service !' : postError?.message || serviceInfo?.errorMsg}
          severtiy={postError?.message || serviceInfo?.errorMsg ? 'error' : 'success'}
          closeStatus={() => setResultState(false)}
        />
      </Grid>
    </>
  )
}
