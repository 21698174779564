import { useMsal } from '@azure/msal-react'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../Routes/constants'

import { useGetAllLucidAccountByTenant } from '../hooks/lucidAccountHooks'
import { useEffect, useState } from 'react'
import { Checkbox, FormControlLabel, Grid, Link, Typography } from '@mui/material'

export const SignInButton = () => {
  const { instance } = useMsal()
  const [acceptTerms, setAcceptTerms] = useState(false)
  // const [getLucidAccounts] = useGetAllLucidAccountByTenant()

  const navigate = useNavigate()

  const handleLogin = async () => {
    try {
      await instance.loginRedirect()
    } catch (error) {
      navigate(ROUTES.Home)
    }
  }
  // useEffect(() => {
  //   if (accounts.length) {
  //     getLucidAccounts(accounts[0].tenantId)
  //   }
  // }, [accounts])

  https: return (
    <>
      <Grid item container xs={12} direction={'column'}>
        <Grid item xs={12}>
          <FormControlLabel
            label={
              <>
                <Typography>
                  <small>
                    {'Please Accept '}
                    <span>
                      {/* https://stackoverflow.com/questions/51937086/how-do-i-link-to-local-pdf-file-in-a-create-react-app-project */}
                      <Link href={require('./eula.pdf')} target="blank">
                        Terms and Conditions/ EULA
                      </Link>
                    </span>
                  </small>
                </Typography>

                {/* <Button onClick={() => window.open('eula.pdf', '_blank')}>Terms and Conditions</Button> */}
              </>
            }
            control={
              <Checkbox
                checked={acceptTerms}
                onChange={() => setAcceptTerms(true)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
      <Button variant="contained" disabled={!acceptTerms} onClick={() => handleLogin()} color="inherit">
        Signin
      </Button>
    </>
  )
}
