import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Box from '@mui/material/Box'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { DataTableActions, DataTableColumn, DataTableRow } from '../DataTable/DataTableTypes'
import { useEffect, useState } from 'react'
import { Button, Checkbox, IconButton, TableSortLabel } from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteForeverIcon from '@mui/icons-material/DeleteForeverOutlined'
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import { green } from '@mui/material/colors'
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined'

import { AddOutlined, CheckBox } from '@mui/icons-material'
import AnimatedLoader from '../AnimatedLoader'
import { stableSort, getComparator } from 'ui-components/DataTable/logic'
export enum ShadowTableAction {
  Editable,
  Viewable,
  Deletable,
  Add,
  CheckBox,
  Select,
  SelectTarget,
  // EditLinkRelation,
}

type ShadowTableActionParams = {
  actions: ShadowTableAction[]
  onButtonClick: (rowId: string, actionType: ShadowTableAction) => void
}
// type Props = {
//   rows: DataTableRow[]
//   columns: DataTableColumn[]
//   selectedRow?: string
//   selectRow?: (id: string) => void
//   tableActionParams?: ShadowTableActionParams
// }
type Props = {
  rows: DataTableRow[]
  columns: DataTableColumn[]
  selectedRow?: string
  selectRow?: (id: string) => void
  tableActionParams?: ShadowTableActionParams
  sourceIndex?: number[]
  targetIndex?: number[]
  sourceToTarget?: boolean
  isDataLoading?: boolean
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[500],
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme, selected }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: !selected ? theme.palette.grey[200] : undefined,
    //selected: theme.palette.primary.dark,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  rowGap: '2px',
  //selected: theme.palette.primary.dark,
}))

const ShadowTable = React.memo(
  ({
    rows,
    columns,
    selectRow,
    selectedRow,
    tableActionParams,
    sourceIndex,
    targetIndex,
    sourceToTarget,
    isDataLoading = false,
  }: Props) => {
    // const [tRows, setTRows] = useState<DataTableRow[]>([])
    const [sortedRows, setSortedTRows] = useState<DataTableRow[]>([])
    const sortDescendingInitial = columns.map(() => false)
    const [sortDescending, setSortDescending] = React.useState<boolean[]>(sortDescendingInitial)

    const tcolumns = React.useMemo(() => {
      return columns
    }, [columns.length, columns])

    useEffect(() => {
      setSortedTRows(rows)
    }, [rows])

    // useEffect(() => {
    //   setTRows(rows)
    // },[rows])

    const handleRowSelect = (id: string) => {
      selectRow && selectRow(id)
    }

    const getSourceTargetColor = (sourceOrTargetIndex: number) => {
      if (sourceIndex?.includes(sourceOrTargetIndex)) {
        return '#f8f2ae'
      }
      if (targetIndex?.includes(sourceOrTargetIndex)) {
        return '#a4bffb8c'
      }
    }
    // console.log({ sortedRows })
    const sortColumn = (columnIndex: number) => {
      doSort(columnIndex, sortDescending[columnIndex] ? 'desc' : 'asc')
      sortDescending[columnIndex] = !sortDescending[columnIndex]
      setSortDescending(sortDescending)
    }

    const doSort = (col: number, order: string): void => {
      const sRows = stableSort(
        rows,
        getComparator(order === 'asc' ? 'asc' : 'desc', col, columns[col].numeric || false),
      )

      setSortedTRows(sRows)
    }

    const tableCellValue = (cellValue: string, rowId: string) => {
      if (cellValue === 'EDIT') {
        return (
          <Button
            onClick={() => tableActionParams && tableActionParams.onButtonClick(rowId, ShadowTableAction.Editable)}
            startIcon={<EditOutlinedIcon sx={{ color: 'green' }} />}
          />
        )
      }
      if (cellValue === 'VIEW') {
        return (
          <Button
            onClick={() => tableActionParams && tableActionParams.onButtonClick(rowId, ShadowTableAction.Viewable)}
            startIcon={<VisibilityIcon sx={{ color: 'green' }} />}
          />
        )
      }
      if (cellValue === 'DELETE') {
        return (
          <Button
            onClick={() => tableActionParams && tableActionParams.onButtonClick(rowId, ShadowTableAction.Deletable)}
            startIcon={<DeleteForeverIcon sx={{ color: 'red' }} />}
          />
        )
      }
      if (cellValue === 'ADD') {
        return (
          <Button
            onClick={() => tableActionParams && tableActionParams.onButtonClick(rowId, ShadowTableAction.Add)}
            startIcon={<AddOutlined sx={{ color: 'green' }} />}
          />
        )
      }
      if (cellValue === 'CHECKED' || cellValue === 'UNCHECKED') {
        return (
          <Button
            onClick={() => tableActionParams && tableActionParams.onButtonClick(rowId, ShadowTableAction.CheckBox)}
            startIcon={
              <Checkbox
                sx={{
                  color: 'green',
                  '&.Mui-checked': {
                    color: green[600],
                  },
                }}
                edge="start"
                tabIndex={-1}
                checked={cellValue === 'CHECKED' ? true : false}
                disableRipple
              />
            }
          />
        )
      }
      if (cellValue === 'SELECT') {
        return (
          <Button onClick={() => tableActionParams && tableActionParams.onButtonClick(rowId, ShadowTableAction.Select)}>
            Select
          </Button>
        )
      }
      if (cellValue === 'SELECTTARGET') {
        return (
          <Button
            onClick={() => tableActionParams && tableActionParams.onButtonClick(rowId, ShadowTableAction.SelectTarget)}
          >
            Select Target
          </Button>
        )
      }
      // if (cellValue === 'EditLinkRelation') {
      //   return (
      //     <Button
      //       onClick={() => tableActionParams && tableActionParams.onButtonClick(rowId, ShadowTableAction.EditLinkRelation)}
      //     >
      //       Edit In Relationship Page
      //     </Button>
      //   )
      // }
      return cellValue
    }

    return (
      <TableContainer component={Paper} sx={{ backgroundColor: 'white' }}>
        <Table aria-label="customized table" size="small">
          <TableHead>
            <TableRow>
              {tcolumns.map((tc, ix) => (
                <StyledTableCell key={tc.label} align={sourceToTarget ? 'center' : 'justify'}>
                  {tc.sortableColumn ? (
                    <TableSortLabel direction={sortDescending[ix] ? 'desc' : 'asc'} onClick={() => sortColumn(ix)}>
                      <b>{tc.label}</b>
                    </TableSortLabel>
                  ) : (
                    <>{tc.label} </>
                  )}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows.length > 0 ? (
              sortedRows?.map((row, ix) => (
                <StyledTableRow
                  key={row.id + ix}
                  onClick={() => handleRowSelect(row.id)}
                  //color={selectedRow === row.id ? 'primary' : 'inherit'}
                  selected={selectedRow === row.id}
                  sx={{ backgroundColor: selectedRow === row.id ? 'primary.dark' : 'inherit' }}
                >
                  {row.values.map((rvalue, ix) => (
                    <StyledTableCell
                      key={ix}
                      align={sourceToTarget ? 'center' : 'justify'}
                      sx={{
                        paddingLeft: '30px',
                        backgroundColor: getSourceTargetColor(ix) ?? 'inherit',
                        color: !!getSourceTargetColor(ix) ? 'black' : 'inherit',
                      }}
                    >
                      {tableCellValue(rvalue, row.id)}
                      {/* {selectedRow === row.id ? <ChevronRightOutlinedIcon fontSize="medium" /> : undefined} */}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))
            ) : (
              <>
                {!isDataLoading && (
                  <>
                    <TableRow>
                      <TableCell colSpan={tcolumns.length} sx={{ textAlign: 'center' }}>
                        No Data
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </>
            )}
          </TableBody>
        </Table>
        <AnimatedLoader height="50%" width="50%" loading={isDataLoading} />
      </TableContainer>
    )
  },
)
ShadowTable.displayName = 'ShadowTable'

export default ShadowTable
