import { useState } from 'react'
import {
  RunAttributeProfileBody,
  RunResponseFormat,
  // RunAnalyticsMeasureProfileBody,
  NewAttributeMapBody,
  NewAttributeMap,
  UpdateAttributeNameBody,
  AttributeProfile,
  UpdateAttributeMapBody,
  UpdateAttributeMapResponse,
  GetAttributeProfileOutput,
  StatusResponse,
  AttributeProfileMapping,
} from '../businessObjects'
import { attributeProfileServices } from '../apiServices/attributeProfile'
import { useLucidAuthContext } from '../LucidAuthContext/LucidAuthContext'
import { AxiosError } from 'axios'

type dataPodId = string
type runId = number
type RunAttributeProfile = (body: RunAttributeProfileBody, datapodId: dataPodId) => Promise<void>
type AddNewAttributeMap = (body: NewAttributeMapBody, datapodId: dataPodId) => Promise<boolean | undefined>
type UpdateAttributeName = (body: UpdateAttributeNameBody) => Promise<void>
type UpdateAttributeMap = (body: UpdateAttributeMapBody) => Promise<UpdateAttributeMapResponse | undefined>
type GetAttributeOutput = (datapodId: dataPodId, runId: runId) => Promise<void>
type GetAttributeStatus = (datapodId: dataPodId, runId: runId) => Promise<void>

type attributeProfileDataPodId = string | undefined
type attributeProfileName = string | undefined
type GetAttributeProfile = (
  profileName?: attributeProfileName,
  dataPodId?: attributeProfileDataPodId,
  recordCount?: number,
  pageNumber?: number,
) => Promise<void>

type dataTableName = string | undefined
type dataEntityName = string | undefined
type dataColumnName = string | undefined
type dataAttributeName = string | undefined

type GetAttributeMapping = (
  dataTableName?: dataTableName,
  dataEntityName?: dataEntityName,
  dataColumnName?: dataColumnName,
  dataAttributeName?: dataAttributeName,
  datapodId?: attributeProfileDataPodId,
  recordCount?: number,
  pageNumber?: number,
) => Promise<void>

export const useRunAttributeProfile = (): [
  RunAttributeProfile,
  RunResponseFormat | undefined,
  boolean | undefined,
  boolean | undefined,
] => {
  const [runAttributeProfileResponse, setAttributeProfileResponse] = useState<RunResponseFormat>()
  const [loading, setLoading] = useState<boolean>()
  const [errorFetching, setErrorFetching] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const runAttribute = async (body: RunAttributeProfileBody, datapodId: dataPodId) => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await attributeProfileServices.runAttributeProfile(bearerToken, datapodId, body)
        if (status == 200) {
          setAttributeProfileResponse(data)
          setLoading(false)
        }
      }
    } catch (error) {
      setErrorFetching(true)
      setLoading(false)
    }
  }
  return [runAttribute, runAttributeProfileResponse, loading, errorFetching]
}
export const useAddNewAttributeMap = (): [
  AddNewAttributeMap,
  NewAttributeMap | undefined,
  boolean | undefined,
  boolean | undefined,
] => {
  const [newAttributeMap, setNewAttributeMap] = useState<NewAttributeMap>()
  const [loading, setLoading] = useState<boolean>()
  const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const addAttributeMap = async (body: NewAttributeMapBody, datapodId: dataPodId) => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await attributeProfileServices.addNewAttributeMap(bearerToken, datapodId, body)
        if (status == 200) {
          setNewAttributeMap(data)
          setLoading(false)
          return true
        }
      }
    } catch (error) {
      setCreateError(true)
      setLoading(false)
    }
  }
  // console.log('createError', createError)
  // console.log('loading', loading)
  return [addAttributeMap, newAttributeMap, loading, createError]
}
export const useUpdateAttributeProfileName = (): [
  UpdateAttributeName,
  AttributeProfile | undefined,
  boolean | undefined,
  boolean | undefined,
] => {
  const [updatedAttributeProfileName, setUpdatedAttributeProfileName] = useState<AttributeProfile>()
  const [loading, setLoading] = useState<boolean>()
  const [errorFetching, setErrorFetching] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const updateAttributeProfileName = async (body: UpdateAttributeNameBody) => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await attributeProfileServices.updateAttributeName(bearerToken, body)
        if (status == 200) {
          setUpdatedAttributeProfileName(data)
          setLoading(false)
        }
      }
    } catch (error) {
      setErrorFetching(true)
      setLoading(false)
    }
  }
  return [updateAttributeProfileName, updatedAttributeProfileName, loading, errorFetching]
}
export const useUpdateAttributeProfileMap = (): [UpdateAttributeMap, boolean | undefined] => {
  //const [updatedAttributeProfileMap, setUpdatedAttributeProfileMap] = useState<UpdateAttributeMapResponse>()
  const [loading, setLoading] = useState<boolean>()
  //const [errorFetching, setErrorFetching] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const updateAttributeProfileName = async (body: UpdateAttributeMapBody) => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await attributeProfileServices.updateAttributeMap(bearerToken, body)
        if (status == 200) {
          //setUpdatedAttributeProfileMap(data)
          return data as UpdateAttributeMapResponse
        }
      }
    } catch (error) {
      const { message } = error as AxiosError
      //setErrorFetching(!message)
      console.log({ message })
    }
  }
  return [updateAttributeProfileName, loading]
}
export const useGetAttributeProfileOutput = (): [
  GetAttributeOutput,
  GetAttributeProfileOutput | undefined,
  boolean | undefined,
  boolean | undefined,
] => {
  const [attributeProfileOutput, setAttributeProfileOutput] = useState<GetAttributeProfileOutput>()
  const [loading, setLoading] = useState<boolean>()
  const [errorFetching, setErrorFetching] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const fetchAttributeProfileOutput = async (datapodId: dataPodId, runId: runId) => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await attributeProfileServices.getAttributeProfileOutput(bearerToken, datapodId, runId)
        if (status == 200) {
          setAttributeProfileOutput(data)
          setLoading(false)
        }
      }
    } catch (error) {
      setErrorFetching(true)
      setLoading(false)
    }
  }
  return [fetchAttributeProfileOutput, attributeProfileOutput, loading, errorFetching]
}
export const useGetAttributeProfileStatus = (): [
  GetAttributeStatus,
  StatusResponse | undefined,
  boolean | undefined,
  boolean | undefined,
] => {
  const [attributeProfileStatus, setAttributeProfileStatus] = useState<StatusResponse>()
  const [loading, setLoading] = useState<boolean>()
  const [errorFetching, setErrorFetching] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const fetchAttributeProfileStatus = async (datapodId: dataPodId, runId: runId) => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await attributeProfileServices.getAttributeProfileStatus(bearerToken, datapodId, runId)
        if (status == 200) {
          setAttributeProfileStatus(data)
          setLoading(false)
        }
      }
    } catch (error) {
      setErrorFetching(true)
      setLoading(false)
    }
  }
  return [fetchAttributeProfileStatus, attributeProfileStatus, loading, errorFetching]
}
type Pagination = {
  updatePageCount?: (pages: number) => void
}
export const useGetAttributeProfile = ({
  updatePageCount,
}: Pagination): [GetAttributeProfile, AttributeProfile | undefined, boolean | undefined, boolean | undefined] => {
  const [attrubuteProfile, setAttributeProfile] = useState<AttributeProfile>()
  const [loading, setLoading] = useState<boolean>()
  const [errorFetching, setErrorFetching] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const fetchAttributeProfile = async (
    profileName?: attributeProfileName,
    datpodId?: attributeProfileDataPodId,
    recordCount?: number,
    pageNumber?: number,
  ) => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status, headers } = await attributeProfileServices.getAttributeProfile(
          bearerToken,
          profileName,
          datpodId,
          recordCount,
          pageNumber,
        )
        if (status == 200) {
          setAttributeProfile(data)
          setLoading(false)
          if (updatePageCount && recordCount) {
            const totalRecords = headers['x-total-count']
            const totalPages = Math.round(totalRecords / recordCount)
            updatePageCount(totalPages)
          }
        }
      }
    } catch (error) {
      setErrorFetching(true)
      setLoading(false)
    }
  }
  return [fetchAttributeProfile, attrubuteProfile, loading, errorFetching]
}
export const useGetAttributeProfileMapping = ({
  updatePageCount,
}: Pagination): [
  GetAttributeMapping,
  AttributeProfileMapping | undefined,
  boolean | undefined,
  boolean | undefined,
] => {
  const [attributeProfileMapping, setAttributeProfileMapping] = useState<AttributeProfileMapping>()
  const [loading, setLoading] = useState<boolean>()
  const [errorFetching, setErrorFetching] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const fetchAttributeProfileMapping = async (
    dataTableName?: dataTableName,
    dataEntityName?: dataEntityName,
    dataColumnName?: dataColumnName,
    dataAttributeName?: dataAttributeName,
    datapodId?: attributeProfileDataPodId,
    recordCount?: number,
    pageNumber?: number,
  ) => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status, headers } = await attributeProfileServices.getAttributeProfileMapping(
          bearerToken,
          dataTableName,
          dataEntityName,
          dataColumnName,
          dataAttributeName,
          datapodId,
          recordCount,
          pageNumber,
        )
        if (status == 200) {
          setAttributeProfileMapping(data)
          setLoading(false)
          if (updatePageCount && recordCount) {
            const totalRecords = headers['x-total-count']
            const totalPages = Math.round(totalRecords / recordCount)
            updatePageCount(totalPages)
          }
        }
      }
    } catch (error) {
      setErrorFetching(true)
      setLoading(false)
    }
  }
  return [fetchAttributeProfileMapping, attributeProfileMapping, loading, errorFetching]
}

export const useAddttributeMap = (
  datapodId?: string,
): { addAttributeMapFunc: (body: NewAttributeMapBody) => Promise<NewAttributeMap | undefined> } => {
  const { getBearerToken } = useLucidAuthContext()
  const addAttributeMapFunc = async (body: NewAttributeMapBody) => {
    console.log({ body })
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && datapodId && body.dataAttributeId) {
        const { data, status } = await attributeProfileServices.addNewAttributeMap(bearerToken, datapodId, body)
        if (status == 200) {
          return data as NewAttributeMap
        }
      }
    } catch (error) {
      console.log({ error })
    }
  }
  // console.log('createError', createError)
  // console.log('loading', loading)
  return { addAttributeMapFunc }
}
