type API = {
  client_secret: string
  resource: string
  grant_type: string
  client_id: string
}

type AzureOpenAIApi = {
  key: string
  version: string
  instanceName: string
  deploymentName: string
}

type Environment = {
  clientID: string
  tenantID: string
  authority: string
  redirectURI: string
  baseURI: string
  baseGenerateKPIURI: string
  genAiURI:string
  clientSecret: string
  clientScope: string
  api: API
  appInsightsConnectionString: string
  azureOpenAIApi: AzureOpenAIApi
  lucidSparkService: string
  lucidSparkSecret: string
  lucidGenApi: string
  fabricTokenClientId: string
}

export const environment: Environment = {
  clientID: process.env.REACT_APP_CLIENT_ID || '',
  tenantID: process.env.REACT_APP_TENANT_ID || '',
  authority: process.env.REACT_APP_AUTHORITY || '',
  redirectURI: process.env.REACT_APP_REDIRECT_URI || '',
  baseGenerateKPIURI: process.env.REACT_APP_GENERATE_KPI_SERVER_API || '',
  baseURI: process.env.REACT_APP_SERVER_API || '',
  genAiURI:process.env.REACT_APP_GEN_AI_SERVER_API || '',
  clientSecret: process.env.REACT_APP_CLIENT_SECRET || '',
  clientScope: process.env.REACT_APP_CLIENT_SCOPE || '',
  appInsightsConnectionString: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING || '',
  lucidSparkService: process.env.REACT_APP_LUCID_SPARK_SERVER || '',
  lucidSparkSecret: process.env.REACT_APP_LUCID_SPARK_SECRET || '',
  lucidGenApi: process.env.REACT_APP_GEN_API || '',
  fabricTokenClientId: process.env.FABRIC_CLIENT_ID || '',
  api: {
    client_secret: process.env.REACT_APP_API_CLIENT_SECRET || '',
    client_id: process.env.REACT_APP_API_CLIENT_ID || '',
    grant_type: process.env.REACT_APP_API_GRANT_TYPE || '',
    resource: process.env.REACT_APP_API_RESOURCE || '',
  },
  azureOpenAIApi: {
    key: process.env.REACT_APP_AZURE_OPENAI_API_KEY ?? '',
    version: process.env.REACT_APP_AZURE_OPENAI_API_VERSION ?? '',
    instanceName: process.env.REACT_APP_AZURE_OPENAI_API_INSTANCE_NAME ?? '',
    deploymentName: process.env.REACT_APP_AZURE_OPENAI_API_DEPLOYMENT_NAME ?? '',
  },
}
