import { useLucidAuthContext } from '../LucidAuthContext/LucidAuthContext'
import { lucidServices } from '../apiServices/sparkServices'
import { genServices } from '../genapiservices/genDataPodServices'
export type SparkCode = { StatusCode: number; TransformerId: number; TransformerName: string; TransformerQuery: string }
export type GenSparkCode = { result: string; data: string }
export const useGenerateCustomAttribute = (
  dataPodId?: string,
): { genAttribute: (promptText?: string, customAttributeID?: number) => Promise<GenSparkCode | undefined> } => {
  //const [loading, setLoading] = useState<boolean>(false)
  //const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const genAttribute = async (promptText?: string, customAttributeID?: number): Promise<GenSparkCode | undefined> => {
    const bearerToken = await getBearerToken()
    if (bearerToken && dataPodId && promptText && customAttributeID) {
      const { data, status } = await genServices.generateCustomAttributeCode(
        customAttributeID,
        dataPodId,
        promptText,
        undefined,
      )
      // const { data, status } = await lucidServices.genCustomAttribute(
      //   bearerToken,
      //   customAttributeID,
      //   dataPodId,
      //   promptText,
      // )

      if (status == 200) {
        //const stringedData = JSON.parse(data)
        return data as GenSparkCode
        //setLoading(false)
      }
    }
  }
  return { genAttribute }
}

export const useGenerateEnrichedModel = (
  dataPodId?: string,
): { genEnrichedSparkCode: (promptText?: string, entityIds?: number) => Promise<GenSparkCode | undefined> } => {
  const { getBearerToken } = useLucidAuthContext()
  const genEnrichedSparkCode = async (promptText?: string, entityIds?: number): Promise<GenSparkCode | undefined> => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId && promptText && entityIds) {
        const { data, status } = await genServices.generateCustomEntityCode(entityIds, dataPodId, promptText, undefined)
        // const { data, status } = await lucidServices.geneEnrichedModel(
        //   bearerToken,
        //   String(entityIds),
        //   dataPodId,
        //   promptText,
        // )
        // console.log(data)
        if (status == 200) {
          //console.log(status)
          //const stringedData = JSON.parse(data)
          return data as GenSparkCode
          //setLoading(false)
        }
      }
    } catch (error) {
      console.log('error processing spark service')
    }
  }
  return { genEnrichedSparkCode }
}
