import { Configuration, JobRequestParams } from '../businessObjects'
import { sendRequest } from '../requestManager/sendRequest'
import axios from 'axios'
import { environment } from '../utils/environment'
import { generatefabricToken } from '../utils/generateFabricToken'

const configurations_path = {
  getConfigurationTypes: "adminutils/Configuration/GetConfigurationType",
  getConfigurations: "adminutils/Configuration/GetAll",
  addConfiguration: "adminutils/Configuration/Add",
  updateConfiguration: "adminutils/Configuration/Update",
  deleteConfiguration: "",

  getStorageAccounts: "AzureStorageGen2",
}

const getConfigurationTypes = async ( token: string ) => {
  const path = configurations_path.getConfigurationTypes;
  
  return sendRequest(path, 'GET', token)
}

const getConfigurations = async ( token: string, configTypeId?: number ) => {

  const params = configTypeId ? `?configurationType=${configTypeId}&base64=false` : "?base64=false"

  const path = configurations_path.getConfigurations + params;
  
  return sendRequest(path, 'GET', token)
}

const addConfiguration = async (token: string, config: Configuration) => {
  const path = configurations_path.addConfiguration;

  return sendRequest(path, 'POST', token, config);
}

const updateConfiguration = async (token: string, config: Configuration) => {
  const path = configurations_path.updateConfiguration;

  return sendRequest(path, 'PUT', token, config);
}

const deleteConfiguration = async (token: string, id: number) => {
  const path = configurations_path.deleteConfiguration + `ID=${id}`;

  return sendRequest(path, 'DELETE', token);
}

const getStorageAccounts = async (token: string) => {
  // const path = configurations_path.getStorageAccounts;

  // return sendRequest(path, 'GET', token);

  const fabricToken = await generatefabricToken('')

  const url =  environment.baseURI + configurations_path.getStorageAccounts + `?fabricBearerToken=${fabricToken}`;

  const response = await axios.get(url);
  return response;
}

export const configurationServices = {
  getConfigurationTypes,
  getConfigurations,
  addConfiguration,
  updateConfiguration,
  deleteConfiguration,
  getStorageAccounts,
}