

import {
  RunAttributeProfileBody,
  NewAttributeMapBody,
  UpdateAttributeNameBody,
  UpdateAttributeMapBody,
} from '../businessObjects'

import { sendRequest } from '../requestManager/sendRequest'

const attributeProfilePath = {
  runAttributeProfile: 'DataAttributes/RunAttributeProfile',
  addAttributeMap: 'DataAttributes/AddNewAttributeMap',
  updateAttributeName: 'DataAttributes/UpdateAttributeName',
  updateAttributeMap: 'DataAttributes/UpdateAttributeMap',
  getAttributeProfileOutput: 'AnalyticsGroup/GetAttributeProfileOutput',
  getAttributeProfileStatus: 'AnalyticsGroup/GetAttributeProfileStatus',
  getAttributeProfile: 'AnalyticsGroup/getAttributeProfile',
  getAttributeProfileMapping: 'AnalyticsGroup/getMapping',
  getAttributeMaping: 'DataAttributes/getAttributeMap',
}
const runAttributeProfile = async (token: string, datapodId: string, body: RunAttributeProfileBody) => {
  const endPoint = attributeProfilePath.runAttributeProfile + `?datapodId=${datapodId}`
  return sendRequest(endPoint, 'POST', token, body)
}

const addNewAttributeMap = async (token: string, datapodId: string, newAttributeMap: NewAttributeMapBody) => {
 
  const endPoint = attributeProfilePath.addAttributeMap + `?datapodId=${datapodId}`
  return sendRequest(endPoint, 'POST', token, newAttributeMap)
}
const updateAttributeName = async (token: string, updateAttributeMap: UpdateAttributeNameBody) => {
  
  const endPoint = attributeProfilePath.updateAttributeName
  return sendRequest(endPoint, 'PUT', token, updateAttributeMap)
}
const updateAttributeMap = async (token: string, updateAttributeMap: UpdateAttributeMapBody) => {
  const endPoint = attributeProfilePath.updateAttributeMap
  return sendRequest(endPoint, 'PUT', token, updateAttributeMap)
}
const getAttributeProfileOutput = async (token: string, datapodId: string, runId: number) => {
  
  const endPoint =attributeProfilePath.getAttributeProfileOutput + `?runId=${runId}&datapodId=${datapodId}`
  return sendRequest(endPoint, 'GET', token)
}
const getAttributeProfileStatus = async (token: string, datapodId: string, runId: number) => {
  const endPoint = attributeProfilePath.getAttributeProfileStatus + `?runId=${runId}&datapodId=${datapodId}`
  return sendRequest(endPoint, 'GET', token)
}
const getAttributeProfile = async (
  token: string,
  attributeProfileName?: string,
  datapodId?: string,
  records?: number,
  pageNumber?: number,
) => {
  
  const paginationParams = records && pageNumber ? `&RecordsPerPage=${records}&PageNumber=${pageNumber}` : null
  let url = attributeProfilePath.getAttributeProfile
  if (attributeProfileName && datapodId) {
    url += `?analyticsGroupName=${attributeProfileName}&datapodID=${datapodId}`
  } else if (attributeProfileName) {
    url += `?analyticsGroupName=${attributeProfileName}`
  } else if (datapodId) {
    url += `?datapodID=${datapodId}`
  }
  const endPoint = paginationParams ? url + paginationParams : url
  return sendRequest(endPoint, 'GET', token)
}
const getAttributeProfileMapping = async (
  token: string,
  dataTableName?: string,
  dataEntityName?: string,
  dataColumnName?: string,
  dataAttributeName?: string,
  datapodID?: string,
  records?: number,
  pageNumber?: number,
) => {
  
  const paginationParams = records && pageNumber ? `&RecordsPerPage=${records}&PageNumber=${pageNumber}` : null
  const queryParams = []
  if (dataTableName) {
    queryParams.push(`dataTableName=${dataTableName}`)
  }

  if (dataEntityName) {
    queryParams.push(`dataEntityName=${dataEntityName}`)
  }

  if (dataColumnName) {
    queryParams.push(`dataColumnName=${dataColumnName}`)
  }

  if (dataAttributeName) {
    queryParams.push(`dataAttributeName=${dataAttributeName}`)
  }
  if (datapodID) {
    queryParams.push(`datapodID=${datapodID}`)
  }

  const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
  const url =  attributeProfilePath.getAttributeProfileMapping + queryString
 
  const endPoint = paginationParams ? url + paginationParams : url
  return sendRequest(endPoint, 'GET', token,)
}

export const attributeProfileServices = {
  runAttributeProfile,
  addNewAttributeMap,
  updateAttributeName,
  updateAttributeMap,
  getAttributeProfileOutput,
  getAttributeProfileStatus,
  getAttributeProfile,
  getAttributeProfileMapping,
}
