import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Pagination,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { useGetDataScienceModels } from '../../hooks/analyticsMeasureHooks'
import { useEffect, useMemo, useState } from 'react'
import { TechniqueDetails } from '../../businessObjects'
import AnimatedLoader from '../../ui-components/AnimatedLoader'
import ResultStatus from '../../ui-components/ResultStatus'

type Props = {
  dataPodId?: string
  measureId?: number
  measureName?: string
}

export const DataScienceModels = ({ dataPodId, measureId, measureName }: Props) => {
  const [dataScienceModels, setDataScienceModels] = useState<TechniqueDetails[]>()
  const [selectedDataScModel, setSelectedDataScModel] = useState<TechniqueDetails>()

  const [errorMessage, setErrorMessage] = useState<string>()
  const [errorState, setErrorState] = useState<boolean>(false)

  const [page, setPage] = useState<number>(1)
  const [count, setCount] = useState<number>()
  const itemsPerPage = 3

  const paginatedModels: TechniqueDetails[] = useMemo(() => {
    if (!dataScienceModels) return []

    return dataScienceModels.slice((page - 1) * itemsPerPage, page * itemsPerPage)
  }, [page, dataScienceModels])

  const { getDataScienceModelsData, getDataScienceModelsError, isGetDataScienceModelsLoading, getDataScienceModels } =
    useGetDataScienceModels(dataPodId && dataPodId, measureName)

  useEffect(() => {
    setDataScienceModels(undefined)
  }, [measureId])

  useEffect(() => {
    if (dataScienceModels) {
      setCount(Math.ceil(dataScienceModels.length / itemsPerPage))
    }
  }, [dataScienceModels])

  useEffect(() => {
    if (getDataScienceModelsData) {
      setDataScienceModels(getDataScienceModelsData.data)
    } else if (getDataScienceModelsError) {
      setErrorMessage('Failed to propose Data Science Models.')
      setErrorState(true)
    }
  }, [getDataScienceModelsData, getDataScienceModelsError])

  useEffect(() => {
    setSelectedDataScModel(undefined)
  }, [isGetDataScienceModelsLoading])

  const pageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const closeErrorMessage = () => {
    setErrorState(false)
    setErrorMessage(undefined)
  }

  return (
    <>
      {errorMessage && (
        <ResultStatus
          showStatus={errorState}
          alertMessage={errorMessage}
          severtiy="error"
          closeStatus={() => closeErrorMessage()}
        />
      )}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h4" textAlign={'left'} width={'100%'}>
            Data Science Models
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box width="100%">
            <Box display="flex" justifyContent="flex-end" marginBottom={2}>
              <Button variant="contained" color="primary" onClick={getDataScienceModels}>
                Suggest data Science models
              </Button>
            </Box>

            <Grid container spacing={2} marginTop={2}>
              <AnimatedLoader height="50%" width="40%" loading={isGetDataScienceModelsLoading} />
              {paginatedModels.map((model, index) => (
                <Grid item xs={12} key={index + '.' + model.TechniqueName}>
                  <Card>
                    <CardHeader title={model.TechniqueName} titleTypographyProps={{ variant: 'h6' }} />
                    <CardContent>
                      <Typography variant="body1" color="text.secondary" style={{ textAlign: 'justify' }}>
                        {model.TechniqueDescription}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            {dataScienceModels && (
              <Box display="flex" justifyContent="flex-end" marginTop={2}>
                <Pagination count={count} page={page} onChange={pageChange} />
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  )
}
