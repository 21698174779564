import { Grid, Input, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Node, NodeProps } from 'reactflow'
import { isValidDescription } from '../utils/constants'
import { useParams } from 'react-router-dom'
import { useGetCoreEntityByID } from '../hooks/entityHooks'
type NodeData = {
    value: number
}
type CustomNode = Node<NodeData>
const handleStyle = {
    left: 10,
}
type ViewResultsParams = {
    dataPodId: string
    dataSystemID: string
}

function CustomNodeEditor({ data }: NodeProps) {
    const { dataPodId } = useParams<ViewResultsParams>()
    const[userEditedCoreEntity, setUserEditedCustomEntity] = useState<string>(data.entityName)
    const [updatedCoreEntityBody, setUpdatedCoreEntityBody] = useState<any>()
    const { entity } = useGetCoreEntityByID(dataPodId, Number(data.coreEntityId))
    const changeUserParams = (EditedcoreEntityName: string) => {
        // console.log("Inside the edit core entity", EditedcoreEntityName,data.coreEntityId)
        // console.log(data)
        setUserEditedCustomEntity(EditedcoreEntityName)
        if(entity){
            // console.log(entity)
            const updatedBody = entity
            updatedBody.dataEntityName = EditedcoreEntityName
            setUpdatedCoreEntityBody(updatedBody)
        }
    }
    const submitData = async() =>{
        if(updatedCoreEntityBody){
            // console.log(updatedCoreEntityBody)
            data.submitData(updatedCoreEntityBody)
        }
    }
    return (
        <>
            <div style={handleStyle}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Enter Core Entity Name"
              variant="outlined"
              color="secondary"
              required
            //   error={!isValidEntry(newEntityReconData?.dataEntityName)}
            //   helperText={!isValidEntry(newEntityReconData?.dataEntityName) ? 'Invalid Entry' : ''}
              value={userEditedCoreEntity ?? ''}
              onChange={({ target }) => changeUserParams(target.value)}
              onKeyDown={(e)=>{if(e.key== 'Enter'){submitData()}}}
            />
            </div>
        </>
    )
}
CustomNodeEditor.displayName = 'CustomNodeEditor'
export default CustomNodeEditor
