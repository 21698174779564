import React, { useEffect, useMemo, useState } from 'react'
import ActionPage from '../../ui-components/ActionPage'
import { Box, Button, Card, CardContent, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CancelIcon from '@mui/icons-material/Cancel'

import { cardContainer } from '../../styles/globalStyles'

import OutputIcon from '../../ui-icons/Output.svg'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import { useGetJobStatus, useGetProfileInputs, useRunJob } from '../../hooks/dataProfilingHooks'
import { useGetQueryString } from '../../hooks/queryStringHook'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useGetAllDataPods } from '../../hooks/datapodHooks'
import { GridColDef, GridFilterModel, GridRowSelectionModel, GridRowsProp } from '@mui/x-data-grid'
import { ActionTypes, GridColStatus, LucidDataGrid } from '../../ui-components/LucidDataTable/LucidDatatable'
import { JobName, JobStatus, jobNames } from '../../hooks/constants'
import { LucidPopUp } from '../../ui-components/LucidPopUp/LucidPopUp'
import OutputOutlinedIcon from '@mui/icons-material/OutputOutlined'
import { ROUTES } from '../../Routes/constants'
import { PaginationParams } from '../../businessObjects'
import { useGetSessionPodInfo } from '../../hooks/getSessionPodInfo'
import DataProcessCard from './DataProcessCard'

type Props = {}
export type TriggerState = {
  triggerId: string | number
  status: boolean
}
export type TriggeredJobState = {
  jobName: JobName
  isFinished?: boolean
  isRunning?: boolean
}
export const DataProfiling = () => {
  const [pages, setPages] = useState<number>(0)
  const [pagination, setPagination] = useState<PaginationParams>({ pageNumber: 1, recordsPerPage: 5 })
  const dataPod = useGetSessionPodInfo()
  const { profileInputs } = useGetProfileInputs(dataPod?.id ?? undefined, undefined, undefined, pagination, setPages)

  const dataSystemList = useMemo(() => {
    if (!profileInputs) return []
    return profileInputs
  }, [profileInputs, pagination])

  return (
    <ActionPage>
      <Box style={cardContainer}>
        <Grid container spacing={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} />
            <Grid item xs={15} container>
              <Grid item xs={2}>
                <Typography variant="h4" gutterBottom color="primary">
                  Data Profiling
                </Typography>
              </Grid>

              <Grid item sx={{ paddingLeft: 2 }} xs={10} alignItems={'flex-start'} container spacing={1}>
                {dataSystemList.map((d) => (
                  <Grid item xs={3} md={5.7} key={d.dataSystemID}>
                    <DataProcessCard
                      headingText="Scan & Profile Data Source"
                      type="Data Profiling"
                      key={d.dataSystemID}
                      labelType="Data System"
                      dataPodId={dataPod?.id}
                      dataSystemID={d.dataSystemID}
                      subLabel={d.dataSystemName}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ActionPage>
  )
}
