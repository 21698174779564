import {
  Box,
  Grid,
  IconButton,
  Typography,
  Card,
  TextField,
  Divider,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CardHeader,
  InputAdornment,
  Button,
} from '@mui/material'
import { Dispatch, useEffect, useMemo, useState } from 'react'

import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { green } from '@mui/material/colors'

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import CancelIcon from '@mui/icons-material/Cancel'
import { ROUTES } from '../../../Routes/constants'
import ActionPage from '../../../ui-components/ActionPage'
import { cardContainer } from '../../../styles/globalStyles'
import { DataQualityRule, Entity, RunNewJobParams, RunStatusResponse } from '../../../businessObjects'
import { useGetEntity, useGetEntityMapping } from '../../../hooks/entityHooks'
import { DataTableRow } from '../../../ui-components/DataTable/DataTableTypes'
import ShadowTable, { ShadowTableAction } from '../../../ui-components/ShadowTable/ShadowTable'
import { useGetDataPodInfo } from '../../../hooks/datapodHooks'
import {
  useGetDataAttributes,
  useGetDataAttributesByEntityId,
  useGetDataAttributesMapping,
  useDeleteAttributeId,
} from '../../../hooks/dataAttrbutesHooks'
import { useGetAllDataSystemsByPodId, useGetDataSystemTypes } from '../../../hooks/dataSystemHooks'
import { useNotebookToQueryString } from '../../../hooks/notebookHooks'
import { useGetQueryString } from '../../../hooks/queryStringHook'
import { LucidJupyter } from '../../../ui-components/JupyterComponents/LucidJupyter'
import { usePostDatabricksJobRunNew } from '../../../hooks/databricksHooks'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import { filterActiveRunIds, initiateActiveRunId } from '../../../features/notification/notificationSlice'
import { useGetServiceConnection } from '../../../hooks/dataEngineeringHooks'
import { useDeleteDataQualityRule, useGetDataQualityRules } from '../../../hooks/dataQualityHooks'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { ArrowDownwardOutlined, ArrowUpwardOutlined } from '@mui/icons-material'
import { LucidTextLabel } from 'ui-components/LucidTextLabel'

type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
}
export const CanonicalModel = (props: Props) => {
  const { dataPodId, dataSystemID } = useParams<ViewResultsParams>()
  const typeId = useGetQueryString('typeId')
  const { dataPodInfo } = useGetDataPodInfo(dataPodId)
  const [selectedSourceID, setSelectedSourceID] = useState<number>()
  // const [tableView, setTableView] = useState<boolean>(true)
  // const [cardView, setCardView] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')
  // const [dataSystems] = useGetAllDataSystemsByPodId({}, dataPodId)
  const [entities] = useGetEntity({}, dataPodId, undefined, typeId && typeId ? Number(typeId) : undefined)
  const [filteredEntities, setFilteredEntities] = useState<Entity[]>(entities)
  // const {fetchEntity} = useGetEntity;

  useEffect(() => {
    if (dataSystemID && !selectedSourceID) {
      setSelectedSourceID(Number(dataSystemID))
    }
  }, [dataSystemID])

  useEffect(() => {
    if (searchText.trim() === '') {
      setFilteredEntities(entities)
    } else {
      setFilteredEntities(
        entities?.filter((entity) => entity.dataEntityName.toLowerCase().includes(searchText.trim().toLowerCase())),
      )
    }
  }, [searchText])

  const allEntities = useMemo(() => {
    if (searchText.trim() === '') {
      return entities.sort((p1, p2) => (p1.dataEntityName > p2.dataEntityName ? 1 : -1))
    }
    return filteredEntities.sort((p1, p2) => (p1.dataEntityName > p2.dataEntityName ? 1 : -1))
  }, [entities?.length, filteredEntities?.length])

  const navigate = useNavigate()
  const onClickHome = () => {
    const dataProfilingRoute = generatePath(ROUTES.Overview, { dataPodId: dataPodId })
    navigate(dataProfilingRoute)
  }

  // const confirmDeleteAttributeId = async (attributeId: number) => {
  //   if (attributeId) {
  //     const deleteMapSuccess = await deleteAttributeId(attributeId)
  //     if (deleteMapSuccess) {
  //       console.log('Success')
  //       // const filteredRows = tableRows?.filter((r) => r.id !== String(selectedAttributeRow?.id))
  //       // refetch()
  //       // setTableRows(filteredRows)
  //       // setDeleteSuccess(true)
  //       // closeDeleteWarning()
  //     }
  //   }
  // }

  return (
    <ActionPage>
      <Grid item container spacing={2}>
        <Grid item xs={2}>
          <IconButton onClick={onClickHome}>
            <HomeOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box style={cardContainer}>
        {/* <Grid container spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={4} alignItems={'center'}>
                  <Grid item container xs={15} spacing={1}>
                    <Grid item xs={6}>
                      <Typography component="span" variant="subtitle1">
                        Data Pod Name
                      </Typography>
                    </Grid>
                    <Grid item />

                    <Grid item xs={5}>
                      <Typography component="span" variant="subtitle1">
                        Data Pod ID
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={15} />

                    <Grid item container xs={15} spacing={1}>
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          id="outlined-select-dataSource"
                          variant="outlined"
                          value={dataPodInfo?.name}
                          required
                          size="small"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={1} />
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          id="outlined-select-dataSource"
                          variant="outlined"
                          value={dataPodInfo?.id}
                          required
                          size="small"
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} />
          </Grid> */}
        {/* </Grid> */}
        <Grid item container spacing={2}>
          <Grid item xs={12} container alignItems={'center'}>
            {/* <Grid item xs={7} /> */}
            <Grid item xs={3}>
              <TextField
                placeholder="Search"
                value={searchText}
                onChange={({ target }) => setSearchText(target.value)}
                color="primary"
                margin="dense"
                size="small"
                type="search"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: !!searchText ? (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearchText('')}>
                        <CancelIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <></>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} />
          <Grid item container xs={14}>
            <DataModelEntityAttibuteProps dataSourceTables={allEntities} dataPodId={dataPodId} />
          </Grid>
        </Grid>
      </Box>
    </ActionPage>
  )
}

type DataModelEntityAttibutePropsProps = {
  dataSourceTables: Entity[]
  dataPodId: string | undefined
}

const DataModelEntityAttibuteProps = ({ dataSourceTables, dataPodId }: DataModelEntityAttibutePropsProps) => {
  const navigate = useNavigate()

  const [selectedEntityID, setSelectedEntityID] = useState<number | undefined>(dataSourceTables[0]?.id)
  // const [dataEntityTypes, setDataEntityType] = useState<number>(dataSourceTables[0]?.dataEntityType)
  const [entityMappings] = useGetEntityMapping({}, selectedEntityID)
  const [fetchDataAttributes, dataAttributes, loading, createError] = useGetDataAttributes(false)
  const [selectedEntityInfo, setSelectedEntityInfo] = useState<Entity>()
  // const [showPopup, setShowPopup] = useState<boolean>(false)
  // const [fetchAttributesMappingData, attributesMappingData] = useGetDataAttributesMapping()
  const [notebookString, setNotebookString] = useState<string>()
  const [isTableAsc, setIsTableAsc] = useState<boolean>(true)
  // const [deleteAttributeIdStatus, setDeleteAttributeIdStatus] = useState<boolean>(true)

  const { attributeProfiles, refetchAttributes } = useGetDataAttributesByEntityId(selectedEntityID)

  const { loadCanonicalNotebookStringByEntityId, notebookResponse } = useNotebookToQueryString(dataPodId)

  const [allDataSystems] = useGetAllDataSystemsByPodId({}, dataPodId)
  const { allDataSystemTypes } = useGetDataSystemTypes()
  const { deleteAttributeId } = useDeleteAttributeId()

  const { postRunNewJob } = usePostDatabricksJobRunNew()
  const activeRunIds = useAppSelector((state: RootState) => state.notification.activeRunIds)
  const dispatch = useAppDispatch()
  const { getConnections } = useGetServiceConnection()

  const entityMappingTableColumns = useMemo(() => {
    return [
      {
        label: 'Data System Name',
        sortableColumn: true,
      },
      {
        label: 'Data System Type',
        sortableColumn: true,
      },

      {
        label: 'Table Name',
        sortableColumn: true,
      },
    ]
  }, [])

  const dataPodDataSystems = useMemo(() => {
    if (allDataSystems.length) return allDataSystems
    return allDataSystems
  }, [allDataSystems])

  const dataSystemTypes = useMemo(() => {
    if (allDataSystemTypes.length) return allDataSystemTypes
    return allDataSystemTypes
  }, [allDataSystemTypes])

  const generatedTableColumns = useMemo(() => {
    return [
      { label: 'Attribute Name', sortableColumn: true },
      { label: 'Attribute Description', sortableColumn: true },
      { label: 'Attribute Type', sortableColumn: true },
      { label: 'Data Type', sortableColumn: true },
      { label: 'Data Classification', sortableColumn: true },
      { label: 'isBusinessKey', sortableColumn: true },
      //{ label: 'View Details' },
    ]
  }, [])

  const cusomtAttribTableColumns = useMemo(() => {
    return [
      { label: 'Attribute Name', sortableColumn: true },
      { label: 'Attribute Description', sortableColumn: true },
      { label: 'Attribute Type', sortableColumn: true },
      { label: 'Data Type', sortableColumn: true },
      { label: 'Data Classification', sortableColumn: true },
      { label: 'isBusinessKey', sortableColumn: true },
      { label: 'View Details' },
      { label: 'Delete' },
    ]
  }, [])

  // const attributesModalColumns = useMemo(() => {
  //   return [
  //     { label: 'Data System Name' },
  //     { label: 'Data Table Name' },
  //     { label: 'Column Name' },
  //     { label: 'Transformer Text' },
  //     { label: 'Transformer Query' },
  //     { label: 'Add/Edit Transformer' },
  //   ]
  // }, [])

  useEffect(() => {
    if (notebookResponse?.message) {
      console.log('comes here')
      setNotebookString(notebookResponse?.message)
    }
  }, [notebookResponse])

  useEffect(() => {
    if (dataSourceTables.length > 0) {
      if (selectedEntityID) {
        if (!dataSourceTables.some((item) => item.id === selectedEntityID)) {
          const firstEntityId = dataSourceTables[0]?.id
          setSelectedEntityID(firstEntityId)
        }
      } else {
        const firstEntityId = dataSourceTables[0]?.id
        setSelectedEntityID(firstEntityId)
      }
    } else {
      setSelectedEntityID(undefined)
    }
  }, [dataSourceTables])

  useEffect(() => {
    if (selectedEntityID) {
      // console.log('selected entity id is changed in use effect')
      const selectedEntity = dataSourceTables.find((entity) => entity.id === selectedEntityID)
      setSelectedEntityInfo(selectedEntity)
      if (selectedEntity) fetchDataAttributes(selectedEntityID)
      loadCanonicalNotebookStringByEntityId(selectedEntityID)
    } else {
      setSelectedEntityInfo(undefined)
    }
  }, [selectedEntityID])

  // const updatedDataAttributesTable = useMemo(() => {
  //   if (selectedEntityID) {
  //     const attributesRow = dataAttributes?.map((dt) => ({
  //       id: `${dt.id}`,
  //       values: [
  //         dt.dataAttributeName,
  //         dt.description,
  //         dt.dataAttributeType == '2' ? 'Target' : 'Source',
  //         dt.dataAttributeDataType,
  //         dt.dataClassification,
  //         dt.isBusinessKey ? 'True' : 'False',
  //         'VIEW',
  //       ],
  //     })) as DataTableRow[]
  //     return attributesRow
  //   } else return []
  // }, [dataAttributes, selectedEntityID])

  const generatedAttributes = useMemo(() => {
    if (!attributeProfiles.length) return []
    return attributeProfiles
      .filter((ad) => ad.dataAttributeType !== 'CustomAttribute')
      .map((ad) => ({
        id: `${ad.id}`,
        values: [
          ad.dataAttributeName,
          ad.description,
          ad.dataAttributeType,
          ad.dataAttributeDataType,
          ad.dataClassification,
          ad.isBusinessKey ? 'True' : 'False',
          //'EDIT'
        ],
      }))
  }, [attributeProfiles, selectedEntityID])

  const compositeAttributes = useMemo(() => {
    if (!attributeProfiles.length) return []
    return attributeProfiles
      .filter((ad) => ad.dataAttributeType === 'CustomAttribute')
      .map((ad) => ({
        id: `${ad.id}`,
        values: [
          ad.dataAttributeName,
          ad.description,
          ad.dataAttributeType,
          ad.dataAttributeDataType,
          ad.dataClassification,
          ad.isBusinessKey ? 'True' : 'False',
          'EDIT',
          'DELETE',
        ],
      }))
  }, [attributeProfiles, selectedEntityID])

  const updatedEntityMappingTable = useMemo(() => {
    if (selectedEntityID) {
      const entityMappingRows = entityMappings.map((dt) => {
        const dataSystemInfo = dataPodDataSystems.find((ds) => ds.id === dt.dataSystemId)
        const dataSystemType = dataSystemTypes.find((dst) => dst.id === dataSystemInfo?.dataSystemType)
        return {
          id: `${dt.id}`,
          values: [dt.dataSystemName, dataSystemType?.dataSystemType1, dt.dataTableName],
        }
      }) as DataTableRow[]
      return entityMappingRows
    } else return []
  }, [entityMappings[0]?.id, selectedEntityID])

  const selectedEntitySourceInfo = useMemo(() => {
    if (selectedEntityID) {
      return entityMappings.find((emp) => emp.dataEntityId === selectedEntityID)
    }
  }, [entityMappings, selectedEntityID])

  const onCompositeButtonClick = async (rowId: string, actionType: ShadowTableAction) => {
    if (actionType === ShadowTableAction.Deletable) {
      const deleteMapSuccess = await deleteAttributeId(parseInt(rowId))
      if (deleteMapSuccess && selectedEntityID) {
        refetchAttributes()
      }
    }
    if (actionType === ShadowTableAction.Editable) {
      const dataAttributeDetailsRoute = generatePath(ROUTES.DataModelingCanonicalModelEditCustomAttribute, {
        dataPodId: dataPodId,
        entityID: selectedEntityID,
        // tableID: selectedEntitySourceInfo?.dataTableId,
        // dataSystemID: selectedEntitySourceInfo?.dataSystemId,
        dataAttributeID: rowId,
      })
      navigate(dataAttributeDetailsRoute)
      if (selectedEntityID) loadCanonicalNotebookStringByEntityId(selectedEntityID)
    }
  }

  const onClickCustomAttribute = () => {
    const dataAttributeDetailsEditRoute = generatePath(ROUTES.DataModelingCanonicalModelAddAttribute, {
      dataPodId: dataPodId,
      entityID: selectedEntityID,
      // tableID: selectedEntitySourceInfo?.dataTableId,
      // dataSystemID: selectedEntitySourceInfo?.dataSystemId,
    })
    navigate(dataAttributeDetailsEditRoute)
  }

  const runNewJob = async () => {
    if (dataPodId) {
      const serviceConnection = await getConnections(dataPodId, undefined, 'Spark')
      if (serviceConnection) {
        const jsonTemplate = serviceConnection[0].serviceConnectionJsonTemplate
        const parsedJsonTemplate = JSON.parse(jsonTemplate)
        const params: RunNewJobParams = {
          notebookParams: {
            i_data_pod_id: dataPodId || '',
            i_data_entity_id: selectedEntityID?.toString() || '',
          },
          url: parsedJsonTemplate.workspace_Url,
          jobId: parsedJsonTemplate.dataCanonical_Job_Id,
          token: parsedJsonTemplate.PAT_Token,
        }
        const result = await postRunNewJob(params)

        if (result) {
          const activeRunId = activeRunIds.find((element) => element.run_id === result)

          if (!activeRunId) {
            const newRunId: RunStatusResponse = {
              run_id: result,
              state: {
                life_cycle_state: 'INITIATED',
                result_state: 'LOADING',
              },
              params: {
                run_id: result,
                url: params.url,
                token: params.token,
              },
            }

            dispatch(filterActiveRunIds())
            dispatch(initiateActiveRunId(newRunId))
          }
        }
      }
    }
  }

  const { dataQualityRules, isDataQualityRulesLoading, isDataQualityRulesError } =
    useGetDataQualityRules(selectedEntityID)
  const { isDeleteRuleSuccess, isDeleteRuleError, deleteDataQualityRule } = useDeleteDataQualityRule()

  const [rules, setRules] = useState<DataQualityRule[]>([])

  useEffect(() => {
    setRules(dataQualityRules)
  }, [dataQualityRules])

  const dataQualityColumnNames = useMemo(() => {
    return [{ label: 'Name', sortableColumn: true }, { label: 'View Details' }, { label: 'Delete' }]
  }, [])

  const dataQualityRowValues = useMemo(() => {
    if (!rules.length) return []
    return rules.map((rule) => ({
      id: `${rule.id}`,
      values: [rule.name, 'EDIT', 'DELETE'],
    }))
  }, [rules, selectedEntityID])

  const onDataQualityRuleSelect = async (rowId: string, actionType: ShadowTableAction) => {
    if (actionType === ShadowTableAction.Deletable) {
      const isDeleted: boolean = await deleteDataQualityRule(parseInt(rowId))
      if (isDeleted && selectedEntityID) {
        const filteredRules = rules.filter((rule) => rule.id !== parseInt(rowId))

        setRules(filteredRules)
      }
    }
    if (actionType === ShadowTableAction.Editable) {
      const dataQualityEditRoute = generatePath(ROUTES.DataModelingCanonicalModelEditDataQuality, {
        dataPodId: dataPodId,
        entityID: selectedEntityID,
        // tableID: selectedEntitySourceInfo?.dataTableId,
        // dataSystemID: selectedEntitySourceInfo?.dataSystemId,
        dataQualtiyID: rowId,
      })
      navigate(dataQualityEditRoute)
      // if(selectedEntityID) loadCanonicalNotebookStringByEntityId(selectedEntityID)
    }
  }

  const onClickDataQuality = () => {
    const dataQualityAddRoute = generatePath(ROUTES.DataModelingCanonicalModelAddDataQuality, {
      dataPodId: dataPodId,
      entityID: selectedEntityID,
      // tableID: selectedEntitySourceInfo?.dataTableId,
      // dataSystemID: selectedEntitySourceInfo?.dataSystemId,
    })
    navigate(dataQualityAddRoute, { state: {} })
  }
  const sortTableFn = (t1: Entity, t2: Entity) => {
    return t1.dataEntityName > t2.dataEntityName && isTableAsc ? -1 : 1
  }

  const sortedEntities = useMemo(() => {
    if (!dataSourceTables?.length) return []
    return dataSourceTables.sort(sortTableFn)
  }, [dataSourceTables, isTableAsc])

  return (
    <Grid item container justifyContent={'center'} alignItems={'stretch'} xs={14} spacing={2}>
      <Grid item xs={3}>
        <Card sx={{ backgroundColor: 'white' }}>
          <CardHeader
            disableTypography
            sx={{ backgroundColor: 'grey' }}
            title={
              <Grid item xs={12} container alignItems="baseline" spacing={1}>
                <Grid item xs={6}>
                  <Typography variant="body2" gutterBottom>
                    {'List of Entites'}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    title={`sort ${isTableAsc ? 'asc' : 'desc'}`}
                    onClick={() => setIsTableAsc(!isTableAsc)}
                  >
                    {isTableAsc ? <ArrowDownwardOutlined fontSize="small" /> : <ArrowUpwardOutlined fontSize="small" />}
                  </IconButton>
                </Grid>
              </Grid>
            }
          />
          <Divider />
          <List dense>
            {dataSourceTables.map((p, ix) => (
              <ListItem key={ix}>
                <ListItemButton
                  onClick={() => {
                    setNotebookString('')
                    setSelectedEntityID(p.id)
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      sx={{
                        color: 'green',
                        '&.Mui-checked': {
                          color: green[600],
                        },
                      }}
                      edge="start"
                      tabIndex={-1}
                      checked={selectedEntityID === Number(p.id)}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={p.dataEntityName} />
                  {!!p.canonicalLoad ? (
                    <CheckCircleIcon fontSize="small" color="primary" titleAccess="Loaded to Canonical" />
                  ) : (
                    <></>
                  )}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Card>
      </Grid>
      <Grid item xs={8} spacing={2}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 2 }}>
          <Button variant="contained" onClick={runNewJob}>
            Canonical load
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: 12 }}>
          <Grid item xs={10} sx={{ marginBottom: 2 }}>
            <Typography sx={{ fontSize: 16 }} color="primary">
              Selected Entity Details
            </Typography>
          </Grid>
          <Grid item xs={12} container sx={{ marginBottom: 2 }}>
            <Grid item xs={4}>
              <Typography variant="body1" textAlign={'left'}>
                Entity Name
              </Typography>
              <Typography color="text.secondary" textAlign={'left'}>
                {selectedEntityInfo?.dataEntityName}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" textAlign={'left'}>
                Entity Type
              </Typography>
              <Typography color="text.secondary" textAlign={'left'}>
                {selectedEntityInfo?.entityType}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" textAlign={'left'}>
                Is Core
              </Typography>
              <Typography color="text.secondary" textAlign={'left'}>
                {selectedEntityInfo?.coreEntity}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" textAlign={'left'}>
              DataPod Description
            </Typography>
            <Typography color="text.secondary" textAlign={'left'}>
              {selectedEntityInfo?.description}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} container sx={{ marginBottom: 4 }} spacing={1}>
          <Typography variant="h6" gutterBottom color="primary">
            Entity's Source Tables
          </Typography>
          <ShadowTable rows={updatedEntityMappingTable} columns={entityMappingTableColumns} />
        </Grid>

        <Grid item xs={12} container sx={{ marginBottom: 4 }} spacing={1}>
          <Grid item xs={10} container alignItems="flex-end">
            <Grid item xs={4}>
              <Typography variant="h6" gutterBottom color="primary">
                Source Attributes
              </Typography>
            </Grid>
            <Grid item xs={4} />
          </Grid>
          <Grid item xs={12} />
          <ShadowTable rows={generatedAttributes} columns={generatedTableColumns} />
        </Grid>
        <Grid item xs={12} container display={'flex'} justifyContent={'space-between'} sx={{ mb: 2 }}>
          <Typography variant="h6" gutterBottom color="primary">
            Custom Attributes
          </Typography>
          <Button variant="contained" color="primary" onClick={onClickCustomAttribute}>
            Add Custom Attribute
          </Button>
        </Grid>

        <Grid item xs={12} sx={{ mb: 2 }} />
        <ShadowTable
          rows={compositeAttributes}
          columns={cusomtAttribTableColumns}
          tableActionParams={{
            onButtonClick: onCompositeButtonClick,
            actions: [ShadowTableAction.Viewable, ShadowTableAction.Deletable],
          }}
        />

        <Grid item xs={12} container display={'flex'} justifyContent={'space-between'} sx={{ mt: 4, mb: 2 }}>
          <Typography variant="h6" gutterBottom color="primary">
            Data Quality
          </Typography>
          <Button variant="contained" color="primary" onClick={onClickDataQuality}>
            Add Data Quality
          </Button>
        </Grid>

        <Grid item xs={12} />
        <ShadowTable
          rows={dataQualityRowValues}
          columns={dataQualityColumnNames}
          tableActionParams={{
            onButtonClick: onDataQualityRuleSelect,
            actions: [ShadowTableAction.Viewable, ShadowTableAction.Deletable],
          }}
        />
        <Grid item xs={12} container sx={{ mt: 2 }} spacing={1}>
          {/* <LucidJupyter
            noteBookString={notebookString}
            headingText={'Canonical Load - Verify and adjust generated spark code'}
            updateNoteBookString={setNotebookString}
          /> */}
        </Grid>
      </Grid>
    </Grid>
  )
}
