import { Container, Box, Grid, Typography, TextField, MenuItem } from '@mui/material'
import React from 'react'
import { UserAccount } from '../../businessObjects'
import { Country } from 'country-state-city'

type Props = {
  userAccount?: UserAccount
  updateUserParams: (userParams: UserAccount) => void
}

export const AddPersonalDetails = ({ userAccount, updateUserParams }: Props) => {
  const changeUserParams = (name: string, value: string) => {
    updateUserParams({
      ...(userAccount as UserAccount),
      [name]: value,
    })
  }
  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 5 }}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Typography color={'text.primary'}>
                <span style={{ color: 'red' }}>*</span> Indicates a required field
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography color={'text.primary'}>
                First Name<span style={{ color: 'red' }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Enter First Name"
                variant="outlined"
                color="secondary"
                required
                size="small"
                value={userAccount?.primaryContactFirstName ?? ''}
                onChange={({ target }) => changeUserParams('primaryContactFirstName', target.value)}
              />
            </Grid>
            <Grid item xs={9}>
              <Typography color={'text.primary'}>Middle Name</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Enter Middle Name"
                variant="outlined"
                color="secondary"
                size="small"
                value={userAccount?.primaryContactMiddleName ?? ''}
                onChange={({ target }) => changeUserParams('primaryContactMiddleName', target.value)}
              />
            </Grid>
            <Grid item xs={9}>
              <Typography color={'text.primary'}>
                Last Name<span style={{ color: 'red' }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Enter Last Name"
                variant="outlined"
                color="secondary"
                required
                size="small"
                value={userAccount?.primaryContactLastName ?? ''}
                onChange={({ target }) => changeUserParams('primaryContactLastName', target.value)}
              />
            </Grid>
            <Grid item xs={4} />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography color={'text.primary'}>
                  Country Code<span style={{ color: 'red' }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography color={'text.primary'}>
                  Phone Number<span style={{ color: 'red' }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={4} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-select-countryCode"
                select
                label="Select Country Code"
                color="secondary"
                required
                size="small"
                //value={userAccount}
                //onChange={({ target }) => changeUserParams('primaryContactEmail', target.value)}
              >
                {Country.getAllCountries().map((country) => (
                  <MenuItem key={country.isoCode} value={country.phonecode}>
                    {country.phonecode}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Enter Phone Number"
                variant="outlined"
                color="secondary"
                required
                size="small"
                value={userAccount?.primaryContactPhone}
                onChange={({ target }) => changeUserParams('primaryContactPhone', target.value)}
              />
            </Grid>
            <Grid item xs={9}>
              <Typography color={'text.primary'}>
                Country<span style={{ color: 'red' }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-select-country"
                select
                label="Select Country"
                color="secondary"
                required
                size="small"
                //onChange={({ target }) => changeUserParams('primaryContactEmail', target.value)}
              >
                {Country.getAllCountries().map((country) => (
                  <MenuItem key={country.name} value={country.name}>
                    {country.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid item xs={9}></Grid>
        </Grid>
      </Box>
    </Container>
  )
}
