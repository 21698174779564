import { Alert, Snackbar, colors } from '@mui/material'
import React, { useEffect, useState } from 'react'

type Props = {
  severtiy: 'success' | 'error' | 'info' | 'warning'
  showStatus: boolean
  closeStatus?: (status: boolean) => void
  alertMessage: string
}

const ResultStatus = ({ severtiy, closeStatus, showStatus, alertMessage }: Props) => {
  const [close, setClose] = useState<boolean>(false)
  const handleClose = () => {
    closeStatus && closeStatus(!showStatus)
    setClose(!close)
  }
  useEffect(() => {
    if (showStatus && !close) {
      setClose(true)
    }
  }, [showStatus])
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={close}
        autoHideDuration={10000}
        onClose={handleClose}
        color={severtiy === 'success' ? 'green' : undefined}
      >
        <Alert onClose={handleClose} severity={severtiy} variant="filled" sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

export default ResultStatus
