import { useState } from "react"
import { useLucidAuthContext } from "../LucidAuthContext/LucidAuthContext"
import { createMultipleCoreEntityAttributes, getCoreEntityAttribute, updateCoreEntityAttribute } from "../apiServices/coreEntityAttributes"
import { AddCoreEntityAttribute, GenAIRelationsBody, coreEntityAttributeData, getCoreEntityAttributeResponse } from "../businessObjects"

type GetCoreEntityAttributes = (coreEntityId:number) => Promise<void>

export const useAddMultipleCoreEntityAttributes = () => {
    //const [newDataAttribute, setNewDataAttribute] = useState<AttributeProfile>()
    //const [loading, setLoading] = useState<boolean>(false)
    //const [createError, setCreateError] = useState<boolean>()
    const { getBearerToken } = useLucidAuthContext()
    const submitNewAttribute = async (params: AddCoreEntityAttribute[]): Promise<getCoreEntityAttributeResponse[] | undefined> => {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await createMultipleCoreEntityAttributes(bearerToken, params)
        // console.log(data)
        if (status == 200) {
          //console.log(status)
          return data
          //setLoading(false)
        }
      }
    }
    return [submitNewAttribute]
}
export const useGetCoreEntityAttributes= (
    datapodId:string | undefined,
  ): [GetCoreEntityAttributes, getCoreEntityAttributeResponse[] | undefined, boolean | undefined, boolean | undefined] => {
    const [coreAttributes, setCoreAttributes] = useState<getCoreEntityAttributeResponse[]>()
    const [loading, setLoading] = useState<boolean>(false)
    const [createError, setCreateError] = useState<boolean>()
    const { getBearerToken } = useLucidAuthContext()
    const fetchCoreAttributes = async (coreEntityId:number): Promise<void> => {
      setLoading(true)
      try {
        const bearerToken = await getBearerToken()
        if (bearerToken && datapodId && coreEntityId) {
            const { data, status } = await getCoreEntityAttribute(bearerToken, datapodId, coreEntityId)
            if (status == 200) {
              setCoreAttributes(data)
              setLoading(false)
            }
        }
      } catch (error) {
        setCreateError(true)
        setLoading(false)
      }
    }
    return [fetchCoreAttributes, coreAttributes, loading, createError]
}

export const useUpdateCoreAttributeDetails = () => {
  //const [newDataAttribute, setNewDataAttribute] = useState<AttributeProfile>()
  //const [loading, setLoading] = useState<boolean>(false)
  //const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const updateCoreEntityAttibuteFunc = async (params: getCoreEntityAttributeResponse): Promise<getCoreEntityAttributeResponse[] | undefined> => {
    const bearerToken = await getBearerToken()
    if (bearerToken) {
      const { data, status } = await updateCoreEntityAttribute(bearerToken, params)
      // console.log(data)
      if (status == 200) {
        //console.log(status)
        return data
        //setLoading(false)
      }
    }
  }
  return { updateCoreEntityAttibuteFunc }
}