import React, { useEffect, useMemo, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../Routes/constants'
import { useGetAllLuciAccounts } from '../../hooks/lucidAccountHooks'
import { useLucidAuthContext } from '../../LucidAuthContext/LucidAuthContext'
import ActionPage from '../../ui-components/ActionPage'
import {
  CardHeader,
  Button,
  Card,
  CardContent,
  Card as MUICard,
  Typography,
  Grid,
  styled,
  TextField,
  MenuItem,
  Box,
} from '@mui/material'
import { LucidDataGrid } from '../../ui-components/LucidDataTable/LucidDatatable'
import { AddOutlined } from '@mui/icons-material'
import DataProcessCard from '../DataProcess/DataProcessCard'
import { useGetDataPodInfo } from '../../hooks/datapodHooks'
import { useGetConfigurationTypes } from '../../hooks/lucidConfigurationHooks'
import { ConfigurationType } from '../../businessObjects'

type Props = {}

type ViewResultsParams = {
  dataPodId: string
}

const SettingsHome = (props: Props) => {
  const { dataPodId } = useParams<ViewResultsParams>()
  const { dataPodInfo } = useGetDataPodInfo(dataPodId)
  const [configTypes, setconfigTypes] = useState<ConfigurationType[]>([])
  const [getUserAccount, lucidAccount, loading] = useGetAllLuciAccounts()
  const { getConfigurationTypes } = useGetConfigurationTypes()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      const types = await getConfigurationTypes()

      if (types.length > 0) setconfigTypes(types)
    }

    fetchData()
  }, [])

  return (
    <ActionPage>
      <Box
        sx={{
          width: '100%',
          // maxWidth: 500,
          display: 'grid',
          // gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
          gap: 2,
        }}
      >
        <Card sx={{ p: 3, backgroundColor: 'white', boxShadow: 3, borderRadius: 3, maxWidth: 400 }}>
          <CardHeader
            sx={{ mb: -2 }}
            disableTypography
            title={
              <Typography gutterBottom variant="h4">
                {'New Service Connections'}
              </Typography>
            }
          />
          <CardContent>
            <Grid item xs={14} container spacing={2}>
              <Grid item xs={12} container>
                <Grid item xs={12}>
                  <Typography variant="body1">{''}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={1}>
                <Grid item xs={8}>
                  <Grid>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => navigate(ROUTES.ListServiceConnection)} // Take it to the Service Connection List
                    >
                      List Service Connections
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ p: 3, backgroundColor: 'white', boxShadow: 3, borderRadius: 3, maxWidth: 400 }}>
          <CardHeader
            sx={{ mb: -2 }}
            disableTypography
            title={
              <Typography gutterBottom variant="h4">
                {'Configurations'}
              </Typography>
            }
          />
          <CardContent>
            <Grid item xs={12} direction={'row'} container spacing={2}>
              {configTypes.map((configType, index) => (
                <Grid item xs={6}>
                  <Grid>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        navigate(generatePath(ROUTES.ManageConfiguration, { configTypeId: configType.id }))
                      }
                    >
                      {configType.name}
                    </Button>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ p: 3, backgroundColor: 'white', boxShadow: 3, borderRadius: 3, maxWidth: 400 }}>
          <CardHeader
            sx={{ mb: -2 }}
            disableTypography
            title={
              <Typography gutterBottom variant="h4">
                {'Roles'}
              </Typography>
            }
          />
          <CardContent>
            <Grid item xs={14} container spacing={2}>
              <Grid item xs={12} container>
                <Grid item xs={12}>
                  <Typography variant="body1">{''}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={1}>
                <Grid item xs={8}>
                  <Grid>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => navigate(ROUTES.ManageRoles)} // Take it to the Service Connection List
                    >
                      Manage
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </ActionPage>
  )
}

export default SettingsHome
