import { GetSubjectAreaTablesBody } from "../businessObjects"
import { sendGenApiRequest } from '../requestManager/sendGenApiRequest'

const sampleData = {
    getSubjectAreaTables: 'GenerateSampleTableColumnData',
    getSubjectAreaTableData: 'GenerateSampleRecords',
  }

const getSubjectAreaTables = async (
    token: string,
    body: GetSubjectAreaTablesBody,
) => {
    const endPoint = sampleData.getSubjectAreaTables

    return await sendGenApiRequest(endPoint, 'POST', token, body)
}

const getSubjectAreaTableData = async (
    token: string,
    body: any,
) => {
    const endPoint = sampleData.getSubjectAreaTableData

    return await sendGenApiRequest(endPoint, 'POST', token, body)
}

export const entityServices = { getSubjectAreaTables, getSubjectAreaTableData }