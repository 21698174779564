import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export const useGetQueryString = (queryString: string): string | null => {
  const location = useLocation().search
  const query = new URLSearchParams(location)
  //console.log('id', getParam, 'query', query.get(queryString ?? ''))
  // const params = useMemo(() => {
  //   return  query.get(s) as string)
  // }, [queryStrings])

  const param = useMemo(() => {
    return query.get(queryString)
  }, [queryString])
  return param
}
