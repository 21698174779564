import React, { useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import {
  Button,
  CardMedia,
  Checkbox,
  Container,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { AppProfileEntity, DataPodInfo, Entity, Industry } from '../../businessObjects'

import { Add, SaveOutlined } from '@mui/icons-material'
import { useCreateIndustry } from '../../hooks/industryHooks'
import { isValidDescription, isValidEntry } from '../../utils/constants'
import { useGetDataPodTypes } from '../../hooks/datapodHooks'
import TextFormInput from '../../ui-components/TextForm/TextFormInput'

type Props = {
  dataPodInitInput: DataPodInfo
  dataPodId: string
  saveToProceedNext: (datapodInp: DataPodInfo) => void
  industries: Industry[]
  updateNewIndustry: (ind: Industry[]) => void
}

export const DataContextDatapodDetails = ({
  dataPodInitInput,
  saveToProceedNext,
  industries,
  updateNewIndustry,
}: Props) => {
  const [dataPodInfo, setDataPodInfo] = useState<DataPodInfo>()
  const [isNewIndustry, setIsNewIndustry] = useState<boolean>(false)
  const [newIndustry, setNewIndustry] = useState<string>()
  const [allIndustries, setAllIndustries] = useState<Industry[]>([])

  const [podType, setPodType] = React.useState('Generative Lakehouse')

  const { allDatapodTypes } = useGetDataPodTypes()

  const [createIndustry, industry] = useCreateIndustry()

  const podTypes = useMemo(() => {
    if (!allDatapodTypes.length) return []
    return allDatapodTypes
  }, [allDatapodTypes])

  useEffect(() => {
    if (dataPodInitInput && !dataPodInfo) {
      setDataPodInfo(dataPodInitInput)
    }
  }, [dataPodInitInput])

  useEffect(() => {
    if (podTypes.length > 0) {
      const defaultPodType = podTypes.find((pt) => pt.dataPodType1.toLowerCase() === podType.toLowerCase())

      defaultPodType && changeDataPodInfo('datapodType', defaultPodType.id)
    }
  }, [podTypes])

  useEffect(() => {
    if (dataPodInitInput && podTypes.length) {
      const currentPodType = podTypes.filter((pt) => pt.id === dataPodInitInput.datapodType)
      currentPodType.length && setPodType(currentPodType[0].dataPodType1)
    }
  }, [dataPodInitInput, podTypes.length])

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setPodType((event.target as HTMLInputElement).value)

  //   const ptype = event.target.value
  //   const podTypeId = podTypes.find((pt) => pt.dataPodType1 === ptype)
  //   if (podTypeId && podTypeId?.id) {
  //     changeDataPodInfo('datapodType', podTypeId.id)
  //   }
  // }

  useEffect(() => {
    if (industries.length && !allIndustries.length) {
      setAllIndustries(industries)
    }
  }, [industries])

  useEffect(() => {
    if (industry) {
      setAllIndustries([...allIndustries, industry])
      updateNewIndustry([...allIndustries, industry])
      setNewIndustry(undefined)
      setIsNewIndustry(false)
      setDataPodInfo(
        (prev) =>
          ({
            ...prev,
            industryId: industry.id,
            industryName: industry.industryName,
          } as DataPodInfo),
      )
      dataPodInfo &&
        saveToProceedNext({
          ...dataPodInfo,
          industryId: industry.id,
          industryName: industry.industryName,
        })
    }
  }, [industry])

  const changeDataPodInfo = (name: string, value: unknown) => {
    setDataPodInfo(
      (prev) =>
        ({
          ...prev,
          [name]: value,
        } as DataPodInfo),
    )
    if (name === 'industryId') {
      setIsNewIndustry(false)
      setNewIndustry(undefined)
    }

    dataPodInfo &&
      saveToProceedNext({
        ...dataPodInfo,
        ...dataPodInitInput,
        [name]: value,
      })
  }
  const saveNewIndustry = async () => {
    if (newIndustry) {
      await createIndustry(newIndustry.trim())
    }
  }

  const onSelectIndustry = (industryId: string) => {
    const selectedIndustry = allIndustries.find((industry) => industry.id == Number(industryId))

    if (selectedIndustry) {
      changeDataPodInfo('industryName', selectedIndustry.industryName)
      changeDataPodInfo('industryId', industryId)
    }
  }

  const isIndustryExists = useMemo(() => {
    return allIndustries.some((ind) => ind.industryName.toLowerCase() === newIndustry?.trim().toLowerCase())
  }, [newIndustry, allIndustries])

  const validateNameText = useMemo(() => {
    if (!dataPodInfo?.name) return
    return !isValidEntry(dataPodInfo?.name) ? 'Invalid Name' : undefined
  }, [dataPodInfo?.name])

  const validateDescText = useMemo(() => {
    if (!dataPodInfo?.description) return
    return !isValidDescription(dataPodInfo?.description) ? 'Invalid Entry' : undefined
  }, [dataPodInfo?.description])

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color={'text.primary'}>
              <span style={{ color: 'red' }}>*</span> Indicates a required field
            </Typography>
          </Grid>
          <TextFormInput
            value={dataPodInfo?.name ?? ''}
            labelText="Name"
            required
            placeholderText="Enter Data Pod Name"
            changeHandle={(podName) => changeDataPodInfo('name', podName)}
            validateInput={!!validateNameText}
            validateErrorText={validateNameText}
            columnSize={12}
          />
          {/* <Grid item xs={12}>
            <Typography color={'text.primary'}>
              Name<span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Enter Data Pod Name"
              variant="outlined"
              required
              onChange={({ target }) => changeDataPodInfo('name', target.value)}
              value={dataPodInfo?.name}
              error={!isValidEntry(dataPodInfo?.name)}
              helperText={!isValidEntry(dataPodInfo?.name) ? 'Invalid Name' : ''}
              size="small"
            />
          </Grid> */}
          <TextFormInput
            value={dataPodInfo?.description ?? ''}
            labelText="Description"
            required
            placeholderText="Enter Description"
            changeHandle={(podDesc) => changeDataPodInfo('description', podDesc)}
            validateInput={!!validateDescText}
            validateErrorText={validateDescText}
            multiline={4}
          />
          {/* <Grid item xs={12}>
            <Typography color={'text.primary'}>
              Description<span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Enter description"
              variant="outlined"
              color="secondary"
              required
              onChange={({ target }) => changeDataPodInfo('description', target.value)}
              value={dataPodInfo?.description ?? ''}
              helperText={!isValidDescription(dataPodInfo?.description) ? 'Invalid Entry' : ''}
              error={!isValidDescription(dataPodInfo?.description)}
              rows={4}
              multiline
            />
          </Grid> */}
          <Grid item xs={12}>
            <Typography color={'text.primary'}>
              Industry<span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              fullWidth
              id="outlined-select-industry"
              select
              label="Select Industry"
              // SelectProps={{
              //   native: true,
              // }}
              value={dataPodInfo?.industryId ?? '1'}
              color="secondary"
              required
              size="small"
              onChange={({ target }) => onSelectIndustry(target.value)}
            >
              {allIndustries.map((industry, index) => (
                <MenuItem key={industry.id + '_' + index} value={industry.id}>
                  {industry.industryName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={2}>
            <Button onClick={() => setIsNewIndustry(true)} startIcon={<Add />}>
              Add New Industry
            </Button>
          </Grid>
          {isNewIndustry && (
            <Grid item xs={9} container spacing={1}>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  placeholder="Add New Industry"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onChange={({ target }) => setNewIndustry(target.value)}
                  value={newIndustry ?? ''}
                  error={!isValidEntry(newIndustry) || isIndustryExists}
                  helperText={
                    !isValidEntry(newIndustry) ? ' Invalid industry' : isIndustryExists ? 'Industry Exits' : ''
                  }
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={saveNewIndustry}
                  disabled={!isValidEntry(newIndustry) || isIndustryExists}
                  startIcon={<SaveOutlined />}
                >
                  Add Industry
                </Button>
              </Grid>
            </Grid>
          )}

          {/* <Grid container item xs={12}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={8}>
                <FormControl>
                  <FormLabel id="radio-buttons-group-label">Datapod Type</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={podType}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="Generative Lakehouse" control={<Radio />} label="Generative Lakehouse" />
                    <FormControlLabel
                      value="Generative Integration"
                      control={<Radio />}
                      label="Generative Integrations"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </Box>
    </Container>
  )
}
