import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../Routes/constants'
import NoEntries from '../../ui-components/NoEntries'
import { useGetAllLuciAccounts } from '../../hooks/lucidAccountHooks'
import { useLucidAuthContext } from '../../LucidAuthContext/LucidAuthContext'
import ActionPage from '../../ui-components/ActionPage'
import { Box } from '@mui/material'
import { paddingTopBottom } from '../../styles/globalStyles'
import { Confirmation } from './Confirmation'
import { ProgressBar } from '../../ui-components/ProgressBar'
import AnimatedLoader from '../../ui-components/AnimatedLoader'

type Props = {}

export const AccountHome = (props: Props) => {
  const { tenantId } = useLucidAuthContext()
  const [getUserAccount, lucidAccount, loading] = useGetAllLuciAccounts()
  const navigate = useNavigate()
  const handleAccount = () => {
    navigate(ROUTES.AccountLayout)
  }

  useEffect(() => {
    if (tenantId && !lucidAccount) {
      getUserAccount(tenantId)
    }
  }, [tenantId])

  const lucidUserInfo = useMemo(() => {
    if (lucidAccount) {
      return lucidAccount[0]
    }
  }, [lucidAccount])

  return (
    <ActionPage>
      <div style={paddingTopBottom}>
        <Box sx={{ width: '100%' }}>
          {/* <ProgressBar loading={loading} /> */}
          <AnimatedLoader height='50%' width='40%' loading={loading}/>
          {lucidUserInfo ? <Confirmation userAccount={lucidUserInfo} /> : null}

          {!lucidUserInfo && !loading && (
            <NoEntries onClick={handleAccount} buttonText="Add Account" headingText="No accounts are created yet" />
          )}
        </Box>
      </div>
    </ActionPage>
  )
}
