import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { getAccessToken } from '../apiServices/getAccessToken'
import { accountServices } from '../apiServices/lucidAccount'
import { LucidAccount } from '../businessObjects'
import { AxiosError } from 'axios'
import { getAccountToken, setAccountToken } from '../utils/tokens'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'Routes/constants'
import { environment } from 'utils/environment'

interface ApiToken {
  token_type: string
  expires_in: number
  ext_expires_in: number
  access_token: string
}

export interface LuciAuthContextProps {
  tenantId: string | null
  accountId: string | null
  updatePreferredAccountId: (id: string) => void
  getBearerToken: () => Promise<string | undefined>
}

export const LucidAuthContext = createContext<LuciAuthContextProps>({
  tenantId: null,
  accountId: null,
  updatePreferredAccountId: (id: string) => {
    return
  },
  getBearerToken: async () => undefined,
})
// const calculateTokenExpiration = (expiresIn?: number): number => {
//   const currentTime = new Date().getTime()
//   const expiresInMs = expiresIn ? expiresIn * 1000 : 0 // Convert expiresIn seconds to milliseconds
//   const expirationTime = currentTime + expiresInMs
//   return expirationTime
// }

export const isTokenExpired = (expriryTime: number) => {
  const currentTime = new Date().getTime()
  // console.log({ expriryTime }, { currentTime })
  const expTime = (expriryTime ? expriryTime : 0) * 1000
  return expTime - currentTime <= 60000
}

// const evaluateIDTokenExpiry = (tokenExp?: number) => {
//   // const expTime = calculateTokenExpiration(tokenExp)
//   // console.log({ expTime })
//   return isTokenExpired(tokenExp)
// }
export const useLucidAuthContext = () => useContext<LuciAuthContextProps>(LucidAuthContext)

export const LucidAuthContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tenantId, setTenantId] = useState<string | null>(null)
  const [accountId, setAccountId] = useState<string | null>(getAccountToken())
  // const [expiresIn, setExpiresIn] = useState<number | null>(null)
  // const [bearerToken, setBearerToken] = useState<string | null>(null)
  const isAuthenticated = useIsAuthenticated()
  const { accounts, instance } = useMsal()
  const account = useAccount(accounts[0] || {})
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated && account && !tenantId) {
      setTenantId(account.tenantId)
      getUserAccount(account.tenantId)
    }
  }, [isAuthenticated, account])

  // const getIdToken = async (): Promise<string | undefined> => {
  //   return accounts[0].idToken
  // }

  const getNewBearerToken = async (): Promise<string | undefined> => {
    // Id token issued by azureAD gets expired  approx 1 hour.
    // Idtoken expiry is evaluated and refetch new IdToken. The refresh happens silently without affecting user session
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md
    try {
      const idTokenExp = account?.idTokenClaims?.exp

      if (idTokenExp && !isTokenExpired(idTokenExp)) {
        return account.idToken
      }
      const tokenRefreshRes = await instance.acquireTokenSilent({
        scopes: [],
        forceRefresh: true,
        refreshTokenExpirationOffsetSeconds: 3000,
      })

      // console.log({ tokenRefreshRes })
      return tokenRefreshRes.idToken
    } catch (error) {
      console.log(error)
    }

    // try {
    //   const { data, status } = await getAccessToken()
    //   if (status == 200) {
    //     const { access_token, expires_in } = data as ApiToken
    //     setBearerToken(access_token)
    //     setExpiresIn(calculateTokenExpiration(expires_in))
    //     return access_token
    //   }
    // } catch (error) {
    //   console.log(error)
    // }
  }

  const getUserAccount = async (usrTenantId: string) => {
    try {
      const token = await getNewBearerToken()

      if (token && usrTenantId) {
        const { data, status } = await accountServices.getAllAccountsByTenantID(token, usrTenantId)
        if (status == 200) {
          const usrAccount = data as LucidAccount[]
          //'067B78ED-825E-493F-A9D0-F0A13512AF15'
          if (usrAccount[0].accountId) {
            setAccountToken(usrAccount[0].accountId)
            setAccountId(usrAccount[0].accountId)
          } else {
            navigate(ROUTES.Account)
          }
        }
      }
    } catch (error) {
      const err = error as AxiosError
      console.log(err.message)
    }
  }
  const updateAccountId = (id: string) => {
    setAccountId(id)
    setAccountToken(id)
  }

  return (
    <LucidAuthContext.Provider
      value={{
        tenantId,
        accountId,
        updatePreferredAccountId: updateAccountId,
        // getBearerToken: getIdToken,
        getBearerToken: getNewBearerToken,
      }}
    >
      {children}
    </LucidAuthContext.Provider>
  )
}

export default LucidAuthContextProvider
