import * as React from 'react'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import { useMemo } from 'react'
import { Container, Box } from '@mui/material'
type Props = {
  loading?: boolean
}
export const ProgressBar = ({ loading }: Props) => {
  const showStatus = useMemo(() => {
    return loading && loading ? true : false
  }, [loading])
  if (showStatus)
    return (
      <Container maxWidth="sm">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
            gap: 3,
            position: 'absolute',
            width: 'calc(1336px/2 - 208.5px)',
            height: 180,
            top: 'calc(45% - 206px/2 + 30px)',
            pointerEvents: 'none'
          }}
        >
          <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
            <CircularProgress color="success" />
          </Stack>
        </Box>
      </Container>
    )
  return <></>
}
