import { Typography } from '@mui/material'

import React from 'react'

type Props = {
  labelText: string
  required?: boolean
}

export const LucidTextLabel = ({ labelText, required }: Props) => {
  return (
    <Typography sx={{ color: '#1D3783' }} variant="h6" gutterBottom>
      {labelText} {required && <span style={{ color: 'red' }}>*</span>}
    </Typography>
  )
}

type HeadingLabelProps = {
  headingText: string
}
export const HeadingLabel = ({ headingText }: HeadingLabelProps) => {
  return (
    <Typography variant="h4" gutterBottom>
      {headingText}
    </Typography>
  )
}
