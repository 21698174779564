import { RecommendedSubjectArea } from 'pages/DataContext/DataContextSubjectArea'
import {
  getCoreEntityAttributes,
  getEnrichedCoreEntityAttributes,
  getGenAiReconcilation,
  getGenAiRelations,
  generateGenAISubjectAreas,
} from '../apiServices/genAiApiServices'
import {
  GenAIReconcilationResponse,
  GenAIRelationResponse,
  Industry,
  RecommendedSubjectAreas,
  coreEntityAttributes,
} from '../businessObjects'
import React, { useEffect, useState } from 'react'

export const useGetGenAiReconcilation = (): {
  fetchEntity: (datapodId: string, prompt: string) => Promise<GenAIReconcilationResponse | undefined>
} => {
  // const { getBearerToken } = useLucidAuthContext()
  const fetchEntity = async (datapodId: string, prompt?: string) => {
    try {
      const bearerToken = 'Lucid123'
      if (bearerToken && datapodId) {
        const { data, status } = await getGenAiReconcilation(bearerToken, datapodId, prompt)
        if (status == 200) {
          return data as GenAIReconcilationResponse
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  return { fetchEntity }
}

export const userGenerateSubjectAreas = (): {
  fetchSubjectAreas: (datapodId: string) => Promise<RecommendedSubjectAreas | undefined>
} => {
  // const [subjectAreaList, setSubjectAreaList] = React.useState<RecommendedSubjectAreas>()
  const fetchSubjectAreas = async (datapodId: string) => {
    try {
      const bearerToken = 'Lucid123'
      if (bearerToken && datapodId) {
        const { data, status } = await generateGenAISubjectAreas(bearerToken, datapodId)
        if (status == 200) {
          return data as RecommendedSubjectAreas
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  return { fetchSubjectAreas }
}

export const useGetGenAiRelations = (): {
  fetchRelations: (datapodId: string, dataCoreEntityId: number) => Promise<GenAIRelationResponse | undefined>
} => {
  // const { getBearerToken } = useLucidAuthContext()
  const fetchRelations = async (datapodId: string, dataCoreEntityId: number) => {
    try {
      const bearerToken = 'Lucid123'
      if (bearerToken && datapodId && dataCoreEntityId) {
        const { data, status } = await getGenAiRelations(bearerToken, datapodId, dataCoreEntityId)
        if (status == 200) {
          return data as GenAIRelationResponse
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  return { fetchRelations }
}

export const useGetGenAiCoreEntityAttributes = (): {
  fetchCoreEntityAttributes: (datapodId: string, dataCoreEntityId: number) => Promise<coreEntityAttributes | undefined>
} => {
  // const { getBearerToken } = useLucidAuthContext()
  const fetchCoreEntityAttributes = async (datapodId: string, dataCoreEntityId: number) => {
    try {
      const bearerToken = 'Lucid123'
      if (bearerToken && datapodId && dataCoreEntityId) {
        const { data, status } = await getCoreEntityAttributes(bearerToken, datapodId, dataCoreEntityId)
        if (status == 200) {
          return data as coreEntityAttributes
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  return { fetchCoreEntityAttributes }
}
export const useGetGenAiEnrichedCoreEntityAttributes = (): {
  fetchEnrichedCoreEntityAttributes: (
    datapodId: string,
    coreEntityName: string,
    entityDescription: string,
    entityType: string,
    canonicalEntityIds: number[],
  ) => Promise<coreEntityAttributes | undefined>
} => {
  // const { getBearerToken } = useLucidAuthContext()
  const fetchEnrichedCoreEntityAttributes = async (
    datapodId: string,
    coreEntityName: string,
    entityDescription: string,
    entityType: string,
    canonicalEntityIds: number[],
  ) => {
    try {
      const bearerToken = 'Lucid123'
      if (bearerToken && datapodId && coreEntityName && entityDescription && entityType && canonicalEntityIds) {
        const { data, status } = await getEnrichedCoreEntityAttributes(
          bearerToken,
          datapodId,
          coreEntityName,
          entityDescription,
          entityType,
          canonicalEntityIds,
        )
        if (status == 200) {
          return data as coreEntityAttributes
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  return { fetchEnrichedCoreEntityAttributes }
}

export const useRecommendSubjectAreas = (datapodId?: string, industry?: Industry) => {
  const [recommendations, setRecommendations] = useState<RecommendedSubjectArea[]>([])
  // const [recommendationsRow, setRecommendationRows] = useState<DataTableRow[]>([])
  const [recommendationError, setRecommendationError] = useState<boolean>(false)
  const [loadingRecommendations, setLoadingRecommendations] = useState<boolean>(false)
  const fetchSubjectAreas = async () => {
    try {
      const bearerToken = 'Lucid123'
      if (bearerToken && datapodId) {
        setLoadingRecommendations(true)
        const { data, status } = await generateGenAISubjectAreas(bearerToken, datapodId)
        if (status == 200 && data) {
          const res = data as RecommendedSubjectAreas
          if (res.result === 'Success') {
            setLoadingRecommendations(false)
            if (res.data.length) {
              setRecommendations(
                res.data.map((sa) => ({
                  name: sa.SubjectAreaName,
                  description: sa.SubjectAreaDescription,
                  industryname: industry?.industryName ?? '',
                  industryId: industry?.id ?? 0,
                })),
              )
            } else {
              setRecommendationError(true)
            }
          }
        }
      }
    } catch (err) {
      setLoadingRecommendations(false)
      setRecommendationError(true)
      console.log(err)
    }
  }

  useEffect(() => {
    if (datapodId && !recommendations.length) fetchSubjectAreas()
  }, [datapodId])
  return { recommendations, loadingRecommendations, recommendationError }
}
