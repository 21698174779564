import { SvgIcon } from '@mui/material'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import SellOutlinedIcon from '@mui/icons-material/SellOutlined'
import InsertPageBreakOutlinedIcon from '@mui/icons-material/InsertPageBreakOutlined'
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined'
import RadarOutlinedIcon from '@mui/icons-material/RadarOutlined'
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices'
import AirOutlinedIcon from '@mui/icons-material/AirOutlined'
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined'
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined'
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'

export const drawerWidth = 240
export type NavigationDrawerProps = {
  onDrawerAction: (open: boolean) => void
  onLinkClicked: (link: string) => void
  entries: NavigationEntry[]
  activeLink?: string
}

export type NavigationEntry = {
  Icon: typeof SvgIcon
  text: string
  link: string
}

export const entries: NavigationEntry[] = [
  //{ Icon: FolderOutlinedIcon, text: 'Home', link: '' },
  { Icon: CorporateFareOutlinedIcon, text: 'Org. Account', link: 'account' },
  { Icon: PersonOutlinedIcon, text: 'User Account', link: 'useraccount' },
  { Icon: WorkspacesOutlinedIcon, text: 'Data Pods', link: '' },
  { Icon: HomeOutlinedIcon, text: 'Overview', link: 'overview' },
  { Icon: InsertPageBreakOutlinedIcon, text: 'Data Profiling', link: 'dataProfiling' },
  { Icon: CompareArrowsOutlinedIcon, text: 'Data Mapping', link: 'dataMapping' },
  { Icon: SchemaOutlinedIcon, text: 'Data Modeling', link: 'dataModeling' },
  { Icon: AirOutlinedIcon, text: 'Data Engineering', link: 'dataengineering' },
  { Icon: RadarOutlinedIcon, text: 'Data Lakes', link: 'datalakes' },
  { Icon: SignalCellularAltOutlinedIcon, text: 'Data Analytics', link: 'dataanalytics' },
  { Icon: SellOutlinedIcon, text: 'Performance Tiers', link: 'milestoneplan' },
  { Icon: SettingsOutlinedIcon, text: 'Settings', link: 'settings' },
  { Icon: InsertDriveFileOutlinedIcon, text: 'Documentation', link: 'documentation' },
]

export const listItemCommon = {
  height: 50,
  margin: 10,
  borderRadius: 6,
  transition: '0.3s',
}
export const listItemOpen = {
  ...listItemCommon,
  width: 220,
}

export const listItem = {
  ...listItemCommon,
  width: 60,
}

export const drawerTop = {
  paddingTop: 20,
}

export const paddingForDrawer = {
  paddingLeft: 240,
  transition: '0.3s',
}

export const paddingForClosedDrawer = {
  paddingLeft: 74,
  transition: '0.3s',
}
