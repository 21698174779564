export const completeContainer = {
  overflowX: 'hidden' as const,
  backgroundColor: '#FCFEFF', // add primay palette color
} as React.CSSProperties

export const outerPageContainer = {
  backgroundColor: '', // add primay palette color
}

export const fullHeight = {
  height: '100%',
  lineHeight: '100%',
  verticalAlign: 'center',
  display: 'flex',
  alignItems: 'center',
}

export const innerPageContainer = {
  backgroundColor: '#fff',
  borderRadius: 10,
  marginTop: '0px',
  marginBottom: '0px',
  height: '88vh',
  overflow: 'auto',
  maxHeight: '80%',
} as React.CSSProperties

export const mainPageContainer = {
  width: '95%',
  margin: 'auto',
  maxHeight: '100%',
  paddingBottom: '20px',
  paddingTop: '10px',
  flexGrow: 1,
} as React.CSSProperties

export const paddingBottomActionBar = {
  paddingBottom: '80px',
}

export const paddingTopBottom = {
  paddingTop: '20px',
  paddingBottom: '30px',
}
export const cardContainer = {
  minWidth: '1069px',
  margin: '0 auto',
  maxWidth: 'calc(100% - 15px)%',
  padding: '0 10px',
}
export const dataSystemCard = {
  width: '650px',
  margin: '0 auto',
  maxWidth: 'calc(100% - 15px)%',
  padding: '0 8px',
}
export const paddingLeft = {
  paddingLeft: '30px',
}

export const paddingRight = {
  paddingRight: '30px',
}

export const paddingLeftRight = {
  ...paddingLeft,
  ...paddingRight,
}

export const standardContentPadding = {
  padding: '30px',
}

export const marginLeftRight = {
  marginLeft: 20,
  marginRight: 20,
}

export const paddingTop = {
  paddingTop: '20px',
}

export const paddingBottom = {
  paddingBottom: '20px',
}
