import { Container, Box, Grid, Typography, TextField, MenuItem } from '@mui/material'
import React from 'react'
import { UserAccount } from '../../businessObjects'

type Props = {
  userAccount?: UserAccount
  updateUserParams: (userParams: UserAccount) => void
}

export const AddAdditionalDetails = ({ userAccount, updateUserParams }: Props) => {
  const changeUserParams = (name: string, value: string) => {
    updateUserParams({
      ...(userAccount as UserAccount),
      [name]: value,
    })
  }
  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Typography color={'text.primary'}>
              <span style={{ color: 'red' }}>*</span> Indicates a required field
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography color={'text.primary'}>
              Name<span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Enter Account Name"
              variant="outlined"
              color="secondary"
              required
              size="small"
              value={userAccount?.name ?? ''}
              onChange={({ target }) => changeUserParams('name', target.value)}
            />
          </Grid>
          <Grid item xs={9}>
            <Typography color={'text.primary'}>
              Description<span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Enter Account description"
              variant="outlined"
              color="secondary"
              required
              rows={4}
              multiline
              value={userAccount?.accountDescription ?? ''}
              onChange={({ target }) => changeUserParams('accountDescription', target.value)}
            />
          </Grid>
          <Grid item xs={9}>
            <Typography color={'text.primary'}>
              Tenant id<span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Enter Tenant id"
              variant="outlined"
              color="secondary"
              required
              size="small"
              value={userAccount?.accountTenantId ?? ''}
              onChange={({ target }) => changeUserParams('accountTenantId', target.value)}
            />
          </Grid>
          <Grid item xs={9}>
            <Typography color={'text.primary'}>
              Aggrement Status<span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Enter Aggrement Status"
              variant="outlined"
              color="secondary"
              required
              size="small"
              value={userAccount?.agreementStatus ?? ''}
              onChange={({ target }) => changeUserParams('agreementStatus', target.value)}
            />
          </Grid>
          <Grid item xs={12} />
        </Grid>
      </Box>
    </Container>
  )
}
