import Box from '@mui/material/Box'
import { Card, CardContent, Container, Grid, Icon, Typography } from '@mui/material'
import { SignInButton } from './SignInButton'

// import { LucidIcon } from '../ui-icons/LucidIcon';
import Logo from '../ui-icons/LogoBlue.svg'

export default function LucidHomePage() {
  return (
    <>
      <Container maxWidth="xs">
        <Box py={16}>
          <Card variant="outlined">
            <CardContent>
              <Box display="flex" mt={3}>
                <Box mx="auto">
                  {/* <LucidIcon fontSize='large' viewBox=' 0 0 40 40'/>
                   */}
                  <img src={Logo} alt="lucidLogo" height={32} width={124} />
                </Box>
              </Box>
              <Box mt={2} px={4}>
                <Typography variant="h6" align="center" gutterBottom>
                  Welcome to Lucid Data Hub
                </Typography>
                <Box my={3}>
                  <Grid item container alignItems={'center'} justifyContent={'center'}>
                    <SignInButton />
                  </Grid>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Container>
      <Container maxWidth="md">
        <Box>
          <Typography variant="body2" align="justify" gutterBottom>
            <small>
              Your Modern Gen AI Era Data & Analytics Management Platform powered by Gen AI and LLM models. Empower 100%
              Data exploration and curation, accelerating development 20x. Achieve 10x Cost Savings with Rapid Data Prep
              and BI readiness. Ensure Enterprise-Grade Security and Data Privacy.{' '}
            </small>
          </Typography>
        </Box>
      </Container>
    </>
  )
}
