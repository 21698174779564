import { Box, Grid, IconButton, Typography, TextField, Button, Chip, MenuItem, CardContent } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { cardContainer } from '../../../styles/globalStyles'
import ActionPage from '../../../ui-components/ActionPage'
import { useGetSessionPodInfo } from '../../../hooks/getSessionPodInfo'
import DataPodDetailRibbon from '../../../ui-components/DataPodDetailRibbon/DataPodDetailRibbon'
import DataProcessCard from '../DataProcessCard'
// import { format } from 'sql-formatter'

type Props = {}
type ViewResultsParams = {
  dataPodId: string
}
export const DataEngineeringResults = (props: Props) => {
  const dataPod = useGetSessionPodInfo()
  return (
    <ActionPage>
      {/* <Box>
        <Grid item xs={12}>
          <Typography gutterBottom>Data Mapping</Typography>
        </Grid>
      </Box> */}
      <Box style={cardContainer}>
        <Grid container spacing={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} />
            <Grid item xs={15} container>
              <Grid item xs={2}>
                <Typography variant="h4" gutterBottom color="primary">
                  Data Engineering
                </Typography>
              </Grid>
              <Grid item sx={{ paddingLeft: 2 }} xs={10} container spacing={1}>
                <Grid item xs={3} md={5.7}>
                  <DataProcessCard
                    headingText="Pipeline Schedules"
                    type="Data Engineering Pipeline Schedules"
                    dataPodId={dataPod?.id}
                    labelType={'DataPod'}
                    subLabel={dataPod?.name}
                  />
                </Grid>
                <Grid item xs={3} md={5.7}>
                  <DataProcessCard
                    headingText="Pipeline Monitoring"
                    type="Data Engineering Pipeline Monitoring"
                    dataPodId={dataPod?.id}
                    labelType={'DataPod'}
                    subLabel={dataPod?.name}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ActionPage>
  )
}