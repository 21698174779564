import { DataColumnBody } from '../businessObjects'
import { sendRequest } from '../requestManager/sendRequest'

const dataColumnPath = {
  addDataColumn: 'DataColumn/addnew',
  getDataColumnById: 'DataColumn/GetById',
  getDataColumnByName: 'DataColumn/GetByName',
  getDataColumnByTableId: 'DataColumn/GetByTableId',
}

export const createDataColumn = async (token: string, dataColumn: DataColumnBody, datapodId: string) => {
  const url = dataColumnPath.addDataColumn + `?datapodID=${datapodId}`
  return await sendRequest(url, 'POST', token, dataColumn)
}

export const getDataColumnById = async (token: string, id: number) => {
  const url = dataColumnPath.getDataColumnById + `?id=${id}`
  return await sendRequest(url, 'GET', token)
}
export const getDataColumnByName = async (
  token: string,
  columnName: string,
  datapodId?: string,
  dataTableId?: number,
) => {
  let url = dataColumnPath.getDataColumnByName
  if (columnName && datapodId && dataTableId) {
    url += `?dataColumnName=${columnName}&datapodID=${datapodId}&dataTableId=${dataTableId}`
  } else if (columnName && datapodId) {
    url += `?dataColumnName=${columnName}&datapodID=${datapodId}`
  } else if (columnName) {
    url += `?dataColumnName=${columnName}`
  }
  return await sendRequest(url, 'GET', token)
}
export const getDataColumnByTableId = async (token: string, dataTableId: number, searchText?: string) => {
  //const config = getAxiosRequestConfig(token)
  let url = dataColumnPath.getDataColumnByTableId + `?dataTableId=${dataTableId}`
  if (searchText) {
    url += `&searchText=${searchText}`
  }
  return await sendRequest(url, 'GET', token)
}
