import { Container, Box, Grid, Typography, TextField } from '@mui/material'
import React from 'react'
import { UserAccount } from '../../businessObjects'

type Props = {
  userAccount?: UserAccount
  updateUserParams: (userParams: UserAccount) => void
}

const EMAIL_PATTERN = /^\w+([\.-]?\w+)+@([\.-]?\w+)+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/

export const AddEmailAddress = ({ userAccount, updateUserParams }: Props) => {
  const changeUserParams = (name: string, value: string) => {
    updateUserParams({
      ...(userAccount as UserAccount),
      [name]: value,
    })
  }
  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Typography color={'text.primary'}>
              <span style={{ color: 'red' }}>*</span> Indicates a required field
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography color={'text.primary'}>
              Email<span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Enter Email Address"
              variant="outlined"
              color="secondary"
              required
              type="email"
              helperText={
                userAccount?.primaryContactEmail
                  ? !userAccount?.primaryContactEmail.match(EMAIL_PATTERN) && 'Invalid Email'
                  : ''
              }
              value={userAccount?.primaryContactEmail ?? ''}
              onChange={({ target }) => changeUserParams('primaryContactEmail', target.value)}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
