import {
  Box,
  Grid,
  IconButton,
  Typography,
  TextField,
  Button,
  Container,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { cardContainer } from '../../../styles/globalStyles'
import ActionPage from '../../../ui-components/ActionPage'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../../Routes/constants'
import ShadowTable, { ShadowTableAction } from '../../../ui-components/ShadowTable/ShadowTable'
import { DataTableRow } from '../../../ui-components/DataTable/DataTableTypes'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import { useGetDataAttributes, useGetDataAttributesMapping } from '../../../hooks/dataAttrbutesHooks'
import { LucidPopUp } from '../../../ui-components/LucidPopUp/LucidPopUp'
import { z } from 'zod'
import { ChatOpenAI } from 'langchain/chat_models/openai'
import { PromptTemplate } from 'langchain/prompts'
import { LLMChain } from 'langchain/chains'
import { StructuredOutputParser, OutputFixingParser } from 'langchain/output_parsers'
import { attributeMappingDataBody } from '../../../businessObjects'
import { environment } from '../../../utils/environment'
import { useAddNewDataTransformer, useUpdateDataTransformer } from '../../../hooks/dataTransformerHooks'
import ResultStatus from '../../../ui-components/ResultStatus'
import { updateDataAttribute } from '../../../apiServices/dataAttributes'

type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataAttributeID: string
}
export const CanonicalModelAttributeDetails = (props: Props) => {
  const { dataPodId, dataAttributeID } = useParams<ViewResultsParams>()
  const [fetchAttributesMappingData, attributesMappingData] = useGetDataAttributesMapping()
  const [fetchDataAttributes, dataAttributes, loading, createError] = useGetDataAttributes(true)
  const [attributesMappingDataState, setAttributesMappingDataState] = useState<attributeMappingDataBody[] | undefined>(attributesMappingData)

  // const [searchText, setSearchText] = useState<string>('')

  useEffect(() => {
    fetchDataAttributes(Number(dataAttributeID))
    fetchAttributesMappingData(Number(dataAttributeID))
  }, [])

  useEffect(() => {
    setAttributesMappingDataState(attributesMappingData)
  }, [attributesMappingData])

  const navigate = useNavigate()
  const onClickHome = () => {
    const dataProfilingRoute = generatePath(ROUTES.Overview, { dataPodId: dataPodId })
    navigate(dataProfilingRoute)
  }

  return (
    <ActionPage>
      <Grid item container spacing={2}>
        <Grid item xs={2}>
          <IconButton onClick={onClickHome}>
            <HomeOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box style={cardContainer}>
        <Grid item container spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={4} alignItems={'center'}>
                  <Grid item container xs={15} spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">Entity Name</Typography>
                    </Grid>
                    <Grid item />

                    <Grid item xs={5}>
                      <Typography variant="subtitle1">Attribute Name</Typography>
                    </Grid>
                    <Grid item xs={12} md={15} />

                    <Grid item container xs={15} spacing={1}>
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          id="outlined-select-dataSource"
                          variant="outlined"
                          value={dataAttributes?.[0].entityName}
                          required
                          size="small"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={1} />
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          id="outlined-select-dataSource"
                          variant="outlined"
                          value={dataAttributes?.[0].dataAttributeName}
                          required
                          size="small"
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} />
          </Grid>
        </Grid>
        {attributesMappingData && attributesMappingData.length > 0 ? (
          <AttributeMappingData attributesMappingData={attributesMappingDataState} setAttributesMappingData={setAttributesMappingDataState} />
        ) : (
          <Grid container sx={{ justifyContent: 'center', marginTop: '4rem', fontSize: '1rem' }}>
            No Mappings Present For The Selected Attribute
          </Grid>
        )}
      </Box>
    </ActionPage>
  )
}

type AttributeMappingDataProps = {
  attributesMappingData: attributeMappingDataBody[] | undefined
  setAttributesMappingData: React.Dispatch<React.SetStateAction<attributeMappingDataBody[] | undefined>>
}

export const AttributeMappingData = ({ attributesMappingData, setAttributesMappingData }: AttributeMappingDataProps) => {
  const [leftTableData, setLeftTableData] = useState<DataTableRow[] | undefined>()
  const [transformersData, setTransformersData] = useState<DataTableRow[] | undefined | []>()
  const [selectedRowIds, setSelectedRowIds] = useState<number[] | undefined>([])
  const [selectedRowIdToAdd, setSelectedRowIdToAdd] = useState<number | undefined>()
  const [transformerRowIdToEdit, setTransformerRowIdToEdit] = useState<number | undefined>()
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [actionType, setActionType] = useState<'ADD' | 'UPDATE'>('ADD')
  const [popupError, setPopupError] = useState<{ inputError: boolean; queryError: boolean }>({
    inputError: false,
    queryError: false,
  })
  const [userInput, setUserInput] = useState<string>('')
  const [query, setQuery] = useState<string>('')
  const [selectedRow, setSelectedRow] = useState<attributeMappingDataBody | undefined>()

  const leftTableColumns = useMemo(() => {
    return [{ label: '' }, { label: 'Data System Name' }, { label: 'Data Table Name' }, { label: 'Column Name' }, { label: 'Add New Transformer' }]
  }, [])

  const rightTableColumns = useMemo(() => {
    return [
      { label: 'dataSystemName' },
      { label: 'Transformer Text' },
      { label: 'Transformer Query' },
      { label: 'Edit Transformer' },
    ]
  }, [])


  const updateTransformerData = () => {
    let updatedAttributesMappingData
    let allAttributeMapTransformations = []
    if (selectedRowIds?.length && attributesMappingData) {
      updatedAttributesMappingData = attributesMappingData.filter((attr) => selectedRowIds.includes(attr.id))
      for (let row of updatedAttributesMappingData) {
        const singleAttributeMapTransformations = row.transformation.map((transformer) => {
          if (transformer.transformerID) {
            return {
              id: `${transformer.transformerID}`,
              values: [row.dataSystemName, transformer.transformerText, transformer.transformerQuery, 'EDIT'],
            }
          }
          else {
            return {
              id: `${transformer.transformerID}`,
              values: []
            }
          }
        })
        allAttributeMapTransformations.push(...singleAttributeMapTransformations)
      }
    }
    setTransformersData(allAttributeMapTransformations)
  }

  useEffect(() => {
    if (attributesMappingData && attributesMappingData.length > 0) {
      setSelectedRowIds([attributesMappingData[0].id])
    }
  }, [attributesMappingData?.length])

  useEffect(() => {
    if (userInput.trim() === "") {
      setPopupError(prev => { return { ...prev, inputError: true } })
    } else {
      setPopupError(prev => { return { ...prev, inputError: false } })
    }
    if (query.trim() === "") {
      setPopupError(prev => { return { ...prev, queryError: true } })
    } else {
      setPopupError(prev => { return { ...prev, queryError: false } })
    }
  }, [userInput, query])

  useEffect(() => {
    setLeftTableData(
      () =>
        attributesMappingData?.map((dt) => {
          return {
            id: `${dt.id}`,
            values: [
              selectedRowIds?.includes(dt.id) ? 'CHECKED' : 'UNCHECKED',
              dt.dataSystemName,
              dt.dataTableName,
              dt.dataColumnName,
              'ADD'
            ],
          }
        }) as DataTableRow[],
    )
    if (attributesMappingData) updateTransformerData()
  }, [selectedRowIds])

  // const showRelevantTransformers = (id: string) => {
  //   const rowId = Number(id)
  //   if (selectedRowIds?.includes(rowId)) {
  //     setSelectedRowIds(selectedRowIds.filter((existingId) => existingId !== rowId))
  //   } else {
  //     setSelectedRowIds((prev) => {
  //       if (prev) return [...prev, rowId]
  //     })
  //   }
  // }

  const addNewTransformerHandler = (id: string, actionType: ShadowTableAction) => {
    const rowId = Number(id)
    if (actionType === ShadowTableAction.CheckBox) {
      if (selectedRowIds?.includes(rowId)) {
        setSelectedRowIds(selectedRowIds.filter((existingId) => existingId !== rowId))
      } else {
        setSelectedRowIds((prev) => {
          if (prev) return [...prev, rowId]
        })
      }
    }
    if (actionType === ShadowTableAction.Add) {
      setSelectedRowIdToAdd(rowId)
      if (attributesMappingData) {
        setSelectedRow(attributesMappingData.find(row => row.id === selectedRowIdToAdd))
      }
      setQuery('')
      setUserInput('')
      setActionType('ADD')
      setShowPopup(true)
      setUserInput('')
      setQuery('')
    }

  }

  const editTransformerHandler = (id: string) => {
    setTransformerRowIdToEdit(Number(id))
    if (attributesMappingData)
      for (let row of attributesMappingData) {
        const selectedTransformerRowToEdit = row.transformation.find(
          (trans) => trans.transformerID === Number(id),
        )
        if (selectedTransformerRowToEdit) {
          setSelectedRow(row)
          setQuery(selectedTransformerRowToEdit?.transformerQuery ?? '')
          setUserInput(selectedTransformerRowToEdit?.transformerText ?? '')
          break
        }
      }
    setActionType('UPDATE')
    setShowPopup(true)
  }

  return (
    <Grid item container direction={'row'} sx={{ alignItems: 'start' }}>
      <TransformerPopup
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        transformerRowIdToEdit={transformerRowIdToEdit}
        actionType={actionType}
        error={popupError}
        setError={setPopupError}
        updateTransformerData={updateTransformerData}
        query={query}
        setQuery={setQuery}
        userInput={userInput}
        setUserInput={setUserInput}
        attributesMappingData={attributesMappingData}
        setAttributesMappingData={setAttributesMappingData}
        selectedRow={selectedRow}
      />
      <Grid item container xs={11}>
        <ShadowTable
          rows={leftTableData ?? []}
          columns={leftTableColumns}
          tableActionParams={{
            // onButtonClick: showRelevantTransformers,
            // actions: [ShadowTableAction.CheckBox],
            onButtonClick: addNewTransformerHandler,
            actions: [ShadowTableAction.Add],

          }}
        />
      </Grid>
      {/* <Grid item xs={11} /> */}

      {/* <Grid item container xs={5} direction={'column'}>
        <Button
          variant="contained"
          color="primary"
          sx={{ marginBottom: 1, width: '50%', marginTop: 6 }}
          onClick={addNewTransformerHandler}
        >
          Add New Transformer
        </Button>
      </Grid> */}
      <Grid item container xs={11} direction={'column'} marginTop={6}>
        {transformersData?.[0]?.values?.[1] && (
          <ShadowTable
            rows={transformersData ?? []}
            columns={rightTableColumns}
            tableActionParams={{
              onButtonClick: editTransformerHandler,
              actions: [ShadowTableAction.Editable],
            }}
          />
        )}
      </Grid>
    </Grid>
  )
}

type TransformerPopupProps = {
  showPopup: boolean
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>
  transformerRowIdToEdit: number | undefined
  actionType: string
  error: {
    inputError: boolean
    queryError: boolean
  }
  setError: React.Dispatch<
    React.SetStateAction<{
      inputError: boolean
      queryError: boolean
    }>
  >
  updateTransformerData: () => void
  query: string
  setQuery: React.Dispatch<React.SetStateAction<string>>
  userInput: string
  setUserInput: React.Dispatch<React.SetStateAction<string>>
  attributesMappingData: attributeMappingDataBody[] | undefined
  setAttributesMappingData: React.Dispatch<React.SetStateAction<attributeMappingDataBody[] | undefined>>
  selectedRow: attributeMappingDataBody | undefined
}

export const TransformerPopup = ({
  showPopup,
  setShowPopup,
  transformerRowIdToEdit,
  actionType,
  error,
  setError,
  updateTransformerData,
  query,
  setQuery,
  userInput,
  setUserInput,
  attributesMappingData,
  setAttributesMappingData,
  selectedRow
}: TransformerPopupProps) => {
  const [generateQueryButton, setGenerateQueryButton] = useState<'Generate Query' | 'Generating...'>('Generate Query')
  let { addTransformer, transformerAddStatus } = useAddNewDataTransformer()
  let { updateTransformer, transformerUpdateStatus } = useUpdateDataTransformer()
  const { dataPodId, dataAttributeID } = useParams<ViewResultsParams>()
  const [fetchAttributesMappingData, attributesMapping] = useGetDataAttributesMapping()
  const [generateQueryError, setGenerateQueryError] = useState(false)

  // useEffect(() => {
  //   // console.log("I am running inside useEffect")
  //   if (actionType === 'UPDATE' && attributesMappingData) {
  //     for (let row of attributesMappingData) {
  //       const selectedTransformerRowToEdit = row.transformation.find(
  //         (trans) => trans.transformerID === transformerRowIdToEdit,
  //       )
  //       if (selectedTransformerRowToEdit) {
  //         setSelectedRow(row)
  //         setQuery(selectedTransformerRowToEdit?.transformerQuery ?? '')
  //         setUserInput(selectedTransformerRowToEdit?.transformerText ?? '')
  //         break
  //       }
  //     }
  //   }
  //   if (actionType === 'ADD' && attributesMappingData) {
  //     setSelectedRow(attributesMappingData.find(row => row.id === selectedRowIdToAdd))
  //     setQuery('')
  //     setUserInput('')
  //   }
  // }, [transformerRowIdToEdit, selectedRowIdToAdd])

  const outputParser = StructuredOutputParser.fromZodSchema(
    z
      .object({
        fields: z.object({
          query: z.string().describe(`sql query to answer the question asked by the user.`),
        }),
      })
      .describe(
        `An object which contains the sql query to answer the question asked by the user. The sql query should be formed Assuming that the database we are working with is a sqlite3 database, the name of the table which is present in the database is ${selectedRow?.dataTableName ?? 'tableName'
        } and the name of the column present in the table is ${selectedRow?.dataColumnName ?? 'columnName'}`,
      ),
  )

  const chatModel = new ChatOpenAI({
    temperature: 0,
    azureOpenAIApiKey: environment.azureOpenAIApi.key,
    azureOpenAIApiVersion: environment.azureOpenAIApi.version,
    azureOpenAIApiInstanceName: environment.azureOpenAIApi.instanceName,
    azureOpenAIApiDeploymentName: environment.azureOpenAIApi.deploymentName,
  })

  const outputFixingParser = OutputFixingParser.fromLLM(chatModel, outputParser)

  const prompt = new PromptTemplate({
    template: `Answer the user's question as best you can:\n{format_instructions}\n{query}`,
    inputVariables: ['query'],
    partialVariables: {
      format_instructions: outputFixingParser.getFormatInstructions(),
    },
  })

  const answerFormattingChain = new LLMChain({
    llm: chatModel,
    prompt,
    outputKey: 'records', // For readability - otherwise the chain output will default to a property named "text"
    outputParser: outputFixingParser,
  })

  const generateQuery = async (userQuestion: string) => {
    setGenerateQueryError(false)
    if (!userQuestion.trim()) return
    setQuery('')
    try {
      setGenerateQueryButton('Generating...')
      const response = await answerFormattingChain.call({
        query: `${userQuestion}`,
      })
      setQuery(response.records.fields.query)
      setGenerateQueryButton('Generate Query')
    } catch {
      setGenerateQueryButton('Generate Query')
      setGenerateQueryError(true)
    }
  }

  const closePopup = () => {
    setShowPopup(false)
    setUserInput('')
    setQuery('')
    setGenerateQueryButton('Generate Query')
    setGenerateQueryError(false)
  }

  useEffect(() => {
    if (transformerAddStatus && actionType === 'ADD') {
      fetchAttributesMappingData(Number(dataAttributeID))
    } else if (transformerUpdateStatus && actionType === 'UPDATE') {
      fetchAttributesMappingData(Number(dataAttributeID))
    }
  }, [transformerAddStatus, transformerUpdateStatus])

  useEffect(() => {
    if (attributesMapping) {
      setAttributesMappingData(attributesMapping)
    }
  }, [attributesMapping])

  useEffect(() => {
    if (attributesMappingData) {
      updateTransformerData()
    }
  }, [attributesMappingData])

  const inputTextHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(e.target.value)
    if (e.target.value.trim()) {
      setError(prev => { return { ...prev, inputError: false } })
    } else {
      setError(prev => { return { ...prev, inputError: true } })
    }
  }

  const queryTextHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
    if (e.target.value.trim()) {
      setError(prev => { return { ...prev, queryError: false } })
    } else {
      setError(prev => { return { ...prev, queryError: true } })
    }
  }

  const onSubmit = () => {
    // setError({ inputError: false, queryError: false })

    const newDataTransformer = {
      dataAttributeMapId: selectedRow?.id ?? 0,
      transformerText: userInput.trim(),
      transformerQuery: query.trim(),
      dataEntityId: selectedRow?.dataEntityId ?? 0,
      transformerTypeName: 'canonical',
    }
    if (newDataTransformer.dataAttributeMapId === 0) {
      return
    } else if (!userInput && userInput.trim() === '') {
      setError((prev) => {
        return { ...prev, inputError: true }
      })
      return
    } else if (!query && query.trim() === '') {
      setError((prev) => {
        return { ...prev, queryError: true }
      })
      return
    } else if (actionType === 'ADD' && dataPodId) {
      addTransformer(newDataTransformer, dataPodId)
    } else if (actionType === 'UPDATE' && dataPodId && transformerRowIdToEdit) {
      updateTransformer({ ...newDataTransformer, id: transformerRowIdToEdit }, dataPodId)
    }
    setShowPopup(false)
    setGenerateQueryButton('Generate Query')
    setGenerateQueryError(false)
  }

  return (
    <LucidPopUp
      openPopUp={showPopup}
      closePopup={closePopup}
      // onConfirm={onSubmit}
      // confirmText="Save"
      headingText={<Typography variant="h4">{actionType === "ADD" ? 'Add New Transformer' : 'Edit Transformer'}</Typography>}
      showCloseIcon
    >
      <Grid item container justifyContent={'center'} alignItems={'stretch'} spacing={1.5}>
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          {/* {actionType === 'ADD' && (
            <FormControl fullWidth sx={{ marginBottom: 4 }}>
              <InputLabel id="data-system-name-label">Data System Name</InputLabel>
              <Select
                labelId="data-system-name-label"
                label="Data System Name"
                id="demo-simple-select"
                onChange={({ target }) => selectRow(target.value)}
                value={selectedRow?.id.toString() ?? ''}
                fullWidth
              >
                {attributesMappingData?.map((data) => (
                  <MenuItem key={data.id} value={data.id}>
                    {data.dataSystemName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )} */}
          <TextField
            label="Transformation Text"
            variant="outlined"
            multiline
            fullWidth
            onChange={inputTextHandler}
            value={userInput}
            sx={{ marginBottom: 1 }}
            error={error.inputError}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              generateQuery(userInput)
            }}
            disabled={error.inputError}
          >
            {generateQueryButton}
          </Button>

          <Box>
            <TextField
              label="Query"
              variant="outlined"
              multiline
              fullWidth
              onChange={queryTextHandler}
              value={query}
              sx={{ marginBottom: 1, marginTop: 4 }}
              error={error.queryError}
            />
            <Button variant="contained" color="primary" onClick={onSubmit} disabled={error.inputError || error.queryError}>
              Save
            </Button>
          </Box>
        </Grid>
        <ResultStatus
          showStatus={!!generateQueryError}
          alertMessage="Unable to generate query"
          severtiy="error"
          closeStatus={setGenerateQueryError}
        />
      </Grid>
    </LucidPopUp>
  )
}
