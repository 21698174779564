import { AxiosRequestConfig } from 'axios'

export const getAxiosRequestConfig = (token: string): AxiosRequestConfig => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  } as AxiosRequestConfig
}

export const getMeasureAxiosRequestConfig = (token?: string): AxiosRequestConfig => {
  const tempToken = 'Lucid123'
  return {
    headers: {
      // Authorization: `Bearer ${tempToken}`,
      Authorization: `${tempToken}`,
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Origin': '*'
    },
  } as AxiosRequestConfig
}