import { Button, Card, CardActions, CardContent, CardHeader, ClickAwayListener, Grow, IconButton, Paper, Popper, Typography } from "@mui/material"
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined'

import { useEffect, useState } from "react";

import { useGetDatabricksRunStatus } from "../../hooks/databricksHooks";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addActiveRunIds } from "../../features/notification/notificationSlice";
import { RunIdStatus, RunStatusResponse } from "../../businessObjects";


type Props = {
  handleToggle: () => void;
  isOpen: boolean;
  shouldNotify: boolean;
  setShouldNotify: React.Dispatch<React.SetStateAction<boolean>>;
  anchorRef: React.MutableRefObject<null>
}

export const NotificationPopper = ({ handleToggle, isOpen, anchorRef, shouldNotify, setShouldNotify }: Props) => {
  const [runStatus, setRunStatus] = useState<RunStatusResponse[]>([]);

  const { fetchRunStatus } = useGetDatabricksRunStatus();

  const activeRunIds = useAppSelector((state) => state.notification.activeRunIds)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (activeRunIds?.length > 0) {
      // setRunStatus(prevArray => [...activeRunIds, ...prevArray])
      setRunStatus(prevArray => {
        const newSet = new Set([...activeRunIds, ...prevArray]);
        return Array.from(newSet);
      });

      if (!isOpen)
        setShouldNotify(true)

      let interval = setInterval(async () => {
        await getRunStatus();
      }, 10000);

      return () => {
        clearInterval(interval);
      };
    }
    else {
      setShouldNotify(false)
    }
  }, [activeRunIds])

  const getRunStatus = async () => {
    //Filter the run status of failed or success. So, we don't need status of them again.
    const filteredRunStatus = activeRunIds.filter(run => {
      const status = run.state.result_state
      return status !== 'FAILED' && status !== 'SUCCESS';
    })

    const payload: RunIdStatus[] = filteredRunStatus.map(run => {
      return {
        runId: run.run_id,
        status: run.state.life_cycle_state,
        url: run.params.url,
        token: run.params.token
      }
    })

    if (payload.length) {
      const result = await fetchRunStatus(payload);

      if (result) {
        dispatch(addActiveRunIds([...result]))
      }
    }
  }

  return (
    <Popper
      open={isOpen}
      anchorEl={anchorRef.current}
      transition
      disablePortal
      placement="bottom"
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
          <Paper id="menu-list-grow"
            elevation={4}
            style={{
              height: '100vh',
              overflowY: 'auto',
              minWidth: '22vw'
            }}>
            <ClickAwayListener onClickAway={handleToggle}>
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px' }}>
                  <Typography variant="h2" align="left">
                    Notifications
                  </Typography>
                  <IconButton onClick={handleToggle}>
                    <CancelPresentationOutlinedIcon />
                  </IconButton>
                </div>

                <Button onClick={() => setRunStatus([])}>Clear</Button>

                <div style={{ padding: '8px' }}>
                  {runStatus.map((element, index) => (

                    <Card
                      key={index}
                      sx={{
                        marginBottom: 1,
                        boxShadow: 4,
                        borderRadius: 1,
                      }}
                    >
                      <CardHeader
                        title={`JOB ${element.state.result_state == "LOADING" ? element.state.life_cycle_state : element.state.result_state}`}
                      />
                      <CardContent>
                        <Typography variant="h6">
                          {`Job Type: ${element.jobDetails?.triggerType}`}
                        </Typography>
                        <Typography variant="h6">
                          {`Name: ${element.jobDetails?.parameters.name}`}
                        </Typography>
                        <Typography variant="body1">
                          {`Run ID: ${element.run_id}`}
                        </Typography>
                      </CardContent>
                      <CardActions
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}>
                        {element.run_page_url && (
                          <>
                            <Button
                              variant="contained"
                              href={element.jobDetails?.parameters.route ?? '/'}
                              rel="noopener noreferrer"
                              disabled={(element.state.result_state !== "SUCCESS")}
                            >
                              Go To Page
                            </Button>
                            <Button
                              variant="contained"
                              href={element.run_page_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              GO TO JOB
                            </Button>
                          </>
                        )}
                      </CardActions>
                    </Card>
                  ))}
                </div>
              </>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}