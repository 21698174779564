
import { environment } from '../utils/environment'
import {  DataSystemInput } from '../businessObjects'

import { DataSystemUpdateParams } from '../hooks/dataSystemHooks'
import { sendRequest } from '../requestManager/sendRequest'
import axios from 'axios'

const datasystemPath = {
  getOneDataSystem: 'DataSystem/GetOne',
  getDataSystemByName: 'DataSystem/GetByName',
  getAllDataSystems: 'DataSystem/getall/',
  addNewDataSystem: 'DataSystem/addnew',
  deleteDataSystem: 'DataSystem/delete',
  updateDataSystem: 'DataSystem/update',
  injectSparkParams: 'DataSystem/injectSparkParam',
  getDataSystemType: 'DataSystem/GetDataSystemType',
}

const createDataSystem = async (token: string, dataSystemInp: DataSystemInput, dataPodId: string) => {
  const endPoint = datasystemPath.addNewDataSystem + `?dataPodId=${dataPodId}`
  return await sendRequest(endPoint, 'POST', token, dataSystemInp)
}

//send parameters to spark and build rest api call here
const injectDataParamsToSpark = async (dataSystemId: string, dataPodId: string) => {
  const URI = `${environment.lucidSparkService}/${datasystemPath.injectSparkParams}`
  console.log("injectDataParamsToSpark.URI => ", URI)
  const sparkResponse = await axios.post(URI, { dataSystemId, dataPodId, columns_to_ignore: ['batchId', 'byteOffset'] })
  console.log("injectDataParamsToSpark.sparkResponse => ", sparkResponse)
  return sparkResponse
}

const getDataSystemById = async (token: string, dataSystemId: number) => {
  return await sendRequest(datasystemPath.getOneDataSystem + `?dataSystemId=${dataSystemId}`, 'GET', token)
}

const getAllDataSystems = async (
  token: string,
  dataPodId: string,
  subjectAreaID?: number,
  records?: number,
  pageNumber?: number,
  searchText?: string,
) => {
  let endPoint = datasystemPath.getAllDataSystems + dataPodId
  const paginationParams = records && pageNumber ? `?RecordsPerPage=${records}&PageNumber=${pageNumber}` : null
  const subjectAreaParam = subjectAreaID ? `&subjectAreaID=${subjectAreaID}` : null
  const searchTextParams = searchText ? `&searchText=${searchText}` : null
  if (paginationParams) {
    endPoint += paginationParams
  }
  if (subjectAreaParam) {
    endPoint += subjectAreaParam
  }
  if (searchTextParams) {
    endPoint += searchTextParams
  }

  return await  sendRequest(endPoint, 'GET', token)
}
const getDataSystemByName = async (token: string, dataPodId: string, dataSystemName: string) => {
  const endPoint = datasystemPath.getDataSystemByName + `?dataSystemName=${dataSystemName}&dataPodId=${dataPodId}`
  return await  sendRequest(endPoint, 'GET', token)
}

const deleteDataSystem = async (token: string, dataSystemId: number) => {
  const endPoint =  datasystemPath.deleteDataSystem + `?dataSystemId=${dataSystemId}`
  return await sendRequest(endPoint, 'DELETE', token)
}

const updateDataSystem = async (token: string, dataSystem: DataSystemUpdateParams, dataPodId: string) => {
  const endPoint = datasystemPath.updateDataSystem + `?dataPodId=${dataPodId}`
  return await sendRequest(endPoint, 'PUT', token, dataSystem)
}

const getDataSystemType = async (token: string) => {
  const endPoint =  datasystemPath.getDataSystemType
  return sendRequest(endPoint, 'GET', token)
}

export const dataSystemServices = {
  injectDataParamsToSpark,
  getDataSystemById,
  getAllDataSystems,
  getDataSystemByName,
  createDataSystem,
  updateDataSystem,
  deleteDataSystem,
  getDataSystemType
}
