import * as React from 'react'
import Box from '@mui/material/Box'

import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import MuiDrawer from '@mui/material/Drawer'

import { useState } from 'react'
import { getSessionPodId } from '../../utils/tokens'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'

import Logo from '../../ui-icons/LogoBlue.svg'
import LogoSmall from '../Icons/favicon.png'
import { NavigationEntry } from './NavigationDrawerStyles'
import { CSSObject, IconButton, Theme, Tooltip, styled, useTheme } from '@mui/material'
import { ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material'
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined'
import { LucidAppBar } from '../AppBar/AppBar'

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

export const drawerWidth = 240
interface LucidDataDrawerProps {
  entries: NavigationEntry[]
  onClick: (link: string) => void
  activeLink: string
}

const DataPodDependMenuItems = [
  'dataProfiling',
  'dataMapping',
  'dataModeling',
  'dataengineering',
  'dataanalytics',
  'datalakes',
  'overview',
]

export default function LucidDataDrawer({ entries, onClick, activeLink }: LucidDataDrawerProps) {
  const dataPodId = getSessionPodId()
  const [selectedLink, setSelectedLink] = useState<string>('')
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)

  React.useEffect(() => {
    if (activeLink) {
      setSelectedLink(activeLink)
    }
  }, [activeLink])

  const handleListItemClick = (selectedString: string) => {
    setSelectedLink(selectedString)
    onClick(selectedString)
  }
  return (
    <Box sx={{ display: 'flex' }}>
      <LucidAppBar open={openDrawer} />
      <Drawer
        sx={{
          '& .MuiDrawer-paper': {
            bgcolor: 'white',
            borderColor: 'transparent',
            boxShadow: 2,
          },
        }}
        variant="permanent"
        open={openDrawer}
      >
        <DrawerHeader style={{ paddingLeft: '0px' }}>
          <IconButton title="Open" disableRipple={true} onClick={() => setOpenDrawer(!openDrawer)}>
            {openDrawer ? <ChevronRightOutlined fontSize="small" /> : <ChevronLeftOutlined fontSize="small" />}
            <img src={Logo} alt="lucidLogo" height={32} width={124} />
          </IconButton>
        </DrawerHeader>

        <Box sx={{ maxWidth: 260 }}>
          <List>
            {entries.map((entry, index) => {
              const activeSeletion = entry.link === selectedLink
              return (
                <ListItemButton
                  key={index + entry.link}
                  selected={activeSeletion}
                  onClick={() => handleListItemClick(entry.link)}
                  disabled={dataPodId ? false : DataPodDependMenuItems.includes(entry.link)}
                >
                  <ListItemIcon title={entry.text}>
                    <entry.Icon color={activeSeletion ? 'primary' : 'inherit'} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant="body1" color={activeSeletion ? 'primary.main' : 'ButtonText'}>
                        {entry.text}
                      </Typography>
                    }
                  />

                  {activeSeletion ? (
                    <ListItemIcon>
                      <KeyboardArrowRightOutlinedIcon color="primary" />
                    </ListItemIcon>
                  ) : (
                    <></>
                  )}
                </ListItemButton>
              )
            })}
          </List>
        </Box>
      </Drawer>
    </Box>
  )
}
