import React from 'react'
import { Panel, useReactFlow, getRectOfNodes, getTransformForBounds } from 'reactflow'
import { toPng, toSvg } from 'html-to-image'
import DownloadIcon from '@mui/icons-material/Download'
function downloadImage(dataUrl: any) {
  const a = document.createElement('a')
  a.setAttribute('download', 'myFlow.svg')
  a.setAttribute('href', dataUrl)
  a.click()
}

const imageWidth = Number.MAX_VALUE
const imageHeight = Number.MAX_VALUE

function DownloadButton() {
  const { getNodes } = useReactFlow()
  const onClick = () => {
    const viewportElement = document.querySelector('.reactComponentMount')  as HTMLElement

    if (viewportElement) {
      const nodesBounds = getRectOfNodes(getNodes())
      const transform = getTransformForBounds(nodesBounds, imageWidth, imageHeight,0.1,2,0.5)

      toSvg(viewportElement, {
        backgroundColor: 'white',
        style: {
            transform: `translate(${transform[0]}px) scale(200})`,
          },
        quality:100
      }).then(downloadImage)
    } else {
      console.error('Viewport element not found.')
    }
  }

  return <DownloadIcon onClick={onClick}></DownloadIcon>
}

export default DownloadButton
