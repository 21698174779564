import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RunIdStatus, RunStatusResponse } from '../../businessObjects';

interface NotificationState {
    jobIds: string[];
    activeRunIds: RunStatusResponse[];
    jobDetails?:{
        triggerType: "SourceScan" | "TableScan" | "Measures" | "Entity"
        parameters:{
            id:number | string,
            name:string,
            route: string
        }
    }
}
  
const initialState: NotificationState = {
    jobIds: [],
    activeRunIds: [],
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addJobId: (state, action: PayloadAction<string>) => {
        state.jobIds.push(action.payload);
    },
    removeJobId: (state, action: PayloadAction<string>) => {
        state.jobIds = state.jobIds.filter(id => id !== action.payload);
    },
    initiateActiveRunId: (state, action: PayloadAction<RunStatusResponse>) => {
        state.activeRunIds.push({...action.payload});
    },
    addActiveRunIds: (state: NotificationState, action: PayloadAction<RunStatusResponse[]>) => {
        //Removes "INITIATED" state run ids if they get new status.
        //So these won't appear again in the notifications
        const filteredResults = state.activeRunIds.filter(run => {
            const matchingElement = action.payload.find(element =>
                element.run_id === run.run_id 
            );
        
            return !matchingElement;
        });
        const apiRunEle = action.payload.map((element) => {
            const stateRunEle = state.activeRunIds.find(run => run.run_id == element.run_id)
 
            return {...element, jobDetails: stateRunEle?.jobDetails}
        })
 
        state.activeRunIds = [...apiRunEle, ...filteredResults]

        // state.activeRunIds = [...action.payload, ...filteredResults]
    },
    removeActiveRunId: (state, action: PayloadAction<number>) => {
        state.activeRunIds = state.activeRunIds.filter(element => element.run_id !== action.payload);
    },
    filterActiveRunIds: (state: NotificationState) => {
        //Remove "FAILED" OR "SUCCESS" 
        //So these won't appear again in the notifications
        state.activeRunIds = state.activeRunIds.filter(element =>
            !(element.state.result_state === 'FAILED' || element.state.result_state === 'SUCCESS')
        );
    },
  },
})

// Action creators are generated for each case reducer function
export const { addJobId, removeJobId, initiateActiveRunId, addActiveRunIds, filterActiveRunIds, removeActiveRunId } = notificationSlice.actions

export default notificationSlice.reducer