import React, { useCallback, useEffect, useMemo } from 'react'
import { getComparator, stableSort } from './logic'
import { DataTableButton } from './DataTableButton'
import { DataItemAction, DataTableProps, DataTableRow } from './DataTableTypes'
import { DataTableHead } from './DataTableHead'
import { DataTableRowCells } from './DataTableRowCells'
import { DataTableCheckbox } from './DataTableCheckbox'
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  styled,
  tableCellClasses,
  Paper,
  Button,
} from '@mui/material'

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
  },
  // [`&.${tableCellClasses.body}`]: {
  //   fontSize: 14,
  // },
  alignSelf: 'justify',
  paddingLeft: '30px',
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.divider,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))
const filterRows: (
  r: DataTableRow[],
  filterString: string | undefined,
  filterColumn: number | undefined,
) => DataTableRow[] = (r: DataTableRow[], filterString: string | undefined, filterColumn: number | undefined) =>
  !filterString
    ? r
    : r.filter((row) => row.values[filterColumn ?? 0].toLowerCase().includes(filterString.toLowerCase()))

export const DataTable: React.FC<DataTableProps> = React.memo(
  ({
    rows,
    columns,
    filterColumn,
    filterString,
    onButtonClicked,
    allowedActions,
    autoselect,
    onSelectionChanged,
    disableHover,
    onSortingChanged,
    fromPage,
  }: DataTableProps) => {
    const selected = React.useRef<string[]>([])
    const [selectedAll, setSelectedAll] = React.useState<boolean>(false)
    const [sortedRows, setSortedRows] = React.useState<DataTableRow[]>(rows)

    const appliedFilterString = useMemo<string>(() => filterString ?? '', [filterString])

    const nRows = useMemo(() => {
      return rows.length
    }, [rows])

    useEffect(() => {
      const filtered = filterRows(rows, appliedFilterString, filterColumn)
      if (
        !allowedActions?.sortable ||
        filtered
          .map((f) => f.id)
          .sort()
          .join(',') !==
          sortedRows
            .map((s) => s.id)
            .sort()
            .join(',')
      ) {
        setSortedRows(filtered)
        if (onSortingChanged) {
          onSortingChanged(filtered.map((f) => f.id))
        }
      }
    }, [appliedFilterString, sortedRows, rows, filterColumn, onSortingChanged])

    // when data changes, per default, select everything
    useEffect(() => {
      if (autoselect) {
        selected.current = rows.map((r) => r.id)
      }
    }, [rows, autoselect])

    const handleSelectAllClick = useCallback(
      (doSelect: boolean): void => {
        const s = doSelect ? rows.map((r) => r.id) : []
        selected.current = s
        setSelectedAll((prev) => !prev)

        if (onSelectionChanged) {
          onSelectionChanged(s)
        }
      },
      [onSelectionChanged, selected, rows],
    )

    const buttonClicked = useCallback(
      (id: string, action: DataItemAction): void => {
        if (onButtonClicked) {
          onButtonClicked(id, action)
        }
      },
      [onButtonClicked],
    )

    const editClicked = useCallback((id: string) => buttonClicked(id, 'edit'), [buttonClicked])
    const viewClicked = useCallback((id: string) => buttonClicked(id, 'view'), [buttonClicked])
    const deleteClicked = useCallback((id: string) => buttonClicked(id, 'delete'), [buttonClicked])
    const generateClicked = useCallback((id: string) => buttonClicked(id, 'generate'), [buttonClicked])

    const handleSelectClick = useCallback(
      (id: string): void => {
        const selectedIndex = selected.current.indexOf(id)
        let newSelected: string[] = []

        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected.current, id)
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.current.slice(1))
        } else if (selectedIndex === selected.current.length - 1) {
          newSelected = newSelected.concat(selected.current.slice(0, -1))
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.current.slice(0, selectedIndex),
            selected.current.slice(selectedIndex + 1),
          )
        }

        if (onSelectionChanged) {
          onSelectionChanged(newSelected)
        }
        selected.current = newSelected
      },
      [onSelectionChanged, selected],
    )

    const doSort = (col: number, order: string): void => {
      const sRows = filterRows(
        stableSort(rows, getComparator(order === 'asc' ? 'asc' : 'desc', col, columns[col].numeric || false)),
        appliedFilterString,
        filterColumn,
      )
      setSortedRows(sRows)
      if (onSortingChanged) {
        onSortingChanged(sRows.map((f) => f.id))
      }
    }

    return (
      <TableContainer component={Paper} style={{ backgroundColor: 'white', marginTop: '2rem' }}>
        <Table>
          <DataTableHead
            doSort={doSort}
            sortable={allowedActions?.sortable}
            columns={columns}
            onSelectAllClick={handleSelectAllClick}
            checkable={allowedActions?.checkable}
          />
          <TableBody>
            {sortedRows.map((row, i: number) => (
              <StyledTableRow hover={false} key={row.id + i}>
                {allowedActions?.checkable && (
                  <DataTableCheckbox
                    filterString={appliedFilterString}
                    nRows={nRows}
                    selectedAll={selectedAll}
                    checkboxClicked={handleSelectClick}
                    id={row.id}
                  />
                )}
                <DataTableRowCells row={row} columns={columns} />
                <StyledTableCell align="right">
                  <DataTableButton
                    condition={allowedActions?.editable}
                    title={'edit'}
                    action={'edit'}
                    onButtonClicked={editClicked}
                    id={row.id}
                  />

                  <DataTableButton
                    condition={allowedActions?.deletable}
                    title={'delete'}
                    action={'delete'}
                    onButtonClicked={deleteClicked}
                    id={row.id}
                    fromPage={fromPage}
                  />
                  <DataTableButton
                    condition={allowedActions?.viewable}
                    title={'view'}
                    action={'view'}
                    onButtonClicked={viewClicked}
                    id={row.id}
                  />
                  <DataTableButton
                    condition={allowedActions?.selectable}
                    title={'select'}
                    action={'select'}
                    onButtonClicked={viewClicked}
                    id={row.id}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  {allowedActions?.generate && (
                    <Button id={row.id} title={'generate'} variant="contained" onClick={() => generateClicked(row.id)}>
                      Generate
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  },
)
DataTable.displayName = 'DataTable'

export default DataTable
