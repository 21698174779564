import React, { useState, useCallback } from 'react'
import { Node, NodeProps } from 'reactflow'
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import TableChartIcon from '@mui/icons-material/TableChart';
type NodeData = {
  value: number
}
type CustomNode = Node<NodeData>
const handleStyle = {
  left: 10,
  display:"flex",
  flex:"inline"
}

function CustomNodeIcon({ data }: NodeProps) {
  return (
    <>
      <div style={handleStyle}>
        <ArrowDropDownCircleIcon
          id="TableData"
          fontSize='medium'
        />
        <div className="custom-node__header_with_button">{data.label}</div>
        <TableChartIcon
        id="SourceTable"
        fontSize='medium'
        style={{paddingInlineStart:'3px'}}
        />
      </div>
    </>
  )
}
CustomNodeIcon.displayName = 'CustomNodeIcon'
export default CustomNodeIcon
