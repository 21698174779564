import React from 'react'

import { DataItemAction } from './DataTableTypes'
import { Button } from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteForeverIcon from '@mui/icons-material/DeleteForeverOutlined'
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined'
import LucidConfirmDialog from '../LucidConfirmDialog'

export type DataTableButtonProps = {
  condition?: boolean
  title: string
  onButtonClicked?: (id: string) => void
  id: string
  disabledAction?: boolean
  action: DataItemAction
  fromPage?: string
}

export const DataTableButton: React.FC<DataTableButtonProps> = React.memo(
  ({ condition, disabledAction, action, title, id, onButtonClicked, fromPage }: DataTableButtonProps) => {
    const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false)

    return (
      <>
        <LucidConfirmDialog
          isOpen={confirmOpen}
          onClick={onButtonClicked}
          title="Are you sure wanted to delete ?"
          actionId={id}
        />
        {condition && (
          <Button
            disabled={disabledAction}
            id={id}
            title={title}
            color={action === 'delete' ? 'error' : 'inherit'}
            onClick={() =>
              fromPage && action === 'delete' ? setConfirmOpen(true) : onButtonClicked && onButtonClicked(id)
            }
          >
            {action === 'view' && <VisibilityIcon />}
            {action === 'edit' && <EditOutlinedIcon />}
            {action === 'delete' && <DeleteForeverIcon />}
          </Button>
        )}
        {condition && action === 'select' ? (
          <Button
            disabled={disabledAction}
            id={id}
            title={title}
            variant="contained"
            onClick={() => onButtonClicked && onButtonClicked(id)}
          >
            SELECT
          </Button>
        ) : null}
      </>
    )
  },
)

DataTableButton.displayName = 'DataTableButton'

export default DataTableButton
