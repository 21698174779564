import React, { useEffect, useMemo, useState } from 'react'
import {
  DataGrid as MUIGrid,
  GridColDef,
  GridRowsProp,
  DataGridProps,
  GridPaginationModel,
  GridCallbackDetails,
  useGridApiRef,
  GridActionsCellItem,
  GridRowSelectionModel,
  GridFilterModel,
  GridRowId,
} from '@mui/x-data-grid'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowDownward from '@mui/icons-material/ArrowDownward'
import ArrowUpward from '@mui/icons-material/ArrowUpward'
import { PaginationParams } from '../../businessObjects'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { Button, Chip, Grid, MenuItem, Stack, TextField, TextFieldProps, Typography } from '@mui/material'
import { JobStatus } from '../../hooks/constants'
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined'
import EventRepeatIcon from '@mui/icons-material/EventRepeat'

import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import { StyledTableCell } from '../DataTable/DataTable'
import { pink } from '@mui/material/colors'

export type AllowedActions = {
  editable?: boolean
  delible?: boolean
  viewable?: boolean
  cellEditable?: boolean
  checkable?: boolean
  movable?: boolean
}
type Props = {
  rows: GridRowsProp
  columns: GridColDef[]
  selectedRows?: GridRowSelectionModel
  onRowSelectionChanged?: (rowIDs: GridRowSelectionModel) => void
  pagination?: PaginationParams
  gridProps?: DataGridProps
  onPaginationChange?: (param: PaginationParams) => void
  enablePagination?: boolean
  actions?: AllowedActions
  onClickAction?: (actionType: ActionTypes, paramId: string) => void
  searchFilter?: GridFilterModel
}
export type ActionTypes = 'view' | 'edit' | 'delete' | 'save' | 'move'

export const LucidDataGrid = ({
  rows,
  columns,
  pagination,
  gridProps,
  onPaginationChange,
  actions,
  onClickAction,
  onRowSelectionChanged,
  selectedRows,
  searchFilter,
  enablePagination,
}: Props) => {
  const [tablePageParams, setTablePageParams] = useState<GridPaginationModel>()
  const [rowsSelected, setRowsSelected] = useState<GridRowSelectionModel>([])

  useEffect(() => {
    if (selectedRows) {
      setRowsSelected(selectedRows)
    }
  }, [selectedRows])
  // const apiRef = useGridApiRef()
  const gridColumns: GridColDef[] = useMemo(() => {
    if (actions) {
      const baseColActionDef: GridColDef = {
        field: 'actions',
        type: 'actions',
        width: 100,
        getActions: (params) => {
          let actionsArray = []
          const { viewable, editable, delible, cellEditable, movable } = actions
          if (delible) {
            actionsArray.push(
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => onClickAction && onClickAction('delete', params.id as string)}
              />,
            )
          }
          if (viewable) {
            actionsArray.push(
              <GridActionsCellItem
                icon={<VisibilityIcon color="action" />}
                label="view"
                onClick={() => onClickAction && onClickAction('view', params.id as string)}
              />,
            )
          }
          if (editable) {
            actionsArray.push(
              <GridActionsCellItem
                icon={<EditOutlinedIcon color="action" />}
                label="edit"
                onClick={() => onClickAction && onClickAction('edit', params.id as string)}
              />,
            )
          }
          if (cellEditable) {
            actionsArray.push(
              <GridActionsCellItem
                icon={<SaveOutlinedIcon color="action" />}
                label="save"
                title="Update"
                onClick={() => onClickAction && onClickAction('save', params.id as string)}
              />,
            )
          }

          if (movable) {
            // const index = rows.findIndex((t) => t.id === params.id)
            actionsArray.push(
              <>
                <GridActionsCellItem
                  icon={<ArrowDownward />}
                  label="Move"
                  onClick={() => onClickAction && onClickAction('move', params.id+'_Down' as string)}
                />
                <GridActionsCellItem
                  icon={<ArrowUpward />}
                  label="Move"
                  onClick={() => onClickAction && onClickAction('move', params.id+'_Up' as string)}
                />
              </>,
            )
          }

          return actionsArray
        },
      }
      columns.push(baseColActionDef)
    }
    return columns
  }, [actions])
  useEffect(() => {
    if (pagination) {
      setTablePageParams({
        pageSize: pagination.recordsPerPage ?? 100,
        page: pagination.pageNumber ?? 0,
      })
    }
  }, [pagination])

  const onPageChange = (model: GridPaginationModel, details: GridCallbackDetails<any>) => {
    setTablePageParams((prv) => ({
      ...prv,
      ...model,
    }))
    onPaginationChange && onPaginationChange({ recordsPerPage: model.pageSize, pageNumber: model.page })
  }

  const handleRowsSelected = (rows: GridRowSelectionModel) => {
    setRowsSelected(rows)
    onRowSelectionChanged && onRowSelectionChanged(rows)
  }

  return (
    <div style={{ height: '80vh', width: '100%' }}>
      <MUIGrid
        sx={{ ml: 10, mr: 2 }}
        rows={rows}
        columns={!actions ? columns : gridColumns}
        initialState={{
          pagination: {
            paginationModel: { page: pagination?.pageNumber ?? 1, pageSize: pagination?.recordsPerPage ?? 100 },
          },
        }}
        {...gridProps}
        onPaginationModelChange={onPageChange}
        pagination={true}
        //paginationModel={tablePageParams}
        checkboxSelection={actions?.checkable}
        onRowSelectionModelChange={handleRowsSelected}
        rowSelectionModel={rowsSelected}
        isRowSelectable={() => true}
        filterModel={searchFilter}
        hideFooterPagination={enablePagination}
        //editMode="cell"
        disableColumnMenu={true}
      />
    </div>
  )
}

type GridColStatusProps = {
  valueOptions: string[]
  headerLabel: string
  fieldStatus: string
  onClickScheduler?: () => void
}
export const GridColStatus = ({
  valueOptions,
  fieldStatus,
  headerLabel,
  onClickScheduler,
}: GridColStatusProps): GridColDef => {
  return {
    field: fieldStatus,
    headerName: headerLabel,
    width: 150,
    editable: false,
    type: 'singleSelect',
    valueOptions: valueOptions,
    renderCell: (p) => {
      // if (p.value === 'BUTTON')
      //   return (
      //     <Button
      //       endIcon={
      //         <EventRepeatIcon
      //           fontSize="small"
      //           onClick={() => {
      //             onClickScheduler && onClickScheduler()
      //           }}
      //         />
      //       }
      //     />
      //   )
      if (p.value == 'ReDeploy')
        return (
          <Chip
            color="warning"
            icon={<PendingActionsOutlinedIcon fontSize="small" />}
            label={<Typography variant="caption">{p.value}</Typography>}
            variant="outlined"
          />
        )
      if (p.value == 'Deployed')
        return (
          <Chip
            color="success"
            icon={<CheckCircleOutlineOutlinedIcon fontSize="small" />}
            label={<Typography variant="caption">{p.value}</Typography>}
            variant="outlined"
          />
        )
      if (p.value == 'Not Deployed' || p.value == 'Not deployed')
        return (
          <Chip
            color="error"
            icon={<WarningOutlinedIcon fontSize="small" />}
            label={<Typography variant="caption">{p.value}</Typography>}
            variant="outlined"
          />
        )
      return (
        <Chip
          color="info"
          icon={<InfoOutlinedIcon fontSize="small" />}
          label={<Typography variant="caption">{p.value}</Typography>}
          variant="outlined"
        />
      )
    },
  }
}
type ActionProps = {
  headerLabel: string
  field: string
  onClickScheduler: <T, V>(id: T, cellValue: V) => void
  onChange?: <T, V>(id: T, cellValue: V) => void
}
export const GridColActions = (props: ActionProps): GridColDef => {
  return {
    field: props.field,
    headerName: props.headerLabel,
    width: 250,
    flex: 10,
    editable: true,
    type: 'actions',
    renderCell: (p) => {
      return (
        // <Stack direction="row" spacing={1}>
        //   <Button
        //     title="schedulule Pipeline"
        //     startIcon={
        //       <EventRepeatIcon
        //         onClick={() => {
        //           props.onClickScheduler(p.id, p.value)
        //         }}
        //       />
        //     }
        //   />
        //   <Typography>{p.value}</Typography>
        // </Stack>
        <Stack direction="row" spacing={1} alignItems="flex-end">
          <EventRepeatIcon
            fontSize="small"
            sx={{ color: '#2E92DA' }}
            onClick={() => {
              props.onClickScheduler(p.id, p.value)
            }}
          />
          <Typography>{p.value}</Typography>
        </Stack>
      )
    },
  }
}

type NumberCellActionProps = {
  headerLabel: string
  field: string
  onChange: <T, V>(id: T, cellValue: V) => void
}

export const GridColNumberAction = (props: NumberCellActionProps): GridColDef => {
  return {
    field: props.field,
    headerName: props.headerLabel,
    width: 180,
    flex: 5,
    editable: true,
    type: 'actions',
    renderCell: (p) => {
      return (
        <Stack direction="row" spacing={0.5} alignContent="center">
          <TextField
            id={`schedule-order-${p.id}`}
            type="number"
            value={p.value}
            size="small"
            variant="standard"
            onChange={({ target }) => props.onChange(p.id, target.value)}
          />
        </Stack>
      )
    },
  }
}

type SelectCellActionProps = {
  headerLabel: string
  field: string
  onChange: <T, V>(id: T, cellValue: V) => void
  options: any[]
}

export const GridColSelectAction = (props: SelectCellActionProps): GridColDef => {
  return {
    field: props.field,
    headerName: props.headerLabel,
    width: 180,
    flex: 5,
    editable: true,
    type: 'actions',
    renderCell: (p) => {
      return (
        <TextField
          id={`schedule-${p.id}`}
          value={p.value}
          variant="standard"
          size="small"
          select
          fullWidth
          onChange={({ target }) => props.onChange(p.id, target.value)}
        >
          {props.options.map((opt) => (
            <MenuItem key={opt.id} value={opt.id}>
              {opt.value}
            </MenuItem>
          ))}
        </TextField>
      )
    },
  }
}
