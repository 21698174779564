
import { entityServices } from "../apiServices/generateSampleData"
import {  GetSubjectAreaTableDataBody, GetSubjectAreaTablesBody, GetSubjectAreaTablesBodyResponse } from "../businessObjects"

export const useGetSubjectAreaTables = (): { fetchTables: (dataPodId: string, subjectAreaId: number) => Promise<GetSubjectAreaTablesBodyResponse | undefined> } => {
  
    const fetchTables = async (dataPodId: string, subjectAreaId: number) => {   
        try {
            const bearerToken = 'Lucid123'

            if (bearerToken && dataPodId && subjectAreaId) {

                const body: GetSubjectAreaTablesBody = { dataPodId, subjectAreaId }
                const { data, status } = await entityServices.getSubjectAreaTables(bearerToken, body)

                if (status == 200) {
                    return data as GetSubjectAreaTablesBodyResponse
                }
            }
        } catch (err) {
            console.log(err)
        }
  }

  return { fetchTables }
}


export const useGetSubjectAreaTableData = (): { fetchTableData: (payload: GetSubjectAreaTableDataBody) => Promise<any | undefined> } => {
  
    const fetchTableData = async (payload: GetSubjectAreaTableDataBody) => {   
        try {
            const bearerToken = 'Lucid123'

            if (bearerToken && payload) {

                const { data, status } = await entityServices.getSubjectAreaTableData(bearerToken, payload)

                if (status == 200 && data.result == "Success" && data.data.length) {
                    return data 
                }
                else {
                    return undefined
                }
            }
        } catch (err) {
            console.log(err)
        }
  }

  return { fetchTableData }
}