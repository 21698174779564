import { Grid, Card, CardContent, Typography, CardHeader, Button, Box } from '@mui/material'

import { generatePath, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../Routes/constants'

import { green } from '@mui/material/colors'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import { useState, useMemo } from 'react'
import { PaginationParams } from '../../../businessObjects'
import { useGetProfileInputs } from '../../../hooks/dataProfilingHooks'
import { useGetSessionPodInfo } from '../../../hooks/getSessionPodInfo'
import DataProcessCard from '../DataProcessCard'
import { cardContainer } from '../../../styles/globalStyles'
import ActionPage from '../../../ui-components/ActionPage'

export const DataMapping = () => {
  const [pages, setPages] = useState<number>(0)
  const [pagination, setPagination] = useState<PaginationParams>({ pageNumber: 1, recordsPerPage: 5 })
  const dataPod = useGetSessionPodInfo()
  const { profileInputs } = useGetProfileInputs(dataPod?.id ?? undefined, undefined, undefined, pagination, setPages)

  const dataSystemList = useMemo(() => {
    if (!profileInputs) return []
    return profileInputs
  }, [profileInputs, pagination])

  return (
    <ActionPage>
      <Box style={cardContainer}>
        <Grid container spacing={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} />
            <Grid item xs={15} container>
              <Grid item xs={2}>
                <Typography variant="h4" gutterBottom color="primary">
                  Data Mapping
                </Typography>
              </Grid>

              <Grid item sx={{ paddingLeft: 2 }} xs={10} alignItems={'flex-start'} container spacing={1}>
                {dataSystemList.map((d, ix) => (
                  <Grid item xs={3} md={5.7} key={d.dataSystemID}>
                    <DataProcessCard
                      headingText="Generate Data Mapping"
                      type="Data Mapping"
                      key={d.dataSystemID}
                      dataPodId={dataPod?.id}
                      dataSystemID={d.dataSystemID}
                      subLabel={d.dataSystemName}
                      labelType="Data System"
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ActionPage>
  )
}
