import { Box, Stepper, Step, StepLabel, Grid, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ActionPage from '../../ui-components/ActionPage'
import { useLucidAuthContext } from '../../LucidAuthContext/LucidAuthContext'
import { AddEmailAddress } from './AddEmailAddress'
import { AddPersonalDetails } from './AddPersonalDetails'
import { AddAdditionalDetails } from './AddAdditionalDetails'
import { Confirmation } from './Confirmation'
import { UserAccount } from '../../businessObjects'
import { useCreateLucidAccount } from '../../hooks/lucidAccountHooks'
import { LucidPopUp } from '../../ui-components/LucidPopUp/LucidPopUp'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../Routes/constants'

type Props = {}
const steps = ['Email Address', 'Additional Details', 'Personal Details', 'Confirmation']

export const AccountLayout = (props: Props) => {
  const [activeStep, setActiveStep] = useState(0)
  const [skipped, setSkipped] = useState(new Set<number>())
  const [lucidUserAccount, setLucidUserAccount] = useState<UserAccount>()
  const { updatePreferredAccountId, tenantId } = useLucidAuthContext()
  const [saveNewUser, lucidAccountInfo] = useCreateLucidAccount()
  const [showPopUp, setShowPopUp] = useState<boolean>(false)
  const [accountSuccess, setAccountSuccess] = useState(false)

  const navigate = useNavigate()

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }
  const isStepOptional = (step: number) => {
    return step === 1
  }
  const saveAccount = async (): Promise<void> => {
    if (lucidUserAccount) {
      await saveNewUser(lucidUserAccount)
    }
  }

  useEffect(() => {
    if (lucidAccountInfo?.accountId) {
      updatePreferredAccountId(lucidAccountInfo.accountId)
    }
  }, [lucidAccountInfo])

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }
    activeStep === 0 && setShowPopUp(false)

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
    if (activeStep === steps.length - 1) {
      saveAccount()
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  const buttonDisabled = React.useMemo(() => {
    switch (activeStep) {
      case 0:
        return !lucidUserAccount?.primaryContactEmail
      case 1:
        return (
          !lucidUserAccount?.name ||
          !lucidUserAccount.accountTenantId ||
          !lucidUserAccount.agreementStatus ||
          !lucidUserAccount.accountDescription
        )
      case 2:
        return (
          !lucidUserAccount?.primaryContactFirstName ||
          !lucidUserAccount.primaryContactLastName ||
          !lucidUserAccount.primaryContactPhone
        )
      case 3:
        return !!tenantId
      default:
        false
    }
    return false
  }, [activeStep, lucidUserAccount])

  const handleReset = () => {
    setActiveStep(0)
    setLucidUserAccount(undefined)
  }

  const handleFirstStep = () => {
    setShowPopUp(true)
  }
  return (
    <ActionPage>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {}
            const labelProps: {
              optional?: React.ReactNode
            } = {}
            if (isStepOptional(index)) {
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        <LucidPopUp
          showCloseIcon
          closePopup={setShowPopUp}
          openPopUp={showPopUp}
          onConfirm={handleNext}
          headingText=""
          confirmText="Continue"
        >
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} />
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Typography align="center">
                By proceeding you acknowledge that if you use your organization's email, your organization may have
                rights to access and manage your data and account.
              </Typography>
            </Grid>
          </Grid>
        </LucidPopUp>
        <Grid item alignContent="center" container xs={6} md={8} lg={12}>
          {activeStep == 0 ? (
            <AddEmailAddress userAccount={lucidUserAccount} updateUserParams={setLucidUserAccount} />
          ) : (
            <></>
          )}
          {activeStep == 1 ? (
            <AddAdditionalDetails userAccount={lucidUserAccount} updateUserParams={setLucidUserAccount} />
          ) : (
            <></>
          )}
          {activeStep == 2 ? (
            <AddPersonalDetails userAccount={lucidUserAccount} updateUserParams={setLucidUserAccount} />
          ) : (
            <></>
          )}
          {activeStep == 3 ? <Confirmation userAccount={lucidUserAccount} goToStep={setActiveStep} /> : <></>}
        </Grid>

        <LucidPopUp
          headingText={`Hello ${lucidAccountInfo?.name}, Your Account is successfully created`}
          showCloseIcon
          openPopUp={accountSuccess}
          confirmText="OK"
          closePopup={setAccountSuccess}
          onConfirm={() => setAccountSuccess(false)}
        >
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs={4}>
              <Typography color="primary" variant="h3">
                Your Account Details:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography color="primary" variant="h3">
                AccountId:
              </Typography>
            </Grid>
            <Grid item xs={0} />
            <Grid item xs={8}>
              <Typography color="text.secondary">{lucidAccountInfo?.accountId}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography color="text.secondary">TenantId:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography color="text.secondary">{lucidAccountInfo?.accountTenantId}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography color="text.secondary">Email:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography color="text.secondary">{lucidAccountInfo?.primaryContactEmail}</Typography>
            </Grid>
          </Grid>
        </LucidPopUp>

        {activeStep === steps.length ? (
          <React.Fragment>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button disabled={!lucidAccountInfo?.accountId} onClick={handleReset}>
                Reset
              </Button>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button disabled={!!lucidAccountInfo?.accountId} onClick={() => navigate(ROUTES.Account)}>
                Finish
              </Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button
                color="secondary"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
                variant="contained"
                size="small"
              >
                Back
              </Button>
              <Button
                color="secondary"
                onClick={activeStep === 0 ? handleFirstStep : handleNext}
                disabled={buttonDisabled}
                variant="contained"
                size="small"
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Save and continue'}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </ActionPage>
  )
}
