import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NodeProps } from 'reactflow';
import { Autocomplete, TextField } from '@mui/material';
import { useGetEntity } from '../hooks/entityHooks';
import { Entity } from '../businessObjects';
import { makeStyles } from '@mui/styles';
import { useAddEntityRecon } from '../hooks/applicationHooks';
import { useGetDataReconcilationByEntityId } from '../hooks/dataAttrbutesHooks';

type ViewResultsParams = {
    dataPodId: string;
    dataSystemID: string;
};

interface ExcludeInfo {
    id: number;
    dataPodId: string;
    dataCoreEntityId: number;
    dataSystemEntityId: number;
    parent: string;
    child: string;
}

interface SelectionNodeProps extends NodeProps {
    data: {
        exclude: ExcludeInfo[];
        parentEntity:string;
        parentId:number;
    };
}
export type AddEntityRecon = {
    dataCoreEntityId: number
    dataSystemEntityId: number
    profileName: string
  }

function SelectionNode({ data }: SelectionNodeProps) {
    const { dataPodId } = useParams<ViewResultsParams>();
    const [entities] = useGetEntity({}, dataPodId);
    const { addEntityRecon } = useAddEntityRecon(dataPodId)
    const { reconData, refetchAttributes } = useGetDataReconcilationByEntityId(dataPodId)
    const [selectedOption, setSelectedOption] = useState<Entity | undefined>(undefined);

    const filteredEntities = useMemo(() => {
        if (!data || !entities) return entities;
        const excludeSystemEntityIds = new Set(data.exclude.map(item => item.dataSystemEntityId));
        return entities.filter(entity => !excludeSystemEntityIds.has(entity.id));
    }, [entities, data]);

    const handleSelect = async (event: React.ChangeEvent<{}>, selectedOption: Entity | null) => {
        if (selectedOption) {
            setSelectedOption(selectedOption)
            const requestBody:AddEntityRecon = {
                dataCoreEntityId: data.parentId,
                dataSystemEntityId: selectedOption.id,
                profileName: 'clientApp'
            };
            const newRecon = await addEntityRecon(requestBody)
            if(newRecon){
                // console.log(selectedOption)
                setSelectedOption(undefined)
            }
        }
    };

    return (
        <center>
            <Autocomplete
                disableClearable
                disablePortal
                id="combo-box-demo"
                size="small"
                options={filteredEntities}
                getOptionLabel={(option) => option.dataEntityName || ''}
                sx={{ width: '100%' }}
                renderInput={(params) => <TextField {...params} label="Child Entities" />}
                ListboxProps={{
                    style: { maxHeight: '100%', overflow: 'auto' },
                }}
                value={selectedOption}
                onChange={(event, selectedOption) => {handleSelect(event, selectedOption);}}
            />
        </center>
    );
}

SelectionNode.displayName = 'SelectionNode';
export default SelectionNode;
