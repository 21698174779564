import React, { useState, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  Chip,
  Typography,
  IconButton,
  Autocomplete,
  TextField,
} from '@mui/material'
import ArrowDownward from '@mui/icons-material/ArrowDownward'
import ArrowUpward from '@mui/icons-material/ArrowUpward'
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined'

interface TableProps {
  rows: any
  setDeploypipelines: (data: any) => void
}

const headers = [
  'Entity ID',
  'Entity Name',
  'Data Lake Zone',
  'Pipeline Name',
  'Schedule Order',
  'Dependency On',
  'Deployment Status',
  'Action',
]

function DataPipelineTable({ rows, setDeploypipelines }: TableProps) {
  const [tableData, setTableData] = useState<any>(rows)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  useEffect(() => {
    setTableData(
      rows?.sort((p1: { scheduleOrder: number }, p2: { scheduleOrder: number }) =>
        p1.scheduleOrder > p2.scheduleOrder ? 1 : -1,
      ),
    )
  }, [rows])

  useEffect(() => {
    setDeploypipelines((prev: any) => ({
      ...prev,
      entitiesWithOrders: tableData.map((p: any, ix: number) => ({
        scheduleOrder: ix,
        entityID: p.entityid,
        dependsOn: p.dependsOn || [],
      })),
    }))
  }, [tableData])

  // Handle page change event
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  // Handle rows per page change event
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0) // Reset page to 1 when rows per page changes
  }

  const moveRow = (rowEntityId: number, direction: 'up' | 'down') => {
    const rowIndex = tableData.findIndex((t: { entityid: number }) => t.entityid === rowEntityId)
    const newData = [...tableData]
    const [rowToMove] = newData.splice(rowIndex, 1)

    const newIndex = direction === 'up' ? Math.max(rowIndex - 1, 0) : rowIndex + 1
    newData.splice(newIndex, 0, rowToMove)

    // return newData
    setTableData(newData)
  }

  const handleChange = (rowEntityId: number, values: any) => {
    if (values && values.length && tableData?.length) {
      const rowIndex = tableData.findIndex((t: { entityid: number }) => t.entityid === rowEntityId)
      tableData[rowIndex].dependsOn = values?.map((v: any) => v.entityid)
      setDeploypipelines((prev: any) => ({
        ...prev,
        entitiesWithOrders: tableData.map((p: any, ix: number) => ({
          scheduleOrder: ix,
          entityID: p.entityid,
          dependsOn: p.dependsOn || [],
        })),
      }))
      setTableData(tableData)
    }
  }

  const renderDeploymentStatus = (value: any) => {
    if (value == 'ReDeploy')
      return (
        <Chip
          color="warning"
          icon={<PendingActionsOutlinedIcon fontSize="small" />}
          label={<Typography variant="caption">{value}</Typography>}
          variant="outlined"
        />
      )
    if (value == 'Deployed')
      return (
        <Chip
          color="success"
          icon={<CheckCircleOutlineOutlinedIcon fontSize="small" />}
          label={<Typography variant="caption">{value}</Typography>}
          variant="outlined"
        />
      )
    if (value == 'Not Deployed' || value == 'Not deployed')
      return (
        <Chip
          color="error"
          icon={<WarningOutlinedIcon fontSize="small" />}
          label={<Typography variant="caption">{value}</Typography>}
          variant="outlined"
        />
      )
    return (
      <Chip
        color="info"
        icon={<InfoOutlinedIcon fontSize="small" />}
        label={<Typography variant="caption">{value}</Typography>}
        variant="outlined"
      />
    )
  }

  const tablePageData = tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  return (
    <Grid item xs={12}>
      <TableContainer sx={{ maxHeight: 440, mt: 2 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headers?.map((header: string) => (
                <TableCell>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tablePageData.map((row: any, index: number) => (
              <TableRow key={row.entityid}>
                <TableCell>{row.entityid}</TableCell>
                <TableCell>{row.dataEntityName}</TableCell>
                <TableCell>{row.dataLakeZone === 1 ? 'Canonical' : 'Enriched'}</TableCell>
                <TableCell>{row.pipelinename}</TableCell>
                <TableCell>{row.scheduleOrder}</TableCell>
                <TableCell>
                  <Autocomplete
                    multiple
                    id={`dependency_entity_${index}`}
                    options={tablePageData}
                    getOptionLabel={(option: any) => option.dataEntityName}
                    onChange={(event, values) => handleChange(row.entityid, values)}
                    renderInput={(params) => <TextField {...params} variant="standard" placeholder="Choose Entity's" />}
                  />
                </TableCell>
                <TableCell>{renderDeploymentStatus(row.pipelineStatus)}</TableCell>
                <TableCell sx={{ display: 'flex' }}>
                  <>
                    <IconButton sx={{ mr: 2 }}>
                      <ArrowDownward onClick={() => moveRow(row.entityid, 'down')} />
                    </IconButton>
                    <IconButton>
                      <ArrowUpward onClick={() => moveRow(row.entityid, 'up')} />
                    </IconButton>
                  </>
                </TableCell>
              </TableRow>
            ))}
            {!tablePageData.length && (
              <TableRow style={{ height: 200 }}>
                <TableCell colSpan={7} align="center">
                  No data found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Grid>
  )
}

export default DataPipelineTable
