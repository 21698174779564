import secureLocalStorage from 'react-secure-storage'

export const getAccountToken = () => {
  const accountId = secureLocalStorage.getItem('_aId')
  return !accountId ? null : (accountId as string)
}

export const setAccountToken = (id: string) => {
  secureLocalStorage.setItem('_aId', id)
}

export const setSessionPodId = (id: string) => {
  secureLocalStorage.setItem('_pId', id)
}

export const getSessionPodId = () => {
  const sessionPodId = secureLocalStorage.getItem('_pId')
  return !sessionPodId ? undefined : (sessionPodId as string)
}

export const clearTokens = () => {
  secureLocalStorage.clear()
  localStorage.clear()
}
