import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Button, Card, CardContent, Grid, Link, ListItemButton, ListItemText, Paper, TextField, TextareaAutosize, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useGetDataScienceModels, useMeasureNotebook, useUpdateMeasureNB } from '../../hooks/analyticsMeasureHooks'
import { ProgressBar } from '../../ui-components/ProgressBar'
import ResultStatus from '../../ui-components/ResultStatus'
import { LucidJupyter } from '../../ui-components/JupyterComponents/LucidJupyter'
import { Insights, AddOutlined, Preview } from '@mui/icons-material'
import AnimatedLoader from '../../ui-components/AnimatedLoader'
import { LucidPopUp } from '../../ui-components/LucidPopUp/LucidPopUp'
import { usePostCreateDatabricksJob } from '../../hooks/databricksHooks'
import { CreateJobParams, CreateJobResponse, JobClusterConfig, GenerateKPI, CustomDictionary, RunStatusResponse, TechniqueDetails } from '../../businessObjects'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import { filterActiveRunIds, initiateActiveRunId } from '../../features/notification/notificationSlice'
import { useGetServiceConnection } from '../../hooks/dataEngineeringHooks'
import constants from '../constants'

type SingleObject = {
  key: string,
  value: string
}

type ViewResultsParams = {
  dataPodId: string
}

type Props = {
  measureId: number | undefined
  measureDescription?: string
  measureName?: string
  editMeasure?: () => void
  callSyncDimensions?: () => void
  generateKPI?: () => void
  generateKPILoading?: boolean
  generateKPIResponse?: GenerateKPI
  isEditOrAddMode?: boolean
}

const GenerateMeasureNotebook = ({
  measureId,
  measureDescription, 
  measureName,
  editMeasure,
  callSyncDimensions,
  generateKPI,
  generateKPILoading,
  generateKPIResponse,
  isEditOrAddMode,
}: Props) => {
  const { dataPodId } = useParams<ViewResultsParams>()
  const {
    getMeasureNotebook,
    // generateKPI,
    addMeasureNotebookLoading,
    measureNotebookResponse,
    addMeasureNotebook,
    noteBookError,
  } = useMeasureNotebook(dataPodId)
  const {
    updateMeasureNotebook,
    updateMeasureNotebookResponse,
    updateMeasureNotebookLoading,
    measureNotebookError,
  } = useUpdateMeasureNB(dataPodId)



  const { getConnections } = useGetServiceConnection()

  const { postCreateDatabricksJob } = usePostCreateDatabricksJob()
  const activeRunIds = useAppSelector((state: RootState) => state.notification.activeRunIds)
  const dispatch = useAppDispatch()

  const [processingReq, setProcessingReq] = useState<boolean>(false)
  const [notebookString, setNotebookString] = useState<string>(constants.defaultNotebookString)
  const [commitMessage, setCommitMessage] = useState<string>('initial commit')

  const [dataScienceModels, setDataScienceModels] = useState<TechniqueDetails[]>()
  const [selectedDataScModel, setSelectedDataScModel] = useState<TechniqueDetails>()


  const [addedSuccess, setAddedSuccess] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [errorState, setErrorState] = useState<boolean>(false)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [showPopUp, setShowPopUp] = useState<boolean>(false)
  const [generateKpiClcicked, setGenerateKpiClicked] = useState<boolean>(false)
  const [jobClusterParams, setJobClusterParams] = useState<JobClusterConfig | null>(null)

  useEffect(() => {
    const fetchJobClusterParams = async () => {
      if (dataPodId) {
        const serviceConnection = await getConnections(dataPodId, undefined, 'Spark')

        if (serviceConnection && serviceConnection.length > 0) {
          const jsonTemplate = serviceConnection[0].serviceConnectionJsonTemplate
          const parsedJson = JSON.parse(jsonTemplate)
          const params: JobClusterConfig = {
            url: parsedJson.workspace_Url,
            token: parsedJson.PAT_Token,
            clusterKey: parsedJson.cluster_Id,
            sparkVersion: parsedJson.sparkVersion,
            nodeTypeId: parsedJson.nodeTypeId,
          }
          setJobClusterParams(params)
        }
      }
    }

    fetchJobClusterParams()
  }, [])

  useEffect(() => {
    if (noteBookError) {
      setErrorMessage('No saved notebook found.')
      setProcessingReq(false)
      setErrorState(true)
      setNotebookString(constants.defaultNotebookString)
    }
  }, [noteBookError])

  useEffect(() => {
    if (measureNotebookResponse) {
      setProcessingReq(false)
      setAddedSuccess(true)

      if (measureNotebookResponse?.notebook) {
        // console.log("Inside the measure notebook response if")
        setNotebookString(measureNotebookResponse?.notebook)
      }
    }
    // The when the get NB response is updated it makes it empty , which is not expected
    // return () => {
    //   setNotebookString('')
    // }
  }, [measureNotebookResponse])

  useEffect(() => {
    if (measureId) {
      //calling measure notebook id
      getMeasureNotebook(measureId)
      setDataScienceModels(undefined)
      setSelectedDataScModel(undefined)
    }
  }, [measureId])

  useEffect(() => {
    if (generateKPIResponse && generateKPIResponse.notebook) {
      setNotebookString(generateKPIResponse.notebook)
    }
  }, [generateKPIResponse])

  const addMeasureNotebookString = () => {
    if (measureId && dataPodId && notebookString && commitMessage) {
      setProcessingReq(true)
      addMeasureNotebook({
        datapodID: dataPodId,
        measureId,
        notebookToRun: notebookString,
        commitMessage,
      })
      setGenerateKpiClicked(false)
    }
  }

  const updateMeasureNotebookString = () => {
    if (measureId && dataPodId && notebookString && commitMessage) {
      setProcessingReq(true)
      updateMeasureNotebook({
        datapodID: dataPodId,
        measureId,
        notebookToRun: notebookString,
        commitMessage,
      })
    }
  }

  const handleNotebookString = () => {
    if (measureNotebookResponse && !generateKpiClcicked) {
      // console.log("there already exists a notebook")
      setShowPopUp(true)
    } else if (!measureNotebookResponse && !notebookString?.includes(constants.defaultNotebookString)) {
      addMeasureNotebookString()
    }

    callSyncDimensions && callSyncDimensions()
  }

  const handleNext = async () => {
    if (measureId && notebookString && commitMessage) {
      updateMeasureNotebookString()
      setShowPopUp(false)
      setProcessingReq(false)
    }
  }

  const generateGroupMeasure = () => {
    if (isEditOrAddMode && generateKPI) {
      generateKPI()
    }
  }

  const executeNotebook = async () => {
    if (dataPodId && measureId && jobClusterParams) {
      const params: CreateJobParams = {
        dataPodId,
        measureID: measureId,
        jobClusterConfig: jobClusterParams,
      }
      const result = await postCreateDatabricksJob(params)

      if (result) {
        updateRunIdState(result)
      } else {
        setErrorMessage('Failed to create Databricks Job')
        setErrorState(true)
      }
    }
  }

  const updateRunIdState = (result: CreateJobResponse) => {
    const activeRunId = activeRunIds.find((element) => element.run_id === result.runId)

    if (!activeRunId) {
      const newRunId: RunStatusResponse = {
        run_id: result.runId,
        state: {
          life_cycle_state: 'INITIATED',
          result_state: 'LOADING',
        },
        params: {
          run_id: result.runId,
          url: result.url,
          token: result.token,
        },
      }

      dispatch(filterActiveRunIds())
      dispatch(initiateActiveRunId(newRunId))
    }
  }

  const onTextChange = (name: string, value: string) => {
    setCommitMessage(value)
  }

  const closeErrorMessage = () => {
    setErrorState(false)
    setErrorMessage(undefined)
  }

  const clickDataMode = (model: TechniqueDetails) => {
    if(!dataScienceModels)
      return;

    setSelectedDataScModel(model)
  }

  return (
    <>
      {/* <ProgressBar loading={addMeasureNotebookLoading || processingReq} /> */}
      <AnimatedLoader height="50%" width="40%" loading={addMeasureNotebookLoading || processingReq} />
      {errorMessage && (
        <ResultStatus
          showStatus={errorState}
          alertMessage={errorMessage}
          severtiy="error"
          closeStatus={() => closeErrorMessage()}
        />
      )}
      <ResultStatus
        severtiy="success"
        showStatus={addedSuccess}
        closeStatus={() => setAddedSuccess(false)}
        alertMessage={`SUCCESS: ${measureNotebookResponse?.notebook ? 'Notebook generated !' : 'Notebook saved !'}`}
      />
      <Card sx={{ p: 2, mb: 2 }}>
        <Grid item xs={12} justifyContent={'end'} display={'flex'} flexDirection={'row'} sx={{ mt: 2 }}>
          {(isEditOrAddMode) && (
            <>
              <LoadingButton
                loading={addMeasureNotebookLoading || generateKPILoading}
                variant="outlined"
                color="primary"
                startIcon={<Insights />}
                onClick={generateGroupMeasure}
                disabled={!measureId}
                sx={{ mr: 2 }}
              >
                Generate code
              </LoadingButton>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNotebookString}
                startIcon={<AddOutlined />}
                disabled={notebookString?.includes(constants.defaultNotebookString) && !measureId}
              >
                Save Notebook
              </Button>
            </>
          )}
          {!isEditOrAddMode && 
            <Button
            variant="contained"
            color="primary"
            onClick={editMeasure}
            >
              {notebookString?.includes(constants.defaultNotebookString) ? 'Generate code' : 'Review and Generate code'}
            </Button>
          }
        </Grid>
        
        <Grid container>
          <Grid item xs={12}>
            <LucidJupyter
              executeNotebook={executeNotebook}
              noteBookString={notebookString}
              headingText={constants.codeHeaderText}
              updateNoteBookString={setNotebookString}
              commitMessage={commitMessage}
              updateCommitMessage={setCommitMessage}
            />
          </Grid>
        </Grid>
      </Card>
      <LucidPopUp
        showCloseIcon
        closePopup={setShowPopUp}
        openPopUp={showPopUp}
        onConfirm={handleNext}
        headingText=""
        confirmText="Commit"
      >
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <Link href={'https://github.com/Lucid-Data-Hub/gen-datalake-dev'} target="blank">
              Git Hub Repository
            </Link>
          </Grid>
          <Grid item xs={12}>
            <TextField
              sx={{ fontSize: 12 }}
              fullWidth
              id="outlined-basic"
              label="Enter Commit Message"
              variant="outlined"
              color="secondary"
              required
              rows={2}
              multiline
              value={commitMessage}
              onChange={({ target }) => onTextChange('commitMessage', target.value)}
            />
          </Grid>
        </Grid>
      </LucidPopUp>
    </>
  )
}

export default GenerateMeasureNotebook
