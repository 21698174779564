import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Tabs,
  Tab,
  Tooltip,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { cardContainer } from '../../styles/globalStyles'
import ActionPage from '../../ui-components/ActionPage'
import { LucidDataGrid } from '../../ui-components/LucidDataTable/LucidDatatable'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useGetAllDataPods } from '../../hooks/datapodHooks'
import { useGetAllDataSystems, useGetAllDataSystemsByPodId } from '../../hooks/dataSystemHooks'
import { DataSystem } from '../../businessObjects'
import { useGetAllDataTables, useGetDataTables } from '../../hooks/dataTableHooks'
import { TableColumnCard } from './TableColumnCards'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import { ROUTES } from '../../Routes/constants'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

type Props = {}

export const SourceDataModels = (props: Props) => {
  const { dataPodId, datasystemIDs } = useParams<{ dataPodId: string; datasystemIDs: string }>()

  const [value, setValue] = React.useState(0)
  const [dataSourceIds, setDataSourceIds] = useState<number[]>([])
  const [dataSystems, setDataSystems] = useState<DataSystem[]>([])
  const [getDataPod, dataPodInfo] = useGetAllDataPods({})
  const [allDataSystems] = useGetAllDataSystemsByPodId({}, dataPodId)
  const navigate = useNavigate()
  useEffect(() => {
    if (dataPodId) {
      getDataPod(dataPodId)
    }
  }, [dataPodId])

  useEffect(() => {
    if (datasystemIDs) {
      const ids = datasystemIDs.split(',')

      setDataSourceIds(ids.map((id) => Number(id)))
    }
  }, [datasystemIDs])

  useEffect(() => {
    if (allDataSystems) {
      const selectedDS = allDataSystems.filter((ds) => dataSourceIds.includes(ds.id))
      setDataSystems(selectedDS)
    }
  }, [allDataSystems])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const onClickBack = () => {
    const dataProfilingRoute = generatePath(ROUTES.DataSystemProfiling, { dataPodId })
    navigate(dataProfilingRoute)
  }
  return (
    <ActionPage>
      <Box>
        <Grid container alignItems="baseline">
          <Grid item xs={0}>
            <IconButton onClick={onClickBack}>
              <KeyboardBackspaceOutlinedIcon fontSize="small" />
            </IconButton>
          </Grid>

          <Grid item xs={4}>
            <Typography sx={{ fontSize: 16 }} color={'text.primary'}>
              Source Data Models
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box style={cardContainer}>
        <Grid container spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Card sx={{ height: 111, maxWidth: 'calc(100% - 15px)%' }}>
                <CardContent>
                  <Grid item xs={12} />
                  <Typography component="span" variant="h3" sx={{ fontSize: 16 }}>
                    Data Pod Information
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography component="span" variant="h3" sx={{ fontSize: 12 }}>
                        Data Pod id:
                      </Typography>
                      <Typography sx={{ fontSize: 12, ml: 4, color: '#7E7F97' }}>
                        {dataPodInfo ? dataPodInfo[0].id : undefined}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography component="span" variant="subtitle2" sx={{ fontSize: 12 }}>
                        Data Pod Name:
                      </Typography>
                      <Typography sx={{ fontSize: 12, ml: 2, color: '#7E7F97' }}>
                        {dataPodInfo ? dataPodInfo[0].name : undefined}
                      </Typography>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} />
          </Grid>

          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ bgcolor: 'background.paper', display: 'flex' }}>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: 'divider', width: 700, display: 'block' }}
                >
                  {dataSystems.map((ds, ix) => (
                    <Tab
                      key={ix}
                      label={
                        <>
                          {ds.dataSystemName.length > 25 ? (
                            <Tooltip title={ds.dataSystemName} placement="top-start">
                              <Typography sx={{ fontSize: 14 }}>{ds.dataSystemName.slice(0, 20) + '...'}</Typography>
                            </Tooltip>
                          ) : (
                            <Typography sx={{ fontSize: 14 }}>{ds.dataSystemName}</Typography>
                          )}
                        </>
                      }
                      {...a11yProps(ix)}
                    />
                  ))}
                </Tabs>

                {dataSystems.map((ds, ix) => (
                  <TabPanel key={ds.id} value={value} index={ix}>
                    <Grid item container xs={10} direction={'row'} spacing={2}>
                      <Grid item>
                        <Typography sx={{ fontSize: 18 }}>{ds.dataSystemName}</Typography>
                      </Grid>
                      <Grid item container xs={10}>
                        <DataSourceTable dataPodId={dataPodId} dataSystemId={ds.id} />
                      </Grid>
                    </Grid>
                  </TabPanel>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ActionPage>
  )
}

type DataSourceTableProps = {
  dataSystemId: number
  dataPodId?: string
}
export const DataSourceTable = ({ dataSystemId, dataPodId }: DataSourceTableProps) => {
  const { dataTables } = useGetAllDataTables(dataPodId, dataSystemId)
  const selectedDSTable = useMemo(() => {
    if (!dataTables) return []
    return dataTables
  }, [dataTables])
  return (
    <Grid container spacing={2}>
      {selectedDSTable.map((tb) => (
        <Grid item xs={4} key={tb.id}>
          <TableColumnCard tableId={tb.id} tableName={tb.name} />
        </Grid>
      ))}
    </Grid>
  )
}
