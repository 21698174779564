import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Autocomplete,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useAssignRole, useGetRoles, useGetUsers, useGetUsersByRole } from '../../../hooks/rolesHooks';
import { Role, User } from '../../../businessObjects';

const ManageRoles = () => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [users, setUsers] = useState<User[]>([]);

  const { allRoles } = useGetRoles();
  const { allUsers } = useGetUsers();
  const { assignRole } = useAssignRole();
  const { getUsersByRole } = useGetUsersByRole();
 
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedRoleId, setSelectedRoleId] = useState<number | undefined>(0);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (selectedUser && selectedRoleId) {
        try {
            const status = await assignRole(selectedUser.id, selectedRoleId);
        } catch (error) {
            console.log("Error assigning role:", error);
        }
    }
    setOpenDialog(false);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  useEffect(() => {
    if (allRoles?.length) {
      setRoles(allRoles);
    }
    if (allUsers?.length) {
      setUsers(allUsers);
    }
  }, [allRoles, allUsers]);

  const filteredUsers = users.filter(user =>
    user.email.toLowerCase().includes(searchString.toLowerCase())
  );

  const onAccordionExpand = async (role: Role, index: number) => {
    if(role.users != undefined) 
      return;

    const userIds: number[] = await getUsersByRole(role.id);
    
    role.users = [];
    userIds.forEach(id => {
      const user = users.find(user => user.id === id);
      if (user) {
        role.users.push(user);
      }
    });    
    
    setSelectedRoleId(role.id);
  };

  return (
    <div>
      <Button onClick={() => setOpenDialog(true)}>+ Add role assignment</Button>
      {roles.map((role: Role, index: number) => (
        <Accordion key={index} onChange={() => onAccordionExpand(role, index)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <div>{role.roleName}</div>
          </AccordionSummary>
          <AccordionDetails>
            <div>{role.roleDescription}</div>
              <div>
                <strong>Users:</strong>
                <ul>
                  {role.users?.map(user => (
                    <li key={user.id}>{user.email}</li>
                  ))}
                </ul>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Assign Role</DialogTitle>
        <DialogContent>
          <form id="addUserForm" onSubmit={handleFormSubmit}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <Autocomplete
                  id="email"
                  options={searchString.trim() === '' ? [] : filteredUsers}
                  getOptionLabel={(option) => option.email}
                  value={selectedUser}
                  onChange={(event, newValue) => {
                    setSelectedUser(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Email Address"
                      type="text"
                      fullWidth
                      onChange={(event) => setSearchString(event.target.value)}
                      required
                    />
                  )}
                  
                />
            </FormControl>
            <FormControl variant="filled" fullWidth sx={{ m: 1 }}>
              <InputLabel id="role_select">Role *</InputLabel>
              <Select 
                labelId="role_select"
                label="Role *"
                value={selectedRoleId}
                onChange={(event) => setSelectedRoleId(Number(event.target.value))}
                
                required
              >
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>{role.roleName}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <DialogActions>
              <Button type="submit" form="addUserForm" disabled={!selectedUser || !selectedRoleId}>
                Add
              </Button>
              <Button onClick={handleCloseDialog}>Cancel</Button>
            </DialogActions> 
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ManageRoles;
