import { useEffect, useMemo } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { ROUTES } from "../Routes/constants"
import { getSessionPodId } from "../utils/tokens"
import { useGetDataPodInfo } from "./datapodHooks"

export const useGetSessionPodInfo = () => {
    const { dataPodId } = useParams<{ dataPodId: string }>()
    const sessionPodId = !getSessionPodId() ? dataPodId : getSessionPodId()
  
    const { dataPodInfo } = useGetDataPodInfo(sessionPodId)
  
    const navigate = useNavigate()
  
    useEffect(() => {
      if (!sessionPodId) {
        navigate(ROUTES.DataPods)
      }
    }, [sessionPodId])
    return useMemo(() => {
        if(dataPodInfo) return dataPodInfo
    },[dataPodInfo])
}